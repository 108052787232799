.trx-modal-main-wrapper {
	.raven-modal-content-wrap {
		width: 52.1rem;
		max-height: 95vh;
	}

	.content {
		overflow: hidden;
	}

	.details-modal-wrap {
		display: flex;
		flex: 1;
		flex-direction: column;
		overflow: hidden;

		&__title {
			color: #000;
			font-size: 2rem;
			font-style: normal;
			font-weight: 600;
			margin-bottom: 0.8rem;
		}

		.badge {
			display: flex;
			align-items: center;
			height: 2.5rem;
			// width: 1rem;
			border-radius: 7.2rem;
			padding: 0.6rem 1.2rem;
			gap: 0.6rem;

			&--credit {
				background: #1ace37;
				text-transform: capitalize;

				figure {
					width: 2.5rem;
					height: min-content;
				}

				p {
					color: white;
				}
			}

			&--debit {
				background: #ff0f00;
				text-transform: capitalize;

				figure {
					width: 1.75rem;
					height: 1.75rem;
				}

				p {
					color: white;
				}
			}

			&--successful {
				background: #1ace37;
				text-transform: capitalize;

				p {
					color: white;
				}
			}

			&--failed {
				background: #ff0f00;
				text-transform: capitalize;

				p {
					color: white;
				}
			}

			p {
				font-weight: 600;
				font-size: 1.2rem;
			}

			figure {
				// width: 2rem;
				// height: 2rem;
				display: grid;
				place-items: center;

				svg {
					width: 100%;
					height: 100%;
				}
			}
		}

		&__content {
			border-radius: 1.2rem;
			margin-bottom: 3rem;
			display: flex;
			width: 100%;
			flex-direction: column;
			overflow: hidden;

			.content__title {
				display: flex;
				width: 100%;
				justify-content: space-between;

				span {
					h5 {
						font-weight: 600;
						font-size: 2rem;
						display: flex;
						align-items: center;
						// color: #000000;
					}

					p {
						font-weight: 400;
						font-size: 1.4rem;
						display: flex;
						align-items: center;
						color: #676767;
					}
				}

				figure {
					display: grid;
					place-items: center;
					width: 3.5rem;
					height: 3.5rem;

					svg {
						width: 100%;
						height: 100%;
					}
				}
			}

			.content__main {
				display: flex;
				flex-direction: column;
				overflow-y: hidden;

				.main__label {
					font-size: 1.6rem;
					display: flex;
					align-items: center;
					margin-top: 3.2rem;
					// color: #000000;
					margin-bottom: 1rem;
				}

				.main__account-details {
					display: flex;
					flex-direction: column;
					border-radius: 1.2rem;
					width: 100%;
					position: relative;
					height: 100%;
					max-height: 100rem;
					transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
					overflow-y: auto;

					&.not-collapsed {
						border: 0.7px solid #e5e6e6;
					}

					.panel {
						position: absolute;
						width: 100%;
						height: 100%;
						transition: transform 0.3s ease;
						transform: translateX(100%);

						&.active {
							transform: translateX(0);
						}
					}

					.account-details__item {
						display: flex;
						padding: 2.2rem 1.6rem;
						width: 100%;
						overflow: hidden;
						min-height: 6rem;

						&.address {
							flex-direction: column;
							width: 100%;
							gap: 0.8rem;
							p {
								font-size: 1.2rem;
								width: 100%;
							}
							span {
								width: 100%;
								p {
									color: #676767;
									font-size: 1.2rem;
									width: 100%;
								}
							}
						}

						.item__value {
							flex: 1;
							p {
								// inline-size: min-content;
								// overflow-wrap: break-word;
								width: 100%;
								min-width: 80%;
								word-break: break-all;
							}
						}

						p {
							font-weight: 400;
							font-size: 1.4rem;
							display: flex;
							// padding-right: 1rem;
							align-items: center;
							// color: #000000;
						}

						&:not(:last-child) {
							border-bottom: 0.6px solid #e5e6e6;
						}

						p:first-child {
							width: 40%;
						}

						p:last-child {
							// width: min-content;
							width: 100%;
							overflow: hidden;
						}

						span {
							display: flex;
							width: 60%;
							justify-content: space-between;
							align-items: center;

							figure {
								display: grid;
								place-items: center;
								width: 2.5rem;
								cursor: pointer;
								height: auto;
								svg {
									width: 100%;
									height: 100;
								}
							}
						}
					}
				}
			}
		}

		.meta_preview_wrapper {
			display: flex;
			flex-direction: column;
			width: 100%;
			margin-top: -2rem;
			padding: 1rem;
			border-radius: 1.2rem;

			&.expanded {
				margin-top: 2rem;
				border: 0.7px solid #e5e6e6;
			}

			.collapse-icon {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				cursor: pointer;
			}
			.meta_preview {
				max-height: 50rem;
				transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
			}
		}

		.collapsed {
			max-height: 0 !important;
			overflow-x: hidden;
			overflow-y: hidden;
			transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
			-webkit-transition: max-height 0.3s;
			-moz-transition: max-height 0.3s;
		}
	}

	.trx-modal__button-wrap {
		display: flex;
		gap: 1.2rem;
		button {
			display: flex;
			align-items: center;
			gap: 1rem;
			background: var(--raven-black-50, #f7f7f7);
			border: none;
			box-shadow: none;
			margin-top: 2rem;
			padding: 0.8rem 1.2rem;
			border-radius: 0.8rem;
			cursor: pointer;
			transition: transform 0.2s linear;

			&:hover {
				transform: translateY(-3px);
				figure {
					animation: scale 1s cubic-bezier(0.1, 0.5, 0.3, 1);

					@keyframes rotate {
						0% {
							transform: scale(1);
						}
						50% {
							transform: scale(1.2);
						}
						100% {
							transform: scale(1.2);
						}
					}
				}
			}

			figure {
				display: grid;
				place-items: center;
				width: 2rem;
				height: 2rem;

				svg {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
}
