.business-info-verification-wrap {
	width: 65%;
	min-width: 44rem;
	align-items: center;
	// background-color: red;
	margin-top: 3rem;
	margin: 0 auto;
	background: #ffffff;
	box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
		0px 48px 64px -36px rgba(51, 51, 51, 0.08);
	border-radius: 16px;
	padding: 4.8rem 2.4rem;

	.business-info-verification-wrap__title {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		margin-bottom: 3rem;

		.title__sub {
			color: #676767;
			font-size: 1.4rem;
			font-weight: 400;
		}

		.title__big {
			font-size: 2.1rem;
			color: #1b1b1b;
			font-weight: 700;
		}
	}

	.business-info-verification-wrap__form {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 2.8rem;

		.form__group {
			width: 100%;
			gap: 1.6rem;
			display: flex;
		}
	}

	.business-info-verification-wrap__button {
		margin-top: 10rem;
		width: 100%;
	}
}
