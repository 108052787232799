.issuing .table__main .table__card-pan {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}
.issuing .table__main .table__card-pan figure {
  width: 3.5rem;
  height: auto;
}
.issuing .table__main .table__card-pan figure img {
  width: 100%;
  height: 100%;
}

.virtual {
  border-radius: 72px;
  background: var(--raven-blue-shade-20, #e9f5ff);
  color: var(--raven-accent-blue, #476885);
  text-transform: capitalize;
}/*# sourceMappingURL=index.css.map */