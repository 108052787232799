.loader-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	place-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	// flex: 1;

	figure {
		width: 15rem;
		height: 15rem;
		// background-color: red;
	}

	p {
		font-size: 1.2rem;
		margin-top: -1.8rem;
	}
}
