.bulk_transfer {
	// width: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 2rem;

	.bulk_transfer {
		&__arrow-back {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 2rem;
			height: 2rem;
			transition: (transform, margin) 300ms ease-in-out;
			cursor: pointer;

			&:hover {
				// transform: translateX(-3px);
				margin-left: 4px;
			}

			svg {
				width: 100%;
				height: 100%;

				path {
					stroke: black;
				}
			}
		}

		&__title-contain {
			display: flex;
			flex-direction: column;
			margin-top: 2rem;
			margin-bottom: 1.4rem;

			small {
				font-size: 1.4rem;
				display: flex;
				align-items: center;
				color: #676767;
			}

			h5 {
				font-weight: 600;
				font-size: 2rem;
				display: flex;
				align-items: center;
				color: #000000;
			}
		}

		&__upload-container {
			display: flex;
			flex-direction: column;
			gap: 1.6rem;
			margin-top: 1.4rem;
			width: 100%;

			.upload-container-select__item {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				padding: 1.5rem 1.8rem;
				border-radius: 0.8rem;
				cursor: pointer;
				flex-direction: column;
				width: 100%;
				border: 1px solid var(--primary-grey-shade);
				gap: 0.7rem;

				figure {
					display: grid;
					place-items: center;
					width: 1.75rem;
					height: 1.75rem;

					svg {
						width: 100%;
						height: 100%;
					}
				}

				.item__span {
					display: flex;
					width: 100%;
					justify-content: space-between;
					align-items: center;

					small {
						color: #676767;
						font-weight: 400;
						font-size: 1.2rem;
					}
				}

				.item__active {
					font-size: 1.4rem;
					font-weight: 600;
					color: #1ace37;
				}

				&.active {
					border: 1.6px solid #014345;
					background: #e9f5ff;
					p {
						font-weight: 600;
						font-size: 1.4rem;
						line-height: 140%;
						color: #014345;
					}
				}

				&:not(.active) {
					display: flex;
					gap: 0.7rem;
				}
			}

			.upload-container-select__confirm-business {
				border: 1px dashed #e5e6e6;
			}

			p {
				font-weight: 400;
				font-size: 1.4rem;
				line-height: 140%;
				color: black;
			}

			.upload-container-select__upload {
				display: flex;
				flex-direction: column;
				.upload__file-size {
					font-weight: 400;
					font-size: 1.2rem;
					margin-top: 1.2rem;
					color: #1b1b1b;
				}
			}
		}

		&__hint-wrap {
			display: flex;
			background: #e9f5ff;
			border-radius: 1.2rem;
			padding: 2rem;
			flex-direction: column;
			margin-top: 2rem;

			.title {
				font-weight: 700;
				font-size: 1.4rem;
				display: flex;
				margin-bottom: 2rem;
				align-items: center;
				color: #1b1b1b;
			}

			span {
				display: flex;
				flex-direction: column;
				gap: 1.4rem;

				p {
					font-weight: 400;
					font-size: 1.2rem;
					color: #476885;
				}
			}
		}

		&__button-group {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 1.7rem;
			margin-top: 4.8rem;

			.button-group__buttons {
				width: 100%;
			}
		}
	}
}

.bulk-transfer__table-wrap {
	display: flex;
	flex-direction: column;
	padding: 0 2rem;
	flex: 1;

	.table-wrap__add-row-btn {
		background: #e9f5ff;
		display: flex;
		margin-top: 2rem;
		align-items: center;

		figure {
			width: 1.5rem;
			height: 1.5rem;

			svg {
				width: 100%;
				height: 100%;
			}
		}

		p {
			color: #014345;
		}
	}

	.transaction-status {
		display: grid;
		place-items: center;
		background-color: aliceblue;
		padding: 0.8rem 1.2rem;
		width: fit-content;
		border-radius: 7.2rem;

		p {
			font-weight: 600;
			font-size: 1.2rem;
		}

		&.pending {
			background-color: #fff6ed;
			text-transform: capitalize;

			p {
				color: #ea872d;
			}
		}

		&.successful {
			background-color: #e8fff6;
			text-transform: capitalize;

			p {
				color: #1ace37;
			}
		}

		&.error {
			background-color: #fff5f5;
			text-transform: capitalize;

			p {
				color: #ff0f00;
			}
		}
	}

	.table__main {
		.account_name {
			display: flex;
			align-items: center;
			gap: 1.6rem;
			span {
				background-color: #e9f5ff;
				display: grid;
				place-items: center;
				width: 4rem;
				height: 4rem;
				border-radius: 7.5rem;

				p {
					font-weight: 600;
					font-size: 1.2rem;
					display: flex;
					align-items: center;
					text-align: center;
					color: #014345;
				}
			}
		}
		.account_name--placeholder {
			padding: 0.8rem 1.2rem;
			/* RAVEN/Grey shade 10 */
			width: max-content;
			background: #f7f8f7;
			border-radius: 7.2rem;

			p {
				background: #cccccc;
				padding: 0.5rem 3rem;
				border-radius: 2.4rem;
			}
		}
	}
}

.table-container {
	height: 100%;
	width: 100%;
	max-width: 90%;
	flex-direction: column;
	// background-color: red;

	&__arrow-back {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 2rem;
		height: 2rem;
		transition: (transform) 300ms ease-in-out;
		cursor: pointer;
		// padding: 2rem;
		margin-top: 2rem;
		margin-left: 2rem;

		&:hover {
			transform: translateX(-3px);
			// margin-left: 1px;
		}

		svg {
			width: 100%;
			height: 100%;

			path {
				stroke: black;
			}
		}
	}

	.content__main {
		display: flex;
		width: 100%;
		overflow-y: scroll;
		flex: 1;
		flex-direction: column;

		&::-webkit-scrollbar {
			display: none;
		}

		&--top-bar {
			display: flex;
			align-items: center;

			padding: 2rem;

			width: 100%;
			justify-content: space-between;

			.top-bar__title {
				display: flex;
				align-items: center;
				gap: 2.4rem;

				h5 {
					font-weight: 600;
					font-size: 16px;
					line-height: 140%;
					/* or 22px */

					display: flex;
					align-items: center;

					/* RAVEN/Base Black */

					color: #1b1b1b;
				}

				p {
					display: none;
					&.active {
						display: grid;
						place-items: center;
						padding: 0.8rem 1.2rem;
						background: #f7f8f7;
						border-radius: 7.2rem;
						font-weight: 600;
						font-size: 1.2rem;
						line-height: 140%;
						text-align: center;
						color: #014345;
					}
				}
			}
		}

		.main__active-filters {
			width: 100%;
			justify-content: space-between;
			align-items: center;
			overflow: hidden;
			max-height: 0;
			display: flex;
			transition: max-height 300ms ease-in-out;

			&--show {
				display: flex;
				overflow: unset;
				max-height: 30rem;
				transition: max-height 300ms ease-in-out;
			}

			.active-filters__item {
				background: #e9f5ff;
				border-radius: 7.2rem;
				display: flex;
				padding: 0.55rem 0.7rem;
				align-items: center;
				gap: 1rem;
				margin-top: 1rem;
				margin-bottom: 2rem;

				figure {
					width: 1.75rem;
					height: 1.75rem;
					display: grid;
					place-items: center;
					cursor: pointer;

					svg {
						width: 100%;
						height: 100%;
					}
				}

				p {
					color: #476885;
					display: flex;
					align-items: center;
					gap: 0.6rem;
				}
			}

			.active-filters__clear-item {
				background: #fff5f5;
				border-radius: 72px;
				border-radius: 7.2rem;
				display: flex;
				padding: 0.55rem 0.7rem;
				align-items: center;
				cursor: pointer;

				p {
					font-weight: 600;
					font-size: 1.2rem;
					color: #ff0f00;
				}
			}
		}

		.main__wrap {
			display: flex;
			margin-top: 2rem;
			width: 100%;
			height: 100%;
			// background-color: red;
			overflow: hidden;

			.transaction__table {
				width: 100%;
				// background-color: yellow;
				height: 100%;
				border-radius: 1.2rem;
				overflow: auto;

				&::-webkit-scrollbar {
					display: none;
				}

				.table__main {
					border-radius: 1.2rem;
					overflow: auto;

					.main__direction {
						display: flex;
						align-items: center;
						gap: 0.8rem;

						figure {
							display: grid;
							place-items: center;
							width: 2.75rem;
							height: 2.75rem;

							svg {
								width: 100%;
								height: 100%;
							}
						}
					}
				}
			}
		}
	}

	&__buttons {
		display: flex;
		align-items: flex-end;
		width: 100%;
		justify-content: flex-end;
		margin-top: 0.4rem;
		gap: 1.6rem;
		padding: 2rem;

		.reupload {
			background: #e9f5ff;
			color: black;
		}
	}
}

.table-container-manual {
	height: 100%;
	width: 100%;
	border-radius: 1.2rem;
	flex-direction: column;
	background-color: white;

	&__arrow-back {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 2rem;
		height: 2rem;
		transition: (transform) 300ms ease-in-out;
		cursor: pointer;
		// padding: 2rem;
		margin-top: 2rem;
		margin-left: 2rem;

		&:hover {
			transform: translateX(-3px);
			// margin-left: 1px;
		}

		svg {
			width: 100%;
			height: 100%;

			path {
				stroke: black;
			}
		}
	}

	.content__main {
		display: flex;
		width: 100%;
		overflow-y: scroll;
		flex: 1;
		flex-direction: column;

		&::-webkit-scrollbar {
			display: none;
		}

		&--top-bar {
			display: flex;
			align-items: center;

			padding: 2rem;

			width: 100%;
			justify-content: space-between;

			.top-bar__title {
				display: flex;
				align-items: center;
				gap: 2.4rem;

				h5 {
					font-weight: 600;
					font-size: 16px;
					line-height: 140%;
					/* or 22px */

					display: flex;
					align-items: center;

					/* RAVEN/Base Black */

					color: #1b1b1b;
				}

				p {
					display: none;
					&.active {
						display: grid;
						place-items: center;
						padding: 0.8rem 1.2rem;
						background: #f7f8f7;
						border-radius: 7.2rem;
						font-weight: 600;
						font-size: 1.2rem;
						line-height: 140%;
						text-align: center;
						color: #014345;
					}
				}
			}
		}

		.main__active-filters {
			width: 100%;
			justify-content: space-between;
			align-items: center;
			overflow: hidden;
			max-height: 0;
			display: flex;
			transition: max-height 300ms ease-in-out;

			&--show {
				display: flex;
				overflow: unset;
				max-height: 30rem;
				transition: max-height 300ms ease-in-out;
			}

			.active-filters__item {
				background: #e9f5ff;
				border-radius: 7.2rem;
				display: flex;
				padding: 0.55rem 0.7rem;
				align-items: center;
				gap: 1rem;
				margin-top: 1rem;
				margin-bottom: 2rem;

				figure {
					width: 1.75rem;
					height: 1.75rem;
					display: grid;
					place-items: center;
					cursor: pointer;

					svg {
						width: 100%;
						height: 100%;
					}
				}

				p {
					color: #476885;
					display: flex;
					align-items: center;
					gap: 0.6rem;
				}
			}

			.active-filters__clear-item {
				background: #fff5f5;
				border-radius: 72px;
				border-radius: 7.2rem;
				display: flex;
				padding: 0.55rem 0.7rem;
				align-items: center;
				cursor: pointer;

				p {
					font-weight: 600;
					font-size: 1.2rem;
					color: #ff0f00;
				}
			}
		}

		.main__wrap {
			display: flex;
			margin-top: 2rem;
			width: 100%;
			height: 100%;
			// background-color: red;
			overflow: hidden;

			.transaction__table {
				width: 100%;
				// background-color: yellow;
				height: 100%;
				border-radius: 1.2rem;
				overflow: auto;

				&::-webkit-scrollbar {
					display: none;
				}

				.table__main {
					border-radius: 1.2rem;
					overflow: auto;

					.main__direction {
						display: flex;
						align-items: center;
						gap: 0.8rem;

						figure {
							display: grid;
							place-items: center;
							width: 2.75rem;
							height: 2.75rem;

							svg {
								width: 100%;
								height: 100%;
							}
						}
					}
				}
			}
		}
	}

	&__buttons {
		display: flex;
		align-items: flex-end;
		width: 100%;
		justify-content: flex-end;
		margin-top: 0.4rem;
		gap: 1.6rem;
		padding: 2rem;
		border-top: 1px solid #e5e6e6;

		.reupload {
			background: #e9f5ff;
			color: black;
		}
	}
}

.bulk-transfer-summary {
	display: flex;
	flex-direction: column;
	padding: 2rem;
	flex: 1;

	&__arrow-back {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 2rem;
		height: 2rem;
		transition: (transform, margin) 300ms ease-in-out;
		cursor: pointer;

		&:hover {
			// transform: translateX(-3px);
			margin-left: 4px;
		}

		svg {
			width: 100%;
			height: 100%;

			path {
				stroke: black;
			}
		}
	}

	&__title {
		display: flex;
		flex-direction: column;
		margin-top: 2rem;
		margin-bottom: 1.4rem;

		p {
			font-size: 1.4rem;
			display: flex;
			align-items: center;
			color: #676767;
		}

		h5 {
			font-weight: 600;
			font-size: 2rem;
			display: flex;
			align-items: center;
			color: #000000;
		}
	}

	&__table {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-top: 3rem;

		.table__item {
			width: 100%;
			padding: 1rem 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid #eeeeee;

			p {
				font-weight: 400;
				font-size: 1.4rem;
				line-height: 140%;
				display: flex;
				align-items: center;
				color: #000000;
			}
		}
	}

	&__button {
		width: 100%;
		margin-top: 6rem;

		.act-btn {
			width: 100%;
		}
	}
}
