.business-profile {
	display: flex;
	flex-direction: column;
	flex: 1;
	border-radius: 12px;
	border: 1px solid var(--ravengreyshade-50, #e5e6e6);
	height: 100%;
	padding: 2.4rem;

	&__owner {
		display: flex;
		flex-direction: column;
		align-items: start;
		gap: 1.6rem;

		span {
			display: flex;
			flex-direction: column;
			gap: 1.2rem;

			h5 {
				font-weight: 700;
				font-size: 2.4rem;
			}

			p {
				overflow: hidden;
				color: var(--raven-base-bg-dark, #676767);
				text-overflow: ellipsis;
				font-size: 1.4rem;
			}
		}
		figure {
			width: 6.4rem;
			height: 6.4rem;
			display: grid;
			place-items: center;
			object-fit: contain;
			overflow: hidden;
			border-radius: 50%;

			.business-profile__logo-text {
				background-color: #f7f7f7;
				border-radius: 50%;
				width: 6.4rem;
				height: 6.4rem;
				place-items: center;
				display: grid;
				p {
					text-transform: uppercase;
					color: var(--primary-darkgreen-100, #014345);
					text-align: center;
					font-size: 2.4rem;
					font-weight: 700;
				}
			}

			img {
				width: 10rem;
				object-fit: cover;
				object-position: top center;
				height: 10rem;
			}
		}
	}

	&__details {
		display: flex;
		flex: 1;
		width: 100%;
		margin-top: 3.2rem;
		border-radius: 10px;
		border: 0.6px solid var(--raven-black-200, #e3e3e3);
		padding: 1.6rem;

		div {
			display: flex;
			flex-direction: column;
			gap: 4rem;

			span {
				display: flex;
				flex-direction: column;
				gap: 1rem;
				small {
					color: var(--raven-base-bg-dark, #676767);
					font-size: 1.2rem;
					font-weight: 500;
				}

				p {
					color: var(--raven-base-background-dark, #020202);
					font-size: 1.4rem;
				}
			}
		}

		div:first-child {
			flex: 0 0 30%;
		}

		div:last-child {
			flex: 0 0 30%;
		}
	}
}

// ==== styles for verifications

.verification-settings {
	display: flex;
	flex-direction: column;
	border-radius: 1.2rem;
	flex: 1;
	border: 1px solid var(--ravengreyshade-50, #e5e6e6);
	padding: 2.4rem;
	height: 100%;
	overflow-y: auto;

	&__title {
		display: flex;
		flex-direction: column;
		margin-bottom: 3.3rem;

		h5 {
			font-size: 2.4rem;
			font-weight: 700;
		}

		p {
			font-size: 1.4rem;
			color: var(--raven-base-bg-dark, #676767);
		}
	}

	&__main {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		width: 100%;

		.main__items {
			display: flex;
			flex-direction: row;
			border-radius: 12px;
			border: 1px solid #e5e6e6;
			background: var(--raven-base-white, #fff);
			padding: 1.6rem;
			width: 100%;
			justify-content: space-between;

			.items__grouped {
				display: flex;
				align-items: center;
				gap: 1.3rem;

				figure {
					width: 8rem;
					height: 8rem;
					display: grid;
					place-items: center;

					svg {
						width: 100%;
						height: 100%;
					}
				}

				div {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					flex-direction: row;

					span {
						display: flex;
						flex-direction: column;
						gap: 0.5rem;

						h5 {
							color: var(--raven-base-background-dark, #020202);
							font-size: 1.6rem;
							line-height: 140%;
							font-weight: 700;
						}

						p {
							color: var(--raven-base-bg-dark, #676767);
							font-size: 1.2rem;
							line-height: 140%;
						}
					}
				}

				span {
					display: flex;
					flex-direction: column;
					gap: 0.5rem;

					h5 {
						color: var(--raven-base-background-dark, #020202);
						font-size: 1.6rem;
						line-height: 140%;
						font-weight: 700;
					}

					p {
						color: var(--raven-base-bg-dark, #676767);
						font-size: 1.2rem;
						line-height: 140%;
					}
				}
			}

			.items__status {
				display: flex;
				height: 2.8rem;
				padding: 0.8rem 1.2rem;
				border-radius: 72px;
				justify-content: center;
				align-items: center;
				gap: 4px;

				figure {
					width: 1.5rem;
					height: 1.5rem;
					place-items: center;

					svg {
						width: 100%;
						height: 100%;
					}
				}

				&.verified {
					background: var(--ravengreenshade-30, #e8fff6);

					p {
						color: var(--ravensuccess, #1ace37);
					}
				}

				&.failed {
					background: var(--raven-error-10, #fff5f5);
					p {
						color: var(--raven-error-color, #ff0f00);
					}
				}

				&.pending {
					background: var(--raven-error-10, #fff6ed);
					p {
						color: var(--raven-error-color, #ea872d);
					}
				}

				&.review {
					background: var(--raven-error-10, #e9f5ff);
					p {
						color: var(--raven-error-color, #476885);
					}
				}

				p {
					text-align: center;
					font-size: 1.2rem;
					line-height: 140%;
				}
			}
		}
	}
}

.webhook-settings {
	display: flex;
	flex-direction: column;
	border-radius: 1.2rem;
	flex: 1;
	height: 100%;
	border: 1px solid var(--ravengreyshade-50, #e5e6e6);
	padding: 2.4rem;

	&__title {
		display: flex;
		flex-direction: column;
		margin-bottom: 3.3rem;

		h5 {
			font-size: 2.4rem;
			font-weight: 700;
		}

		p {
			font-size: 1.4rem;
			color: var(--raven-base-bg-dark, #676767);
		}
	}

	&__main {
		display: flex;
		gap: 2rem;
		width: 100%;

		.main__right,
		.main__left {
			display: flex;
			flex-direction: column;
			flex: 1;
			gap: 2.6rem;

			.left__input {
				display: flex;
				position: relative;

				.input__copy-icon {
					// opacity: 0.5;
					z-index: 200;
					position: absolute;
					top: 3.5rem;
					right: 1rem;
					// width: 2.3rem;
					// height: 2.3rem;
					display: flex;
					align-items: center;
					justify-content: center;
					place-items: center;

					figure {
						display: grid;
						place-items: center;
						width: 2.5rem;
						cursor: pointer;
						height: 2.5rem;
					}
				}
			}

			.form-group__deep-green-light .input-group .show-hide {
				display: none;
			}
		}
	}

	&__button {
		margin-top: 4.2rem;
	}
}

.payment-settings {
	display: flex;
	flex-direction: column;
	border-radius: 1.2rem;
	flex: 1;
	border: 1px solid var(--ravengreyshade-50, #e5e6e6);
	padding: 2.4rem;
	height: 100%;

	&__title {
		display: flex;
		flex-direction: column;
		margin-bottom: 3.3rem;

		h5 {
			font-size: 2.4rem;
			font-weight: 700;
		}

		p {
			font-size: 1.4rem;
			color: var(--raven-base-bg-dark, #676767);
		}
	}

	&__main {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		width: 100%;
		overflow-y: scroll;
		&::-webkit-scrollbar {
			display: none;
		}
		.main__items {
			display: flex;
			flex-direction: column;
			border-radius: 12px;
			border: 1px solid #e5e6e6;
			background: var(--raven-base-white, #fff);
			padding: 1.6rem;
			width: 100%;
			gap: 4.2rem;

			.items__grouped {
				display: flex;
				align-items: center;
				gap: 1.3rem;

				figure {
					width: 8rem;
					height: 8rem;
					display: grid;
					place-items: center;

					svg {
						width: 100%;
						height: 100%;
					}
				}

				div {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					flex-direction: row;

					span {
						display: flex;
						flex-direction: column;
						gap: 0.5rem;

						h5 {
							color: var(--raven-base-background-dark, #020202);
							font-size: 1.6rem;
							line-height: 140%;
							font-weight: 700;
						}

						p {
							color: var(--raven-base-bg-dark, #676767);
							font-size: 1.2rem;
							line-height: 140%;
						}
					}
				}
				span {
					display: flex;
					flex-direction: column;
					gap: 0.5rem;

					h5 {
						color: var(--raven-base-background-dark, #020202);
						font-size: 1.6rem;
						line-height: 140%;
						font-weight: 700;
					}

					p {
						color: var(--raven-base-bg-dark, #676767);
						font-size: 1.2rem;
						line-height: 140%;
					}
				}
			}

			.items__options {
				display: flex;
				flex-direction: row;
				gap: 2rem;

				.options__right,
				.options__left {
					display: flex;
					flex: 1 0 0;
					flex-direction: column;
					gap: 2rem;

					.right__item,
					.left__item {
						padding: 1.8rem;
						gap: 1.4rem;
						border-radius: 8px;
						display: flex;
						border: 1px solid var(--ravengreyshade-50, #e5e6e6);
						cursor: pointer;

						&.inactive {
							span {
								p {
									font-weight: 400;
								}
							}
						}

						&.active {
							border: 2px solid var(--raven-primary-dark-green, #014345);
						}
						span {
							display: flex;
							flex-direction: column;
							gap: 1rem;

							p {
								color: var(--raven-base-background-dark, #020202);
								font-size: 1.4rem;
								font-weight: 600;
								line-height: 140%;
							}

							small {
								color: var(--raven-base-bg-dark, #676767);
								font-size: 1.2rem;
								line-height: 140%;
							}
						}
					}
				}
			}
		}
	}
}

.settings-modal-wrap {
	display: flex;
	flex-direction: column;

	&__title {
		display: flex;
		flex-direction: column;
		gap: 0.4rem;
		margin-bottom: 2.8rem;
		h5 {
			font-size: 2.4rem;
			font-weight: 700;
		}

		p {
			color: var(--raven-base-bg-dark, #676767);
			font-size: 1.4rem;
		}
	}

	&__form {
		display: flex;
		flex-direction: column;
		gap: 2.4rem;
		margin-bottom: 5.8rem;

		.form__grouped {
			display: flex;
			align-items: center;
			gap: 1.6rem;

			input {
				flex: 1 0 0;
			}
		}
	}

	&__stepper {
		display: inline-flex;
		padding: 12px 14px;
		flex-direction: row;
		align-items: flex-start;
		gap: 10px;
		border-radius: 128px;
		margin-bottom: 3.6rem;
		width: min-content;
		background: var(--base-background, #f9f9f9);

		p {
			width: 2.4rem;
			height: 0.8rem;
			flex-shrink: 0;
			cursor: pointer;
			border-radius: 3.2rem;
			background: var(--raven-black-200, #e3e3e3);
		}

		p.active {
			width: 4.7rem;
		}
	}
}
