.login {
  position: relative;
  display: flex;
  flex-direction: column;
}
.login .login__atlas-logo {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 5.5rem;
}
.login .login__atlas-logo figure {
  border-right: 1px solid #e9f5ff;
  padding-right: 1.5rem;
}
.login .login__atlas-logo p {
  font-weight: 900;
  font-size: 2rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: var(--primary-blue);
}
.login .login__create-account {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 4rem;
}
.login .login__create-account h5 {
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #000000;
}
.login .login__create-account p {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: var(--border-grey-dark);
}
.login .login__form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.8rem;
  width: 100%;
}
.login .login__form f .form__grouped {
  display: flex;
  gap: 2rem;
  width: 100%;
}
.login .login__form .form__password {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1.6rem;
}
.login .login__form .form__password--forgot-password {
  color: var(--ravenprimarypurple, #755ae2);
  text-align: right;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
}
.login .login__form .form__action {
  margin-top: 0.4rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4rem;
}
.login .login__form .form__action .action__button {
  display: flex;
  align-items: center;
  gap: 4rem;
  width: 100%;
}
.login .login__form .form__action .action__button--submit {
  width: 100%;
}
.login .login__form .form__action .action__have-an-account {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.login .login__form .form__action .action__have-an-account p {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 160%;
  display: flex;
  align-items: center;
  color: var(--border-grey-dark);
}
.login .login__form .form__action .action__have-an-account span {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  color: var(--primary-purple);
  gap: 0.5rem;
  cursor: pointer;
  transition: transform 300ms ease-in-out;
}
.login .login__form .form__action .action__have-an-account span:hover {
  transform: translateX(3px);
}
.login .login__form .form__action .action__have-an-account span figure {
  display: grid;
  place-items: center;
}

.login__live-chat {
  position: absolute;
  bottom: 0;
  right: 6%;
}/*# sourceMappingURL=index.css.map */