.verification {
  width: 100vw;
  height: 100vh;
  color: black;
  display: flex;
  flex-direction: column;
  flex: column;
  position: relative;
  background-color: #f9fafb;
}
.verification .verification__header {
  display: flex;
  background-color: white;
  padding: 2rem 7rem;
  width: 100%;
  align-items: center;
  gap: 1.2rem;
  align-items: center;
  justify-content: flex-start;
}
.verification .verification__header .logo-text-box {
  display: flex;
  align-items: center;
  height: 100%;
}
.verification .verification__header .logo-text-box .text {
  color: var(--raven-black-700, #515151);
  font-weight: 600;
  font-size: 1.35rem;
  height: 100%;
  display: flex;
  align-self: center;
  place-items: center;
}
.verification .verification__header .logo-text-box .logo-box {
  margin-right: 1.2rem;
  padding-right: 1.2rem;
  height: 100%;
  align-items: center;
  display: flex;
  align-items: center;
  align-self: center;
  border-right: 0.13rem solid #e3e3e3;
}
.verification .verification__header .logo-text-box .logo-box .img-box {
  width: 10rem;
  height: -moz-min-content;
  height: min-content;
  display: flex;
  align-items: center;
}
.verification .verification__header .logo-text-box .logo-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.verification .verification__children {
  max-width: 40%;
  width: 100%;
  max-height: 75%;
  margin-top: 6.7rem;
  height: 100%;
  align-self: center;
}
.verification .verification__footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 6.4rem;
}
.verification .verification__footer .footer__left {
  display: flex;
  gap: 1.2rem;
}
.verification .verification__footer .footer__left p {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 140%;
  color: #1b1b1b;
}
.verification .verification__footer .footer__right {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  cursor: pointer;
}
.verification .verification__footer .footer__right figure {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white;
  place-items: center;
  border-radius: 50%;
}
.verification .verification__footer .footer__right figure svg {
  width: 100%;
  height: 100%;
}
.verification .verification__footer .footer__right p {
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 140%;
  color: #755ae2;
}/*# sourceMappingURL=styles.css.map */