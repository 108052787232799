.login {
	// background: red;

	position: relative;
	display: flex;
	flex-direction: column;
	// gap: 6.5rem;

	.login__atlas-logo {
		display: flex;
		align-items: center;
		gap: 1.5rem;
		margin-bottom: 5.5rem;

		figure {
			border-right: 1px solid #e9f5ff;
			padding-right: 1.5rem;
		}

		p {
			font-weight: 900;
			font-size: 2rem;
			line-height: 140%;
			display: flex;
			align-items: center;
			color: var(--primary-blue);
		}
	}
	.login__create-account {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 1rem;
		margin-bottom: 4rem;

		h5 {
			font-weight: 700;
			font-size: 2.4rem;
			line-height: 140%;
			display: flex;
			align-items: center;
			color: #000000;
		}
		p {
			font-weight: 400;
			font-size: 1.4rem;
			line-height: 140%;
			display: flex;
			align-items: center;
			color: var(--border-grey-dark);
		}
	}
	.login__form {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 2.8rem;
		width: 100%;
		f .form__grouped {
			display: flex;
			gap: 2rem;
			width: 100%;
		}

		.form__password {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			gap: 1.6rem;

			&--forgot-password {
				color: var(--ravenprimarypurple, #755ae2);
				text-align: right;
				font-size: 1.6rem;
				font-style: normal;
				font-weight: 600;
				// text-decoration-line: underline;
			}
		}
		.form__action {
			margin-top: calc(3.2rem - 2.8rem);
			display: flex;
			flex-direction: column;
			width: 100%;
			gap: 4rem;

			.action__button {
				display: flex;
				align-items: center;
				gap: 4rem;
				width: 100%;

				&--submit {
					width: 100%;
				}
			}

			.action__have-an-account {
				display: flex;
				gap: 1rem;
				align-items: center;

				p {
					font-weight: 400;
					font-size: 1.6rem;
					line-height: 160%;
					display: flex;
					align-items: center;
					color: var(--border-grey-dark);
				}

				span {
					font-weight: 700;
					font-size: 1.6rem;
					line-height: 140%;
					display: flex;
					align-items: center;
					display: flex;
					align-items: center;
					color: var(--primary-purple);
					gap: 0.5rem;
					cursor: pointer;
					transition: transform 300ms ease-in-out;

					&:hover {
						transform: translateX(3px);
					}

					figure {
						display: grid;
						place-items: center;
					}
				}
			}
		}
	}
}

.login__live-chat {
	position: absolute;
	bottom: 0;
	right: 6%;
}
