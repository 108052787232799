.team-invite-wrap-container {
  display: flex;
  flex-direction: column;
}
.team-invite-wrap-container .top-wrap {
  align-self: center;
}
.team-invite-wrap-container .white-content-card {
  margin-top: 5rem;
  width: 100%;
  min-height: 35rem;
  background-color: #ffffff;
  border-radius: 0.8rem;
  box-shadow: 0.1rem 0.1rem 8rem 0.4rem rgba(51, 51, 51, 0.08);
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem;
  flex: 1;
  gap: 2rem;
}
.team-invite-wrap-container .white-content-card .role-email-box-wrap {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.team-invite-wrap-container .white-content-card .role-email-box-wrap .badge-box {
  padding: 1rem 2rem;
  border-radius: 2rem;
}
.team-invite-wrap-container .white-content-card .role-email-box-wrap .badge-box span {
  font-weight: 500;
}
.team-invite-wrap-container .white-content-card .role-email-box-wrap .email {
  background-color: rgb(247, 248, 247);
}
.team-invite-wrap-container .white-content-card .role-email-box-wrap .email span {
  color: rgb(0, 0, 0);
}
.team-invite-wrap-container .white-content-card .role-email-box-wrap .role {
  background-color: rgb(233, 245, 255);
}
.team-invite-wrap-container .white-content-card .role-email-box-wrap .role span {
  color: rgb(71, 104, 133);
}
.team-invite-wrap-container .white-content-card .title-box {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin-top: 4rem;
}
.team-invite-wrap-container .white-content-card .title-box .sub-title {
  color: rgb(27, 27, 27);
}
.team-invite-wrap-container .white-content-card .title-box .main-title {
  font-weight: 700;
  font-size: 2rem;
  color: rgb(27, 27, 27);
}
.team-invite-wrap-container .white-content-card .form-field-wrap {
  margin-bottom: 3.5rem;
}
.team-invite-wrap-container .white-content-card .des-box {
  padding: 2rem 1.5rem;
  border-radius: 1.5rem;
  border: 0.2rem dashed rgb(185, 222, 254);
  margin: 3rem 0rem 2rem 0rem;
}
.team-invite-wrap-container .white-content-card .des-box .text {
  color: rgb(100, 100, 100);
}
.team-invite-wrap-container .white-content-card .btn-submit {
  margin-top: auto;
}/*# sourceMappingURL=TeamInviteIndex.css.map */