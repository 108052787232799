.register {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.register .register__atlas-logo {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 5.6rem;
}
.register .register__atlas-logo figure {
  height: -moz-fit-content;
  height: fit-content;
  border-right: 1px solid #a0d2fe;
  padding-right: 1.5rem;
}
.register .register__atlas-logo p {
  font-weight: 900;
  font-size: 2rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: var(--primary-blue);
}
.register .register__create-account {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 4rem;
}
.register .register__create-account h5 {
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #000000;
}
.register .register__create-account p {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: var(--border-grey-dark);
}
.register .register__form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.8rem;
  width: 100%;
}
.register .register__form .form__grouped {
  display: flex;
  gap: 2rem;
  width: 100%;
}
.register .register__form .form__action {
  margin-top: 2.8rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2.4rem;
}
.register .register__form .form__action .action__have-an-account {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.register .register__form .form__action .action__have-an-account p {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 160%;
  display: flex;
  align-items: center;
  color: var(--border-grey-dark);
}
.register .register__form .form__action .action__have-an-account span {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  color: var(--primary-purple);
  gap: 0.5rem;
  cursor: pointer;
  transition: transform 300ms ease-in-out;
}
.register .register__form .form__action .action__have-an-account span:hover {
  transform: translateX(3px);
}
.register .register__form .form__action .action__have-an-account span figure {
  display: grid;
  place-items: center;
}
.register .register__onboarding-step {
  display: flex;
  align-items: center;
}
.register .register__onboarding-step p {
  width: 50%;
  height: 0.6rem;
}
.register .register__onboarding-step p:first-child {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  background-color: #014345;
}
.register .register__onboarding-step p:last-child {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  background: #e9f5ff;
}
.register .register__onboarding-step p:last-child.active {
  background: #014345;
}
.register .register__feature-select {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 100%;
}
.register .register__feature-select .feature-select__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 1.8rem;
  border-radius: 0.8rem;
  cursor: pointer;
  border: 1px solid var(--primary-grey-shade);
}
.register .register__feature-select .feature-select__item.active {
  border: 1.6px solid #014345;
  background: #f8fcff;
}
.register .register__feature-select .feature-select__item.active p {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 140%;
  color: #014345;
}
.register .register__feature-select p {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 140%;
  color: black;
}
.register .register__business-details {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.6rem;
  margin-top: 2.8rem;
  margin-bottom: 4rem;
}
.register .register__business-details h5 {
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #000000;
}
.register .register__business-details p {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: var(--border-grey-dark);
}
.register .register__feature-submit {
  margin-top: 4rem;
}

.register__live-chat {
  position: absolute;
  bottom: 2rem;
  right: 8rem;
}

.form-modal-auth {
  display: flex;
  flex-direction: column;
}
.form-modal-auth .pin-group .pin_field_group {
  width: 85%;
}
.form-modal-auth .pin-group .black-white-color {
  color: #020202;
}
.form-modal-auth .pin-group .resend-text {
  color: var(--primary-purple);
  margin-bottom: 0rem;
  align-self: flex-start;
  margin-top: 2rem;
  cursor: pointer;
  font-weight: 600;
}
.form-modal-auth .resend-text {
  color: var(--primary-purple);
  margin-bottom: 0rem;
  align-self: flex-end;
  cursor: pointer;
  font-weight: 600;
}
.form-modal-auth .text-box {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 0.5rem;
  margin-bottom: 2rem;
}
.form-modal-auth .text-box .small-title {
  color: #676767;
  font-size: 1.2rem;
  font-weight: 400;
}
.form-modal-auth .text-box .big-title {
  font-size: 1.6rem;
  color: #020202;
  font-weight: 700;
}

.auth-pin-modal-wrap .raven-modal-content-wrap {
  width: 40rem;
}
.auth-pin-modal-wrap .raven-modal-content-wrap .button-wrap .form {
  padding: 0;
}
.auth-pin-modal-wrap .raven-modal-content-wrap .form-modal-auth .pin-group .pin_field_group {
  width: 100%;
  padding: 0 0.2rem;
}/*# sourceMappingURL=index.css.map */