.onboarding {
  width: 95%;
  height: 100%;
  display: flex;
  align-self: center;
  flex-direction: column;
}
.onboarding .onboarding__hi-there {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.onboarding .onboarding__hi-there h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 140%;
}
.onboarding .onboarding__hi-there p {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 160%;
  width: 50%;
  color: var(--border-grey-dark);
}
.onboarding .onboarding__content-wrap {
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 90%;
  gap: 3rem;
}
.onboarding .onboarding__content-wrap .mobile-onboarding-steps {
  width: 40%;
}
.onboarding .onboarding__verification-steps {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  background: #ffffff;
  border-radius: 1.6rem;
  padding: 2rem 2.3rem;
  width: 100%;
  margin-top: 3.2rem;
  overflow-y: hidden;
  overflow-x: scroll;
  min-height: 200px;
  height: -moz-max-content;
  height: max-content;
}
.onboarding .onboarding__verification-steps .verification-steps__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.onboarding .onboarding__verification-steps .verification-steps__item .item__step-rail {
  display: flex;
  margin-bottom: 2.4rem;
  align-items: center;
  width: 100%;
  position: relative;
}
.onboarding .onboarding__verification-steps .verification-steps__item .item__step-rail p {
  width: 100%;
  display: grid;
  overflow: hidden;
  place-items: center;
}
.onboarding .onboarding__verification-steps .verification-steps__item .item__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 95%;
  cursor: pointer;
}
.onboarding .onboarding__verification-steps .verification-steps__item .item__content h5 {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
}
.onboarding .onboarding__verification-steps .verification-steps__item .item__content p {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  width: 90%;
  color: #676767;
}
.onboarding .onboarding__verification-steps .verification-steps__item .item__content span {
  padding: 0 1.2rem;
  gap: 1rem;
  display: flex;
  height: 2.8rem;
  background: #fff6ed;
  border-radius: 7.2rem;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}
.onboarding .onboarding__verification-steps .verification-steps__item .item__content span.verified {
  background: #e8fff6;
}
.onboarding .onboarding__verification-steps .verification-steps__item .item__content span.verified p {
  color: #1ace37;
  width: 100%;
}
.onboarding .onboarding__verification-steps .verification-steps__item .item__content span p {
  font-size: 1.2rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ea872d;
  text-wrap: nowrap;
}
.onboarding .onboarding__learn-more {
  display: flex;
  width: 50%;
  flex-direction: column;
  height: 100%;
  flex: 1;
}
.onboarding .onboarding__learn-more .learn-more__title {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 140%;
}
.onboarding .onboarding__learn-more .learn-more__content {
  display: flex;
  align-items: center;
  margin-top: 2.8rem;
  flex-direction: column;
  gap: 3.4rem;
  justify-content: space-between;
  flex: 1;
  height: 100%;
  width: 100%;
}
.onboarding .onboarding__learn-more .learn-more__content .content__video {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2.8rem;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) -4.6%, rgba(0, 0, 0, 0.66331) 56.89%, rgba(0, 0, 0, 0.75) 80.83%), url("../../../../assets/images/pexels-marcelo-chagas-6930504.jpg");
  border-radius: 14px;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.onboarding .onboarding__learn-more .learn-more__content .content__video .video__title {
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
}
.onboarding .onboarding__learn-more .learn-more__content .content__video .video__title h5 {
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 140%;
  color: #ffffff;
}
.onboarding .onboarding__learn-more .learn-more__content .content__video .video__title span {
  color: #cccccc;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 160%;
  display: flex;
  align-items: center;
}
.onboarding .onboarding__learn-more .learn-more__content .content__actions {
  display: flex;
  width: 100%;
  gap: 2rem;
}
.onboarding .onboarding__learn-more .learn-more__content .content__documentation {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background-color: white;
  height: 100%;
  gap: 1.6rem;
  cursor: pointer;
  border-radius: 1.4rem;
}
.onboarding .onboarding__learn-more .learn-more__content .content__documentation .documentation__title {
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 140%;
  display: flex;
  align-items: center;
}
.onboarding .onboarding__learn-more .learn-more__content .content__documentation p {
  font-weight: 400;
  margin-top: -1rem;
  font-size: 1.2rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #676767;
}/*# sourceMappingURL=index.css.map */