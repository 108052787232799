.preview-modal-wrap {
  display: flex;
  top: 40rem;
  width: 100%;
  right: 0;
  border-radius: 8px;
  border: 1px solid var(--grey-shade-10, #f7f8f7);
  border-radius: 12px;
  background: var(--base-white, #fff);
  padding: 1.6rem;
  max-height: 30rem;
}
.preview-modal-wrap .preview-modal-wrap__contain {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
  overflow: auto;
}
.preview-modal-wrap .preview-modal-wrap__contain .contain__item {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.preview-modal-wrap .preview-modal-wrap__contain .contain__item .item1 {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
}
.preview-modal-wrap .preview-modal-wrap__contain .contain__item .item1 figure {
  width: 4rem;
  height: 4rem;
  display: grid;
  place-items: center;
}
.preview-modal-wrap .preview-modal-wrap__contain .contain__item .item1 figure svg {
  width: 100%;
  height: 100%;
}
.preview-modal-wrap .preview-modal-wrap__contain .contain__item .item1 span {
  display: flex;
  flex-direction: column;
}
.preview-modal-wrap .preview-modal-wrap__contain .contain__item .item1 span h5 {
  color: #000;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
}
.preview-modal-wrap .preview-modal-wrap__contain .contain__item .item1 span p {
  color: var(--base-black-base-bg-dark, #676767);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.048px;
}
.preview-modal-wrap .preview-modal-wrap__contain .contain__item .remove-btn {
  cursor: pointer;
  transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.preview-modal-wrap .preview-modal-wrap__contain .contain__item .remove-btn:hover {
  transform: scale(1.1);
  padding-right: 0.1rem;
  transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
}/*# sourceMappingURL=previewmodal.css.map */