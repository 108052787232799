.checkmark__wrapper {
	width: 20px;
	height: 20px;
	.copy-checkmark {
		border-radius: 50px;
		background-color: #e8fff6;

		svg {
			stroke: white !important;
			width: 50%;
			height: 50%;
		}
	}
}
