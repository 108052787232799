.payment-page-wrap {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}
.payment-page-wrap .payment-page-wrap__header {
  width: 100%;
}
.payment-page-wrap .payment-page-wrap__body {
  flex: 1;
  background-position: center !important;
  background-size: 90% !important;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 1rem;
  background-repeat: no-repeat !important;
  background: var(--raven-blue-shade-10, #f8fcff);
}
.payment-page-wrap .payment-page-wrap__body .body__left {
  width: 55%;
  height: 100%;
  padding-left: 16rem;
}
.payment-page-wrap .payment-page-wrap__body .body__left .top__wallet-select {
  display: flex;
  align-items: center;
  background: #ffffff;
  padding: 0.6rem;
  width: -moz-max-content;
  width: max-content;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 0.8rem;
  margin-top: 6.8rem;
  margin-bottom: 11.5rem;
}
.payment-page-wrap .payment-page-wrap__body .body__left .top__wallet-select .wallet-select__item {
  padding: 1.1rem 1.6rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 1.4rem;
  color: #1b1b1b;
}
.payment-page-wrap .payment-page-wrap__body .body__left .top__wallet-select .wallet-select__item.active {
  background: #e9f5ff;
  border-radius: 0.8rem;
  color: #014345;
  font-weight: 700;
}
.payment-page-wrap .payment-page-wrap__body .body__right {
  width: 45%;
  height: 100%;
  display: flex;
  padding-top: 8.2rem;
  justify-content: center;
}
.payment-page-wrap .payment-page-wrap__body .body__right .right__content-container {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 80%;
  border-radius: 1.8rem;
  padding: 2rem;
  position: relative;
  background: var(--raven-base-white, #fff);
  box-shadow: 0px 48px 64px -36px rgba(51, 51, 51, 0.08), 0px 24px 48px -8px rgba(51, 51, 51, 0.04);
}
.payment-page-wrap .payment-page-wrap__body .body__right .right__content-container .content-container__top-blue-shade {
  border-radius: 1.4rem;
  background: var(--raven-blue-shade-20, #e9f5ff);
  height: 20%;
  top: 0;
  right: 0;
  width: 100%;
}
.payment-page-wrap .payment-page-wrap__body .body__right .right__content-container .content-container__illustration {
  display: grid;
  place-items: center;
  width: 13rem;
  height: 13rem;
  border-radius: 130px;
  position: absolute;
  border: 8px solid #fff;
  background: var(--raven-blue-shade-20, #e9f5ff);
  box-shadow: 0px 48px 64px -36px rgba(51, 51, 51, 0.08), 0px 24px 48px -8px rgba(51, 51, 51, 0.04);
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
}
.payment-page-wrap .payment-page-wrap__body .body__right .right__content-container .content-container__illustration.error {
  background: var(--raven-error-20, #ffefee);
}
.payment-page-wrap .payment-page-wrap__body .body__right .right__content-container .content-container__illustration img {
  width: 70%;
  height: 70%;
}
.payment-page-wrap .payment-page-wrap__body .body__right .right__content-container .content-container__illustration svg {
  width: 70%;
  height: 70%;
}
.payment-page-wrap .payment-page-wrap__body .body__right .right__content-container .content-container__main {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
}
.payment-page-wrap .payment-page-wrap__body .body__right .right__content-container .content-container__main .main__title {
  color: #000;
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  margin-top: 7.5rem;
}
.payment-page-wrap .payment-page-wrap__body .body__right .right__content-container .content-container__main .main__link-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2.6rem;
  border: 1px solid #e5e6e6;
  border-radius: 1.6rem;
}
.payment-page-wrap .payment-page-wrap__body .body__right .right__content-container .content-container__main .main__link-details div:not(:last-child) {
  border-bottom: 0.6px solid #e5e6e6;
}
.payment-page-wrap .payment-page-wrap__body .body__right .right__content-container .content-container__main .main__link-details .link-details__item {
  display: flex;
  flex-direction: row;
  padding: 2.2rem;
  width: 100%;
  align-items: center;
}
.payment-page-wrap .payment-page-wrap__body .body__right .right__content-container .content-container__main .main__link-details .link-details__item span {
  display: flex;
  width: 60%;
  align-items: center;
  justify-content: space-between;
}
.payment-page-wrap .payment-page-wrap__body .body__right .right__content-container .content-container__main .main__link-details .link-details__item span figure {
  width: 2.5rem;
  height: 2.5rem;
  display: grid;
  place-items: center;
}
.payment-page-wrap .payment-page-wrap__body .body__right .right__content-container .content-container__main .main__link-details .link-details__item span figure svg {
  width: 100%;
  height: 100%;
}
.payment-page-wrap .payment-page-wrap__body .body__right .right__content-container .content-container__main .main__link-details .link-details__item p,
.payment-page-wrap .payment-page-wrap__body .body__right .right__content-container .content-container__main .main__link-details .link-details__item small {
  font-weight: 400;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  color: #000;
}
.payment-page-wrap .payment-page-wrap__body .body__right .right__content-container .content-container__main .main__link-details .link-details__item p:first-child {
  width: 40%;
}
.payment-page-wrap .payment-page-wrap__body .body__right .right__content-container .content-container__main .main__not-success {
  display: flex;
  flex: 1;
  margin-top: 2rem;
}
.payment-page-wrap .payment-page-wrap__body .body__right .right__content-container .content-container__main .main__not-success p {
  color: var(--ravengrey, #676767);
  text-align: center;
  font-size: 1.6rem;
  line-height: 160%;
}
.payment-page-wrap .payment-page-wrap__body .body__right .right__content-container .content-container__main .main__button-wrap {
  display: flex;
  margin-top: 5.6rem;
  width: 100%;
  align-self: flex-end;
  justify-self: flex-end;
}
.payment-page-wrap .payment-page-wrap__body .body__right .right__content-container .content-container__main .main__button-wrap .main__button {
  width: 100%;
}
.payment-page-wrap .payment-page-wrap__body .body__right .link-status-active {
  display: flex;
  margin-top: 3.2rem;
}
.payment-page-wrap .payment-page-wrap__body .body__right .link-status-active__business-info {
  display: flex;
  padding-top: 3.2rem;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2.8rem;
}
.payment-page-wrap .payment-page-wrap__body .body__right .link-status-active__business-info h5 {
  color: #000;
  font-size: 1.6rem;
  font-weight: 700;
}
.payment-page-wrap .payment-page-wrap__body .body__right .link-status-active__business-info figure {
  width: 4.8rem;
  height: 4.8rem;
  display: grid;
  margin-bottom: 0.3rem;
  place-items: center;
}
.payment-page-wrap .payment-page-wrap__body .body__right .link-status-active__business-info figure img {
  width: 100%;
  height: 100%;
}
.payment-page-wrap .payment-page-wrap__body .body__right .link-status-active__payment-details {
  width: 100%;
  border-radius: 1.2rem;
  border: 1px solid var(--raven-blue-shade-50, #a0d2fe);
  background: var(--raven-blue-shade-20, #e9f5ff);
  padding: 2.2rem 1.2rem;
  margin-bottom: 2.4rem;
}
.payment-page-wrap .payment-page-wrap__body .body__right .link-status-active__payment-details h5 {
  color: #000;
  font-size: 1.6rem;
  font-weight: 700;
}
.payment-page-wrap .payment-page-wrap__body .body__right .link-status-active__payment-details p {
  color: var(--raven-base-bg-dark, #676767);
  font-size: 1.2rem;
}
.payment-page-wrap .payment-page-wrap__body .body__right .link-status-active__form {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  flex: 1;
  gap: 2.4rem;
}
.payment-page-wrap .payment-page-wrap__body .body__right .link-status-active__form--grouped {
  display: flex;
  gap: 2rem;
  width: 100%;
  flex-direction: row;
  align-items: center;
}
.payment-page-wrap .payment-page-wrap__body .body__right .link-status-active__form--grouped .grouped-input {
  width: 50%;
}
.payment-page-wrap .payment-page-wrap__body .body__right .link-status-active__btn {
  width: 100%;
}
.payment-page-wrap .payment-page-wrap__body .body__right .link-status-active__btn button {
  width: 100%;
}
.payment-page-wrap .payment-page-wrap__body .body__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  padding: 2.3rem 0;
  position: absolute;
  width: 100%;
  bottom: 0;
  gap: 1.2rem;
  right: 0;
}
.payment-page-wrap .payment-page-wrap__body .body__footer p {
  color: var(--ravenprimarygreen, #0b8376);
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 140%;
  margin-right: 2rem;
}
.payment-page-wrap .payment-page-wrap__body .body__footer figure {
  width: 10rem;
}/*# sourceMappingURL=index.css.map */