.mobile-table-card-wrapper-box {
	display: flex;
	align-items: flex-start;
	width: 100%;
	// transform: translateX(-1%);
	padding: 1.6rem 0;
	border-bottom: 1px solid var(--ravengreyshade-50, #e5e6e6);

	.avatar-img-box {
		display: grid;
		place-items: center;
		align-self: center;
		margin-right: 1rem;
		flex: 0 0 5%;

		.avatar-box {
			width: 3.3rem;
			height: 3.3rem;
			border-radius: 50%;
			display: grid;
			place-items: center;
			grid-gap: 0.4rem;

			span {
				font-weight: 700;
				font-size: 1.1rem;
				text-transform: uppercase;
			}
		}

		.img-box-cre-deb {
			width: 3.3rem;
			height: 3.3rem;
			background-color: #e8fff6;
			border-radius: 50%;
			display: grid;
			place-items: center;

			.img {
				width: 35%;
				height: 35%;
				object-fit: contain;
			}
		}

		.img-box-debit {
			background-color: #fff5f5;
		}
	}

	.title-text-box {
		.title {
			font-weight: 700;
			font-size: 1.3rem;
		}

		.text {
			margin-top: 0.6rem;
			font-size: 1.1rem;
		}
	}

	.amount-box {
		margin-left: auto;
		text-align: end;
		.text {
			margin-top: 0.6rem;
			font-size: 1.1rem;
		}
		.amount {
			font-weight: 700;
			font-size: 1.25rem;
		}
	}
}
