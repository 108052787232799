.select-method {
  display: flex;
  width: 100%;
  background: #ffffff;
  border: 0.8px solid #e5e6e6;
  flex: 1;
  padding: 3.6rem 2rem;
  border-radius: 1.6rem;
  flex-direction: column;
}
.select-method h5 {
  font-weight: 600;
  font-size: 1.6rem;
  display: flex;
  margin-bottom: 2.4rem;
}
.select-method .select-method__select-container {
  display: flex;
  align-items: center;
  gap: 2.4rem;
  width: 100%;
}
.select-method .select-method__select-container .select-container__item {
  padding: 5.6rem 1.2rem;
  padding-bottom: 6.4rem;
  background: #f9f9f9;
  border-radius: 12px;
  display: flex;
  align-items: center;
  width: 50%;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}
.select-method .select-method__select-container .select-container__item:hover {
  transform: scale(1.03);
}
.select-method .select-method__select-container .select-container__item figure {
  width: 8rem;
  height: 8rem;
  display: grid;
  place-items: center;
  background: #ffffff;
  border-radius: 7.2rem;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
}
.select-method .select-method__select-container .select-container__item figure svg {
  width: 40%;
  height: 40%;
}
.select-method .select-method__select-container .select-container__item .item__content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.2rem;
  margin-top: 4.5rem;
}
.select-method .select-method__select-container .select-container__item .item__content p:first-child {
  font-weight: 600;
  font-size: 1.6rem;
  text-align: center;
}
.select-method .select-method__select-container .select-container__item .item__content p:last-child {
  font-weight: 400;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: #676767;
}

.create-link-form {
  display: flex;
  flex-direction: column;
  padding: 2.8rem 2.4rem;
  width: 100%;
}
.create-link-form .create-link-form__title {
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  display: flex;
  align-items: center;
}
.create-link-form .create-link-form__subTitle {
  font-weight: 400;
  font-size: 1.6rem;
  margin-top: 2rem;
  margin-bottom: 1.2rem;
  color: #676767;
}
.create-link-form .create-link-form__main {
  display: flex;
  margin-top: 2.8rem;
  flex-direction: column;
  gap: 2rem;
}
.create-link-form .create-link-form__main .main__grouped-input {
  display: flex;
  gap: 2rem;
}
.create-link-form .create-link-form__main .main__upload-container {
  display: flex;
  flex-direction: column;
}
.create-link-form .create-link-form__main .main__upload-container .upload-container--title {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1.6rem;
  z-index: 111;
}
.create-link-form .create-link-form__main .main__upload-container .upload-container--title label {
  font-weight: 600;
  font-size: 1.6rem;
  color: #1b1b1b;
}
.create-link-form .create-link-form__main .main__upload-container .upload-container--title figure {
  width: 2rem;
  height: 2rem;
  display: grid;
  place-items: center;
}
.create-link-form .create-link-form__main .main__upload-container .upload-container--title figure svg {
  width: 100%;
  height: 100%;
}
.create-link-form .create-link-form__main .main__act-btn {
  width: 100%;
  margin-top: 7.2rem;
}

.single-preview-top-acts {
  display: flex;
  align-items: center;
  gap: 1.6rem;
}
.single-preview-top-acts span {
  background: #ffffff;
  align-items: center;
  display: grid;
  place-items: center;
  cursor: pointer;
  width: 5rem;
  height: 5rem;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 1rem;
  transition: transform 0.2s ease-in-out;
}
.single-preview-top-acts span:hover {
  transform: scale(1.1);
}
.single-preview-top-acts span figure {
  width: 70%;
  height: 70%;
}

.single-preview {
  display: flex;
  gap: 3rem;
  flex: 1;
  width: 100%;
}
.single-preview .single-preview__right,
.single-preview .single-preview__left {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.single-preview .single-preview__left {
  flex: 1;
  display: flex;
}
.single-preview .single-preview__left .left__preview-title {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 1.6rem;
  align-items: center;
}
.single-preview .single-preview__left .left__preview-title figure {
  width: 8rem;
  height: 8rem;
  display: grid;
  place-items: center;
}
.single-preview .single-preview__left .left__preview-title figure img {
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
}
.single-preview .single-preview__left .left__preview-title span {
  display: flex;
  flex-direction: column;
}
.single-preview .single-preview__left .left__preview-title span h5 {
  font-weight: 700;
  font-size: 1.8rem;
}
.single-preview .single-preview__left .left__preview-title span p {
  font-weight: 400;
  font-size: 1.4rem;
  color: #676767;
  margin-top: 0.2rem;
}
.single-preview .single-preview__left .left__link-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2.6rem;
  border: 1px solid #e5e6e6;
  border-radius: 1.6rem;
}
.single-preview .single-preview__left .left__link-details div:not(:last-child) {
  border-bottom: 0.6px solid #e5e6e6;
}
.single-preview .single-preview__left .left__link-details .link-details__item {
  display: flex;
  flex-direction: row;
  padding: 2.2rem;
  width: 100%;
  align-items: center;
}
.single-preview .single-preview__left .left__link-details .link-details__item span {
  display: flex;
  width: 70%;
  align-items: center;
  justify-content: space-between;
}
.single-preview .single-preview__left .left__link-details .link-details__item span figure {
  width: 2.5rem;
  height: 2.5rem;
  display: grid;
  place-items: center;
}
.single-preview .single-preview__left .left__link-details .link-details__item span figure svg {
  width: 100%;
  height: 100%;
}
.single-preview .single-preview__left .left__link-details .link-details__item p,
.single-preview .single-preview__left .left__link-details .link-details__item small {
  font-weight: 400;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
}
.single-preview .single-preview__left .left__link-details .link-details__item p:first-child {
  width: 30%;
}
.single-preview .single-preview__right {
  display: flex;
  width: 100%;
  overflow-y: scroll;
  flex: 1;
  flex-direction: column;
  margin-top: 2rem;
}
.single-preview .single-preview__right::-webkit-scrollbar {
  display: none;
}
.single-preview .single-preview__right .right__pagination-wrap {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.single-preview .single-preview__right .right__pagination-wrap h5 {
  font-weight: 600;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
}
.single-preview .single-preview__right .main__wrap {
  display: flex;
  margin-top: 2rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.single-preview .single-preview__right .main__wrap .transaction__table {
  width: 100%;
  height: 100%;
  border-radius: 1.2rem;
  overflow: auto;
}
.single-preview .single-preview__right .main__wrap .transaction__table::-webkit-scrollbar {
  display: none;
}
.single-preview .single-preview__right .main__wrap .transaction__table .table__main {
  border-radius: 1.2rem;
  overflow: auto;
}
.single-preview .single-preview__right .main__wrap .transaction__table .table__main .main__direction {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
.single-preview .single-preview__right .main__wrap .transaction__table .table__main .main__direction figure {
  display: grid;
  place-items: center;
  width: 2.75rem;
  height: 2.75rem;
}
.single-preview .single-preview__right .main__wrap .transaction__table .table__main .main__direction figure svg {
  width: 100%;
  height: 100%;
}
.single-preview .single-preview__right .main__wrap .transaction__table .table__main .account_name {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.single-preview .single-preview__right .main__wrap .transaction__table .table__main .account_name span {
  padding: 0.6rem;
  border-radius: 50%;
}/*# sourceMappingURL=index.css.map */