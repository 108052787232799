@media screen and (max-width: 29.9375em) {
  html,
  body,
  .App,
  .auth-layout-wrap,
  .wrap,
  .login {
    max-width: 100vw;
    max-height: 100vh;
  }
  figure.tablet-menu-toggle {
    background-color: yellow;
    display: none !important;
    width: 0px;
    height: 0px;
    position: absolute;
    overflow: hidden;
  }
  figure.tablet-menu-toggle svg {
    display: none;
  }
  .dashboard-layout-wrap .main-body-wrap .main-wrap {
    overflow-y: scroll;
  }
  .overview .overview__charts .charts__guage .guage__stats .stats__item .item__count {
    width: 3rem;
    height: 3rem;
  }
  .dashboard-layout-headernav-wrap .search-box {
    max-width: 150px;
  }
  .dashboard-layout-headernav-wrap .search-box div:nth-child(2) {
    width: 100%;
  }
  .dashboard-layout-headernav-wrap .search-box input {
    width: 100%;
  }
  .dashboard-layout-headernav-wrap .api-documentation-box,
  .overview .top-bar__topup-btn {
    display: none;
  }
  .dashboard-layout-wrap .main-body-wrap .main-wrap {
    padding-top: 3.1rem;
  }
  .overview .overview__wallets-wrap {
    width: 100%;
  }
  body .overview .overview__wallets {
    overflow-x: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: -moz-max-content;
    width: max-content;
  }
  .overview .overview__wallets .wallets__balance {
    width: 28rem;
  }
  .overview .overview__wallets .wallets__statistics {
    width: -moz-max-content;
    width: max-content;
  }
  .overview .overview__charts {
    flex-direction: column;
    height: -moz-max-content;
    height: max-content;
    max-height: unset;
    min-height: unset;
  }
  .overview .overview__charts .charts__guage .guage__wrap .wrap__guage {
    height: unset;
    width: unset;
  }
  body .overview,
  body .history-wrap,
  body .container-wrap {
    height: -moz-max-content;
    height: max-content;
  }
  body .overview .overview__charts .charts__graph {
    overflow: hidden;
  }
  body .overview .overview__charts .charts__graph .graph__top .top__title p {
    font-size: 1.2rem;
  }
  body .no-record .no-record__text {
    width: 100%;
    padding: 8rem 0;
    gap: 1.2rem;
  }
  .container-page-mode {
    overflow: hidden;
  }
  .dashboard-layout-wrap .mobile-nav-thread {
    height: 90vh;
    margin-top: 7rem;
  }
  .dashboard-layout-wrap .show-mobile-nav {
    transform: unset !important;
    border-top-right-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem;
  }
  .dashboard-layout-headernav-wrap .mobile-hamburger {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .dashboard-layout-headernav-wrap .mobile-hamburger p {
    background-color: black;
    transition: transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  .dashboard-layout-headernav-wrap .mobile-hamburger p:first-child,
  .dashboard-layout-headernav-wrap .mobile-hamburger p:last-child {
    width: 24px;
    height: 1.5px;
  }
  .dashboard-layout-headernav-wrap .mobile-hamburger p:nth-child(2) {
    width: 15px;
    height: 1.5px;
  }
  .dashboard-layout-headernav-wrap .mobile-hamburger.collapsed {
    gap: 0;
  }
  .dashboard-layout-headernav-wrap .mobile-hamburger.collapsed p:nth-child(2) {
    opacity: 0;
    display: none;
  }
  .dashboard-layout-headernav-wrap .mobile-hamburger.collapsed p:first-child {
    margin: -0.5px;
    transform: rotate(45deg);
  }
  .dashboard-layout-headernav-wrap .mobile-hamburger.collapsed p:last-child {
    margin: -1px;
    transform: rotate(-45deg);
  }
  body .login {
    padding: 0 2rem;
  }
  body .auth-layout-wrap .right-wrap .wrap {
    width: 100%;
    padding: 0 3rem;
  }
  body .auth-layout-wrap .right-wrap {
    display: flex;
  }
  body .non-mobile {
    display: none !important;
  }
  body .non-desktop {
    display: flex !important;
  }
  body .atlas-mobile-table-wrap {
    display: flex;
  }
  body .form-group__black-light .pin-group .pin_field_group {
    grid-column-gap: 1.2rem;
    margin-right: 0 !important;
  }
  body .trx-modal-main-wrapper .raven-modal-content-wrap {
    width: 400px;
  }
  body .modal-parent-wrap .raven-modal-content-wrap {
    max-width: 350px;
  }
  body .smartfilter .top-bar__filter-export .filter-export__drop-down-wrap {
    width: 100%;
    max-width: 35rem;
  }
  body .smartfilter .top-bar__filter-export .filter-export__drop-down .dropdown__apply-filter-btn {
    z-index: 300;
    background-color: white;
  }
  body .walletslide {
    max-height: 33rem;
    min-height: 25rem;
  }
  body .smartfilter .top-bar__filter-export .filter-export__export-btn p,
  body .smartfilter .top-bar__filter-export .filter-export__filter-btn p {
    display: none;
  }
  body .smartfilter {
    gap: 2rem;
  }
  body .walletslide__wallet,
  body .overview .overview__wallets .wallets__statistics .statistics__item {
    min-width: 200px;
  }
  body .dashboard-layout-wrap .main-body-wrap .main-wrap {
    height: 100%;
    margin-top: 5rem;
    overflow: hidden;
  }
  body .no-record-mascot .no-record-mascot__bottom p {
    width: 90%;
  }
  body .history-wrap .history-wrap__top .top__top-right-content {
    border-radius: 50%;
  }
  body .history-wrap .history-wrap__top .top__top-right-content button {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    padding: 0;
  }
  body .history-wrap .history-wrap__top .top__top-right-content p {
    display: none;
  }
  body .container-page-wrap .container-page .content {
    max-width: 100% !important;
    width: 100% !important;
    border: none;
  }
  body .container-page-wrap {
    height: -moz-max-content;
    height: max-content;
  }
  body .container-page-wrap .container-page {
    padding: 0;
    height: -moz-max-content;
    height: max-content;
  }
  body .atlas-payment-container {
    padding: 0;
  }
  body .atlas-payment-container .form {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  body .container-wrap {
    height: -moz-max-content;
    height: max-content;
  }
  body .new-transfer__mode .mode__items .items__content p {
    display: flex;
    text-wrap: nowrap;
    align-items: flex-start;
  }
  body .security-lock-wrap-box {
    padding: 0;
    margin: 0;
  }
  body .security-lock-wrap-box * {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    display: flex;
  }
  body .preview-transfer {
    border: none;
  }
  body .preview-transfer .preview-transfer__header {
    margin-top: 7.2rem;
  }
  body .smartfilter .top-bar__filter-export .filter-export__drop-down .drop-down__item .custom-date__form-wrap.show {
    display: flex;
    flex-direction: column;
  }
  body .smartfilter .top-bar__filter-export .filter-export__drop-down-wrap {
    min-width: 32.45rem;
  }
  body .smartfilter .top-bar__filter-export .filter-export__drop-down {
    right: 5%;
    max-height: -moz-min-content;
    max-height: min-content;
  }
  body .smartfilter .top-bar__filter-export .filter-export__drop-down .drop-down__item {
    padding-bottom: 9rem;
  }
  body .virtual-account__account-details .account-details__main .account-details__item p:first-child {
    width: 40%;
  }
  body .container-page-mode__content {
    max-height: 80rem;
    overflow-y: scroll !important;
  }
  body .onboarding {
    margin-top: 5rem;
  }
  body .onboarding .onboarding__learn-more {
    width: 100%;
  }
  body .onboarding .onboarding__learn-more .learn-more__content .content__actions {
    flex-direction: column;
    margin-bottom: 4rem;
  }
  body .onboarding .onboarding__content-wrap {
    flex-direction: column;
  }
  body .onboarding .onboarding__content-wrap .mobile-onboarding-steps {
    width: 100%;
  }
  body .onboarding .onboarding__hi-there p {
    width: 100%;
  }
  body .onboarding .onboarding__learn-more .learn-more__content {
    flex-direction: column;
    width: 100%;
    margin-bottom: 3rem;
    height: -moz-max-content;
    height: max-content;
  }
  body .onboarding .onboarding__learn-more .learn-more__content .content__video {
    width: 100%;
  }
  body .onboarding .onboarding__learn-more .learn-more__content .content__documentation {
    height: -moz-max-content;
    height: max-content;
  }
  body .verification .verification__footer {
    padding: 0 2rem;
    margin-bottom: 1rem;
  }
  body .verification .verification__children {
    width: 100%;
    max-width: unset;
    padding: 0 2rem;
    margin-top: 3rem;
  }
  body .verification .verification__children .verification-wrap {
    width: 100%;
    max-width: unset;
  }
  body .cac-verification .cac-verification__navbar {
    display: none;
  }
  body .business-info-verification-wrap {
    width: 100%;
  }
  .transfer-mode .container-page .atlas-payment-container.expanded {
    max-width: 100%;
  }
  .step-two-transfer-raven {
    width: 100%;
  }
  .preview-transfer,
  .transfer-mode .container-page .atlas-payment-container {
    max-width: 100%;
  }
  .transfer-mode .container-page .atlas-payment-container {
    padding: 0 2rem;
    min-width: unset;
  }
  .transfer-mode .container-page-footer {
    padding: 1rem 2rem;
  }
  .transfer-mode .container-page-footer .container-page-footer-btn {
    max-width: 100%;
  }
  .preview-transfer .preview-transfer__logo {
    top: 0;
  }
  .transfer-mode .container-page-header .container-page-header__steppers .step .stepper-title {
    display: none;
  }
  .container-page-wrap .container-page .content {
    padding: 0;
  }
  .settings .settings__sidebar .sidebar__selector {
    display: none;
  }
  .settings {
    margin-top: 5rem;
  }
  .business-profile {
    border: none;
    padding: 0;
    width: 100%;
    overflow-y: auto;
  }
  .business-profile__owner span {
    display: none;
  }
  .business-profile__details div:first-child {
    flex: 0 0 40%;
  }
  .business-profile__details {
    border: none;
    flex-direction: column;
  }
  .settings .settings__sidebar {
    overflow: hidden;
  }
  .container-page-wrap .container-page .container__top {
    display: none;
  }
  .action_bar_show {
    padding: 1rem !important;
    width: 100%;
  }
  .action_bar__info .action-box {
    display: none;
  }
  .kyc-dashboard-wrapper__verified-details .verified-details__information--details {
    flex-direction: column;
  }
  .kyc-dashboard-wrapper__verified-details .verified-details__information--details div {
    gap: 2rem;
  }
  .kyc-dashboard-wrapper__verified-details .verified-details__information {
    padding: 0;
    padding-top: 2rem;
  }
  .kyc-dashboard-wrapper__verified-details .verified-details__information--preview-json {
    margin-top: 3rem;
  }
  .kyc-dashboard-wrapper__verified-details .verified-details__information--preview-json div {
    height: 5rem;
  }
  .kyc-dashboard-wrapper__verified-details .verified-details__information--preview-json div p {
    font-size: 1.4rem;
  }
  .kyc-dashboard-wrapper__verified-details {
    width: 100%;
  }
  .new-transfer__mode .mode__title {
    margin-left: 2rem;
  }
  .smartfilter .top-bar__filter-export .filter-export__drop-down .drop-down__item span figure {
    width: 2.5rem;
    height: 2.5rem;
    overflow: hidden;
    border-radius: 50%;
    display: grid;
    align-items: center;
    justify-content: center;
  }
  .smartfilter .top-bar__filter-export .filter-export__drop-down .drop-down__item span figure .basic-details-settings__app-name--input-group svg {
    width: 100%;
    height: 100%;
  }
  .auth-legacy-switch {
    display: none;
    z-index: 10000;
  }
  .single-card-container .single-card__content {
    flex-direction: column;
    overflow-y: auto;
  }
  .single-card-container .single-card__content .content__right {
    width: 100%;
  }
  .single-card-container .single-card__content .content__left .mobile-card-preview {
    width: 40rem;
    background: none;
  }
  .single-card-container .single-card__content .content__left .mobile-card-preview img {
    width: 100%;
    height: 100%;
  }
  .single-card-container .single-card__content .content__right .right__content-table .content-table__row p:first-child {
    width: 40%;
  }
  .single-card-container .single-card__content .content__right .right__content-table .content-table__row p:last-child {
    width: 60%;
  }
  .bankbox-overview {
    flex-direction: column;
  }
  .bankbox-overview__right {
    width: 100%;
    padding: 2rem;
    justify-content: start;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    height: -moz-min-content;
    height: min-content;
  }
  .bankbox-overview__left {
    width: 100%;
    height: 30%;
  }
  .bankbox-overview__right--title h6 {
    font-size: 2.4rem;
  }
  .bankbox-modals {
    flex-direction: column;
    height: 100%;
  }
  .bankbox-modals__left {
    height: 20%;
    flex: unset;
    padding-top: 2rem;
  }
  .bankbox-modals__right {
    padding: 2rem 3rem;
    overflow-y: auto;
    max-height: 55rem;
    flex: unset;
  }
  .bankbox-modals__right--content {
    max-height: 100%;
    height: 100%;
    overflow: auto;
  }
  .bankbox-modals__right--content .basic-content__title h6 {
    font-size: 3.2rem;
  }
  .bankbox-create-modal .raven-modal-content-wrap .close-box {
    right: 0;
    left: unset !important;
    float: right;
    width: -moz-min-content;
    width: min-content;
  }
  .basic-details-settings__app-name--input-group {
    flex-direction: column;
  }
  .basic-details-settings__app-name--input-group .input-group__color-swatch {
    width: 100%;
  }
  .basic-details-settings__app-name--avatar p {
    width: 100%;
  }
  .basic-details-settings__prices--input-group {
    display: flex;
    flex-direction: column;
  }
  .settings .settings__title {
    gap: 2rem;
  }
  .bank-box-overview__bottom-contain {
    flex-direction: column;
  }
  .bank-box-overview__stats-wrapper {
    overflow-y: scroll;
    min-height: 18rem;
  }
  .bank-box-overview__stats-wrapper--stats {
    min-width: 23rem;
  }
  .history-wrap .history__content {
    height: 45%;
    flex: unset;
  }
  .history-wrap .flex-one {
    flex: 1 !important;
  }
}
body .check-drop-box {
  opacity: 1;
  z-index: 4;
}/*# sourceMappingURL=index.css.map */