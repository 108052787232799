.walletslide {
  width: 100%;
  overflow-x: auto;
  display: flex;
  min-height: 25rem;
  max-height: 30rem;
  flex-direction: row;
  gap: 2.4rem;
  padding-top: 0.5rem;
  padding-left: 0;
  padding-bottom: 2rem;
}
.walletslide__wallet {
  flex: 1;
  cursor: pointer;
  height: -moz-max-content;
  height: max-content;
  border-radius: 1.2rem;
  background: #fff;
  border: 1px solid var(--raven-black-100, #eee);
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
}
.walletslide__wallet--flag {
  margin-bottom: 2.4rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.walletslide__wallet--flag svg {
  width: 3rem;
  height: 3rem;
}
.walletslide__wallet--available-balance {
  display: flex;
  flex-direction: column;
}
.walletslide__wallet--available-balance p {
  color: var(--ravengrey, #676767);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
}
.walletslide__wallet--available-balance span {
  color: var(--ravenblack, #020202);
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.72px;
}
.walletslide__wallet--buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.2rem;
  margin-top: 2.7rem;
  margin-bottom: 1.5rem;
}
.walletslide__wallet--buttons button {
  border: none;
  box-shadow: none;
  background: none;
  border-radius: 3rem;
  width: 7.8rem;
  height: 3.1rem;
  cursor: pointer;
  transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.walletslide__wallet--buttons button:hover {
  transform: translateY(-2px);
}
.walletslide__wallet--buttons button:hover figure {
  transform: scale(1.2);
}
.walletslide__wallet--buttons .buttons__topup {
  background: #014345;
  color: white;
  border: 1px solid #014345;
  font-size: 1.2rem;
}
.walletslide__wallet--buttons .buttons__withdraw {
  background: #f7f8f7;
  border: 1px solid #cccccc;
  color: var(--ravengrey, #676767);
}
.walletslide__wallet--pending-balance {
  color: var(--ravengrey, #676767);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  letter-spacing: -0.24px;
}
.walletslide__wallet--pending-balance b {
  color: #000;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.24px;
}
.walletslide__wallet.active {
  border: 2px solid var(--primary-darkgreen-100, #014345);
  background: #fff;
  box-shadow: 0px 0px 0px 4px #dff6ed;
}
.walletslide__wallet.active .walletslide__wallet--available-balance span {
  font-weight: 700;
}/*# sourceMappingURL=index.css.map */