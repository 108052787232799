.mobile-bills-wrap {
	display: flex;
	flex-wrap: wrap;
	border-radius: 16px;
	background: var(--base-white, #fff);
	width: 100%;
	flex-direction: row;
	height: max-content;
	align-items: center;
	gap: 2rem;
	height: 100%;

	.mobile-bills-wrap__item {
		border-radius: 1.2rem;
		flex: 1;
		min-width: 40%;
		max-width: 50%;
		border: 1px solid var(--ravengreyshade-50, #e5e6e6);
		padding: 1.2rem;

		figure {
			margin-bottom: 3.2rem;
		}

		.item__content {
			display: flex;
			gap: 1.2rem;
			flex-direction: column;

			h5 {
				color: #000;
				font-size: 1.2rem;
				font-style: normal;
				font-weight: 700;
			}

			p {
				color: var(--base-black-base-black, #1b1b1b);
				font-size: 1rem;
				font-style: normal;
				font-weight: 400;
			}
		}
	}
}
