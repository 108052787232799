.preview-payment-view-main {
  display: flex;
  flex-direction: column;
  box-shadow: 0px -3px 48px -8px #f7f8f7, 0px 4px 23px 12px #f7f8f7;
  margin-top: 1rem;
  border-radius: 12px;
  padding: 1.8rem;
  max-width: 100%;
  justify-self: center;
}
.preview-payment-view-main .preview-payment-view__header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 3rem;
}
.preview-payment-view-main .preview-payment-view__header h5 {
  font-weight: 700;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}
.preview-payment-view-main .preview-payment-view__header small {
  font-weight: 400;
  font-size: 1.2rem;
  display: flex;
  margin-top: 0.4rem;
  align-items: center;
  text-align: center;
  color: #676767;
}
.preview-payment-view-main .preview-payment-view__table {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.preview-payment-view-main .preview-payment-view__table .table__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 2.4rem;
  padding: 0;
  border-bottom: none;
}
.preview-payment-view-main .preview-payment-view__table .table__item p {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 140%;
  /* identical to box height, or 20px */
  display: flex;
  align-items: center;
  color: #000000;
}
.preview-payment-view-main .preview-payment-view__table .table__item p:first-child {
  color: var(--base-black-base-bg-dark, #676767);
}

.preview-payment-view {
  flex: 1;
  height: 100%;
  width: 100%;
  background: #ffffff;
  border-radius: 16px;
  padding: 2rem;
  max-width: 50%;
}
.preview-payment-view .preview-payment-view__logo {
  position: absolute;
  top: 20px;
  left: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
}
.preview-payment-view .preview-payment-view__logo .logo {
  width: 6rem;
  height: 6rem;
}
.preview-payment-view .preview-payment-view__logo .logo img {
  width: 100%;
  height: 100%;
}
.preview-payment-view .preview-payment-view__header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 4rem;
}
.preview-payment-view .preview-payment-view__header h5 {
  font-weight: 700;
  font-size: 2rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}
.preview-payment-view .preview-payment-view__header small {
  font-weight: 400;
  font-size: 1.4rem;
  display: flex;
  margin-top: 0.4rem;
  align-items: center;
  text-align: center;
  color: #676767;
}
.preview-payment-view .preview-payment-view__table {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.preview-payment-view .preview-payment-view__button {
  margin-top: 4rem;
}
.preview-payment-view .preview-payment-view__switch {
  display: flex;
  align-items: center;
  justify-self: center;
  width: 100%;
  justify-content: center;
  margin-top: 2rem;
  gap: 0.6rem;
}
.preview-payment-view .preview-payment-view__switch figure {
  background: #f7f8f7;
  height: 3rem;
  width: 3rem;
  display: grid;
  place-items: center;
  border-radius: 2rem;
  cursor: pointer;
}
.preview-payment-view .preview-payment-view__switch figure svg {
  width: 40%;
  height: 40%;
}
.preview-payment-view .preview-payment-view__switch figure:first-child {
  transform: rotate(180deg);
}
.preview-payment-view .preview-payment-view__switch .switch__pages {
  display: flex;
  height: 3rem;
  width: auto;
  padding: 0 1rem;
  align-items: center;
  background: #f7f8f7;
  border-radius: 2rem;
}
.preview-payment-view .preview-payment-view__switch .switch__pages p {
  font-weight: 700;
  font-size: 1.2rem;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #1b1b1b;
}
.preview-payment-view .preview-payment-view__switch .switch__pages p:last-child {
  color: #acacac;
}/*# sourceMappingURL=styles.css.map */