.atlas-payment-container-preview-box-wrap {
  padding: 2rem;
  width: 100%;
  display: flex;
}
.atlas-payment-container-preview-box-wrap .preview-box-wrap__inner {
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.atlas-payment-container-preview-box-wrap .preview-box-wrap__inner .grey-white-color {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 140%;
  letter-spacing: -0.004em;
}
.atlas-payment-container-preview-box-wrap .preview-box-wrap__inner .wrap__arrow-back {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  transition: transform, margin 300ms ease-in-out;
  cursor: pointer;
}
.atlas-payment-container-preview-box-wrap .preview-box-wrap__inner .wrap__arrow-back:hover {
  margin-left: 4px;
}
.atlas-payment-container-preview-box-wrap .preview-box-wrap__inner .wrap__arrow-back svg {
  width: 100%;
  height: 100%;
}
.atlas-payment-container-preview-box-wrap .preview-box-wrap__inner .wrap__arrow-back svg path {
  stroke: black;
}
.atlas-payment-container-preview-box-wrap .preview-box-wrap__inner .wrap__title-contain {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.atlas-payment-container-preview-box-wrap .preview-box-wrap__inner .wrap__title-contain .title-contain__main {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  margin-bottom: 1.4rem;
}
.atlas-payment-container-preview-box-wrap .preview-box-wrap__inner .wrap__title-contain .title-contain__main small {
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  color: #676767;
}
.atlas-payment-container-preview-box-wrap .preview-box-wrap__inner .wrap__title-contain .title-contain__main h5 {
  font-weight: 600;
  font-size: 2rem;
  display: flex;
  align-items: center;
  color: #000000;
}
.atlas-payment-container-preview-box-wrap .preview-box-wrap__inner .wrap__title-contain .title-contain__item-count {
  background: #f7f8f7;
  border-radius: 7.2rem;
  display: grid;
  place-items: center;
  height: 3rem;
  width: 3rem;
}
.atlas-payment-container-preview-box-wrap .preview-box-wrap__inner .wrap__title-contain .title-contain__item-count p {
  font-weight: 400;
  font-size: 1.2rem;
}
.atlas-payment-container-preview-box-wrap .preview-box-wrap__inner .wrap__items {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  flex: 1;
  overflow-y: scroll;
}
.atlas-payment-container-preview-box-wrap .preview-box-wrap__inner .wrap__items::-webkit-scrollbar {
  display: none;
}
.atlas-payment-container-preview-box-wrap .preview-box-wrap__inner .wrap__items .box {
  padding: 1rem;
  cursor: pointer;
  position: relative;
  background: #f7f8f7;
  border: 1px solid #f7f8f7;
  box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04), 0px 0px 2px rgba(51, 51, 51, 0.02);
  border-radius: 8px;
}
.atlas-payment-container-preview-box-wrap .preview-box-wrap__inner .wrap__items .box .raven-name {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 140%;
  /* identical to box height, or 20px */
  letter-spacing: -0.004em;
  color: #000000;
}
.atlas-payment-container-preview-box-wrap .preview-box-wrap__inner .wrap__items .box .img-box {
  display: grid;
  place-items: center;
  width: 3.5rem;
}
.atlas-payment-container-preview-box-wrap .preview-box-wrap__inner .wrap__items .box .cancel-box {
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  background-color: #ffdfdd;
  margin-left: auto;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.3s;
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.atlas-payment-container-preview-box-wrap .preview-box-wrap__inner .wrap__items .box .cancel-box:hover {
  transform: scale(1.1);
}
.atlas-payment-container-preview-box-wrap .preview-box-wrap__inner .wrap__items .box .cancel-box .icon {
  fill: #ff0f00;
  width: 1.1rem;
  height: 1.1rem;
}
.atlas-payment-container-preview-box-wrap .preview-box-wrap__inner .wrap__items .box .name {
  margin-top: 2rem;
  font-size: 1.45rem;
}/*# sourceMappingURL=viewall.css.map */