.bankbox-modals {
	display: flex;
	min-height: 80rem;
	width: 100%;
	// position: absolute;
	// height: 50rem;
	flex: 1;

	&__left {
		flex: 40%;
		position: relative;
		border-radius: var(--Count, 0px);
		padding: 2.8rem;

		padding-top: 15rem;
		position: relative;

		// &--bg-absolute {
		// 	position: absolute;
		// }

		&--bg-absolute::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			flex: 1;
			bottom: 0;
			right: 0;
			z-index: -1;
			cursor: pointer;
			background-repeat: no-repeat;
			background-image: url('../../../../../../assets/bb-create-user-bg.svg');
			background-size: 100%; /* Adjust the size for the background image */
			background-position: bottom right;
		}

		&--bg-absolute::after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			flex: 1;
			bottom: 0;
			right: 0;
			z-index: -2;
			cursor: pointer;
			background: linear-gradient(180deg, #f7f7f7 0%, #f7f7f7 100%);
			background-position: bottom right;
		}
		&--stepper {
			display: flex;
			flex-direction: column;
			gap: 2.4rem;

			.stepper-item {
				display: flex;
				align-items: center;
				cursor: pointer;
				gap: 0.8rem;

				&.completed {
					span {
						border-color: #014345;
						background-color: #014345;

						p {
							color: var(--base-white, var(--themelightmode, #fff));
						}
					}
				}

				&.active {
					span {
						border-color: #014345;
						background-color: #014345;

						p {
							color: var(--base-white, var(--themelightmode, #fff));
							text-align: center;
							font-size: 1.6rem;
							font-style: normal;
							font-weight: 700;
						}
					}
				}

				span {
					width: 3rem;
					height: 3rem;
					flex-shrink: 0;
					display: grid;
					place-items: center;
					border-radius: 72px;
					border: 1px solid #d1d1d1;

					p {
						color: var(--raven-black-600, #646464);
						text-align: center;
						font-size: 1.6rem;
						font-style: normal;
						font-weight: 400;
					}
				}

				h6 {
					color: var(--raven-black-600, #646464);
					text-align: center;
					font-size: 1.2rem;
					font-style: normal;
					font-weight: 400;
					// line-height: 140%; /* 16.8px */
				}
			}
		}
	}

	&__right {
		flex: 60%;
		padding: 5.6rem 4.5rem;
		display: flex;
		flex-direction: column;
		width: 100%;

		&--content {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 100%;

			.basic-content {
				&__title {
					display: flex;
					flex-direction: column;
					width: 100%;
					gap: 0.8rem;

					h5 {
						color: var(--themedarkmode, #000);
						font-size: 2.4rem;
						font-style: normal;
						font-weight: 600;
					}
					h6 {
						color: var(--RAVEN-GREY, #676767);
						font-size: 3.6rem;
						font-style: normal;
						font-weight: 500;
					}
				}

				&__business-logo {
					display: flex;
					align-items: center;
					gap: 1.6rem;
					margin-top: 2rem;
					margin-bottom: 2.8rem;

					figure {
						width: 8.5rem;
						height: 8.5rem;
						border-radius: 72px;
						cursor: pointer;

						img {
							width: 100%;
							height: 100%;
							border-radius: 72px;
							object-fit: cover;
						}
					}

					p {
						color: var(--RAVEN-GREY, #676767);
						font-size: 1.4rem;
						font-style: normal;
						font-weight: 400;
						width: 40%;
					}
				}

				&__input-contain {
					display: flex;
					flex-direction: column;

					&--brand-color {
						margin-top: 3.2rem;
						display: flex;
						flex-direction: column;
						width: 100%;
						border-radius: var(--Count, 0px);

						.selector-title {
							width: 100%;
							display: flex;
							align-items: center;
							margin-bottom: 1.6rem;
							padding-bottom: 0.6rem;
							border-bottom: 1px solid var(--raven-black-200, #e3e3e3);

							p {
								color: var(--themedarkmode, #000);
								font-size: 1.4rem;
								font-style: normal;
								font-weight: 400;
								line-height: 140%; /* 19.6px */
							}
						}

						.color-selector {
							display: flex;
							align-items: center;
							gap: 1.6rem;
							margin-bottom: 4.8rem;

							.bColors {
								width: 5rem;
								height: 5rem;
								display: grid;
								place-items: center;
								border-radius: 72px;
								cursor: pointer;

								&.active {
									border: 2px solid;
								}
							}
						}
					}
				}
			}

			.price-content {
				&__input-contain {
					margin-top: 2rem;
					margin-bottom: 4.8rem;
					display: flex;
					flex-direction: column;
					gap: 2.4rem;

					&--grouped {
						display: flex;
						gap: 1.6rem;
					}
				}
			}
		}
	}
}
