.modal-transaction-main-wrap {
	.raven-modal-content-wrap {
		width: 38rem;
	}

	.transaction-pin-wrap {
		//  padding: 0rem 2rem;

		.title {
			font-weight: 700;
			color: #020202;
			font-size: 1.7rem;
		}

		.text {
			font-size: 1.3rem;
			color: #676767;
		}

		.form-pin {
			margin-top: 3rem;
		}
	}
}
