.bulk_transfer {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 2rem;
}
.bulk_transfer .bulk_transfer__arrow-back {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  transition: transform, margin 300ms ease-in-out;
  cursor: pointer;
}
.bulk_transfer .bulk_transfer__arrow-back:hover {
  margin-left: 4px;
}
.bulk_transfer .bulk_transfer__arrow-back svg {
  width: 100%;
  height: 100%;
}
.bulk_transfer .bulk_transfer__arrow-back svg path {
  stroke: black;
}
.bulk_transfer .bulk_transfer__title-contain {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 1.4rem;
}
.bulk_transfer .bulk_transfer__title-contain small {
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  color: #676767;
}
.bulk_transfer .bulk_transfer__title-contain h5 {
  font-weight: 600;
  font-size: 2rem;
  display: flex;
  align-items: center;
  color: #000000;
}
.bulk_transfer .bulk_transfer__upload-container {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-top: 1.4rem;
  width: 100%;
}
.bulk_transfer .bulk_transfer__upload-container .upload-container-select__item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.5rem 1.8rem;
  border-radius: 0.8rem;
  cursor: pointer;
  flex-direction: column;
  width: 100%;
  border: 1px solid var(--primary-grey-shade);
  gap: 0.7rem;
}
.bulk_transfer .bulk_transfer__upload-container .upload-container-select__item figure {
  display: grid;
  place-items: center;
  width: 1.75rem;
  height: 1.75rem;
}
.bulk_transfer .bulk_transfer__upload-container .upload-container-select__item figure svg {
  width: 100%;
  height: 100%;
}
.bulk_transfer .bulk_transfer__upload-container .upload-container-select__item .item__span {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.bulk_transfer .bulk_transfer__upload-container .upload-container-select__item .item__span small {
  color: #676767;
  font-weight: 400;
  font-size: 1.2rem;
}
.bulk_transfer .bulk_transfer__upload-container .upload-container-select__item .item__active {
  font-size: 1.4rem;
  font-weight: 600;
  color: #1ace37;
}
.bulk_transfer .bulk_transfer__upload-container .upload-container-select__item.active {
  border: 1.6px solid #014345;
  background: #e9f5ff;
}
.bulk_transfer .bulk_transfer__upload-container .upload-container-select__item.active p {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 140%;
  color: #014345;
}
.bulk_transfer .bulk_transfer__upload-container .upload-container-select__item:not(.active) {
  display: flex;
  gap: 0.7rem;
}
.bulk_transfer .bulk_transfer__upload-container .upload-container-select__confirm-business {
  border: 1px dashed #e5e6e6;
}
.bulk_transfer .bulk_transfer__upload-container p {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 140%;
  color: black;
}
.bulk_transfer .bulk_transfer__upload-container .upload-container-select__upload {
  display: flex;
  flex-direction: column;
}
.bulk_transfer .bulk_transfer__upload-container .upload-container-select__upload .upload__file-size {
  font-weight: 400;
  font-size: 1.2rem;
  margin-top: 1.2rem;
  color: #1b1b1b;
}
.bulk_transfer .bulk_transfer__hint-wrap {
  display: flex;
  background: #e9f5ff;
  border-radius: 1.2rem;
  padding: 2rem;
  flex-direction: column;
  margin-top: 2rem;
}
.bulk_transfer .bulk_transfer__hint-wrap .title {
  font-weight: 700;
  font-size: 1.4rem;
  display: flex;
  margin-bottom: 2rem;
  align-items: center;
  color: #1b1b1b;
}
.bulk_transfer .bulk_transfer__hint-wrap span {
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
}
.bulk_transfer .bulk_transfer__hint-wrap span p {
  font-weight: 400;
  font-size: 1.2rem;
  color: #476885;
}
.bulk_transfer .bulk_transfer__button-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.7rem;
  margin-top: 4.8rem;
}
.bulk_transfer .bulk_transfer__button-group .button-group__buttons {
  width: 100%;
}

.bulk-transfer__table-wrap {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  flex: 1;
}
.bulk-transfer__table-wrap .table-wrap__add-row-btn {
  background: #e9f5ff;
  display: flex;
  margin-top: 2rem;
  align-items: center;
}
.bulk-transfer__table-wrap .table-wrap__add-row-btn figure {
  width: 1.5rem;
  height: 1.5rem;
}
.bulk-transfer__table-wrap .table-wrap__add-row-btn figure svg {
  width: 100%;
  height: 100%;
}
.bulk-transfer__table-wrap .table-wrap__add-row-btn p {
  color: #014345;
}
.bulk-transfer__table-wrap .transaction-status {
  display: grid;
  place-items: center;
  background-color: aliceblue;
  padding: 0.8rem 1.2rem;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 7.2rem;
}
.bulk-transfer__table-wrap .transaction-status p {
  font-weight: 600;
  font-size: 1.2rem;
}
.bulk-transfer__table-wrap .transaction-status.pending {
  background-color: #fff6ed;
  text-transform: capitalize;
}
.bulk-transfer__table-wrap .transaction-status.pending p {
  color: #ea872d;
}
.bulk-transfer__table-wrap .transaction-status.successful {
  background-color: #e8fff6;
  text-transform: capitalize;
}
.bulk-transfer__table-wrap .transaction-status.successful p {
  color: #1ace37;
}
.bulk-transfer__table-wrap .transaction-status.error {
  background-color: #fff5f5;
  text-transform: capitalize;
}
.bulk-transfer__table-wrap .transaction-status.error p {
  color: #ff0f00;
}
.bulk-transfer__table-wrap .table__main .account_name {
  display: flex;
  align-items: center;
  gap: 1.6rem;
}
.bulk-transfer__table-wrap .table__main .account_name span {
  background-color: #e9f5ff;
  display: grid;
  place-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 7.5rem;
}
.bulk-transfer__table-wrap .table__main .account_name span p {
  font-weight: 600;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: #014345;
}
.bulk-transfer__table-wrap .table__main .account_name--placeholder {
  padding: 0.8rem 1.2rem;
  /* RAVEN/Grey shade 10 */
  width: -moz-max-content;
  width: max-content;
  background: #f7f8f7;
  border-radius: 7.2rem;
}
.bulk-transfer__table-wrap .table__main .account_name--placeholder p {
  background: #cccccc;
  padding: 0.5rem 3rem;
  border-radius: 2.4rem;
}

.table-container {
  height: 100%;
  width: 100%;
  max-width: 90%;
  flex-direction: column;
}
.table-container__arrow-back {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  transition: transform 300ms ease-in-out;
  cursor: pointer;
  margin-top: 2rem;
  margin-left: 2rem;
}
.table-container__arrow-back:hover {
  transform: translateX(-3px);
}
.table-container__arrow-back svg {
  width: 100%;
  height: 100%;
}
.table-container__arrow-back svg path {
  stroke: black;
}
.table-container .content__main {
  display: flex;
  width: 100%;
  overflow-y: scroll;
  flex: 1;
  flex-direction: column;
}
.table-container .content__main::-webkit-scrollbar {
  display: none;
}
.table-container .content__main--top-bar {
  display: flex;
  align-items: center;
  padding: 2rem;
  width: 100%;
  justify-content: space-between;
}
.table-container .content__main--top-bar .top-bar__title {
  display: flex;
  align-items: center;
  gap: 2.4rem;
}
.table-container .content__main--top-bar .top-bar__title h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */
  display: flex;
  align-items: center;
  /* RAVEN/Base Black */
  color: #1b1b1b;
}
.table-container .content__main--top-bar .top-bar__title p {
  display: none;
}
.table-container .content__main--top-bar .top-bar__title p.active {
  display: grid;
  place-items: center;
  padding: 0.8rem 1.2rem;
  background: #f7f8f7;
  border-radius: 7.2rem;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 140%;
  text-align: center;
  color: #014345;
}
.table-container .content__main .main__active-filters {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  max-height: 0;
  display: flex;
  transition: max-height 300ms ease-in-out;
}
.table-container .content__main .main__active-filters--show {
  display: flex;
  overflow: unset;
  max-height: 30rem;
  transition: max-height 300ms ease-in-out;
}
.table-container .content__main .main__active-filters .active-filters__item {
  background: #e9f5ff;
  border-radius: 7.2rem;
  display: flex;
  padding: 0.55rem 0.7rem;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.table-container .content__main .main__active-filters .active-filters__item figure {
  width: 1.75rem;
  height: 1.75rem;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.table-container .content__main .main__active-filters .active-filters__item figure svg {
  width: 100%;
  height: 100%;
}
.table-container .content__main .main__active-filters .active-filters__item p {
  color: #476885;
  display: flex;
  align-items: center;
  gap: 0.6rem;
}
.table-container .content__main .main__active-filters .active-filters__clear-item {
  background: #fff5f5;
  border-radius: 72px;
  border-radius: 7.2rem;
  display: flex;
  padding: 0.55rem 0.7rem;
  align-items: center;
  cursor: pointer;
}
.table-container .content__main .main__active-filters .active-filters__clear-item p {
  font-weight: 600;
  font-size: 1.2rem;
  color: #ff0f00;
}
.table-container .content__main .main__wrap {
  display: flex;
  margin-top: 2rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.table-container .content__main .main__wrap .transaction__table {
  width: 100%;
  height: 100%;
  border-radius: 1.2rem;
  overflow: auto;
}
.table-container .content__main .main__wrap .transaction__table::-webkit-scrollbar {
  display: none;
}
.table-container .content__main .main__wrap .transaction__table .table__main {
  border-radius: 1.2rem;
  overflow: auto;
}
.table-container .content__main .main__wrap .transaction__table .table__main .main__direction {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
.table-container .content__main .main__wrap .transaction__table .table__main .main__direction figure {
  display: grid;
  place-items: center;
  width: 2.75rem;
  height: 2.75rem;
}
.table-container .content__main .main__wrap .transaction__table .table__main .main__direction figure svg {
  width: 100%;
  height: 100%;
}
.table-container__buttons {
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: flex-end;
  margin-top: 0.4rem;
  gap: 1.6rem;
  padding: 2rem;
}
.table-container__buttons .reupload {
  background: #e9f5ff;
  color: black;
}

.table-container-manual {
  height: 100%;
  width: 100%;
  border-radius: 1.2rem;
  flex-direction: column;
  background-color: white;
}
.table-container-manual__arrow-back {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  transition: transform 300ms ease-in-out;
  cursor: pointer;
  margin-top: 2rem;
  margin-left: 2rem;
}
.table-container-manual__arrow-back:hover {
  transform: translateX(-3px);
}
.table-container-manual__arrow-back svg {
  width: 100%;
  height: 100%;
}
.table-container-manual__arrow-back svg path {
  stroke: black;
}
.table-container-manual .content__main {
  display: flex;
  width: 100%;
  overflow-y: scroll;
  flex: 1;
  flex-direction: column;
}
.table-container-manual .content__main::-webkit-scrollbar {
  display: none;
}
.table-container-manual .content__main--top-bar {
  display: flex;
  align-items: center;
  padding: 2rem;
  width: 100%;
  justify-content: space-between;
}
.table-container-manual .content__main--top-bar .top-bar__title {
  display: flex;
  align-items: center;
  gap: 2.4rem;
}
.table-container-manual .content__main--top-bar .top-bar__title h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */
  display: flex;
  align-items: center;
  /* RAVEN/Base Black */
  color: #1b1b1b;
}
.table-container-manual .content__main--top-bar .top-bar__title p {
  display: none;
}
.table-container-manual .content__main--top-bar .top-bar__title p.active {
  display: grid;
  place-items: center;
  padding: 0.8rem 1.2rem;
  background: #f7f8f7;
  border-radius: 7.2rem;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 140%;
  text-align: center;
  color: #014345;
}
.table-container-manual .content__main .main__active-filters {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  max-height: 0;
  display: flex;
  transition: max-height 300ms ease-in-out;
}
.table-container-manual .content__main .main__active-filters--show {
  display: flex;
  overflow: unset;
  max-height: 30rem;
  transition: max-height 300ms ease-in-out;
}
.table-container-manual .content__main .main__active-filters .active-filters__item {
  background: #e9f5ff;
  border-radius: 7.2rem;
  display: flex;
  padding: 0.55rem 0.7rem;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.table-container-manual .content__main .main__active-filters .active-filters__item figure {
  width: 1.75rem;
  height: 1.75rem;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.table-container-manual .content__main .main__active-filters .active-filters__item figure svg {
  width: 100%;
  height: 100%;
}
.table-container-manual .content__main .main__active-filters .active-filters__item p {
  color: #476885;
  display: flex;
  align-items: center;
  gap: 0.6rem;
}
.table-container-manual .content__main .main__active-filters .active-filters__clear-item {
  background: #fff5f5;
  border-radius: 72px;
  border-radius: 7.2rem;
  display: flex;
  padding: 0.55rem 0.7rem;
  align-items: center;
  cursor: pointer;
}
.table-container-manual .content__main .main__active-filters .active-filters__clear-item p {
  font-weight: 600;
  font-size: 1.2rem;
  color: #ff0f00;
}
.table-container-manual .content__main .main__wrap {
  display: flex;
  margin-top: 2rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.table-container-manual .content__main .main__wrap .transaction__table {
  width: 100%;
  height: 100%;
  border-radius: 1.2rem;
  overflow: auto;
}
.table-container-manual .content__main .main__wrap .transaction__table::-webkit-scrollbar {
  display: none;
}
.table-container-manual .content__main .main__wrap .transaction__table .table__main {
  border-radius: 1.2rem;
  overflow: auto;
}
.table-container-manual .content__main .main__wrap .transaction__table .table__main .main__direction {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
.table-container-manual .content__main .main__wrap .transaction__table .table__main .main__direction figure {
  display: grid;
  place-items: center;
  width: 2.75rem;
  height: 2.75rem;
}
.table-container-manual .content__main .main__wrap .transaction__table .table__main .main__direction figure svg {
  width: 100%;
  height: 100%;
}
.table-container-manual__buttons {
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: flex-end;
  margin-top: 0.4rem;
  gap: 1.6rem;
  padding: 2rem;
  border-top: 1px solid #e5e6e6;
}
.table-container-manual__buttons .reupload {
  background: #e9f5ff;
  color: black;
}

.bulk-transfer-summary {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  flex: 1;
}
.bulk-transfer-summary__arrow-back {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  transition: transform, margin 300ms ease-in-out;
  cursor: pointer;
}
.bulk-transfer-summary__arrow-back:hover {
  margin-left: 4px;
}
.bulk-transfer-summary__arrow-back svg {
  width: 100%;
  height: 100%;
}
.bulk-transfer-summary__arrow-back svg path {
  stroke: black;
}
.bulk-transfer-summary__title {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 1.4rem;
}
.bulk-transfer-summary__title p {
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  color: #676767;
}
.bulk-transfer-summary__title h5 {
  font-weight: 600;
  font-size: 2rem;
  display: flex;
  align-items: center;
  color: #000000;
}
.bulk-transfer-summary__table {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 3rem;
}
.bulk-transfer-summary__table .table__item {
  width: 100%;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee;
}
.bulk-transfer-summary__table .table__item p {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #000000;
}
.bulk-transfer-summary__button {
  width: 100%;
  margin-top: 6rem;
}
.bulk-transfer-summary__button .act-btn {
  width: 100%;
}/*# sourceMappingURL=index.css.map */