.basic-details-settings {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex: 1;
  gap: 3.2rem;
  overflow-y: auto;
}
.basic-details-settings .grouped__input {
  display: flex;
  margin-top: 2rem;
  position: relative;
}
.basic-details-settings .grouped__input .input__copy-icon {
  z-index: 200;
  position: absolute;
  top: 3.5rem;
  right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  place-items: center;
}
.basic-details-settings .grouped__input .input__copy-icon figure {
  display: grid;
  place-items: center;
  width: 2.5rem;
  cursor: pointer;
  height: 2.5rem;
}
.basic-details-settings__app-name {
  display: flex;
  gap: 2.4rem;
  flex-direction: column;
  display: flex;
  padding: 2.2rem 1.6rem 1.6rem 1.6rem;
  border-radius: 12px;
  border: 1px solid var(--raven-black-100, #eee);
}
.basic-details-settings__app-name--avatar {
  flex-direction: row;
  display: flex;
  align-items: center;
  gap: 2rem;
  padding-bottom: 2rem;
  border-radius: var(--Count, 0px);
  border-bottom: 1px solid var(--raven-black-100, #eee);
}
.basic-details-settings__app-name--avatar p {
  color: var(--RAVEN-GREY, #676767);
  font-size: 1.6rem;
  font-style: normal;
  width: 45%;
  font-weight: 400;
}
.basic-details-settings__app-name--avatar img {
  width: 10rem;
  -o-object-fit: cover;
     object-fit: cover;
  height: 10rem;
  border-radius: 72px;
}
.basic-details-settings__app-name--input-group {
  display: flex;
  align-items: end;
  gap: 2rem;
}
.basic-details-settings__app-name--input-group .input-group__color-swatch {
  display: flex;
  gap: 0.6rem;
  height: 75%;
  padding: 0.6rem;
  border-radius: 12px;
  align-items: center;
  border: 0.5px solid var(--raven-black-200, #e3e3e3);
  position: relative;
}
.basic-details-settings__app-name--input-group .input-group__color-swatch .swatch-drop {
  top: 5rem;
  right: 0;
}
.basic-details-settings__app-name--input-group .input-group__color-swatch--color-preview {
  width: 11.3rem;
  height: 100%;
  border-radius: 8px;
  background: var(--primary-green-100, #0b8376);
  align-items: center;
  display: flex;
  justify-content: center;
  flex: 1;
}
.basic-details-settings__app-name--input-group .input-group__color-swatch--color-preview p {
  color: rgba(255, 255, 255, 0.32);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 16.8px */
}
.basic-details-settings__app-name--input-group .input-group__color-swatch--dropdown {
  display: flex;
  width: 3.8rem;
  height: 4.2rem;
  padding: 1rem 0.8rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--raven-black-50, #f7f7f7);
}
.basic-details-settings__prices {
  display: flex;
  flex-direction: column;
  padding: 2.2rem 1.6rem 1.6rem 1.6rem;
  border-radius: 12px;
  border: 1px solid var(--raven-black-100, #eee);
}
.basic-details-settings__prices--actionbar {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}
.basic-details-settings__prices--title {
  color: var(--themedarkmode, #000);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 1.6rem;
}
.basic-details-settings__prices--input-group {
  display: flex;
  gap: 2rem;
  width: 100%;
  margin-top: 0.8rem;
}
.basic-details-settings__prices--input-group.incomplete {
  margin-top: 3.6rem !important;
}
.basic-details-settings__prices--input-group .inputs {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  gap: 2.4rem;
}
.basic-details-settings .wrap {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 2rem;
}

.colour-pallete {
  display: flex;
  width: -moz-min-content;
  width: min-content;
  flex-direction: column;
  gap: 2rem;
  background-color: white;
  padding: 1.6rem;
  border-radius: 12px;
  box-shadow: 0px -3px 48px -8px #f7f8f7, 0px 4px 23px 12px #f7f8f7;
}/*# sourceMappingURL=index.css.map */