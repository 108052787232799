.wallet {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.wallet .wallet__title {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.wallet .wallet__title .title__main {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.wallet .wallet__title .title__main h5 {
  font-weight: 700;
  font-size: 2rem;
  line-height: 140%;
}
.wallet .wallet__title .title__main p {
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 160%;
  color: #676767;
}
.wallet .wallet__title .title__swap-btn {
  display: flex;
  align-items: center;
  padding: 1rem 2.3rem;
  gap: 1rem;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 10px;
  transition: transform 0.2s linear;
}
.wallet .wallet__title .title__swap-btn:hover {
  transform: translateY(-3px);
}
.wallet .wallet__title .title__swap-btn:hover figure {
  animation: rotate 1s cubic-bezier(0.1, 0.5, 0.3, 1);
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(100deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.wallet .wallet__title .title__swap-btn p {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #014345;
}
.wallet .wallet__title .title__swap-btn figure {
  display: grid;
  place-items: center;
  width: 2rem;
  height: 2rem;
}
.wallet .wallet__title .title__swap-btn figure svg {
  width: 100%;
  height: 100%;
}
.wallet .wallet__wallets-wrap {
  display: flex;
  align-items: flex-start;
  padding: 2rem;
  background: #ffffff;
  border-radius: 1.8rem;
  margin-top: 3.2rem;
  width: 100%;
  gap: 2.4rem;
  flex: 1;
  height: 80%;
}
.wallet .wallet__wallets-wrap .wallets-wrap__selector {
  background: #f9f9f9;
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  width: 15%;
  gap: 3rem;
  padding: 2rem;
}
.wallet .wallet__wallets-wrap .wallets-wrap__selector .selector__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 12.8rem;
  padding: 1.6rem 2.4rem;
  gap: 0.8rem;
  cursor: pointer;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 10px;
}
.wallet .wallet__wallets-wrap .wallets-wrap__selector .selector__item.tooltip-hover-wrap {
  cursor: no-drop;
  z-index: 100000;
}
.wallet .wallet__wallets-wrap .wallets-wrap__selector .selector__item.tooltip-hover-wrap .text-white {
  color: white;
}
.wallet .wallet__wallets-wrap .wallets-wrap__selector .selector__item p {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #8b8b8b;
}
.wallet .wallet__wallets-wrap .wallets-wrap__selector .selector__item svg {
  width: 2rem;
  height: 2rem;
  display: grid;
  place-items: center;
}
.wallet .wallet__wallets-wrap .wallets-wrap__selector .selector__item.active {
  background: #ffffff;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .wallet_slide-wrap {
  min-height: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 500ms cubic-bezier(0.23, 1, 0.32, 1), min-height 500ms cubic-bezier(0.23, 1, 0.32, 1);
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .wallet_slide-wrap.show {
  min-height: 25rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid var(--raven-black-200, #e3e3e3);
  max-height: 30rem;
  overflow: auto;
  transition: max-height 500ms cubic-bezier(0.23, 1, 0.32, 1), min-height 500ms cubic-bezier(0.23, 1, 0.32, 1);
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__main {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin-top: 2rem;
  z-index: 0 !important;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__main--top-bar {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
  justify-content: space-between;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__main--top-bar .top-bar__filter {
  display: flex;
  align-items: center;
  gap: 2.4rem;
  align-items: center;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__main--top-bar .top-bar__filter .main__active-filters {
  align-items: center;
  overflow: hidden;
  max-height: 0;
  gap: 1rem;
  display: flex;
  transition: max-height 300ms ease-in-out;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__main--top-bar .top-bar__filter .main__active-filters--show {
  display: flex;
  overflow: unset;
  max-height: 30rem;
  transition: max-height 300ms ease-in-out;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__main--top-bar .top-bar__filter .main__active-filters .active-filters__collections {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-direction: row;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__main--top-bar .top-bar__filter .main__active-filters .active-filters__item {
  background: #e9f5ff;
  border-radius: 7.2rem;
  display: flex;
  padding: 0.55rem 0.7rem;
  align-items: center;
  gap: 1rem;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__main--top-bar .top-bar__filter .main__active-filters .active-filters__item figure {
  width: 1.5rem;
  height: 1.5rem;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__main--top-bar .top-bar__filter .main__active-filters .active-filters__item figure svg {
  width: 100%;
  height: 100%;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__main--top-bar .top-bar__filter .main__active-filters .active-filters__item p {
  color: #476885;
  display: flex;
  font-size: 1.2rem;
  align-items: center;
  gap: 0.6rem;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__main--top-bar .top-bar__filter .main__active-filters .active-filters__clear-item {
  background: #fff5f5;
  border-radius: 72px;
  border-radius: 7.2rem;
  display: flex;
  padding: 0.55rem 0.7rem;
  align-items: center;
  cursor: pointer;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__main--top-bar .top-bar__filter .main__active-filters .active-filters__clear-item p {
  font-weight: 600;
  font-size: 1.2rem;
  color: #ff0f00;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__main .main__wrap {
  display: flex;
  margin-top: 2rem;
  width: 100%;
  height: 85%;
  overflow: hidden;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__main .main__wrap .transaction__table {
  width: 100%;
  height: 100%;
  border-radius: 1.2rem;
  overflow: auto;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__main .main__wrap .transaction__table .table__main {
  border-radius: 1.2rem;
  overflow: auto;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__main .main__wrap .transaction__table .table__main .session-wrap {
  position: relative;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__main .main__wrap .transaction__table .table__main .main__direction {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__main .main__wrap .transaction__table .table__main .main__direction figure {
  display: grid;
  place-items: center;
  width: 2.75rem;
  height: 2.75rem;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__main .main__wrap .transaction__table .table__main .main__direction figure svg {
  width: 100%;
  height: 100%;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__top-bar {
  display: flex;
  margin-bottom: 2rem;
  margin-top: 2rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  z-index: 0 !important;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__top-bar * {
  z-index: -100 !important;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__top-bar .top-bar__filter-export {
  display: flex;
  gap: 1.6rem;
  align-items: center;
  z-index: 1;
  position: relative;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__top-bar .top-bar__filter-export .filter-export__export-btn,
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__top-bar .top-bar__filter-export .filter-export__filter-btn {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 1.6rem 2.4rem;
  gap: 0.8em;
  cursor: pointer;
  height: 5rem;
  background: #f7f8f7;
  border-radius: 1rem;
  z-index: 100;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__top-bar .top-bar__filter-export .filter-export__export-btn p,
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__top-bar .top-bar__filter-export .filter-export__filter-btn p {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #676767;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__top-bar .top-bar__filter-export .filter-export__export-btn figure,
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__top-bar .top-bar__filter-export .filter-export__filter-btn figure {
  width: -moz-fit-content;
  width: fit-content;
  display: grid;
  place-items: center;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__top-bar .top-bar__filter-export .filter-export__export-btn figure svg,
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__top-bar .top-bar__filter-export .filter-export__filter-btn figure svg {
  width: 2rem;
  height: 2rem;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__top-bar .top-bar__filter-export .filter-export__drop-down {
  position: absolute;
  background: #ffffff;
  width: -moz-max-content;
  width: max-content;
  height: -moz-max-content;
  height: max-content;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 1.6rem;
  box-shadow: 0;
  top: 110%;
  right: 55%;
  padding: 2rem;
  transform-origin: top right;
  transform: translateY(-10%);
  opacity: 0.8;
  transition: all 0.3s ease-in-out;
  z-index: 100;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__top-bar .top-bar__filter-export .filter-export__drop-down--open {
  transform: translateY(0%);
  opacity: 1;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__switch {
  display: flex;
  width: 100%;
  cursor: pointer;
  justify-content: flex-start;
  gap: 1.2rem;
  border-bottom: 0.8px solid #e5e6e6;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__switch span {
  gap: 0.4rem;
  padding-bottom: 1.6rem;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__switch span p {
  font-weight: 400;
  font-size: 1.4rem;
  display: grid;
  place-items: center;
  padding: 1.2rem 1.6rem;
  border-radius: 8px;
  color: #1b1b1b;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__switch span.active {
  border-bottom: 4px solid #014345;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__switch span.active p {
  color: #014345;
  font-weight: 600;
  background: #e9f5ff;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range {
  display: flex;
  flex-direction: column;
  margin-top: 2.4rem;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range h5 {
  font-weight: 600;
  font-size: 1.4rem;
  color: #014345;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range .custom-date-range__grouped-form {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 1.2rem;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range .drop-down__action-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  margin-top: 5.2rem;
  padding: 2.3rem 0rem 0.3rem 0rem;
  border-top: 0.7px solid #e5e6e6;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range .drop-down__action-btns button {
  width: 50%;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range .drop-down__action-btns button:first-child {
  background: #e9f5ff;
  color: #014345;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__top-bar .top-bar__filter-export .filter-export__drop-down-closer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  display: none;
  visibility: none;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .content__top-bar .top-bar__filter-export .filter-export__drop-down-closer--opened {
  display: unset;
  visibility: unset;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .balances-wrap__main {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  border-bottom: 1px solid #e3e3e3;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .balances-wrap__main .main__balances {
  display: flex;
  align-items: center;
  gap: 4rem;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .balances-wrap__main .main__balances .balances__available,
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .balances-wrap__main .main__balances .balances__pending {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding-right: 4rem;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .balances-wrap__main .main__balances .balances__available p,
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .balances-wrap__main .main__balances .balances__pending p {
  font-weight: 400;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  color: #676767;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .balances-wrap__main .main__balances .balances__available h5,
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .balances-wrap__main .main__balances .balances__pending h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  display: flex;
  align-items: center;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .balances-wrap__main .main__balances .balances__pending h5 {
  color: #8b8b8b;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .balances-wrap__main .main__balances .balances__available {
  border-right: 1px solid #e3e3e3;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .balances-wrap__main .main__buttons {
  display: flex;
  gap: 2rem;
  margin-top: 4rem;
  align-items: center;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .balances-wrap__main .main__buttons span {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.8rem 1.4rem 0.8rem 1rem;
  gap: 10px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 300px;
  cursor: pointer;
  transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .balances-wrap__main .main__buttons span:hover {
  transform: translateY(-2px);
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .balances-wrap__main .main__buttons span:hover figure {
  transform: scale(1.2);
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .balances-wrap__main .main__buttons span figure {
  display: grid;
  place-items: center;
  width: 2rem;
  height: 2rem;
  transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .balances-wrap__main .main__buttons span figure svg {
  width: 100%;
  height: 100%;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .balances-wrap__transactions {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 12px;
  border: 0.6px solid var(--ravengreyshade-50, #e5e6e6);
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .balances-wrap__transactions .transaction__title {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .balances-wrap__transactions .transaction__title h5 {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #1b1b1b;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .balances-wrap__transactions .transaction__title p {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #755ae2;
  cursor: pointer;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .balances-wrap__transactions .transaction__title p:hover svg {
  transform: translateX(3px);
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .balances-wrap__transactions .transaction__title p svg {
  width: 1.5rem;
  height: 1.5rem;
  transition: transform 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .balances-wrap__transactions .transaction__table {
  width: 100%;
  height: 100%;
  border-radius: 1.2rem;
  overflow: auto;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .balances-wrap__transactions .transaction__table .table__main {
  border-radius: 1.2rem;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .balances-wrap__transactions .transaction__table .table__main .main__direction {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .balances-wrap__transactions .transaction__table .table__main .main__direction figure {
  display: grid;
  place-items: center;
  width: 2.75rem;
  height: 2.75rem;
}
.wallet .wallet__wallets-wrap .wallets-wrap__balances-wrap .balances-wrap__transactions .transaction__table .table__main .main__direction figure svg {
  width: 100%;
  height: 100%;
}

.fund-modal-wrap {
  width: 100%;
}
.fund-modal-wrap.no-btn .input-submit {
  visibility: hidden;
}
.fund-modal-wrap .raven-modal-content-wrap {
  width: 25%;
}
.fund-modal-wrap .fund-modal-wrap__content {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.fund-modal-wrap .fund-modal-wrap__content .content__title {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.fund-modal-wrap .fund-modal-wrap__content .content__title span h5 {
  font-weight: 600;
  font-size: 2rem;
  display: flex;
  align-items: center;
}
.fund-modal-wrap .fund-modal-wrap__content .content__title span p {
  font-weight: 400;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  color: #676767;
}
.fund-modal-wrap .fund-modal-wrap__content .content__title figure {
  display: grid;
  place-items: center;
  width: 3.5rem;
  height: 3.5rem;
}
.fund-modal-wrap .fund-modal-wrap__content .content__title figure svg {
  width: 100%;
  height: 100%;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main {
  display: flex;
  flex-direction: column;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__label {
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  margin-top: 3.2rem;
  margin-bottom: 1rem;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details {
  display: flex;
  flex-direction: column;
  border: 0.7px solid #e5e6e6;
  border-radius: 1.2rem;
  width: 100%;
  position: relative;
  height: 100%;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .panel {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
  transform: translateX(100%);
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .panel.active {
  transform: translateX(0);
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item {
  display: flex;
  padding: 2.2rem 1.6rem;
  width: 100%;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item .checkmark {
  width: 2.5rem;
  height: 2.5rem;
  display: grid;
  margin: 0.1rem 0rem;
  border-radius: 7.5rem;
  background-color: #e8fff6;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item .checkmark svg {
  width: 50%;
  height: 50%;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item.address {
  flex-direction: column;
  width: 100%;
  gap: 0.8rem;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item.address p {
  font-size: 1.2rem;
  width: 100%;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item.address span {
  width: 100%;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item.address span p {
  color: #676767;
  font-size: 1.2rem;
  width: 100%;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item p {
  font-weight: 400;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item:not(:last-child) {
  border-bottom: 0.6px solid #e5e6e6;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item p:first-child {
  width: 40%;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item p:last-child {
  width: 60%;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item span {
  display: flex;
  width: 60%;
  justify-content: space-between;
  align-items: center;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item span figure {
  display: grid;
  place-items: center;
  width: 2.5rem;
  cursor: pointer;
  height: auto;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item span figure svg {
  width: 100%;
  height: 100;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__swipe-indicator {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: 2.7rem;
  width: -moz-max-content;
  width: max-content;
  align-self: center;
  background: #f9f9f9;
  border-radius: 12.8rem;
  margin-top: 2.9rem;
  padding: 1.2rem 1.4rem;
  cursor: pointer;
  position: relative;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__swipe-indicator p {
  padding: 0.4rem 1.3rem;
  background: #b9defe;
  border-radius: 3.2rem;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__swipe-indicator p.active {
  background-color: #014345;
  width: 5rem;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__swipe-indicator .tab {
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 1;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__swipe-indicator .tab.active {
  background-color: #014345;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__swipe-indicator .ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #014345;
  opacity: 0;
  transform: scale(0);
  pointer-events: none;
  z-index: 0;
}
@keyframes rippleEffect {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 0.5;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}

.swap-modal-wrap .swap-modal-wrap__content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.swap-modal-wrap .swap-modal-wrap__content .content__title {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.swap-modal-wrap .swap-modal-wrap__content .content__title span h5 {
  font-weight: 600;
  font-size: 2rem;
  display: flex;
  align-items: center;
}
.swap-modal-wrap .swap-modal-wrap__content .content__title span p {
  font-weight: 400;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  color: #676767;
}
.swap-modal-wrap .swap-modal-wrap__content .content__form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2.8rem;
}
.swap-modal-wrap .swap-modal-wrap__content .content__form .form__grouped {
  display: flex;
  z-index: 20;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1.8rem;
}
.swap-modal-wrap .swap-modal-wrap__content .content__form .form__grouped input {
  width: 100%;
}
.swap-modal-wrap .swap-modal-wrap__content .content__form .form__grouped .grouped__swap-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 4rem;
  transform: translateY(25%);
  border-radius: 30px;
  background: #e9f5ff;
}
.swap-modal-wrap .swap-modal-wrap__content .content__form .form__grouped .grouped__swap-icon figure {
  width: 60%;
  height: 60%;
  display: grid;
  place-items: center;
}
.swap-modal-wrap .swap-modal-wrap__content .content__form .form__grouped .grouped__swap-icon figure svg {
  width: 100%;
  height: 100%;
}
.swap-modal-wrap .swap-modal-wrap__content .content__form .form__balance {
  font-weight: 600;
  font-size: 1.4rem;
  color: #014345;
  margin-top: 2.8rem;
}
.swap-modal-wrap .swap-modal-wrap__content .content__output {
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e6e6;
  border-radius: 1.2rem;
  padding: 1.2rem;
  margin-top: 4.8rem;
  margin-bottom: 3.6rem;
}
.swap-modal-wrap .swap-modal-wrap__content .content__output .output__rate {
  padding: 0.8rem 1.2rem;
  width: -moz-max-content;
  width: max-content;
  background: #f0eeff;
  border-radius: 72px;
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
  color: #755ae2;
}
.swap-modal-wrap .swap-modal-wrap__content .content__output .output__value {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.4rem;
  margin-top: 1.9rem;
}
.swap-modal-wrap .swap-modal-wrap__content .content__output .output__value h5 {
  font-weight: 700;
  font-size: 2rem;
  display: flex;
  align-items: center;
}
.swap-modal-wrap .swap-modal-wrap__content .content__output .output__value p {
  font-weight: 400;
  font-size: 1.4rem;
  color: #676767;
}

.account-details__item .item__ravenpay {
  width: 100%;
  margin-top: 1.4rem;
  margin-bottom: 11.4rem;
}/*# sourceMappingURL=index.css.map */