.kyc-dashboard-wrapper {
  direction: flex;
  flex-direction: column;
  background-color: white;
  height: 100%;
  flex: 1;
  width: 100%;
  padding: 5.6rem 2rem 2rem 2rem;
}
.kyc-dashboard-wrapper__title {
  display: flex;
  flex-direction: column;
}
.kyc-dashboard-wrapper__title h5 {
  color: #000;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 700;
}
.kyc-dashboard-wrapper__title span {
  color: var(--raven-base-bg-dark, #676767);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
}
.kyc-dashboard-wrapper__select-verification-type {
  display: flex;
  flex-direction: column;
  margin-top: 3.2rem;
}
.kyc-dashboard-wrapper__form-wrap {
  display: flex;
  padding: 1.2rem;
  justify-content: center;
  max-height: 35rem;
  overflow-y: scroll;
  border-radius: 1.2rem;
  margin-top: 2.8rem;
  border-top: 0.6px solid var(--raven-black-300, #d1d1d1);
  flex-direction: column;
  gap: 2.4rem;
}
.kyc-dashboard-wrapper__form-wrap .grouped-input {
  display: flex;
  width: 100%;
  gap: 1.7rem;
}
.kyc-dashboard-wrapper__form-wrap .grouped-input .grouped-input__item {
  width: 50%;
}
.kyc-dashboard-wrapper__form-wrap .grouped-input .grouped-input__item input {
  width: 100%;
}
.kyc-dashboard-wrapper__form-btn {
  margin-top: 5rem;
}
.kyc-dashboard-wrapper__verified-details {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
}
.kyc-dashboard-wrapper__verified-details .avatar {
  width: 7rem;
  height: 7rem;
  background-color: #f7f8f7;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.kyc-dashboard-wrapper__verified-details .avatar p {
  color: var(--primary-darkgreen-100, #014345);
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
}
.kyc-dashboard-wrapper__verified-details .action_bar__info .action-box .arrow-icon {
  display: none;
}
.kyc-dashboard-wrapper__verified-details .verified-details__information {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2rem;
}
.kyc-dashboard-wrapper__verified-details .verified-details__information--owner {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  border-bottom: 1px solid var(--raven-black-200, #e3e3e3);
  padding-bottom: 2rem;
}
.kyc-dashboard-wrapper__verified-details .verified-details__information--owner span {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
.kyc-dashboard-wrapper__verified-details .verified-details__information--owner span h5 {
  font-weight: 700;
  font-size: 2.4rem;
}
.kyc-dashboard-wrapper__verified-details .verified-details__information--owner span p {
  overflow: hidden;
  color: var(--raven-base-bg-dark, #676767);
  text-overflow: ellipsis;
  font-size: 1.4rem;
}
.kyc-dashboard-wrapper__verified-details .verified-details__information--owner figure {
  width: 10rem;
  height: 10rem;
  display: grid;
  place-items: center;
  -o-object-fit: contain;
     object-fit: contain;
  overflow: hidden;
  border-radius: 50%;
}
.kyc-dashboard-wrapper__verified-details .verified-details__information--owner figure img {
  width: 10rem;
  -o-object-fit: 100%;
     object-fit: 100%;
  -o-object-position: top center;
     object-position: top center;
  height: 10rem;
}
.kyc-dashboard-wrapper__verified-details .verified-details__information--details {
  display: flex;
  flex: 1;
  width: 100%;
  margin-top: 3.2rem;
}
.kyc-dashboard-wrapper__verified-details .verified-details__information--details div {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.kyc-dashboard-wrapper__verified-details .verified-details__information--details div span {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.kyc-dashboard-wrapper__verified-details .verified-details__information--details div span small {
  color: var(--raven-base-bg-dark, #676767);
  font-size: 1.2rem;
  font-weight: 500;
}
.kyc-dashboard-wrapper__verified-details .verified-details__information--details div span p {
  color: var(--raven-base-background-dark, #020202);
  font-size: 1.4rem;
}
.kyc-dashboard-wrapper__verified-details .verified-details__information--details div {
  flex: 0 0 25%;
}
.kyc-dashboard-wrapper__verified-details .verified-details__information--preview-json {
  display: flex;
  width: 100%;
  float: bottom;
  justify-content: flex-end;
}
.kyc-dashboard-wrapper__verified-details .verified-details__information--preview-json div {
  display: flex;
  cursor: pointer;
  height: 5.4rem;
  padding: 1.6rem 2.4rem;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--raven-black-50, #f7f7f7);
}
.kyc-dashboard-wrapper__verified-details .verified-details__information--preview-json div p {
  color: var(--raven-primary-dark-green, #014345);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
}

.code-editor-wrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 50rem;
  overflow: hidden;
  position: relative;
}
.code-editor-wrap .copy-button {
  position: absolute;
  top: 2rem;
  right: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
  border-radius: 0.7rem;
  border: 1px solid var(--raven-black-200, #e3e3e3);
  padding: 0.5rem 1rem;
  cursor: pointer;
}
.code-editor-wrap .copy-button figure {
  width: 1.5rem;
  height: 1.5rem;
  display: grid;
  place-items: center;
}
.code-editor-wrap .copy-button figure svg {
  width: 100%;
  height: 100%;
}
.code-editor-wrap .copy-button figure svg path {
  stroke: #014345;
}
.code-editor-wrap .copy-button .checkmark {
  border-radius: 50px;
  background-color: #014345;
}
.code-editor-wrap .copy-button .checkmark svg {
  stroke: white;
  width: 70%;
  height: 70%;
}
.code-editor-wrap .copy-button p {
  color: var(--raven-primary-dark-green, #014345);
  font-size: 1.4rem;
  font-weight: 500;
}/*# sourceMappingURL=index.css.map */