.wallet {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;

	.wallet__title {
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		.title__main {
			display: flex;
			flex-direction: column;
			gap: 0.2rem;

			h5 {
				font-weight: 700;
				font-size: 2rem;
				line-height: 140%;
				// color: #000000;
			}

			p {
				font-style: normal;
				font-weight: 400;
				font-size: 1.6rem;
				line-height: 160%;
				color: #676767;
			}
		}

		.title__swap-btn {
			display: flex;
			align-items: center;
			padding: 1rem 2.3rem;
			gap: 1rem;
			cursor: pointer;
			background: #ffffff;
			box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
				0px 48px 64px -36px rgba(51, 51, 51, 0.08);
			border-radius: 10px;
			transition: transform 0.2s linear;

			&:hover {
				transform: translateY(-3px);
				figure {
					animation: rotate 1s cubic-bezier(0.1, 0.5, 0.3, 1);

					@keyframes rotate {
						0% {
							transform: rotate(0deg);
						}
						50% {
							transform: rotate(100deg);
						}
						100% {
							transform: rotate(360deg);
						}
					}
				}
			}

			p {
				font-weight: 600;
				font-size: 1.6rem;
				line-height: 140%;
				display: flex;
				align-items: center;
				color: #014345;
			}

			figure {
				display: grid;
				place-items: center;
				width: 2rem;
				height: 2rem;

				svg {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	.wallet__wallets-wrap {
		display: flex;
		align-items: flex-start;
		padding: 2rem;
		background: #ffffff;
		border-radius: 1.8rem;
		margin-top: 3.2rem;
		width: 100%;
		gap: 2.4rem;
		flex: 1;
		height: 80%;

		.wallets-wrap__selector {
			background: #f9f9f9;
			border-radius: 1.2rem;
			display: flex;
			flex-direction: column;
			height: 100%;
			align-items: center;
			width: 15%;
			gap: 3rem;
			padding: 2rem;

			.selector__item {
				display: flex;
				flex-direction: row;
				align-items: center;
				width: 12.8rem;
				padding: 1.6rem 2.4rem;
				gap: 0.8rem;
				cursor: pointer;

				&.tooltip-hover-wrap {
					cursor: no-drop;
					z-index: 100000;
					.text-white {
						color: white;
					}
				}

				box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
					0px 48px 64px -36px rgba(51, 51, 51, 0.08);
				border-radius: 10px;

				p {
					font-weight: 400;
					font-size: 1.4rem;
					line-height: 140%;
					display: flex;
					align-items: center;
					color: #8b8b8b;
				}

				svg {
					width: 2rem;
					height: 2rem;
					display: grid;
					place-items: center;
				}

				&.active {
					background: #ffffff;
				}
			}
		}

		.wallets-wrap__balances-wrap {
			background: #ffffff;
			// border-bottom: 1px solid #e3e3e3;
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 100%;

			.wallet_slide-wrap {
				min-height: 0;
				max-height: 0;
				overflow: hidden;
				transition: max-height 500ms cubic-bezier(0.23, 1, 0.32, 1),
					min-height 500ms cubic-bezier(0.23, 1, 0.32, 1);

				&.show {
					min-height: 25rem;
					margin-bottom: 2rem;
					border-bottom: 1px solid var(--raven-black-200, #e3e3e3);
					max-height: 30rem;
					overflow: auto;
					transition: max-height 500ms cubic-bezier(0.23, 1, 0.32, 1),
						min-height 500ms cubic-bezier(0.23, 1, 0.32, 1);
				}
			}

			.content__main {
				display: flex;
				width: 100%;
				height: 100%;
				flex-direction: column;
				margin-top: 2rem;
				z-index: 0 !important;

				&--top-bar {
					display: flex;
					align-items: center;
					width: 100%;
					margin-bottom: 2rem;
					justify-content: space-between;

					.top-bar__filter {
						display: flex;
						align-items: center;
						gap: 2.4rem;
						align-items: center;

						.main__active-filters {
							align-items: center;
							overflow: hidden;
							max-height: 0;
							gap: 1rem;
							display: flex;
							transition: max-height 300ms ease-in-out;

							&--show {
								display: flex;
								overflow: unset;
								max-height: 30rem;
								transition: max-height 300ms ease-in-out;
							}

							.active-filters__collections {
								display: flex;
								align-items: center;
								gap: 1rem;
								flex-direction: row;
							}

							.active-filters__item {
								background: #e9f5ff;
								border-radius: 7.2rem;
								display: flex;
								padding: 0.55rem 0.7rem;
								align-items: center;
								gap: 1rem;
								// margin-top: 1rem;
								// margin-bottom: 2rem;

								figure {
									width: 1.5rem;
									height: 1.5rem;
									display: grid;
									place-items: center;
									cursor: pointer;

									svg {
										width: 100%;
										height: 100%;
									}
								}

								p {
									color: #476885;
									display: flex;
									font-size: 1.2rem;
									align-items: center;
									gap: 0.6rem;
								}
							}

							.active-filters__clear-item {
								background: #fff5f5;
								border-radius: 72px;
								border-radius: 7.2rem;
								display: flex;
								padding: 0.55rem 0.7rem;
								align-items: center;
								cursor: pointer;

								p {
									font-weight: 600;
									font-size: 1.2rem;
									color: #ff0f00;
								}
							}
						}
					}
				}

				.main__wrap {
					display: flex;
					margin-top: 2rem;
					width: 100%;
					height: 85%;
					// background-color: red;
					overflow: hidden;

					.transaction__table {
						width: 100%;
						// background-color: yellow;
						height: 100%;
						border-radius: 1.2rem;
						overflow: auto;

						.table__main {
							border-radius: 1.2rem;
							overflow: auto;

							.session-wrap {
								position: relative;
							}

							.main__direction {
								display: flex;
								align-items: center;
								gap: 1.2rem;

								figure {
									display: grid;
									place-items: center;
									width: 2.75rem;
									height: 2.75rem;

									svg {
										width: 100%;
										height: 100%;
									}
								}
							}
						}
					}
				}
			}

			.content__top-bar {
				display: flex;
				margin-bottom: 2rem;
				margin-top: 2rem;
				width: 100%;
				justify-content: space-between;
				align-items: center;
				z-index: -0 !important;

				* {
					z-index: -100 !important;
				}

				.top-bar__filter-export {
					display: flex;
					gap: 1.6rem;
					align-items: center;
					z-index: 1;
					position: relative;

					.filter-export__export-btn,
					.filter-export__filter-btn {
						flex-direction: row;
						justify-content: center;
						align-items: center;
						display: flex;
						padding: 1.6rem 2.4rem;
						gap: 0.8em;
						cursor: pointer;
						height: 5rem;
						background: #f7f8f7;
						border-radius: 1rem;
						z-index: 100;

						p {
							font-weight: 600;
							font-size: 1.4rem;
							line-height: 140%;
							display: flex;
							align-items: center;
							color: #676767;
						}

						figure {
							width: fit-content;
							display: grid;
							place-items: center;

							svg {
								width: 2rem;
								height: 2rem;
							}
						}
					}

					.filter-export__drop-down {
						position: absolute;
						background: #ffffff;
						width: max-content;
						height: max-content;
						box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
							0px 48px 64px -36px rgba(51, 51, 51, 0.08);
						border-radius: 1.6rem;
						box-shadow: 0;
						top: 110%;
						right: 55%;
						padding: 2rem;
						transform-origin: top right;
						transform: translateY(-10%);
						opacity: 0.8;
						transition: all 0.3s ease-in-out;
						z-index: 100;

						&--open {
							transform: translateY(0%);
							opacity: 1;
						}

						.drop-down__switch {
							display: flex;
							width: 100%;
							cursor: pointer;
							justify-content: flex-start;
							gap: 1.2rem;
							border-bottom: 0.8px solid #e5e6e6;
							span {
								gap: 0.4rem;
								padding-bottom: 1.6rem;

								p {
									font-weight: 400;
									font-size: 1.4rem;
									display: grid;
									place-items: center;
									padding: 1.2rem 1.6rem;
									border-radius: 8px;
									color: #1b1b1b;
								}

								&.active {
									border-bottom: 4px solid #014345;
									p {
										color: #014345;
										font-weight: 600;
										background: #e9f5ff;
									}
								}
							}
						}

						.drop-down__custom-date-range {
							display: flex;
							flex-direction: column;
							margin-top: 2.4rem;

							h5 {
								font-weight: 600;
								font-size: 1.4rem;
								color: #014345;
							}

							.custom-date-range__grouped-form {
								display: flex;
								align-items: center;
								gap: 2rem;
								margin-top: 1.2rem;
							}

							.drop-down__action-btns {
								display: flex;
								align-items: center;
								justify-content: center;
								gap: 2rem;
								width: 100%;
								margin-top: 5.2rem;

								padding: 2.3rem 0rem 0.3rem 0rem;
								border-top: 0.7px solid #e5e6e6;

								button {
									width: 50%;

									&:first-child {
										background: #e9f5ff;
										color: #014345;
									}
								}
							}
						}
					}

					.filter-export__drop-down-closer {
						position: fixed;
						width: 100vw;
						height: 100vh;
						top: 0;
						right: 0;

						display: none;
						visibility: none;

						&--opened {
							display: unset;
							visibility: unset;
						}
					}
				}
			}

			.balances-wrap__main {
				display: flex;
				flex-direction: column;
				padding-bottom: 2rem;
				border-bottom: 1px solid #e3e3e3;

				.main__balances {
					display: flex;
					align-items: center;
					gap: 4rem;

					.balances__available,
					.balances__pending {
						display: flex;
						flex-direction: column;
						gap: 0.8rem;
						padding-right: 4rem;

						p {
							font-weight: 400;
							font-size: 1.6rem;
							display: flex;
							align-items: center;
							color: #676767;
						}

						h5 {
							font-style: normal;
							font-weight: 700;
							font-size: 2.4rem;
							display: flex;
							align-items: center;
							// color: #000000;
						}
					}

					.balances__pending {
						h5 {
							color: #8b8b8b;
						}
					}

					.balances__available {
						border-right: 1px solid #e3e3e3;
					}
				}

				.main__buttons {
					display: flex;
					gap: 2rem;
					margin-top: 4rem;
					align-items: center;

					span {
						display: flex;
						align-items: center;
						gap: 1rem;
						padding: 0.8rem 1.4rem 0.8rem 1rem;
						gap: 10px;
						background: #ffffff;
						border: 1px solid #e3e3e3;
						border-radius: 300px;
						cursor: pointer;
						transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);

						&:hover {
							transform: translateY(-2px);

							figure {
								transform: scale(1.2);
							}
						}

						figure {
							display: grid;
							place-items: center;
							width: 2rem;
							height: 2rem;
							transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);

							svg {
								width: 100%;
								height: 100%;
							}
						}
					}
				}
			}

			.balances-wrap__transactions {
				display: flex;
				align-items: flex-start;
				flex-direction: column;
				// margin-top: 2rem;
				width: 100%;
				height: 100%;
				overflow: hidden;
				border-radius: 12px;
				border: 0.6px solid var(--ravengreyshade-50, #e5e6e6);

				.transaction__title {
					display: flex;
					width: 100%;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 2rem;

					h5 {
						font-weight: 600;
						font-size: 1.6rem;
						line-height: 140%;
						display: flex;
						align-items: center;
						color: #1b1b1b;
					}

					p {
						display: flex;
						align-items: center;
						gap: 0.8rem;
						font-style: normal;
						font-weight: 700;
						font-size: 1.4rem;
						line-height: 140%;
						display: flex;
						align-items: center;
						color: #755ae2;
						cursor: pointer;

						&:hover {
							svg {
								transform: translateX(3px);
							}
						}

						svg {
							width: 1.5rem;
							height: 1.5rem;
							transition: transform 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
						}
					}
				}

				.transaction__table {
					width: 100%;
					height: 100%;
					border-radius: 1.2rem;
					overflow: auto;

					.table__main {
						border-radius: 1.2rem;

						.main__direction {
							display: flex;
							align-items: center;
							gap: 1.2rem;

							figure {
								display: grid;
								place-items: center;
								width: 2.75rem;
								height: 2.75rem;

								svg {
									width: 100%;
									height: 100%;
								}
							}
						}
					}
				}
			}
		}
	}
}

.fund-modal-wrap {
	width: 100%;
	&.no-btn {
		.input-submit {
			visibility: hidden;
		}
	}

	.raven-modal-content-wrap {
		width: 25%;
	}
	.fund-modal-wrap__content {
		display: flex;
		width: 100%;
		flex-direction: column;
		.content__title {
			display: flex;
			width: 100%;
			justify-content: space-between;

			span {
				h5 {
					font-weight: 600;
					font-size: 2rem;
					display: flex;
					align-items: center;
					// color: #000000;
				}

				p {
					font-weight: 400;
					font-size: 1.4rem;
					display: flex;
					align-items: center;
					color: #676767;
				}
			}

			figure {
				display: grid;
				place-items: center;
				width: 3.5rem;
				height: 3.5rem;

				svg {
					width: 100%;
					height: 100%;
				}
			}
		}

		.content__main {
			display: flex;
			flex-direction: column;

			.main__label {
				font-size: 1.6rem;
				display: flex;
				align-items: center;
				margin-top: 3.2rem;
				// color: #000000;
				margin-bottom: 1rem;
			}

			.main__account-details {
				display: flex;
				flex-direction: column;
				border: 0.7px solid #e5e6e6;
				border-radius: 1.2rem;
				width: 100%;
				position: relative;
				height: 100%;

				.panel {
					position: absolute;
					width: 100%;
					height: 100%;
					transition: transform 0.3s ease;
					transform: translateX(100%);

					&.active {
						transform: translateX(0);
					}
				}

				.account-details__item {
					display: flex;
					padding: 2.2rem 1.6rem;
					width: 100%;

					.checkmark {
						width: 2.5rem;
						height: 2.5rem;
						display: grid;
						margin: 0.1rem 0rem;
						border-radius: 7.5rem;
						background-color: #e8fff6;

						svg {
							width: 50%;
							height: 50%;
						}
					}

					&.address {
						flex-direction: column;
						width: 100%;
						gap: 0.8rem;
						p {
							font-size: 1.2rem;
							width: 100%;
						}
						span {
							width: 100%;
							p {
								color: #676767;
								font-size: 1.2rem;
								width: 100%;
							}
						}
					}

					p {
						font-weight: 400;
						font-size: 1.4rem;
						display: flex;
						align-items: center;
						// color: #000000;
					}

					&:not(:last-child) {
						border-bottom: 0.6px solid #e5e6e6;
					}

					p:first-child {
						width: 40%;
					}

					p:last-child {
						width: 60%;
					}

					span {
						display: flex;
						width: 60%;
						justify-content: space-between;
						align-items: center;

						figure {
							display: grid;
							place-items: center;
							width: 2.5rem;
							cursor: pointer;
							height: auto;
							svg {
								width: 100%;
								height: 100;
							}
						}
					}
				}
			}

			.main__swipe-indicator {
				display: flex;
				align-items: center;
				gap: 0.8rem;
				margin-bottom: 2.7rem;
				width: max-content;
				align-self: center;
				background: #f9f9f9;
				border-radius: 12.8rem;
				margin-top: 2.9rem;
				padding: 1.2rem 1.4rem;
				cursor: pointer;
				position: relative;

				p {
					padding: 0.4rem 1.3rem;
					// background-color: red;
					background: #b9defe;
					border-radius: 3.2rem;

					&.active {
						background-color: #014345;
						width: 5rem;
					}
				}

				.tab {
					position: relative;
					cursor: pointer;
					transition: background-color 0.3s ease;
					z-index: 1;
				}

				.tab.active {
					background-color: #014345;
				}

				.ripple {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: #014345;
					opacity: 0;
					transform: scale(0);
					pointer-events: none;
					z-index: 0;
				}

				@keyframes rippleEffect {
					0% {
						opacity: 0;
						transform: scale(0);
					}
					50% {
						opacity: 0.5;
						transform: scale(1);
					}
					100% {
						opacity: 0;
						transform: scale(1.5);
					}
				}
			}
		}
	}
}

.swap-modal-wrap {
	.swap-modal-wrap__content {
		display: flex;
		flex-direction: column;
		width: 100%;

		.content__title {
			display: flex;
			width: 100%;
			justify-content: space-between;

			span {
				h5 {
					font-weight: 600;
					font-size: 2rem;
					display: flex;
					align-items: center;
					// color: #000000;
				}

				p {
					font-weight: 400;
					font-size: 1.4rem;
					display: flex;
					align-items: center;
					color: #676767;
				}
			}
		}

		.content__form {
			display: flex;
			flex-direction: column;
			width: 100%;
			margin-top: 2.8rem;
			.form__grouped {
				display: flex;
				z-index: 20;

				align-items: center;
				justify-content: center;
				width: 100%;
				gap: 1.8rem;

				input {
					width: 100%;
				}

				.grouped__swap-icon {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 25%;
					height: 4rem;
					transform: translateY(25%);
					border-radius: 30px;
					background: #e9f5ff;
					figure {
						width: 60%;
						height: 60%;
						display: grid;
						place-items: center;
						svg {
							width: 100%;
							height: 100%;
						}
					}
				}
			}

			.form__balance {
				font-weight: 600;
				font-size: 1.4rem;
				color: #014345;
				margin-top: 2.8rem;
			}
		}

		.content__output {
			display: flex;
			flex-direction: column;
			border: 1px solid #e5e6e6;
			border-radius: 1.2rem;
			padding: 1.2rem;
			margin-top: 4.8rem;
			margin-bottom: 3.6rem;

			.output__rate {
				padding: 0.8rem 1.2rem;
				width: max-content;
				background: #f0eeff;
				border-radius: 72px;

				font-weight: 600;
				font-size: 1.2rem;
				text-align: center;
				color: #755ae2;
			}

			.output__value {
				display: flex;
				align-items: flex-start;
				flex-direction: column;
				gap: 0.4rem;
				margin-top: 1.9rem;

				h5 {
					font-weight: 700;
					font-size: 2rem;
					display: flex;
					align-items: center;
					// color: #000000;
				}

				p {
					font-weight: 400;
					font-size: 1.4rem;
					color: #676767;
				}
			}
		}
	}
}

.account-details__item .item__ravenpay {
	width: 100%;
	margin-top: 1.4rem;
	margin-bottom: 11.4rem;
}
