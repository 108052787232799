.history-wrap {
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
	height: 100%;

	.history-wrap__top {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 2.8rem;

		.top__title {
			display: flex;
			align-items: flex-start;
			display: flex;
			flex-direction: column;
			gap: 0.2rem;
			flex-direction: column;

			h5 {
				font-style: normal;
				font-weight: 700;
				font-size: 2rem;
				// color: #000000;
			}

			p {
				font-weight: 400;
				font-size: 1.6rem;
				color: #676767;
			}
		}

		.currency-selector {
			background-color: white;
			padding: 1.6rem;
			border-radius: 12px;
			border: 0px solid var(--grey-shade-50, #ccc);

			.currency__dropdown {
				top: 8rem;
			}
		}

		.top__top-right-content {
			display: flex;
			align-items: center;
			gap: 0.6rem;

			&:hover {
				figure {
					transform: scale(1.1);
				}
			}

			figure {
				width: 3rem;
				height: 3rem;
				display: grid;
				place-items: center;
				transition: transform 300ms ease-in-out;

				svg {
					width: 100%;
					height: 100%;
				}
			}
		}

		.top__wallet-select {
			display: flex;
			align-items: center;
			background: #ffffff;
			padding: 0.6rem;
			box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
				0px 48px 64px -36px rgba(51, 51, 51, 0.08);
			border-radius: 0.8rem;

			.wallet-select__item {
				padding: 1.1rem 1.6rem;
				cursor: pointer;
				display: flex;
				align-items: center;
				font-weight: 400;
				font-size: 1.4rem;
				color: #1b1b1b;

				&.active {
					background: #e9f5ff;
					border-radius: 0.8rem;
					color: #014345;
					font-weight: 700;
				}
			}
		}
	}

	.history__content {
		display: flex;
		width: 100%;
		flex: 1;
		background: #ffffff;
		border-radius: 1.6rem;
		padding: 2rem;
		overflow-y: scroll;
		flex-direction: column;

		&::-webkit-scrollbar {
			display: none;
		}

		.content__top-bar {
			display: flex;
			width: 100%;
			justify-content: space-between;
			align-items: center;

			.top-bar__filter-export {
				display: flex;
				gap: 1.6rem;
				align-items: center;
				z-index: 1;
				position: relative;

				.filter-export__export-btn,
				.filter-export__filter-btn {
					flex-direction: row;
					justify-content: center;
					align-items: center;
					display: flex;
					padding: 1.6rem 2.4rem;
					gap: 0.8em;
					cursor: pointer;
					height: 5rem;
					background: #f7f8f7;
					border-radius: 1rem;
					z-index: 100;

					p {
						font-weight: 600;
						font-size: 1.4rem;
						line-height: 140%;
						display: flex;
						align-items: center;
						color: #676767;
					}

					figure {
						width: fit-content;
						display: grid;
						place-items: center;

						svg {
							width: 2rem;
							height: 2rem;
						}
					}
				}

				.filter-export__drop-down {
					position: absolute;
					background: #ffffff;
					width: max-content;
					height: max-content;
					box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
						0px 48px 64px -36px rgba(51, 51, 51, 0.08);
					border-radius: 1.6rem;
					box-shadow: 0;
					top: 110%;
					right: 55%;
					padding: 2rem;
					transform-origin: top right;
					transform: scale(0);
					transition: transform 0.3s ease-in-out;
					z-index: 100;

					&--open {
						transform: scale(1);
					}

					.drop-down__switch {
						display: flex;
						width: 100%;
						cursor: pointer;
						justify-content: flex-start;
						gap: 1.2rem;
						border-bottom: 0.8px solid #e5e6e6;
						span {
							gap: 0.4rem;
							padding-bottom: 1.6rem;

							p {
								font-weight: 400;
								font-size: 1.4rem;
								display: grid;
								place-items: center;
								padding: 1.2rem 1.6rem;
								border-radius: 8px;
								color: #1b1b1b;
							}

							&.active {
								border-bottom: 4px solid #014345;
								p {
									color: #014345;
									font-weight: 600;
									background: #e9f5ff;
								}
							}
						}
					}

					.drop-down__custom-date-range {
						display: flex;
						flex-direction: column;
						margin-top: 2.4rem;

						h5 {
							font-weight: 600;
							font-size: 1.4rem;
							color: #014345;
						}

						.custom-date-range__grouped-form {
							display: flex;
							align-items: center;
							gap: 2rem;
							margin-top: 1.2rem;
						}

						.drop-down__action-btns {
							display: flex;
							align-items: center;
							justify-content: center;
							gap: 2rem;
							width: 100%;
							margin-top: 5.2rem;

							padding: 2.3rem 0rem 0.3rem 0rem;
							border-top: 0.7px solid #e5e6e6;

							button {
								width: 50%;

								&:first-child {
									background: #e9f5ff;
									color: #014345;
								}
							}
						}
					}
				}

				.filter-export__drop-down-closer {
					position: fixed;
					width: 100vw;
					height: 100vh;
					top: 0;
					right: 0;

					display: none;
					visibility: none;

					&--opened {
						display: unset;
						visibility: unset;
					}
				}
			}
		}

		.content__main {
			display: flex;
			width: 100%;
			overflow-y: scroll;
			flex: 1;
			flex-direction: column;
			// margin-top: 2rem;

			&::-webkit-scrollbar {
				display: none;
			}

			&--top-bar {
				display: flex;
				align-items: center;
				width: 100%;
				justify-content: space-between;

				.top-bar__title {
					display: flex;
					align-items: center;
					gap: 2.4rem;

					h5 {
						font-weight: 600;
						font-size: 16px;
						line-height: 140%;
						/* or 22px */

						display: flex;
						align-items: center;

						/* RAVEN/Base Black */

						color: #1b1b1b;
					}

					p {
						display: none;
						&.active {
							display: grid;
							place-items: center;
							padding: 0.8rem 1.2rem;
							background: #f7f8f7;
							border-radius: 7.2rem;
							font-weight: 600;
							font-size: 1.2rem;
							line-height: 140%;
							text-align: center;
							color: #014345;
						}
					}
				}
			}

			.main__active-filters {
				width: 100%;
				justify-content: space-between;
				align-items: center;
				overflow: hidden;
				max-height: 0;
				display: flex;
				transition: max-height 300ms ease-in-out;

				&--show {
					display: flex;
					overflow: unset;
					max-height: 30rem;
					transition: max-height 300ms ease-in-out;
				}

				.active-filters__item {
					background: #e9f5ff;
					border-radius: 7.2rem;
					display: flex;
					padding: 0.55rem 0.7rem;
					align-items: center;
					gap: 1rem;
					margin-top: 1rem;
					margin-bottom: 2rem;

					figure {
						width: 1.75rem;
						height: 1.75rem;
						display: grid;
						place-items: center;
						cursor: pointer;

						svg {
							width: 100%;
							height: 100%;
						}
					}

					p {
						color: #476885;
						display: flex;
						align-items: center;
						gap: 0.6rem;
					}
				}

				.active-filters__clear-item {
					background: #fff5f5;
					border-radius: 72px;
					border-radius: 7.2rem;
					display: flex;
					padding: 0.55rem 0.7rem;
					align-items: center;
					cursor: pointer;

					p {
						font-weight: 600;
						font-size: 1.2rem;
						color: #ff0f00;
					}
				}
			}

			.main__wrap {
				display: flex;
				margin-top: 2rem;
				width: 100%;
				height: 100%;
				// background-color: red;
				overflow: hidden;

				.transaction__table {
					width: 100%;
					// background-color: yellow;
					height: 100%;
					border-radius: 1.2rem;
					overflow: auto;

					&::-webkit-scrollbar {
						display: none;
					}

					.table__main {
						border-radius: 1.2rem;
						overflow: auto;

						.main__direction {
							display: flex;
							align-items: center;
							gap: 1.2rem;

							figure {
								display: grid;
								place-items: center;
								width: 2.75rem;
								height: 2.75rem;

								svg {
									width: 100%;
									height: 100%;
								}
							}
						}
					}
				}
			}
		}
	}
}

.configuration-modal {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;

	&__title {
		display: flex;
		flex-direction: column;

		h5 {
			font-weight: 600;
			font-size: 2rem;
			display: flex;
			align-items: center;
			// color: #000000;
		}

		p {
			font-weight: 400;
			font-size: 1.4rem;
			display: flex;
			align-items: center;
			color: #676767;
		}
	}

	&__currency-select {
		display: flex;
		margin-top: 2.4rem;
	}

	&__destination-selector {
		display: flex;
		width: 100%;
		margin-top: 2.4rem;
		justify-content: space-between;
		border-bottom: 0.8px solid #e5e6e6;

		.destination-selector__item-wrap {
			display: flex;
			cursor: pointer;

			&.active {
				border-bottom: 4px solid #014345;

				.item-wrap {
					background: #e8fff6;

					p {
						font-weight: 600;
					}
				}
			}

			.item-wrap {
				padding: 1rem 3rem;
				display: flex;
				align-items: center;
				gap: 4px;
				border-radius: 8px;
				margin-bottom: 2rem;

				p {
					font-size: 1.4rem;
					color: #014345;
					font-weight: 400;
				}
			}
		}
	}

	&__selected-currency-details {
		display: flex;
		flex-direction: column;
		margin-bottom: 4.5rem;
		margin-top: 2.8rem;

		&--title {
			display: flex;
			font-weight: 400;
			font-size: 1.4rem;
			display: flex;
			align-items: center;
			color: #1b1b1b;
		}
		&--main-content {
			display: flex;
			width: 100%;
			background: #f9f9f9;
			border-radius: 1.2rem;
			margin-top: 1.2rem;
			padding: 2.8rem 1.6rem;
			flex-direction: column;
			gap: 2rem;

			.main-content__top,
			.main-content__bottom {
				display: flex;
				align-items: center;
				gap: 3rem;

				span {
					display: flex;
					flex-direction: column;
					p {
						font-weight: 400;
						font-size: 1rem;
						color: #676767;
					}
					h5 {
						font-weight: 700;
						font-size: 1.4rem;
						color: #020202;
					}
				}
			}
		}
	}
}
