.overview {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  flex-direction: column;
}
.overview .overview__actionbar {
  margin-top: 10rem;
}
.overview .overview__top-bar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.overview .overview__top-bar .top-bar__greetings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.2rem;
}
.overview .overview__top-bar .top-bar__greetings h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 140%;
}
.overview .overview__top-bar .top-bar__greetings p {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 140%;
  color: var(--base-background-black);
}
.overview .overview__wallets-wrap {
  display: flex;
  width: 100%;
  margin-top: 3.5rem;
}
.overview .overview__wallets {
  display: flex;
  width: 100%;
  gap: 2rem;
}
.overview .overview__wallets .wallets__balance {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.6rem;
  height: -moz-min-content;
  height: min-content;
  flex: 0 0 30%;
  border-radius: 1.2rem;
  background: white;
  border: 1px solid #eeeeee;
  border-radius: 1.2rem;
}
.overview .overview__wallets .wallets__balance .balance__currency {
  display: flex;
  align-items: center;
  width: 100%;
}
.overview .overview__wallets .wallets__balance .balance__currency span {
  width: 6.5rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 0.4rem;
}
.overview .overview__wallets .wallets__balance .balance__currency span p {
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 140%;
}
.overview .overview__wallets .wallets__balance .balance__currency span.active {
  background: #f7f8f7;
  border-radius: 0.8rem;
}
.overview .overview__wallets .wallets__balance .balance__currency span.active p {
  font-weight: 600;
  color: #014345;
}
.overview .overview__wallets .wallets__balance .balance__description {
  display: flex;
  gap: 1rem;
  width: 100%;
  align-items: center;
  margin-top: 1.6rem;
}
.overview .overview__wallets .wallets__balance .balance__description figure {
  width: 6%;
  display: grid;
  place-items: center;
  height: auto;
}
.overview .overview__wallets .wallets__balance .balance__description figure svg {
  width: 100%;
  height: auto;
}
.overview .overview__wallets .wallets__balance .balance__description p {
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #676767;
}
.overview .overview__wallets .wallets__balance .balance__amount {
  margin-top: 1rem;
}
.overview .overview__wallets .wallets__balance .balance__amount h5 {
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 140%;
  display: flex;
  align-items: center;
}
.overview .overview__wallets .wallets__balance .balance__view-all-wallet {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
  cursor: pointer;
}
.overview .overview__wallets .wallets__balance .balance__view-all-wallet:hover span figure {
  transform: translateX(3px);
}
.overview .overview__wallets .wallets__balance .balance__view-all-wallet span {
  display: flex;
  font-weight: 600;
  align-items: center;
  font-size: 1.2rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #755ae2;
  gap: 0.6rem;
}
.overview .overview__wallets .wallets__balance .balance__view-all-wallet span figure {
  display: grid;
  place-items: center;
  width: 10%;
  transition: transform 300ms ease-in-out;
}
.overview .overview__wallets .wallets__balance .balance__view-all-wallet span figure svg {
  width: 100%;
}
.overview .overview__wallets .wallets__balance .balance__view-all-wallet div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 1.2rem;
  background: var(--raven-purple-shade-20, #f0eeff);
  border-radius: 7.2rem;
  gap: 0.4rem;
}
.overview .overview__wallets .wallets__balance .balance__view-all-wallet div figure {
  width: 1.2rem;
  height: 1.2rem;
  display: grid;
  place-items: center;
}
.overview .overview__wallets .wallets__balance .balance__view-all-wallet div figure svg {
  width: 100%;
  height: 100%;
}
.overview .overview__wallets .wallets__balance .balance__view-all-wallet div p {
  font-weight: 700;
  font-size: 1rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--ravenprimarypurple, #755ae2);
}
.overview .overview__wallets .wallets__statistics {
  display: grid;
  height: 100%;
  gap: 2rem;
  width: 70%;
  grid-template-columns: repeat(3, 1fr);
}
.overview .overview__wallets .wallets__statistics .statistics__item {
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 1.2rem;
  padding: 2.7rem 1.2rem;
  padding-bottom: 1.7rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
}
.overview .overview__wallets .wallets__statistics .statistics__item figure {
  width: 4.6rem;
  height: auto;
  margin-bottom: auto;
}
.overview .overview__wallets .wallets__statistics .statistics__item figure svg {
  width: 100%;
  height: auto;
}
.overview .overview__wallets .wallets__statistics .statistics__item h5 {
  font-weight: 700;
  font-size: 2.4rem;
  display: flex;
  align-items: center;
}
.overview .overview__wallets .wallets__statistics .statistics__item p {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 200%;
  display: flex;
  align-items: center;
  color: var(--base-background-black);
}
.overview .overview__wallets .wallets__statistics .statistics__item--request-settlement {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
}
.overview .overview__wallets .wallets__statistics .statistics__item--request-settlement:hover span figure {
  transform: translateX(3px);
}
.overview .overview__wallets .wallets__statistics .statistics__item--request-settlement span {
  display: flex;
  font-weight: 600;
  align-items: center;
  font-size: 1.2rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #755ae2;
  gap: 0.6rem;
}
.overview .overview__wallets .wallets__statistics .statistics__item--request-settlement span figure {
  display: grid;
  place-items: center;
  width: 10%;
  transition: transform 300ms ease-in-out;
}
.overview .overview__wallets .wallets__statistics .statistics__item--request-settlement span figure svg {
  width: 100%;
}
.overview .overview__wallets .wallets__statistics .statistics__item--request-settlement div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 1.2rem;
  background: var(--raven-purple-shade-20, #f0eeff);
  border-radius: 7.2rem;
  gap: 0.4rem;
}
.overview .overview__wallets .wallets__statistics .statistics__item--request-settlement div figure {
  width: 1.2rem;
  height: 1.2rem;
  display: grid;
  place-items: center;
}
.overview .overview__wallets .wallets__statistics .statistics__item--request-settlement div figure svg {
  width: 100%;
  height: 100%;
}
.overview .overview__wallets .wallets__statistics .statistics__item--request-settlement div p {
  font-weight: 700;
  font-size: 1rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--ravenprimarypurple, #755ae2);
}
.overview .overview__charts {
  width: 100%;
  display: flex;
  height: min-height;
  max-height: calc(100vh - 50%);
  padding-top: 2.4rem;
  gap: 2rem;
  padding-bottom: 2rem;
}
.overview .overview__charts .charts__guage {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  flex: 0 0 30%;
  border: 1px solid #e3e3e3;
  border-radius: 1.2rem;
  padding: 1.6rem;
}
.overview .overview__charts .charts__guage .guage__wrap {
  width: 100%;
  background: #f9f9f9;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  border-radius: 1.2rem 1.2rem 0px 0px;
  padding-top: 1.7rem;
  padding-right: 1.2rem;
  padding-left: 1.2rem;
}
.overview .overview__charts .charts__guage .guage__wrap .wrap__filter {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 3rem;
}
.overview .overview__charts .charts__guage .guage__wrap .wrap__filter span {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 1.1rem 1.6rem;
}
.overview .overview__charts .charts__guage .guage__wrap .wrap__filter span p {
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 140%;
}
.overview .overview__charts .charts__guage .guage__wrap .wrap__filter span.active {
  background: white;
  border-radius: 0.8rem;
}
.overview .overview__charts .charts__guage .guage__wrap .wrap__filter span.active p {
  font-weight: 600;
  color: #014345;
}
.overview .overview__charts .charts__guage .guage__wrap .wrap__guage {
  width: 110%;
  height: 100%;
  padding: 0;
  overflow: hidden;
  margin-bottom: -5%;
  position: relative;
}
.overview .overview__charts .charts__guage .guage__wrap .wrap__guage #gauge {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: grid;
  place-items: center;
}
.overview .overview__charts .charts__guage .guage__wrap .wrap__guage #gauge svg {
  width: 100%;
  display: grid;
  place-items: center;
}
.overview .overview__charts .charts__guage .guage__wrap .guage-drop-down-wrap {
  border-radius: 72px;
  padding: 0.8rem 1.2rem;
  background: #0b8376;
  display: flex;
  position: absolute;
  bottom: 0;
  bottom: 2rem; /* Move it down by 50% of the container's height */
  left: 50%; /* Move it horizontally by 50% of the container's width */
  transform: translate(-50%, -50%); /* Center the div */
  height: -moz-min-content;
  height: min-content;
  gap: 0.4rem;
  color: white;
  cursor: pointer;
  box-shadow: 0px 48px 64px -36px rgba(51, 51, 51, 0.08), 0px 24px 48px -8px rgba(51, 51, 51, 0.04);
}
.overview .overview__charts .charts__guage .guage__wrap .guage-drop-down-wrap svg path {
  stroke: white;
}
.overview .overview__charts .charts__guage .guage__wrap .guage-drop-down-wrap__main {
  bottom: 0;
  left: 50%;
  min-width: 50%;
  position: absolute;
  transform: translate(-50%, 100%);
  height: -moz-min-content;
  height: min-content;
  z-index: 100%;
  box-shadow: 0px 48px 64px -36px rgba(51, 51, 51, 0.08), 0px 24px 48px -8px rgba(51, 51, 51, 0.04);
}
.overview .overview__charts .charts__guage .guage__wrap .guage-drop-down-wrap__main .guage-drop-active {
  border-radius: 8px;
  width: 100%;
  border: none;
  background: var(--grey-shade-10, #f7f8f7);
}
.overview .overview__charts .charts__guage .guage__stats {
  display: flex;
  align-items: flex-start;
  gap: 1.2rem;
  flex-direction: column;
  margin-top: 2rem;
  overflow-y: auto;
}
.overview .overview__charts .charts__guage .guage__stats .stats__item {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0.8rem;
  background: #ffffff;
  border: 0.6px solid #e5e6e6;
  border-radius: 0.8rem;
}
.overview .overview__charts .charts__guage .guage__stats .stats__item .item__name {
  display: flex;
  gap: 0.8rem;
  align-items: center;
}
.overview .overview__charts .charts__guage .guage__stats .stats__item .item__name p:first-child {
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
}
.overview .overview__charts .charts__guage .guage__stats .stats__item .item__name p:last-child {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #1b1b1b;
}
.overview .overview__charts .charts__guage .guage__stats .stats__item .item__count {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 1.2rem;
  border-radius: 50%;
}
.overview .overview__charts .charts__guage .guage__stats .stats__item .item__count p {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 140%;
  text-align: center;
}
.overview .overview__charts .charts__guage .guage__stats .stats__item.green .item__name p:first-child {
  background: #1ace37;
  border: 1px solid #1ace37;
}
.overview .overview__charts .charts__guage .guage__stats .stats__item.green .item__count {
  background: #f7f8f7;
}
.overview .overview__charts .charts__guage .guage__stats .stats__item.green .item__count p {
  color: #1ace37;
}
.overview .overview__charts .charts__guage .guage__stats .stats__item.red .item__name p:first-child {
  background: #ff0f00;
  border: 1px solid #ff0f00;
}
.overview .overview__charts .charts__guage .guage__stats .stats__item.red .item__count {
  background: #fff5f5;
}
.overview .overview__charts .charts__guage .guage__stats .stats__item.red .item__count p {
  color: #ff0f00;
}
.overview .overview__charts .charts__guage .guage__stats .stats__item.orange .item__name p:first-child {
  background: #ea872d;
  border: 1px solid #ea872d;
}
.overview .overview__charts .charts__guage .guage__stats .stats__item.orange .item__count {
  background: #fff6ed;
}
.overview .overview__charts .charts__guage .guage__stats .stats__item.orange .item__count p {
  color: #ea872d;
}
.overview .overview__charts .charts__graph {
  background: #ffffff;
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  border: 1px solid #eeeeee;
  border-radius: 1.2rem;
  padding: 2.8rem 2.4rem;
}
.overview .overview__charts .charts__graph .graph__top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.overview .overview__charts .charts__graph .graph__top .top__title {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
.overview .overview__charts .charts__graph .graph__top .top__title p:first-child {
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 140%;
}
.overview .overview__charts .charts__graph .graph__top .top__title .title__graph-indicator {
  display: flex;
  gap: 1.6rem;
  align-items: center;
  margin-top: 1.4rem;
}
.overview .overview__charts .charts__graph .graph__top .top__title .title__graph-indicator span {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
.overview .overview__charts .charts__graph .graph__top .top__title .title__graph-indicator span:is(:first-child) p:first-child {
  width: 1.8rem;
  height: 1.8rem;
  background: #014345;
  border-radius: 0.4rem;
}
.overview .overview__charts .charts__graph .graph__top .top__title .title__graph-indicator span:is(:last-child) p:first-child {
  width: 1.8rem;
  height: 1.8rem;
  background: #9be5c9;
  border-radius: 0.4rem;
}
.overview .overview__charts .charts__graph .graph__top .top__filter {
  display: flex;
  gap: 1rem;
  padding: 1rem 1rem;
  border: 1px solid #e3e3e3;
  border-radius: 200px;
  align-items: center;
}
.overview .overview__charts .charts__graph .graph__top .top__filter span {
  gap: 0.8rem;
  align-items: center;
  display: flex;
}
.overview .overview__charts .charts__graph .graph__top .top__filter span figure {
  display: grid;
  cursor: pointer;
  place-items: center;
}
.overview .overview__charts .charts__graph .graph__no-record {
  height: 89%;
  background: #f7f7f7;
  border-radius: 0.8rem;
  margin-top: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;
  background-image: url("../../../../assets/images/graph_no_data.svg");
}
.overview .overview__charts .charts__graph .graph__no-record .no-record__text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  width: 50%;
  gap: 0.2rem;
}
.overview .overview__charts .charts__graph .graph__no-record .no-record__text h5 {
  font-weight: 600;
  font-size: 2rem;
  line-height: 160%;
  text-align: center;
  color: #014345;
}
.overview .overview__charts .charts__graph .graph__no-record .no-record__text p {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 160%;
  text-align: center;
  text-align: center;
  color: var(--base-background-black);
}
.overview .overview__charts .charts__graph .graph__main {
  width: 100%;
  height: 100%;
  margin-top: 1.8rem;
}

.drop-box-guage {
  border-radius: 72px;
  padding: 0.8rem 1.2rem;
  background: #fff;
  box-shadow: 0px 48px 64px -36px rgba(51, 51, 51, 0.08), 0px 24px 48px -8px rgba(51, 51, 51, 0.04);
}

.request-settlement-modal {
  display: flex;
  flex-direction: column;
}
.request-settlement-modal__title {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
.request-settlement-modal__title h6 {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
}
.request-settlement-modal__title p {
  color: var(--BaseBlack-Base-BG-Dark, #676767);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
}
.request-settlement-modal__collection-balance {
  display: flex;
  height: 3.2rem;
  padding: 0.8rem 1.2rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-shrink: 0;
  border-radius: 7.2rem;
  background: var(--RAVEN-Primary-PURPLE-20, #f0eeff);
  width: -moz-min-content;
  width: min-content;
  margin-bottom: 2.4rem;
}
.request-settlement-modal__collection-balance p {
  color: var(--RAVEN-Primary-PURPLE-Primary-PURPLE-100, #755ae2);
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  text-wrap: nowrap;
  font-weight: 600;
}
.request-settlement-modal__settlement-fee {
  display: flex;
  height: 3.2rem;
  padding: 0.8rem 1.2rem 0.8rem 0.6rem;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  border-radius: 7.2rem;
  background: var(--Primary-ORANGE-20, #fff6ed);
  align-items: center;
  gap: 0.2rem;
  width: -moz-min-content;
  width: min-content;
  margin-top: 2rem;
}
.request-settlement-modal__settlement-fee p {
  color: var(--Primary-ORANGE-100, #ea872d);
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  text-wrap: nowrap;
}
.request-settlement-modal__settlement-fee figure {
  width: 1.5rem;
  height: 1.5rem;
  display: grid;
  place-items: center;
}
.request-settlement-modal__settlement-fee figure svg {
  width: 100%;
  height: 100%;
}
.request-settlement-modal__settlement-fee figure svg g path {
  stroke: #ea872d;
}/*# sourceMappingURL=index.css.map */