.transaction-status {
  display: grid;
  place-items: center;
  background-color: aliceblue;
  padding: 0.8rem 1.2rem;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 7.2rem;
}
.transaction-status p {
  font-weight: 600;
  font-size: 1.2rem;
}
.transaction-status.pending {
  background-color: #fff6ed;
  text-transform: capitalize;
}
.transaction-status.pending p {
  color: #ea872d;
}
.transaction-status.successful {
  background-color: #e8fff6;
  text-transform: capitalize;
}
.transaction-status.successful p {
  color: #1ace37;
}
.transaction-status.failed {
  background-color: #fff5f5;
  text-transform: capitalize;
}
.transaction-status.failed p {
  color: #ff0f00;
}

.transaction-details-modal {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.transaction-details-modal__title {
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 1.9rem;
}
.transaction-details-modal__amount-view {
  display: flex;
  background: #f9f9f9;
  border-radius: 1.2rem;
  align-items: center;
  justify-content: center;
  padding: 2.4rem;
  flex-direction: column;
  margin-bottom: 2rem;
}
.transaction-details-modal__amount-view .amount-view__direction {
  display: flex;
  gap: 1.2rem;
  align-items: center;
}
.transaction-details-modal__amount-view .amount-view__direction .direction__badge {
  display: flex;
  align-items: center;
  border-radius: 7.2rem;
  padding: 0.6rem 1.2rem;
  gap: 0.6rem;
}
.transaction-details-modal__amount-view .amount-view__direction .direction__badge--successful {
  background: #1ace37;
}
.transaction-details-modal__amount-view .amount-view__direction .direction__badge--successful p {
  color: white;
}
.transaction-details-modal__amount-view .amount-view__direction .direction__badge--failed {
  background: #ff0f00;
}
.transaction-details-modal__amount-view .amount-view__direction .direction__badge--failed p {
  color: white;
}
.transaction-details-modal__amount-view .amount-view__direction .direction__badge p {
  font-weight: 600;
  font-size: 1.2rem;
}
.transaction-details-modal__amount-view .amount-view__direction .direction__badge figure {
  width: 1.2rem;
  height: 1.2rem;
  display: grid;
  place-items: center;
}
.transaction-details-modal__amount-view .amount-view__direction .direction__badge figure svg {
  width: 100%;
  height: 100%;
}
.transaction-details-modal__amount-view .amount-view__direction .direction__green-checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 2rem;
  background-color: #fffbfb;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
}
.transaction-details-modal__amount-view .amount-view__direction .direction__green-checkmark.failed figure {
  width: 2rem;
  height: 2rem;
}
.transaction-details-modal__amount-view .amount-view__direction .direction__green-checkmark figure {
  width: 1.2rem;
  height: 1.2rem;
  display: grid;
  place-items: center;
}
.transaction-details-modal__amount-view .amount-view__direction .direction__green-checkmark figure svg {
  width: 100%;
  height: 100%;
}
.transaction-details-modal__amount-view .amount-view__amount {
  font-size: 2.4rem;
  font-weight: 600;
  margin-top: 0.8rem;
}
.transaction-details-modal__amount-view .amount-view__actions {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  justify-content: center;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 2rem;
  flex: 1;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
}
.transaction-details-modal__amount-view .amount-view__actions figure {
  width: 5rem;
  height: 5rem;
  display: grid;
  place-items: center;
  background: #fffbfb;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 2rem;
  cursor: pointer;
}
.transaction-details-modal__amount-view .amount-view__actions figure svg {
  width: 2rem;
  height: 2rem;
}
.transaction-details-modal__content {
  border-radius: 1.2rem;
  margin-bottom: 3rem;
  display: flex;
  width: 100%;
  flex-direction: column;
}
.transaction-details-modal__content .content__title {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.transaction-details-modal__content .content__title span h5 {
  font-weight: 600;
  font-size: 2rem;
  display: flex;
  align-items: center;
}
.transaction-details-modal__content .content__title span p {
  font-weight: 400;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  color: #676767;
}
.transaction-details-modal__content .content__title figure {
  display: grid;
  place-items: center;
  width: 3.5rem;
  height: 3.5rem;
}
.transaction-details-modal__content .content__title figure svg {
  width: 100%;
  height: 100%;
}
.transaction-details-modal__content .content__main {
  display: flex;
  flex-direction: column;
}
.transaction-details-modal__content .content__main .main__label {
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  margin-top: 3.2rem;
  margin-bottom: 1rem;
}
.transaction-details-modal__content .content__main .main__account-details {
  display: flex;
  flex-direction: column;
  border: 0.7px solid #e5e6e6;
  border-radius: 1.2rem;
  width: 100%;
  position: relative;
  height: 100%;
}
.transaction-details-modal__content .content__main .main__account-details .panel {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
  transform: translateX(100%);
}
.transaction-details-modal__content .content__main .main__account-details .panel.active {
  transform: translateX(0);
}
.transaction-details-modal__content .content__main .main__account-details .account-details__item {
  display: flex;
  padding: 2.2rem 1.6rem;
  width: 100%;
}
.transaction-details-modal__content .content__main .main__account-details .account-details__item.address {
  flex-direction: column;
  width: 100%;
  gap: 0.8rem;
}
.transaction-details-modal__content .content__main .main__account-details .account-details__item.address p {
  font-size: 1.2rem;
  width: 100%;
}
.transaction-details-modal__content .content__main .main__account-details .account-details__item.address span {
  width: 100%;
}
.transaction-details-modal__content .content__main .main__account-details .account-details__item.address span p {
  color: #676767;
  font-size: 1.2rem;
  width: 100%;
}
.transaction-details-modal__content .content__main .main__account-details .account-details__item p {
  font-weight: 400;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
}
.transaction-details-modal__content .content__main .main__account-details .account-details__item:not(:last-child) {
  border-bottom: 0.6px solid #e5e6e6;
}
.transaction-details-modal__content .content__main .main__account-details .account-details__item p:first-child {
  width: 40%;
}
.transaction-details-modal__content .content__main .main__account-details .account-details__item p:last-child {
  width: 60%;
}
.transaction-details-modal__content .content__main .main__account-details .account-details__item span {
  display: flex;
  width: 60%;
  justify-content: space-between;
  align-items: center;
}
.transaction-details-modal__content .content__main .main__account-details .account-details__item span figure {
  display: grid;
  place-items: center;
  width: 2.5rem;
  cursor: pointer;
  height: auto;
}
.transaction-details-modal__content .content__main .main__account-details .account-details__item span figure svg {
  width: 100%;
  height: 100;
}

.history__content .content__main .main__wrap .transaction__table .table__main .account_name {
  display: flex;
  align-items: center;
  gap: 1.6rem;
}
.history__content .content__main .main__wrap .transaction__table .table__main .account_name span {
  background-color: #e9f5ff;
  display: grid;
  place-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 7.5rem;
}
.history__content .content__main .main__wrap .transaction__table .table__main .account_name span p {
  font-weight: 600;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: #014345;
}/*# sourceMappingURL=index.css.map */