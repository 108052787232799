.mobile-onboarding-steps {
  display: flex;
  padding: 0px 4px 42px 0px;
  flex-direction: column;
  align-self: stretch;
  border-radius: 1.4rem;
  background: var(--base-white, #fff);
  margin-top: 3.2rem;
}
.mobile-onboarding-steps__complete-your-kyc {
  padding: 1rem 1.6rem;
  border-bottom: 0.8px solid var(--raven-black-200, #e3e3e3);
  margin-bottom: 28px;
}
.mobile-onboarding-steps__complete-your-kyc h5 {
  font-size: 1.4rem;
  font-weight: 400px;
}
.mobile-onboarding-steps__items {
  display: flex;
  flex-direction: row;
  height: 100px;
  overflow: hidden;
  cursor: pointer;
}
.mobile-onboarding-steps__items .items__rails {
  display: flex;
  width: 15%;
  height: 100%;
  flex-direction: column;
  align-items: center;
}
.mobile-onboarding-steps__items .items__rails figure {
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
}
.mobile-onboarding-steps__items .items__rails figure svg {
  width: 100%;
  height: 100%;
}
.mobile-onboarding-steps__items .items__content {
  display: flex;
  flex-direction: column;
}
.mobile-onboarding-steps__items .items__content h5 {
  color: #000;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
}
.mobile-onboarding-steps__items .items__content small {
  color: var(--base-black-base-bg-dark, #676767);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
}
.mobile-onboarding-steps__items .items__content span {
  padding: 0 1.2rem;
  gap: 1rem;
  display: flex;
  height: 2.8rem;
  background: #fff6ed;
  border-radius: 7.2rem;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  width: -moz-max-content;
  width: max-content;
}
.mobile-onboarding-steps__items .items__content span.verified {
  background: #e8fff6;
}
.mobile-onboarding-steps__items .items__content span.verified p {
  color: #1ace37;
}
.mobile-onboarding-steps__items .items__content span p {
  font-size: 1.2rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ea872d;
}

.verification-rails {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.verification-rails p {
  width: 1px;
  height: 5px;
  background: #d1d1d1;
}
.verification-rails.completed p {
  background: #755ae2;
}/*# sourceMappingURL=onboardindSteps.css.map */