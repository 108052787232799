.verify-identity-box {
	display: flex;
	align-items: center;
	align-self: flex-start;
	margin-top: -1rem;
	max-height: 0;
	overflow-x: hidden;
	overflow-y: hidden;
	transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
	-webkit-transition: max-height 0.3s;
	-moz-transition: max-height 0.3s;
	width: 100%;

	& > *:first-child .label-check {
		border-radius: 50%;
		transform: scale(0.8);
	}
	.text {
		// color: var(--primary-black);
		font-weight: 600;
		font-size: 1.3rem;
		margin-left: 0.5rem;
	}
}

.verify-identity-box-show {
	max-height: 5rem;
	transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
}
