.wallet {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.wallet .wallet__title {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.wallet .wallet__title .title__main {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.wallet .wallet__title .title__main h5 {
  font-weight: 700;
  font-size: 2rem;
  line-height: 140%;
}
.wallet .wallet__title .title__main p {
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 160%;
  color: #676767;
}
.wallet .wallet__title .title__swap-btn {
  display: flex;
  align-items: center;
  padding: 1rem 2.3rem;
  gap: 1rem;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 10px;
  transition: transform 0.2s linear;
}
.wallet .wallet__title .title__swap-btn:hover {
  transform: translateY(-3px);
}
.wallet .wallet__title .title__swap-btn:hover figure {
  animation: rotate 1s cubic-bezier(0.1, 0.5, 0.3, 1);
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(100deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.wallet .wallet__title .title__swap-btn p {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #014345;
}
.wallet .wallet__title .title__swap-btn figure {
  display: grid;
  place-items: center;
  width: 2rem;
  height: 2rem;
}
.wallet .wallet__title .title__swap-btn figure svg {
  width: 100%;
  height: 100%;
}
.wallet .wallet__bills-wrap {
  display: flex;
  align-items: flex-start;
  background: #ffffff;
  border-radius: 1.8rem;
  width: 100%;
  gap: 2.4rem;
  flex: 1;
  height: 100%;
}
.wallet .wallet__bills-wrap .bills-wrap__selector {
  background: #f9f9f9;
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
  height: -moz-min-content;
  height: min-content;
  min-height: 100%;
  align-items: center;
  width: 20%;
  gap: 1.2rem;
  padding: 2rem;
}
.wallet .wallet__bills-wrap .bills-wrap__selector .selector__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 1.6rem 2.4rem;
  gap: 0.8rem;
  cursor: pointer;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 10px;
}
.wallet .wallet__bills-wrap .bills-wrap__selector .selector__item p {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #8b8b8b;
}
.wallet .wallet__bills-wrap .bills-wrap__selector .selector__item svg {
  width: 2rem;
  height: 2rem;
  display: grid;
  place-items: center;
}
.wallet .wallet__bills-wrap .bills-wrap__selector .selector__item.active {
  background: #ffffff;
}
.wallet .wallet__bills-wrap .bills-wrap__selector .selector__item.active p {
  color: var(--raven-primary-dark-green, #014345);
  font-weight: 700;
}
.wallet .wallet__bills-wrap .bills-wrap__payments-wrap {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  width: 85%;
  height: 100%;
  overflow: hidden;
  border-radius: 1.2rem;
  padding: 2rem;
  border: 1px solid var(--ravengreyshade-50, #e5e6e6);
}
.wallet .wallet__bills-wrap .bills-wrap__payments-wrap .payments-wrap__title {
  display: flex;
  padding-bottom: 2rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.wallet .wallet__bills-wrap .bills-wrap__payments-wrap .payments-wrap__title .title__left h5 {
  color: var(--raven-base-background-dark, #020202);
  font-size: 2rem;
  font-weight: 600;
}
.wallet .wallet__bills-wrap .bills-wrap__payments-wrap .payments-wrap__title .title__left p {
  color: var(--raven-base-bg-dark, #676767);
  font-size: 1.2rem;
  line-height: 140%;
}
.wallet .wallet__bills-wrap .bills-wrap__payments-wrap .payments-wrap__main {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 2rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.wallet .wallet__bills-wrap .bills-wrap__payments-wrap .payments-wrap__main .main__pagination {
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
  align-items: center;
  justify-content: flex-end;
}
.wallet .wallet__bills-wrap .bills-wrap__payments-wrap .payments-wrap__main .main__pagination h5 {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #1b1b1b;
}
.wallet .wallet__bills-wrap .bills-wrap__payments-wrap .payments-wrap__main .main__pagination p {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #755ae2;
  cursor: pointer;
}
.wallet .wallet__bills-wrap .bills-wrap__payments-wrap .payments-wrap__main .main__pagination p:hover svg {
  transform: translateX(3px);
}
.wallet .wallet__bills-wrap .bills-wrap__payments-wrap .payments-wrap__main .main__pagination p svg {
  width: 1.5rem;
  height: 1.5rem;
  transition: transform 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
}
.wallet .wallet__bills-wrap .bills-wrap__payments-wrap .payments-wrap__main .transaction__table {
  width: 100%;
  height: 100%;
  border-radius: 1.2rem;
  overflow: scroll;
  border: 0.6px solid var(--ravengreyshade-50, #e5e6e6);
}
.wallet .wallet__bills-wrap .bills-wrap__payments-wrap .payments-wrap__main .transaction__table .table__main {
  border-radius: 1.2rem;
  height: -moz-max-content;
  height: max-content;
}
.wallet .wallet__bills-wrap .bills-wrap__payments-wrap .payments-wrap__main .transaction__table .table__main .main__direction {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}
.wallet .wallet__bills-wrap .bills-wrap__payments-wrap .payments-wrap__main .transaction__table .table__main .main__direction figure {
  display: grid;
  place-items: center;
  width: 2.75rem;
  height: 2.75rem;
}
.wallet .wallet__bills-wrap .bills-wrap__payments-wrap .payments-wrap__main .transaction__table .table__main .main__direction figure svg {
  width: 100%;
  height: 100%;
}
.wallet .wallet__bills-wrap .bills-wrap__payments-wrap .payments-wrap__main .transaction__table .table__main .main__reference-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.fund-modal-wrap {
  width: 100%;
}
.fund-modal-wrap.no-btn .input-submit {
  visibility: hidden;
}
.fund-modal-wrap .raven-modal-content-wrap {
  width: 25%;
}
.fund-modal-wrap .fund-modal-wrap__content {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.fund-modal-wrap .fund-modal-wrap__content .content__title {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.fund-modal-wrap .fund-modal-wrap__content .content__title span h5 {
  font-weight: 600;
  font-size: 2rem;
  display: flex;
  align-items: center;
}
.fund-modal-wrap .fund-modal-wrap__content .content__title span p {
  font-weight: 400;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  color: #676767;
}
.fund-modal-wrap .fund-modal-wrap__content .content__title figure {
  display: grid;
  place-items: center;
  width: 3.5rem;
  height: 3.5rem;
}
.fund-modal-wrap .fund-modal-wrap__content .content__title figure svg {
  width: 100%;
  height: 100%;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main {
  display: flex;
  flex-direction: column;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__label {
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  margin-top: 3.2rem;
  margin-bottom: 1rem;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details {
  display: flex;
  flex-direction: column;
  border: 0.7px solid #e5e6e6;
  border-radius: 1.2rem;
  width: 100%;
  position: relative;
  height: 100%;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .panel {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
  transform: translateX(100%);
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .panel.active {
  transform: translateX(0);
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item {
  display: flex;
  padding: 2.2rem 1.6rem;
  width: 100%;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item.address {
  flex-direction: column;
  width: 100%;
  gap: 0.8rem;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item.address p {
  font-size: 1.2rem;
  width: 100%;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item.address span {
  width: 100%;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item.address span p {
  color: #676767;
  font-size: 1.2rem;
  width: 100%;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item p {
  font-weight: 400;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item:not(:last-child) {
  border-bottom: 0.6px solid #e5e6e6;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item p:first-child {
  width: 40%;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item p:last-child {
  width: 60%;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item span {
  display: flex;
  width: 60%;
  justify-content: space-between;
  align-items: center;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item span figure {
  display: grid;
  place-items: center;
  width: 2.5rem;
  cursor: pointer;
  height: auto;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item span figure svg {
  width: 100%;
  height: 100;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__swipe-indicator {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: 2.7rem;
  width: -moz-max-content;
  width: max-content;
  align-self: center;
  background: #f9f9f9;
  border-radius: 12.8rem;
  margin-top: 2.9rem;
  padding: 1.2rem 1.4rem;
  cursor: pointer;
  position: relative;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__swipe-indicator p {
  padding: 0.4rem 1.3rem;
  background: #b9defe;
  border-radius: 3.2rem;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__swipe-indicator p.active {
  background-color: #014345;
  width: 5rem;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__swipe-indicator .tab {
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 1;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__swipe-indicator .tab.active {
  background-color: #014345;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__swipe-indicator .ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #014345;
  opacity: 0;
  transform: scale(0);
  pointer-events: none;
  z-index: 0;
}
@keyframes rippleEffect {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 0.5;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}

.swap-modal-wrap .swap-modal-wrap__content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.swap-modal-wrap .swap-modal-wrap__content .content__title {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.swap-modal-wrap .swap-modal-wrap__content .content__title span h5 {
  font-weight: 600;
  font-size: 2rem;
  display: flex;
  align-items: center;
}
.swap-modal-wrap .swap-modal-wrap__content .content__title span p {
  font-weight: 400;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  color: #676767;
}
.swap-modal-wrap .swap-modal-wrap__content .content__form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2.8rem;
}
.swap-modal-wrap .swap-modal-wrap__content .content__form .form__grouped {
  display: flex;
  z-index: 20;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1.8rem;
}
.swap-modal-wrap .swap-modal-wrap__content .content__form .form__grouped input {
  width: 100%;
}
.swap-modal-wrap .swap-modal-wrap__content .content__form .form__grouped .grouped__swap-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 4rem;
  transform: translateY(25%);
  border-radius: 30px;
  background: #e9f5ff;
}
.swap-modal-wrap .swap-modal-wrap__content .content__form .form__grouped .grouped__swap-icon figure {
  width: 60%;
  height: 60%;
  display: grid;
  place-items: center;
}
.swap-modal-wrap .swap-modal-wrap__content .content__form .form__grouped .grouped__swap-icon figure svg {
  width: 100%;
  height: 100%;
}
.swap-modal-wrap .swap-modal-wrap__content .content__form .form__balance {
  font-weight: 600;
  font-size: 1.4rem;
  color: #014345;
  margin-top: 2.8rem;
}
.swap-modal-wrap .swap-modal-wrap__content .content__output {
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e6e6;
  border-radius: 1.2rem;
  padding: 1.2rem;
  margin-top: 4.8rem;
  margin-bottom: 3.6rem;
}
.swap-modal-wrap .swap-modal-wrap__content .content__output .output__rate {
  padding: 0.8rem 1.2rem;
  width: -moz-max-content;
  width: max-content;
  background: #f0eeff;
  border-radius: 72px;
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
  color: #755ae2;
}
.swap-modal-wrap .swap-modal-wrap__content .content__output .output__value {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.4rem;
  margin-top: 1.9rem;
}
.swap-modal-wrap .swap-modal-wrap__content .content__output .output__value h5 {
  font-weight: 700;
  font-size: 2rem;
  display: flex;
  align-items: center;
}
.swap-modal-wrap .swap-modal-wrap__content .content__output .output__value p {
  font-weight: 400;
  font-size: 1.4rem;
  color: #676767;
}

.account-details__item .item__ravenpay {
  width: 100%;
  margin-top: 1.4rem;
  margin-bottom: 11.4rem;
}

.mobile-bills-wrap {
  width: 100%;
}
.mobile-bills-wrap .mobile-bills-table-wrap {
  width: 100%;
}/*# sourceMappingURL=index.css.map */