.basic-details-settings {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	flex: 1;
	gap: 3.2rem;
	overflow-y: auto;

	.grouped__input {
		display: flex;
		margin-top: 2rem;
		position: relative;

		.input__copy-icon {
			// opacity: 0.5;
			z-index: 200;
			position: absolute;
			top: 3.5rem;
			right: 1rem;
			// width: 2.3rem;
			// height: 2.3rem;
			display: flex;
			align-items: center;
			justify-content: center;
			place-items: center;

			figure {
				display: grid;
				place-items: center;
				width: 2.5rem;
				cursor: pointer;
				height: 2.5rem;
			}
		}
	}

	&__app-name {
		display: flex;
		gap: 2.4rem;
		flex-direction: column;
		display: flex;
		padding: 2.2rem 1.6rem 1.6rem 1.6rem;
		border-radius: 12px;
		border: 1px solid var(--raven-black-100, #eee);

		&--avatar {
			flex-direction: row;
			display: flex;
			align-items: center;
			gap: 2rem;
			padding-bottom: 2rem;
			border-radius: var(--Count, 0px);
			border-bottom: 1px solid var(--raven-black-100, #eee);

			p {
				color: var(--RAVEN-GREY, #676767);
				font-size: 1.6rem;
				font-style: normal;
				width: 45%;
				font-weight: 400;
			}

			img {
				width: 10rem;
				object-fit: cover;
				height: 10rem;
				border-radius: 72px;
			}
		}
		&--input-group {
			display: flex;
			align-items: end;
			gap: 2rem;

			.input-group {
				&__color-swatch {
					display: flex;
					gap: 0.6rem;
					height: 75%;
					padding: 0.6rem;
					// margin-bottom: 0.3rem;
					border-radius: 12px;
					align-items: center;
					border: 0.5px solid var(--raven-black-200, #e3e3e3);
					position: relative;

					.swatch-drop {
						top: 5rem;
						right: 0;
					}

					&--color-preview {
						width: 11.3rem;
						height: 100%;
						border-radius: 8px;
						background: var(--primary-green-100, #0b8376);
						align-items: center;
						display: flex;
						justify-content: center;

						flex: 1;

						p {
							color: rgba(255, 255, 255, 0.32);
							font-size: 1.2rem;
							font-style: normal;
							font-weight: 700;
							line-height: 140%; /* 16.8px */
						}
					}
					&--dropdown {
						display: flex;
						width: 3.8rem;
						height: 4.2rem;
						padding: 1rem 0.8rem;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						cursor: pointer;
						flex-shrink: 0;

						border-radius: 8px;
						background: var(--raven-black-50, #f7f7f7);
					}
				}
			}
		}
	}
	&__prices {
		display: flex;
		flex-direction: column;
		padding: 2.2rem 1.6rem 1.6rem 1.6rem;
		border-radius: 12px;
		border: 1px solid var(--raven-black-100, #eee);

		&--actionbar {
			padding-right: 2rem !important;
			padding-left: 2rem !important;
		}

		&--title {
			color: var(--themedarkmode, #000);
			font-size: 1.6rem;
			font-style: normal;
			font-weight: 600;
			margin-bottom: 1.6rem;
		}

		&--input-group {
			display: flex;
			gap: 2rem;
			width: 100%;
			margin-top: 0.8rem;

			&.incomplete {
				margin-top: 3.6rem !important;
			}
			.inputs {
				display: flex;
				flex-direction: column;
				flex: 1 1 0;
				gap: 2.4rem;
			}
		}
	}

	.wrap {
		width: 100%;
		display: grid;
		grid-template-columns: 50% 50%;
		gap: 2rem;
	}
}

.colour-pallete {
	display: flex;
	width: min-content;
	flex-direction: column;
	gap: 2rem;
	background-color: white;
	padding: 1.6rem;
	border-radius: 12px;
	box-shadow: 0px -3px 48px -8px #f7f8f7, 0px 4px 23px 12px #f7f8f7;
}
