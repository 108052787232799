.select-method {
	display: flex;
	width: 100%;
	background: #ffffff;
	// background-color: red;
	border: 0.8px solid #e5e6e6;
	flex: 1;
	padding: 3.6rem 2rem;
	border-radius: 1.6rem;
	flex-direction: column;

	h5 {
		font-weight: 600;
		font-size: 1.6rem;
		display: flex;
		margin-bottom: 2.4rem;
	}

	.select-method__select-container {
		display: flex;
		align-items: center;
		gap: 2.4rem;
		width: 100%;

		.select-container__item {
			padding: 5.6rem 1.2rem;
			padding-bottom: 6.4rem;
			background: #f9f9f9;
			border-radius: 12px;
			display: flex;
			align-items: center;
			width: 50%;
			flex-direction: column;
			justify-content: center;
			cursor: pointer;
			transition: transform 0.2s ease-in-out;

			&:hover {
				transform: scale(1.03);
			}

			figure {
				width: 8rem;
				height: 8rem;
				display: grid;
				place-items: center;
				background: #ffffff;
				border-radius: 7.2rem;
				box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
					0px 48px 64px -36px rgba(51, 51, 51, 0.08);

				svg {
					width: 40%;
					height: 40%;
				}
			}

			.item__content {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				gap: 1.2rem;
				margin-top: 4.5rem;

				p:first-child {
					font-weight: 600;
					font-size: 1.6rem;
					text-align: center;
				}

				p:last-child {
					font-weight: 400;
					font-size: 1.4rem;
					display: flex;
					align-items: center;
					text-align: center;
					color: #676767;
				}
			}
		}
	}
}

.create-link-form {
	display: flex;
	flex-direction: column;
	padding: 2.8rem 2.4rem;
	width: 100%;

	.create-link-form__title {
		font-style: normal;
		font-weight: 600;
		font-size: 2rem;
		display: flex;
		align-items: center;
	}

	.create-link-form__subTitle {
		font-weight: 400;
		font-size: 1.6rem;
		margin-top: 2rem;
		margin-bottom: 1.2rem;
		color: #676767;
	}

	.create-link-form__main {
		display: flex;
		margin-top: 2.8rem;
		flex-direction: column;
		gap: 2rem;

		.main__grouped-input {
			display: flex;
			gap: 2rem;
		}

		.main__upload-container {
			display: flex;
			flex-direction: column;

			.upload-container--title {
				display: flex;
				align-items: center;
				width: 100%;
				justify-content: space-between;
				margin-bottom: 1.6rem;
				z-index: 111;

				label {
					font-weight: 600;
					font-size: 1.6rem;
					color: #1b1b1b;
				}

				figure {
					width: 2rem;
					height: 2rem;
					display: grid;
					place-items: center;

					svg {
						width: 100%;
						height: 100%;
					}
				}
			}
		}

		.main__act-btn {
			width: 100%;
			margin-top: 7.2rem;
		}
	}
}

.single-preview-top-acts {
	display: flex;
	align-items: center;
	gap: 1.6rem;

	span {
		background: #ffffff;
		align-items: center;
		display: grid;
		place-items: center;
		cursor: pointer;
		width: 5rem;
		height: 5rem;
		box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
			0px 48px 64px -36px rgba(51, 51, 51, 0.08);
		border-radius: 1rem;
		transition: transform 0.2s ease-in-out;

		&:hover {
			transform: scale(1.1);
		}
		figure {
			width: 70%;
			height: 70%;
		}
	}
}

.single-preview {
	display: flex;
	gap: 3rem;
	flex: 1;
	width: 100%;

	.single-preview__right,
	.single-preview__left {
		width: 50%;
		display: flex;
		flex-direction: column;
	}

	.single-preview__left {
		flex: 1;
		display: flex;

		.left__preview-title {
			display: flex;
			width: 100%;
			flex-direction: row;
			gap: 1.6rem;
			align-items: center;

			figure {
				width: 8rem;
				height: 8rem;
				display: grid;
				place-items: center;

				img {
					width: 100%;
					height: 100%;
					border-radius: 0.8rem;
				}
			}

			span {
				display: flex;
				flex-direction: column;

				h5 {
					font-weight: 700;
					font-size: 1.8rem;
				}

				p {
					font-weight: 400;
					font-size: 1.4rem;
					color: #676767;
					margin-top: 0.2rem;
				}
			}
		}

		.left__link-details {
			display: flex;
			flex-direction: column;
			width: 100%;
			// background-color: red;
			margin-top: 2.6rem;
			border: 1px solid #e5e6e6;
			border-radius: 1.6rem;

			div:not(:last-child) {
				border-bottom: 0.6px solid #e5e6e6;
			}

			.link-details__item {
				display: flex;
				flex-direction: row;
				padding: 2.2rem;
				width: 100%;
				align-items: center;

				span {
					display: flex;
					width: 70%;
					align-items: center;
					justify-content: space-between;

					figure {
						width: 2.5rem;
						height: 2.5rem;
						display: grid;
						place-items: center;

						svg {
							width: 100%;
							height: 100%;
						}
					}
				}

				p,
				small {
					font-weight: 400;
					font-size: 1.4rem;
					display: flex;
					align-items: center;
				}

				p:first-child {
					width: 30%;
				}
			}
		}
	}

	.single-preview__right {
		display: flex;
		width: 100%;
		// background-color: red;
		overflow-y: scroll;
		flex: 1;
		flex-direction: column;
		margin-top: 2rem;

		&::-webkit-scrollbar {
			display: none;
		}

		.right__pagination-wrap {
			display: flex;
			width: 100%;
			align-items: center;
			justify-content: space-between;

			h5 {
				font-weight: 600;
				font-size: 1.6rem;
				display: flex;
				align-items: center;
			}
		}

		.main__wrap {
			display: flex;
			margin-top: 2rem;
			width: 100%;
			height: 100%;
			// background-color: red;
			overflow: hidden;

			.transaction__table {
				width: 100%;
				// background-color: yellow;
				height: 100%;
				border-radius: 1.2rem;
				overflow: auto;

				&::-webkit-scrollbar {
					display: none;
				}

				.table__main {
					border-radius: 1.2rem;
					overflow: auto;

					.main__direction {
						display: flex;
						align-items: center;
						gap: 0.8rem;

						figure {
							display: grid;
							place-items: center;
							width: 2.75rem;
							height: 2.75rem;

							svg {
								width: 100%;
								height: 100%;
							}
						}
					}

					.account_name {
						display: flex;
						align-items: center;
						gap: 1rem;
						span {
							padding: 0.6rem;
							border-radius: 50%;
						}
					}
				}
			}
		}
	}
}
