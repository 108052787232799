.card-settings {
	display: flex;
	flex-direction: column;
	width: 100%;
	&__title {
		display: flex;
		flex-direction: column;
		h5 {
			color: var(--themedarkmode, #000);
			font-size: 2rem;
			font-style: normal;
			font-weight: 600;
		}

		p {
			color: var(--base-black-base-bg-dark, #676767);
			font-size: 1.4rem;
			font-style: normal;
			font-weight: 400;
		}
	}

	&__tabs {
		display: flex;
		padding: 1.6rem;
		align-items: center;
		gap: var(--Count, 0px);
		flex-direction: column;
		border-radius: 12px;
		width: 100%;
		margin-top: 2rem;
		border: 1px solid #e5e6e6;
		cursor: pointer;
		background: var(--themelightmode, #fff);

		&.destroy {
			border: 1px solid var(--error-100, #ff0f00);

			span {
				h6 {
					color: var(--error-100, #ff0f00);
				}
			}
		}
		span {
			display: flex;
			align-self: center;
			width: 100%;
			justify-content: space-between;

			h6 {
				color: var(--base-black-base-background-dark, #020202);
				font-size: 1.6rem;
				font-style: normal;
				font-weight: 600;
				line-height: 140%; /* 22.4px */
			}
		}

		small {
			color: var(--base-black-base-bg-dark, #676767);
			font-size: 1.2rem;
			font-style: normal;
			font-weight: 400;
			width: 100%;
			line-height: 140%; /* 16.8px */
		}
	}
}

.fund-card {
	display: flex;
	flex-direction: column;
	width: 100%;

	&__title {
		display: flex;
		flex-direction: column;
		h5 {
			color: var(--themedarkmode, #000);
			font-size: 2rem;
			font-style: normal;
			font-weight: 600;
		}

		p {
			color: var(--base-black-base-bg-dark, #676767);
			font-size: 1.4rem;
			font-style: normal;
			font-weight: 400;
		}
	}

	&__card-no {
		display: flex;
		flex-direction: column;
		gap: 0.2rem;
		margin-top: 2.4rem;

		small {
			color: var(--base-black-base-bg-dark, #676767);
			font-size: 1rem;
			font-style: normal;
			font-weight: 400;
			text-transform: uppercase;
		}

		h6 {
			color: var(--themedarkmode, #000);
			font-size: 2.4rem;
			font-style: normal;
			font-weight: 700;
		}
	}

	&__input-contain {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		margin-top: 2.4rem;
	}

	&__amount-suggestion {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10rem;

		.suggestions {
			display: flex;
			max-height: 3.6rem;
			padding: 0.8rem 1.2rem;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			border-radius: 7.2rem;
			background: var(--grey-shade-10, #f7f8f7);

			p {
				color: var(--raven-accent-blue, #476885);
				text-align: center;
				font-size: 1.2rem;
				font-style: normal;
				font-weight: 600;
			}

			&.active {
				background: var(--raven-primary-dark-green, #014345);

				p {
					color: var(--base-white, var(--themelightmode, #fff));
				}
			}
		}
	}
}

.create-card {
	display: flex;
	flex-direction: column;
	width: 100%;

	.form-group__deep-green-light {
		align-items: flex-end;
		.input-submit {
			width: max-content !important;
			padding: 1.6rem 7rem;
		}
	}
	.raven-modal-content-wrap {
		width: 65rem;
	}
	&__title {
		display: flex;
		flex-direction: column;
		h5 {
			color: var(--themedarkmode, #000);
			font-size: 2rem;
			font-style: normal;
			font-weight: 600;
		}

		p {
			color: var(--base-black-base-bg-dark, #676767);
			font-size: 1.4rem;
			font-style: normal;
			font-weight: 400;
		}
	}

	&__stepper {
		display: inline-flex;
		padding: 1.2rem 1.4rem;
		flex-direction: row;
		align-items: flex-start;
		gap: 1rem;
		border-radius: 12.8rem;
		margin-bottom: 3.6rem;
		width: min-content;
		background: var(--base-background, #f9f9f9);
		margin: 2rem 0;

		p {
			width: 2.4rem;
			height: 0.8rem;
			flex-shrink: 0;
			cursor: pointer;
			border-radius: 3.2rem;
			background: var(--primary-blue-30, #b9defe);
		}

		p.active {
			width: 4.7rem;
			background: var(--raven-primary-dark-green, #014345);
		}
	}

	&__card-type {
		display: flex;
		align-items: center;
		gap: 2.8rem;
		padding-bottom: 5rem;

		.create-card__type-unit {
			display: flex;
			cursor: pointer;
			flex-direction: column;
			padding: 0 3.8rem;
			padding-top: 4rem;
			border-radius: 16px;
			border: 1px dashed var(--themedarkmode, #000);
			background: var(--themelightmode, #fff);
			overflow: hidden;

			figure {
				margin-bottom: -8px;
			}

			&.active {
				border-radius: 16px;
				border: 2.4px solid var(--themedarkmode, #000);
				background: var(--grey-shade-10, #f7f8f7);
			}

			&--title {
				display: flex;
				flex-direction: column;
				gap: 0.2rem;
				margin-bottom: 3rem;

				h6 {
					color: var(--raven-primary-dark-green, #014345);
					text-align: center;
					font-size: 1.6rem;
					font-style: normal;
					font-weight: 600;
				}

				.sub-text {
					color: var(--base-black-base-bg-dark, #676767);
					text-align: center;
					font-size: 1.2rem;
					font-style: normal;
					font-weight: 400;
					line-height: 140%; /* 16.8px */
				}
			}
		}
	}

	&__form {
		display: flex;
		width: 100%;
		gap: 2.8rem;
		flex-direction: column;
		padding-bottom: 5rem;
	}

	&__input-group {
		display: flex;
		align-items: center;
		width: 100%;
		gap: 2rem;

		input,
		.form-group__deep-green-light .react-select-class > *:nth-child(3),
		react-select-class
			undefined
			undefined
			undefined
			undefined
			css-b62m3t-container,
		.react-select-class.undefined.undefined.undefined.undefined.css-b62m3t-container {
			width: 100% !important;
		}
	}
}
