.bankbox-user-view {
	display: flex;
	flex: 1;
	width: 100%;
	height: 100%;
	border-radius: 16px;
	background: var(--themelightmode, #fff);
	padding: 0 1.7rem;

	&__left {
		display: flex;
		padding-top: 2.7rem;
		flex-direction: column;
		// flex: 1;
		height: 100%;
		width: 70%;
		border-right: 1px solid var(--raven-black-100, #eee);

		&--title {
			display: flex;
			flex-direction: column;
			gap: 0.2rem;
			padding-bottom: 2rem;

			h6 {
				color: var(--themedarkmode, #000);
				font-size: 2.4rem;
				font-style: normal;
				font-weight: 700;
				line-height: 140%; /* 33.6px */
			}

			p {
				color: var(--RAVEN-GREY, #676767);
				font-size: 1.6rem;
				font-style: normal;
				font-weight: 400;
				line-height: 160%; /* 25.6px */
			}
		}

		&--date-location {
			display: flex;
			align-items: center;
			width: 100%;
			padding: 1.2rem 0;
			border-top: 1.5px solid var(--raven-black-100, #eee);
			border-bottom: 1.5px solid var(--raven-black-100, #eee);
			gap: 3.2rem;

			.item {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 1.2rem;

				figure {
					display: grid;
					width: 4.5rem;
					height: 4.5rem;
					place-items: center;

					svg {
						width: 100%;
						height: 100%;
					}
				}

				p {
					color: var(--themedarkmode, #000);
					font-size: 1.4rem;
					font-style: normal;
					font-weight: 400;
					line-height: 140%; /* 19.6px */
				}
			}
		}

		&--user-details {
			display: flex;
			flex-direction: column;
			gap: 1.8rem;
			width: 100%;
			padding-right: 2.3rem;
			margin-top: 2.5rem;

			.details-row {
				display: flex;
				width: 100%;
				gap: 2.4rem;
				align-items: center;

				.details {
					flex: 1 1 0;
					display: flex;
					display: flex;
					padding: 2.4rem 1.2rem;
					flex-direction: column;
					align-items: flex-start;
					gap: 1rem;

					border-radius: 8px;
					border: 1px solid var(--raven-black-100, #eee);

					span {
						color: var(--base-black-base-bg-dark, #676767);
						font-size: 1.2rem;
						font-style: normal;
						font-weight: 500;
					}

					p {
						color: var(--base-black-base-background-dark, #020202);
						font-size: 1.4rem;
						font-style: normal;
						font-weight: 400;
						line-height: 140%; /* 19.6px */
					}
				}
			}
		}
	}

	&__right {
		display: flex;
		width: 30%;
		height: 100%;
		flex-direction: column;

		&--title {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 1.2rem;
			padding: 1.6rem;
			border-radius: var(--Count, 0px);
			border-bottom: 1px solid var(--raven-black-100, #eee);

			figure {
				display: grid;
				width: 4.5rem;
				height: 4.5rem;
				place-items: center;

				svg {
					width: 100%;
					height: 100%;
				}
			}

			h6 {
				color: var(--themedarkmode, #000);
				font-size: 1.6rem;
				font-style: normal;
				font-weight: 600;
				line-height: 140%; /* 22.4px */
			}
		}

		&--devices-contain {
			display: flex;
			flex-direction: column;
			gap: 2rem;
			height: 60rem;
			overflow-y: auto;
			padding: 2.4rem 1.6rem;

			.empty_all_bb {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;

				h6 {
					font-size: 2rem;
					font-style: normal;
					font-weight: 600;
					color: #014345;
				}

				p {
					color: var(--RAVEN-GREY, #676767);
					text-align: center;
					font-size: 1.6rem;
					font-style: normal;
					font-weight: 400;
					line-height: 160%; /* 25.6px */
				}
			}

			.device {
				display: flex;
				border-radius: 8px;
				flex-direction: column;
				background: var(--raven-black-50, #f7f7f7);
				padding: 2rem 1.2rem;
				background-image: url('../../../../../assets/images/bank-box.png');
				background-size: contain;
				background-repeat: no-repeat;
				background-position: bottom right;

				&__business {
					display: flex;
					flex-direction: column;

					h6 {
						color: var(--raven-black-800, #333);
						font-size: 1.4rem;
						font-style: normal;
						font-weight: 700;
						line-height: 140%; /* 19.6px */
					}

					p {
						color: var(--raven-black-600, #646464);
						font-size: 1.2rem;
						font-style: normal;
						font-weight: 400;
						line-height: 140%; /* 16.8px */
					}
				}

				&__owner {
					display: inline-flex;
					padding: 0.8rem;
					flex-direction: column;
					align-items: flex-start;
					gap: 1.4rem;
					border-radius: 10px;
					margin-top: 1.8rem;
					background: var(--themelightmode, #fff);

					&--email {
						display: flex;
						align-items: center;
						gap: 0.4rem;

						figure {
							display: grid;
							width: 2.5rem;
							height: 2.5rem;
							place-items: center;

							svg {
								width: 100%;
								height: 100%;
							}
						}

						p {
							color: var(--raven-black-600, #646464);
							font-size: 1rem;
							font-style: normal;
							font-weight: 500;
							line-height: 140%; /* 14px */
						}
					}

					&--trx-volume {
						display: flex;
						flex-direction: column;

						p {
							color: var(--raven-black-500, #8b8b8b);
							font-size: 1rem;
							font-style: normal;
							font-weight: 400;
							line-height: 140%; /* 14px */
						}

						h6 {
							color: var(--raven-black-800, #333);
							font-size: 1.4rem;
							font-style: normal;
							font-weight: 600;
							line-height: 140%; /* 19.6px */
						}
					}
				}
			}
		}
	}
}

.bankbox-assign-btn {
	color: var(--primary-darkgreen-100, #014345);
	// font-size: 1.6rem;
	font-style: normal;
	font-weight: 600;
	background: none;
	border-radius: 10px;
	border: 1px solid var(--raven-grey-shade-50, #ccc);
}

.assign-bb-modal {
	display: flex;
	flex-direction: column;

	h6 {
		font-size: 2rem;
		font-style: normal;
		font-weight: 600;
	}
	p {
		color: #676767;
	}
	&__title {
		display: flex;
		flex-direction: column;
		// gap: 1.4rem;
		margin-bottom: 2.8rem;
	}
	&__form-body {
		display: flex;
		flex-direction: column;
		gap: 2.4rem;
	}
}
