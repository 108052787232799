.verification-wrap {
	min-width: 30%;
	max-width: 70%;
	align-items: center;
	// background-color: red;
	margin-top: 3rem;
	margin: 0 auto;
	background: #ffffff;
	box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
		0px 48px 64px -36px rgba(51, 51, 51, 0.08);
	border-radius: 16px;
	padding: 4.8rem 2.4rem;
	// background-color: red;

	.verification-wrap__title {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		margin-bottom: 3rem;

		.title__sub {
			color: #676767;
			font-size: 1.4rem;
			font-weight: 400;
		}

		.title__big {
			font-size: 2.1rem;
			color: #1b1b1b;
			font-weight: 700;
		}
	}

	.verification-wrap__content {
		margin: auto 0rem;
		margin-bottom: 4rem;
		width: 100%;
		flex-direction: column;
		display: flex;
		// gap: 1.5rem;
		border-radius: 1.2rem;
		border: 1px solid #eeeeee;

		& > * {
			padding: 1.2rem;
		}

		.content__instruction-title {
			border-bottom: 0.5px solid #eeeeee;
			display: flex;
			align-items: center;
			gap: 0.8rem;
			.text {
				text-transform: uppercase;
				font-style: normal;
				font-weight: 600;
				font-size: 1.2rem;
				line-height: 140%;
				color: #014345;
			}
		}

		.content__main {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			figure {
				display: grid;
				width: fit-content;
				place-items: center;
				margin-bottom: 1.2rem;
			}
			.main__text {
				font-weight: 500;
				line-height: 2.1rem;
				font-weight: 400;
				font-size: 1.4rem;
				display: inline-block;
				color: var(--base-black-base-bg-dark, #676767);

				span {
					color: #755ae2;
					cursor: pointer;
					margin-right: 0.8rem;
				}
			}
		}

		.content__youtube {
			display: flex;
			align-items: center;
			gap: 1.5rem;
			cursor: pointer;
			border-top: 0.5px solid #eeeeee;
			margin-top: 2rem;
			justify-content: center;

			figure {
				width: fit-content;
				display: grid;
				place-items: center;
			}

			&:hover .icon {
				transform: translateX(0.45rem);
			}

			.img-box {
				width: 2.5rem;
				height: 2.5rem;

				.img {
					object-fit: contain;
					width: 100%;
					height: 100%;
				}
			}

			.text {
				font-weight: 600;
			}

			.icon {
				margin-left: auto;
				color: #755ae2;
				transition: all 0.3s ease-in-out;
			}
		}
	}

	.form {
		margin: auto 0rem;
		width: 100%;
		flex-direction: column;
		display: flex;
		gap: 2rem;

		.verify-name-box {
			display: flex;
			align-items: center;
			align-self: flex-start;
			margin-top: -1rem;
			max-height: 0;
			overflow-x: hidden;
			overflow-y: hidden;
			transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
			-webkit-transition: max-height 0.3s;
			-moz-transition: max-height 0.3s;
			width: 100%;

			& > *:first-child .label-check {
				border-radius: 50%;
				transform: scale(0.8);
			}
			.text {
				// color: var(--primary-black);
				font-weight: 600;
				font-size: 1.3rem;
				margin-left: 0.5rem;
			}
		}

		.verify-name-box-show {
			max-height: 5rem;
			transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
		}

		.btn-submit {
			margin-top: 2rem;
		}

		.label-class-span {
			//   color: #020202;
			font-weight: 700;
			text-transform: uppercase;
			opacity: 0.5;
		}

		.label-class-span-active {
			opacity: 1;
		}

		.how-text {
			display: flex;
			margin-top: -1rem;
			color: #ea872d;
			cursor: pointer;
			align-self: flex-start;

			.img-box {
				width: 1.5rem;
				height: 1.5rem;

				.img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}

			.text {
				margin-left: 0.5rem;
				font-size: 1.2rem;
			}
		}

		.show-option-box {
			display: flex;
			flex-direction: column;

			.upload-box {
				margin-top: 2rem;
			}

			.title {
				color: #676767;
				margin-bottom: 1rem;

				@media only screen and (max-width: 42em) {
					margin-top: 1rem;
				}
			}

			.select-wrap {
				display: flex;
				flex-direction: column;
				gap: 1.5rem;
				width: 100%;
				max-height: calc(100vh - 45rem);
				overflow-y: scroll;

				&::-webkit-scrollbar:horizontal {
					display: none;
				}

				/* width */
				&::-webkit-scrollbar {
					width: 0.5rem;
				}

				/* Track */
				&::-webkit-scrollbar-track {
					-webkit-box-shadow: none !important;
					background-color: transparent !important;
				}

				/* Handle */
				&::-webkit-scrollbar-thumb {
					background: #020202;
					border-radius: 30rem;
				}

				/* Handle on hover */
				&::-webkit-scrollbar-thumb:hover {
					background: #020202;
				}

				// &:hover .box:not(:hover){
				//     transform: scale(.98);
				// }

				// &:hover .box:hover{
				//     transform: scale(1);
				// }

				.box {
					display: flex;
					flex-direction: column;
					position: relative;
					border-radius: 0.8rem;
					border: 0.1rem solid #cccccc;
					padding: 1.6rem 1.3rem 1.6rem 1.6rem;
					cursor: pointer;
					gap: 0.4rem;
					transition: all 0.3s ease-in-out;

					.name {
						font-size: 1.3rem;
						color: #676767;

						@media only screen and (max-width: 42em) {
							font-size: 1.25rem;
						}
					}

					.des {
						font-weight: 700;
						font-size: 1.6rem;
						color: #020202;
						// display: none;

						@media only screen and (max-width: 42em) {
							font-size: 1.45rem;
						}
					}

					.check-wrap {
						position: absolute;
						top: 1rem;
						right: 1rem;
						width: 2.1rem;
						height: 2.1rem;
						border-radius: 50%;
						border: 0.1rem solid #cccccc;

						.icon {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							width: 0.8rem;
							height: 0.8rem;
							visibility: hidden;
						}
					}
				}

				.box-active {
					border: 0.1rem solid #020202;

					.check-wrap {
						border: 0.1rem solid #020202;
						background-color: #020202;

						.icon {
							visibility: visible;
							color: #ffffff;
						}
					}
				}

				// .box-unselect{
				//     max-height: 0 !important;
				//     overflow-x: hidden;
				//     overflow-y: hidden;
				//     transition: max-height 300ms linear-bezier(0, 1.05, 0, 1);
				//     -webkit-transition: max-height 0.8s;
				//     -moz-transition: max-height 0.8s;
				//     width: 100%;
				//     border: unset;
				//     padding: 0rem;
				// }

				// .box-select{

				// }
			}
		}
	}

	.form-business {
		// display: none;
		.two-wrap-business {
			display: grid;
			grid-template-columns: 10rem 1fr;
			grid-column-gap: 1.5rem;
		}

		.two-equal-box {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-column-gap: 1.5rem;
		}

		.accountPurpose {
			display: flex;
			flex-direction: column;
			gap: 2rem;
			align-items: flex-start;
		}

		.purposeContain {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(2, 1fr);
			grid-gap: 1.5rem;
			/* background-color: red; */
			width: 100%;
			margin-bottom: 3rem;
			align-items: center;
		}

		.purposeContain .child_wrap {
			padding: 1.5rem 1.3rem;
			background-color: #f7f8f7;
			border-radius: 4rem;
			font-size: 1.35rem;
			display: grid;
			place-items: center;
			cursor: pointer;
			transition: all 0.3s;
			text-align: center;
		}

		.purposeContain .child_wrap_active {
			background-color: #e5e6e6;
			border: 0.15rem solid #020202;
		}

		.purposeContain .child_wrap:hover {
			transform: translateY(-0.1rem);
		}

		.purposeContain .child_wrap span {
			display: inline-block;
			/* font-weight: 800; */
		}

		.purposeContain .child_wrap_active span {
			font-weight: 800;
			color: #020202;
		}

		.purposeContain .checkbox_wrap {
			display: flex;
			flex-direction: row;
			align-items: center;
		}

		.purposeContain .checkbox_wrap .input_check {
			display: none;
		}

		.purposeContain .checkbox_wrap .label {
			display: flex;
			align-items: center;
		}
	}
}

.reusable-verified-pending-wrap {
	min-height: 21rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2rem;
	border-radius: 1.2rem;

	.failed-btn {
		background-color: transparent !important;
		border-width: 0.15rem;

		span {
			font-weight: 700;
		}
	}

	.img-wrap {
		.img-box {
			width: 4rem;
			height: 4rem;

			.img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}

	.text {
		text-align: center;
		width: 75%;
	}
}
