.team-invite-wrap-container {
	display: flex;
	flex-direction: column;

	.top-wrap {
		align-self: center;
	}

	.white-content-card {
		margin-top: 5rem;
		width: 100%;
		min-height: 35rem;
		background-color: #ffffff;
		border-radius: 0.8rem;
		box-shadow: 0.1rem 0.1rem 8rem 0.4rem rgba(51, 51, 51, 0.08);
		display: flex;
		flex-direction: column;
		padding: 2rem 2rem;
		flex: 1;
		gap: 2rem;

		.role-email-box-wrap {
			display: flex;
			align-items: center;
			gap: 1rem;

			.badge-box {
				padding: 1rem 2rem;
				border-radius: 2rem;

				span {
					font-weight: 500;
				}
			}

			.email {
				background-color: rgba(247, 248, 247, 1);

				span {
					color: rgba(0, 0, 0, 1);
				}
			}

			.role {
				background-color: rgba(233, 245, 255, 1);
				span {
					color: rgba(71, 104, 133, 1);
				}
			}
		}

		.title-box {
			display: flex;
			flex-direction: column;
			gap: 0.3rem;
      margin-top: 4rem;
			// margin-bottom: 1.5rem;

			.sub-title {
				color: rgba(27, 27, 27, 1);
			}

			.main-title {
				font-weight: 700;
				font-size: 2rem;
				color: rgba(27, 27, 27, 1);
			}
		}

    .form-field-wrap{
      margin-bottom: 3.5rem;
    }

    .des-box{
      padding: 2rem 1.5rem;
      border-radius: 1.5rem;
      border: .2rem dashed rgba(185, 222, 254, 1);
      margin: 3rem 0rem 2rem 0rem;

      .text{
        color: rgba(100, 100, 100, 1);
      }
    }

		.btn-submit {
			margin-top: auto;
		}
	}
}
