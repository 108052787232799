.omnisearch {
	position: absolute;
	display: flex;
	flex-direction: column;
	width: 93rem;
	max-height: 64rem;
	top: 52px;
	border-radius: 0px 0px 16px 16px;
	overflow-y: scroll;
	background: #fff;
	/* Raven/Shadow LG */
	box-shadow: 0px 48px 64px -36px rgba(51, 51, 51, 0.08),
		0px 24px 48px -8px rgba(51, 51, 51, 0.04);
	padding: 0.8rem 1.6rem;
	padding-bottom: 3rem;

	.empty_screen_wrap {
		height: 30rem;
		margin-top: 2rem;
	}
	&__tabs {
		display: flex;
		align-items: center;
		height: 5rem;
		width: 100%;
		// background-color: red;
		// justify-content: space-evenly;

		&--item {
			display: flex;
			height: 44px;
			padding: 1.6rem;
			justify-content: center;
			align-items: center;
			gap: 4px;
			border-radius: 8px;
			cursor: pointer;

			span {
				color: var(--raven-black-600, #646464);
				font-size: 1.4rem;
				font-style: normal;
			}

			&.active {
				background: var(--grey-shade-10, #f7f8f7);

				span {
					color: var(--primary-darkgreen-100, #014345);
					text-align: center;
					font-weight: 700;
				}
			}
		}
	}

	&__showing-n-result-of {
		margin-top: 2rem;

		p {
			color: var(--raven-black-500, #8b8b8b);
			font-size: 1.6rem;
			font-style: normal;
			font-weight: 500;

			b {
				color: var(--base-black-base-black, #1b1b1b);
				font-weight: 600;
			}
		}
	}

	&__empty-search-results {
		display: flex;
		flex-direction: column;
		padding: 1.6rem 38.6rem 1rem 1.6rem;
		align-self: stretch;
		// height: 7.2rem;
		border-bottom: 0.8px solid var(--ravengreyshade-50, #e5e6e6);
		// background: red;
		gap: 1.2rem;

		p:first-child {
			color: var(--raven-black-500, #8b8b8b);
			font-size: 1.4rem;
			font-style: normal;
			font-weight: 400;
		}

		p:last-child {
			color: var(--raven-black-500, #8b8b8b);
			font-size: 1.2rem;
			font-style: normal;
			font-weight: 400;
		}
	}

	&__search-results {
		display: flex;
		flex-direction: column;
		padding: 1.6rem 1rem 1rem 1.6rem;
		align-self: stretch;
		width: 100%;
		// height: 7.2rem;
		// border-bottom: 0.8px solid var(--ravengreyshade-50, #e5e6e6);
		// background: red;
		gap: 1.2rem;

		p:first-child {
			color: #000;
			font-size: 1.4rem;
			font-style: normal;
			font-weight: 500;
		}

		.search-results {
			width: 100%;
			align-self: stretch;
			flex: 1;
			color: var(--base-black-base-bg-dark, #676767);
			font-size: 1.2rem;
			font-style: normal;
			font-weight: 400;

			&__item {
				display: flex;
				flex-direction: column;
				gap: 0.9rem;

				padding: 1rem 0;
				cursor: pointer;
				border-bottom: 0.8px solid var(--ravengreyshade-50, #e5e6e6);
			}

			b {
				color: var(--base-black-base-black, #1b1b1b);
			}

			.succesfull {
				color: var(--success-100, #1ace37);
			}

			.pending {
				color: var(--success-100, #ea872d);
			}
		}
	}

	&__single-tab {
		width: 100%;

		&--no-result {
			display: flex;
			width: 100%;
			height: 60rem;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			gap: 2rem;

			figure {
				display: grid;
				width: 15rem;
				height: 15rem;

				svg {
					width: 100%;
					height: 100%;
				}
			}

			span {
				color: var(--raven-black-600, #646464);
				text-align: center;
				width: 32%;
				font-size: 2.4rem;
				font-style: normal;
				font-weight: 400;
				line-height: 140%;

				p {
					color: var(--base-black-base-black, #1b1b1b);
					font-weight: 400;
					line-height: 140%;
					font-size: 2.4rem;
				}
			}
		}
	}
}
