.checkmark__wrapper {
  width: 20px;
  height: 20px;
}
.checkmark__wrapper .copy-checkmark {
  border-radius: 50px;
  background-color: #e8fff6;
}
.checkmark__wrapper .copy-checkmark svg {
  stroke: white !important;
  width: 50%;
  height: 50%;
}/*# sourceMappingURL=style.css.map */