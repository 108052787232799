@import '../_breakpoints.scss';

@include xs {
	html,
	body,
	.App,
	.auth-layout-wrap,
	.wrap,
	.login {
		max-width: 100vw;
		max-height: 100vh;
	}

	figure.tablet-menu-toggle {
		background-color: yellow;
		display: none !important;
		width: 0px;
		height: 0px;
		position: absolute;
		overflow: hidden;
		svg {
			display: none;
		}
	}

	// Dashhoard overview responsiveness
	.dashboard-layout-wrap .main-body-wrap .main-wrap {
		overflow-y: scroll;
	}

	.overview
		.overview__charts
		.charts__guage
		.guage__stats
		.stats__item
		.item__count {
		width: 3rem;
		height: 3rem;
	}

	.dashboard-layout-headernav-wrap .search-box {
		max-width: 150px;

		div:nth-child(2) {
			width: 100%;
		}

		input {
			width: 100%;
		}
	}
	.dashboard-layout-headernav-wrap .api-documentation-box,
	.overview .top-bar__topup-btn {
		display: none;
	}

	.dashboard-layout-wrap .main-body-wrap .main-wrap {
		padding-top: 3.1rem;
	}

	.overview .overview__wallets-wrap {
		width: 100%;
	}
	body .overview .overview__wallets {
		overflow-x: auto;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		width: max-content;
	}

	.overview .overview__wallets .wallets__balance {
		width: 28rem;
	}

	.overview .overview__wallets .wallets__statistics {
		width: max-content;
	}

	.overview .overview__charts {
		flex-direction: column;
		height: max-content;
		max-height: unset;
		min-height: unset;
	}

	.overview .overview__charts .charts__guage .guage__wrap .wrap__guage {
		height: unset;
		width: unset;
	}

	body {
		.overview,
		.history-wrap,
		.container-wrap {
			height: max-content;
			// margin-top: 5rem;
		}

		.overview .overview__charts .charts__graph {
			overflow: hidden;
		}

		.overview .overview__charts .charts__graph .graph__top .top__title {
			p {
				font-size: 1.2rem;
			}
		}

		.no-record .no-record__text {
			width: 100%;
			padding: 8rem 0;
			gap: 1.2rem;
		}
	}

	// Navbar Responsiveness

	.container-page-mode {
		// margin-top: 5rem;
		overflow: hidden;
	}
	.dashboard-layout-wrap {
		.mobile-nav-thread {
			height: 90vh;
			margin-top: 7rem;
		}
		.show-mobile-nav {
			transform: unset !important;
			border-top-right-radius: 1.2rem;
			border-bottom-right-radius: 1.2rem;
		}
	}

	.dashboard-layout-headernav-wrap .mobile-hamburger {
		display: flex;
		flex-direction: column;
		gap: 8px;

		p {
			background-color: black;
			transition: transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
		}

		p:first-child,
		p:last-child {
			width: 24px;
			height: 1.5px;
		}

		p:nth-child(2) {
			width: 15px;
			height: 1.5px;
		}

		&.collapsed {
			gap: 0;
			p:nth-child(2) {
				opacity: 0;
				display: none;
			}

			p:first-child {
				margin: -0.5px;
				transform: rotate(45deg);
			}

			p:last-child {
				margin: -1px;
				transform: rotate(-45deg);
			}
		}
	}

	// End of Dashboard Overview responsiveness

	body {
		.login {
			padding: 0 2rem;
		}

		.auth-layout-wrap .right-wrap .wrap {
			width: 100%;
			padding: 0 3rem;
		}
		.auth-layout-wrap .right-wrap {
			display: flex;
		}
		.non-mobile {
			display: none !important;
		}

		.non-desktop {
			display: flex !important;
		}
		.atlas-mobile-table-wrap {
			display: flex;
		}

		// Modal responsiveness
		.form-group__black-light .pin-group .pin_field_group {
			grid-column-gap: 1.2rem;
			margin-right: 0 !important;
		}

		.trx-modal-main-wrapper .raven-modal-content-wrap {
			width: 400px;
		}

		.modal-parent-wrap .raven-modal-content-wrap {
			max-width: 350px;
		}

		.smartfilter .top-bar__filter-export .filter-export__drop-down-wrap {
			width: 100%;
			max-width: 35rem;
		}

		.smartfilter
			.top-bar__filter-export
			.filter-export__drop-down
			.dropdown__apply-filter-btn {
			z-index: 300;
			background-color: white;
		}

		// End of Navbar responsiveness

		//Wallet Balances Responsiveness
		.walletslide {
			max-height: 33rem;
			min-height: 25rem;
		}

		.smartfilter .top-bar__filter-export .filter-export__export-btn p,
		.smartfilter .top-bar__filter-export .filter-export__filter-btn p {
			display: none;
		}

		.smartfilter {
			gap: 2rem;
		}

		.walletslide__wallet,
		.overview .overview__wallets .wallets__statistics .statistics__item {
			min-width: 200px;
		}

		.dashboard-layout-wrap .main-body-wrap .main-wrap {
			height: 100%;
			margin-top: 5rem;
			overflow: hidden;
		}

		.no-record-mascot .no-record-mascot__bottom p {
			width: 90%;
		}

		.history-wrap .history-wrap__top .top__top-right-content {
			border-radius: 50%;

			button {
				border-radius: 50%;
				height: 50px;
				width: 50px;
				padding: 0;

				figure {
					// width: 5rem;
					// height: 5rem;
				}
			}
			p {
				display: none;
			}
		}

		.container-page-wrap .container-page .content {
			max-width: 100% !important;
			width: 100% !important;
			border: none;
		}

		.container-page-wrap {
			height: max-content;
			.container-page {
				padding: 0;
				height: max-content;
			}
		}

		.atlas-payment-container {
			padding: 0;

			.form {
				width: 100%;
				padding: 0;
				margin: 0;
			}
		}

		.container-wrap {
			height: max-content;
		}
		.new-transfer__mode .mode__items .items__content p {
			display: flex;
			text-wrap: nowrap;
			align-items: flex-start;
		}

		.security-lock-wrap-box {
			padding: 0;
			margin: 0;

			* {
				align-items: flex-start;
				justify-content: flex-start;
				padding: 0;
				margin: 0;
				display: flex;
			}
		}

		.preview-transfer {
			border: none;
		}

		.preview-transfer .preview-transfer__header {
			margin-top: 7.2rem;
		}
		// End of Wallet Responsiveness

		// Responsiveness for filter
		.smartfilter
			.top-bar__filter-export
			.filter-export__drop-down
			.drop-down__item
			.custom-date__form-wrap.show {
			display: flex;
			flex-direction: column;
		}

		.smartfilter .top-bar__filter-export .filter-export__drop-down-wrap {
			min-width: 32.45rem;
		}

		.smartfilter .top-bar__filter-export .filter-export__drop-down {
			right: 5%;
			max-height: min-content;
			.drop-down__item {
				padding-bottom: 9rem;
			}
		}

		// End of Filter Responsiveness

		//Responsiveness for virtual accounts
		.virtual-account__account-details
			.account-details__main
			.account-details__item
			p:first-child {
			width: 40%;
		}

		.container-page-mode__content {
			max-height: 80rem;
			overflow-y: scroll !important;
		}

		// End of Responsiveness for virtual accounts

		// ++++ Onboarding Responsiveness ++++
		.onboarding {
			margin-top: 5rem;

			.onboarding__learn-more {
				width: 100%;

				.learn-more__content .content__actions {
					flex-direction: column;
					margin-bottom: 4rem;
				}
			}
			.onboarding__content-wrap {
				flex-direction: column;

				.mobile-onboarding-steps {
					width: 100%;
				}
			}
			.onboarding__hi-there p {
				width: 100%;
			}

			.onboarding__learn-more {
				.learn-more__content {
					flex-direction: column;
					width: 100%;
					margin-bottom: 3rem;
					height: max-content;

					.content__video {
						width: 100%;
					}

					.content__documentation {
						height: max-content;
					}
				}
			}
		}

		.verification {
			.verification__footer {
				padding: 0 2rem;
				margin-bottom: 1rem;
			}
			.verification__children {
				width: 100%;
				max-width: unset;
				padding: 0 2rem;
				margin-top: 3rem;

				.verification-wrap {
					width: 100%;
					max-width: unset;
				}
			}
		}

		.cac-verification .cac-verification__navbar {
			display: none;
		}

		.business-info-verification-wrap {
			width: 100%;
		}
		// ++ End of onboarding responsiveness +++
	}

	// +++ container layout responsiveness +++

	.transfer-mode .container-page .atlas-payment-container.expanded {
		max-width: 100%;
	}

	.step-two-transfer-raven {
		width: 100%;
	}

	.preview-transfer,
	.transfer-mode .container-page .atlas-payment-container {
		max-width: 100%;
	}

	.transfer-mode .container-page .atlas-payment-container {
		padding: 0 2rem;
		min-width: unset;
	}

	.transfer-mode .container-page-footer {
		padding: 1rem 2rem;
		.container-page-footer-btn {
			max-width: 100%;
		}
	}

	.preview-transfer .preview-transfer__logo {
		top: 0;
	}

	.transfer-mode
		.container-page-header
		.container-page-header__steppers
		.step
		.stepper-title {
		display: none;
	}

	.container-page-wrap .container-page .content {
		padding: 0;
	}

	// ++++ end of container layout responsiveness ++++

	// ++++ beginning of settings responsiveness ++++
	.settings .settings__sidebar .sidebar__selector {
		display: none;
	}

	.settings {
		margin-top: 5rem;
	}

	.business-profile {
		border: none;
		padding: 0;
		width: 100%;
		overflow-y: auto;
	}

	.business-profile__owner span {
		display: none;
	}

	.business-profile__details div:first-child {
		flex: 0 0 40%;
	}

	.business-profile__details {
		border: none;
		flex-direction: column;
	}

	.settings .settings__sidebar {
		overflow: hidden;
	}

	// end of settings responsiveness

	//start user kyc resposiveness
	.container-page-wrap .container-page .container__top {
		display: none;
	}

	.action_bar_show {
		padding: 1rem !important;
		width: 100%;
		// gap: unset;
		// justify-content: unset;
	}

	.action_bar__info .action-box {
		display: none;
	}

	.kyc-dashboard-wrapper__verified-details
		.verified-details__information--details {
		flex-direction: column;
	}

	.kyc-dashboard-wrapper__verified-details
		.verified-details__information--details
		div {
		gap: 2rem;
	}

	.kyc-dashboard-wrapper__verified-details .verified-details__information {
		padding: 0;
		padding-top: 2rem;
	}
	.kyc-dashboard-wrapper__verified-details
		.verified-details__information--preview-json {
		margin-top: 3rem;
	}

	.kyc-dashboard-wrapper__verified-details
		.verified-details__information--preview-json
		div {
		height: 5rem;
	}

	.kyc-dashboard-wrapper__verified-details
		.verified-details__information--preview-json
		div
		p {
		font-size: 1.4rem;
	}

	.kyc-dashboard-wrapper__verified-details {
		width: 100%;
	}

	.new-transfer__mode .mode__title {
		margin-left: 2rem;
	}

	.smartfilter
		.top-bar__filter-export
		.filter-export__drop-down
		.drop-down__item
		span
		figure {
		width: 2.5rem;
		height: 2.5rem;
		overflow: hidden;
		border-radius: 50%;
		display: grid;
		align-items: center;
		justify-content: center;
		.basic-details-settings__app-name--input-group svg {
			width: 100%;
			height: 100%;
		}
	}

	.auth-legacy-switch {
		display: none;
		z-index: 10000;
	}

	//issuing responsiveness
	.single-card-container .single-card__content {
		flex-direction: column;
		overflow-y: auto;

		.content__right {
			width: 100%;
		}

		.content__left {
			.mobile-card-preview {
				width: 40rem;
				background: none;

				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	.single-card-container
		.single-card__content
		.content__right
		.right__content-table
		.content-table__row {
		p:first-child {
			width: 40%;
		}
		p:last-child {
			width: 60%;
		}
	}

	//bankbox responsiveness
	.bankbox-overview {
		flex-direction: column;

		&__right {
			width: 100%;
			padding: 2rem;
			justify-content: start;
			border-bottom-left-radius: 1rem;
			border-bottom-right-radius: 1rem;
			height: min-content;
		}

		&__left {
			width: 100%;
			height: 30%;
		}
	}

	.bankbox-overview__right--title h6 {
		font-size: 2.4rem;
	}

	.bankbox-modals {
		flex-direction: column;
		height: 100%;

		&__left {
			height: 20%;
			flex: unset;
			padding-top: 2rem;
		}

		&__right {
			padding: 2rem 3rem;
			overflow-y: auto;
			max-height: 55rem;
			flex: unset;

			&--content {
				max-height: 100%;
				height: 100%;
				overflow: auto;
				.basic-content__title h6 {
					font-size: 3.2rem;
				}
			}
		}
	}

	.bankbox-create-modal .raven-modal-content-wrap .close-box {
		right: 0;
		left: unset !important;
		float: right;
		width: min-content;
	}
	.basic-details-settings__app-name--input-group {
		flex-direction: column;

		.input-group__color-swatch {
			width: 100%;
		}
	}
	.basic-details-settings__app-name--avatar p {
		width: 100%;
	}

	.basic-details-settings__prices--input-group {
		display: flex;
		flex-direction: column;
	}

	.settings .settings__title {
		gap: 2rem;
	}

	.bank-box-overview__bottom-contain {
		flex-direction: column;
	}
	.bank-box-overview__stats-wrapper {
		overflow-y: scroll;
		min-height: 18rem;
	}

	.bank-box-overview__stats-wrapper--stats {
		min-width: 23rem;
	}

	.history-wrap .history__content {
		height: 45%;
		flex: unset;
	}

	.history-wrap .flex-one {
		flex: 1 !important;
	}
}

body .check-drop-box {
	opacity: 1;
	z-index: 4;
}
