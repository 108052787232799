.table-action-wrap {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.table-action-wrap figure {
  width: 3rem;
  height: 3rem;
  display: grid;
}
.table-action-wrap figure svg {
  width: 100%;
  height: 100%;
}

.table-edit-input-wrap {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  gap: 0.6rem;
  margin: 0;
}
.table-edit-input-wrap .table-edit-input-wrap__input {
  width: 100%;
  max-width: unset;
}
.table-edit-input-wrap .table-edit-input-wrap__input .form-input {
  width: 100% !important;
  min-width: unset !important;
  max-width: unset !important;
}
.table-edit-input-wrap .table-edit-input-wrap__input .form-group {
  width: 100%;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.table-edit-input-wrap p {
  padding: 0.3rem;
  border-radius: 50%;
  background-color: #755ae2;
  animation: blink 2s infinite;
}
.table-edit-input-wrap__input {
  max-width: 15rem;
  min-width: 10rem;
  display: flex;
}
.table-edit-input-wrap__input .form-input {
  max-width: 15rem;
  min-width: 10rem;
}
.bulk-transfer__table-wrap .table-row > *:not(:last-child) {
  padding-right: 2rem !important;
}/*# sourceMappingURL=index.css.map */