.loader-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  place-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.loader-wrapper figure {
  width: 15rem;
  height: 15rem;
}
.loader-wrapper p {
  font-size: 1.2rem;
  margin-top: -1.8rem;
}/*# sourceMappingURL=index.css.map */