.trx-modal-main-wrapper .raven-modal-content-wrap {
  width: 52.1rem;
  max-height: 95vh;
}
.trx-modal-main-wrapper .content {
  overflow: hidden;
}
.trx-modal-main-wrapper .details-modal-wrap {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}
.trx-modal-main-wrapper .details-modal-wrap__title {
  color: #000;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0.8rem;
}
.trx-modal-main-wrapper .details-modal-wrap .badge {
  display: flex;
  align-items: center;
  height: 2.5rem;
  border-radius: 7.2rem;
  padding: 0.6rem 1.2rem;
  gap: 0.6rem;
}
.trx-modal-main-wrapper .details-modal-wrap .badge--credit {
  background: #1ace37;
  text-transform: capitalize;
}
.trx-modal-main-wrapper .details-modal-wrap .badge--credit figure {
  width: 2.5rem;
  height: -moz-min-content;
  height: min-content;
}
.trx-modal-main-wrapper .details-modal-wrap .badge--credit p {
  color: white;
}
.trx-modal-main-wrapper .details-modal-wrap .badge--debit {
  background: #ff0f00;
  text-transform: capitalize;
}
.trx-modal-main-wrapper .details-modal-wrap .badge--debit figure {
  width: 1.75rem;
  height: 1.75rem;
}
.trx-modal-main-wrapper .details-modal-wrap .badge--debit p {
  color: white;
}
.trx-modal-main-wrapper .details-modal-wrap .badge--successful {
  background: #1ace37;
  text-transform: capitalize;
}
.trx-modal-main-wrapper .details-modal-wrap .badge--successful p {
  color: white;
}
.trx-modal-main-wrapper .details-modal-wrap .badge--failed {
  background: #ff0f00;
  text-transform: capitalize;
}
.trx-modal-main-wrapper .details-modal-wrap .badge--failed p {
  color: white;
}
.trx-modal-main-wrapper .details-modal-wrap .badge p {
  font-weight: 600;
  font-size: 1.2rem;
}
.trx-modal-main-wrapper .details-modal-wrap .badge figure {
  display: grid;
  place-items: center;
}
.trx-modal-main-wrapper .details-modal-wrap .badge figure svg {
  width: 100%;
  height: 100%;
}
.trx-modal-main-wrapper .details-modal-wrap__content {
  border-radius: 1.2rem;
  margin-bottom: 3rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
}
.trx-modal-main-wrapper .details-modal-wrap__content .content__title {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.trx-modal-main-wrapper .details-modal-wrap__content .content__title span h5 {
  font-weight: 600;
  font-size: 2rem;
  display: flex;
  align-items: center;
}
.trx-modal-main-wrapper .details-modal-wrap__content .content__title span p {
  font-weight: 400;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  color: #676767;
}
.trx-modal-main-wrapper .details-modal-wrap__content .content__title figure {
  display: grid;
  place-items: center;
  width: 3.5rem;
  height: 3.5rem;
}
.trx-modal-main-wrapper .details-modal-wrap__content .content__title figure svg {
  width: 100%;
  height: 100%;
}
.trx-modal-main-wrapper .details-modal-wrap__content .content__main {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
.trx-modal-main-wrapper .details-modal-wrap__content .content__main .main__label {
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  margin-top: 3.2rem;
  margin-bottom: 1rem;
}
.trx-modal-main-wrapper .details-modal-wrap__content .content__main .main__account-details {
  display: flex;
  flex-direction: column;
  border-radius: 1.2rem;
  width: 100%;
  position: relative;
  height: 100%;
  max-height: 100rem;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
  overflow-y: auto;
}
.trx-modal-main-wrapper .details-modal-wrap__content .content__main .main__account-details.not-collapsed {
  border: 0.7px solid #e5e6e6;
}
.trx-modal-main-wrapper .details-modal-wrap__content .content__main .main__account-details .panel {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
  transform: translateX(100%);
}
.trx-modal-main-wrapper .details-modal-wrap__content .content__main .main__account-details .panel.active {
  transform: translateX(0);
}
.trx-modal-main-wrapper .details-modal-wrap__content .content__main .main__account-details .account-details__item {
  display: flex;
  padding: 2.2rem 1.6rem;
  width: 100%;
  overflow: hidden;
  min-height: 6rem;
}
.trx-modal-main-wrapper .details-modal-wrap__content .content__main .main__account-details .account-details__item.address {
  flex-direction: column;
  width: 100%;
  gap: 0.8rem;
}
.trx-modal-main-wrapper .details-modal-wrap__content .content__main .main__account-details .account-details__item.address p {
  font-size: 1.2rem;
  width: 100%;
}
.trx-modal-main-wrapper .details-modal-wrap__content .content__main .main__account-details .account-details__item.address span {
  width: 100%;
}
.trx-modal-main-wrapper .details-modal-wrap__content .content__main .main__account-details .account-details__item.address span p {
  color: #676767;
  font-size: 1.2rem;
  width: 100%;
}
.trx-modal-main-wrapper .details-modal-wrap__content .content__main .main__account-details .account-details__item .item__value {
  flex: 1;
}
.trx-modal-main-wrapper .details-modal-wrap__content .content__main .main__account-details .account-details__item .item__value p {
  width: 100%;
  min-width: 80%;
  word-break: break-all;
}
.trx-modal-main-wrapper .details-modal-wrap__content .content__main .main__account-details .account-details__item p {
  font-weight: 400;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
}
.trx-modal-main-wrapper .details-modal-wrap__content .content__main .main__account-details .account-details__item:not(:last-child) {
  border-bottom: 0.6px solid #e5e6e6;
}
.trx-modal-main-wrapper .details-modal-wrap__content .content__main .main__account-details .account-details__item p:first-child {
  width: 40%;
}
.trx-modal-main-wrapper .details-modal-wrap__content .content__main .main__account-details .account-details__item p:last-child {
  width: 100%;
  overflow: hidden;
}
.trx-modal-main-wrapper .details-modal-wrap__content .content__main .main__account-details .account-details__item span {
  display: flex;
  width: 60%;
  justify-content: space-between;
  align-items: center;
}
.trx-modal-main-wrapper .details-modal-wrap__content .content__main .main__account-details .account-details__item span figure {
  display: grid;
  place-items: center;
  width: 2.5rem;
  cursor: pointer;
  height: auto;
}
.trx-modal-main-wrapper .details-modal-wrap__content .content__main .main__account-details .account-details__item span figure svg {
  width: 100%;
  height: 100;
}
.trx-modal-main-wrapper .details-modal-wrap .meta_preview_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: -2rem;
  padding: 1rem;
  border-radius: 1.2rem;
}
.trx-modal-main-wrapper .details-modal-wrap .meta_preview_wrapper.expanded {
  margin-top: 2rem;
  border: 0.7px solid #e5e6e6;
}
.trx-modal-main-wrapper .details-modal-wrap .meta_preview_wrapper .collapse-icon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.trx-modal-main-wrapper .details-modal-wrap .meta_preview_wrapper .meta_preview {
  max-height: 50rem;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
}
.trx-modal-main-wrapper .details-modal-wrap .collapsed {
  max-height: 0 !important;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.3s;
  -moz-transition: max-height 0.3s;
}
.trx-modal-main-wrapper .trx-modal__button-wrap {
  display: flex;
  gap: 1.2rem;
}
.trx-modal-main-wrapper .trx-modal__button-wrap button {
  display: flex;
  align-items: center;
  gap: 1rem;
  background: var(--raven-black-50, #f7f7f7);
  border: none;
  box-shadow: none;
  margin-top: 2rem;
  padding: 0.8rem 1.2rem;
  border-radius: 0.8rem;
  cursor: pointer;
  transition: transform 0.2s linear;
}
.trx-modal-main-wrapper .trx-modal__button-wrap button:hover {
  transform: translateY(-3px);
}
.trx-modal-main-wrapper .trx-modal__button-wrap button:hover figure {
  animation: scale 1s cubic-bezier(0.1, 0.5, 0.3, 1);
}
@keyframes rotate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1.2);
  }
}
.trx-modal-main-wrapper .trx-modal__button-wrap button figure {
  display: grid;
  place-items: center;
  width: 2rem;
  height: 2rem;
}
.trx-modal-main-wrapper .trx-modal__button-wrap button figure svg {
  width: 100%;
  height: 100%;
}/*# sourceMappingURL=index.css.map */