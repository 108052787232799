.send-money-wrap-box {
  flex: 1;
  display: flex;
  padding: 2rem;
  flex-direction: column;
}
@media only screen and (max-width: 42em) {
  .send-money-wrap-box {
    width: 100%;
    align-self: stretch;
    flex: unset;
  }
}
.send-money-wrap-box .send-money-wrap {
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  width: 50rem;
  gap: 2rem;
}
@media only screen and (max-width: 42em) {
  .send-money-wrap-box .send-money-wrap {
    width: 100%;
    padding: 2rem 2rem;
    align-self: stretch;
  }
}
.send-money-wrap-box .send-money-wrap .title {
  font-size: 1.6rem;
}
.send-money-wrap-box .send-money-wrap .select-box {
  display: flex;
  flex-direction: column;
}
.send-money-wrap-box .send-money-wrap .select-box > *:last-child {
  border: unset;
}
@media only screen and (max-width: 42em) {
  .send-money-wrap-box .send-money-wrap .select-box {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    align-items: flex-start;
    width: 100%;
    align-self: stretch;
  }
}
.send-money-wrap-box .send-money-wrap .select-box .box {
  cursor: pointer;
  padding: 2.5rem 0rem;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: unset;
  border-radius: unset;
}
.send-money-wrap-box .send-money-wrap .select-box .box:hover .right-arrow .icon {
  transform: translateX(0.4rem);
}
.send-money-wrap-box .send-money-wrap .select-box .box .text-box .name {
  margin-top: unset;
  margin-bottom: 0.3rem;
}
.send-money-wrap-box .send-money-wrap .select-box .box .img-wrap {
  margin-right: 1.3rem;
}
.send-money-wrap-box .send-money-wrap .select-box .box .img-wrap .img-box {
  width: 4.2rem;
  height: 4.2rem;
  display: grid;
  place-items: center;
  transition: all 0.3s;
  border-radius: 50%;
  background-color: #f7f8f7 !important;
}
.send-money-wrap-box .send-money-wrap .select-box .box .img-wrap .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 40%;
  height: 40%;
}
.send-money-wrap-box .send-money-wrap .select-box .box .right-arrow {
  margin-left: auto;
}
.send-money-wrap-box .send-money-wrap .select-box .box .right-arrow .icon {
  width: 1.5rem;
  height: 1.5rem;
  transition: all 0.3s;
}
@media only screen and (max-width: 42em) {
  .send-money-wrap-box .send-money-wrap .select-box .box {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem 1rem;
  }
}
@media only screen and (max-width: 42em) and (max-width: 38em) {
  .send-money-wrap-box .send-money-wrap .select-box .box .img-box {
    margin-top: unset;
    width: 3.5rem;
    height: 3.5rem;
  }
}
@media only screen and (max-width: 42em) {
  .send-money-wrap-box .send-money-wrap .select-box .box .text {
    font-size: 1.2rem;
    width: 85%;
  }
}
@media only screen and (max-width: 42em) {
  .send-money-wrap-box .send-money-wrap .select-box .box .name {
    margin-top: unset;
    font-size: 1.45rem;
  }
}
.send-money-wrap-box .send-money-wrap .select-box > *:not(:first-child) .img-wrap .img-box .img {
  width: 70%;
  height: 70%;
}

.bill-purchase {
  flex: 1;
  display: flex;
  overflow-y: scroll;
  padding: 2rem;
  flex-direction: column;
  position: relative;
}
.bill-purchase.step2 {
  padding: 0;
}
.bill-purchase .bill-purchase__title {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.8rem;
}
.bill-purchase .bill-purchase__title h5 {
  font-weight: 600;
  font-size: 2rem;
  display: flex;
  align-items: center;
  color: #000000;
}
.bill-purchase .bill-purchase__title p {
  font-weight: 400;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  color: #676767;
}
.bill-purchase .bill-purchase__title svg {
  width: 1.75rem;
  height: 1.75rem;
}
.bill-purchase .bill-purchase__title svg path {
  stroke: black;
}
.bill-purchase .form {
  flex-direction: column;
  display: flex;
  gap: 2rem;
  flex: 1;
}
@media only screen and (max-width: 42em) {
  .bill-purchase .form {
    width: 90%;
  }
}
.bill-purchase .form .recepient-box {
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: all 300ms linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  width: 100%;
}
.bill-purchase .form .recepient-box .text-line {
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  align-items: center;
  width: 100%;
}
.bill-purchase .form .recepient-box .text-line .text {
  color: #676767;
  background-color: #f7f8f7;
  font-size: 1.1rem;
  border: 0.1rem solid #cccccc;
  padding: 0.5rem 1.1rem;
  border-radius: 10rem;
  font-weight: 600;
}
.bill-purchase .form .recepient-box .text-line span {
  height: 0.1rem;
  background-color: rgba(204, 204, 204, 0.5);
}
.bill-purchase .form .recepient-box .view-total-box {
  display: flex;
  align-items: center;
}
.bill-purchase .form .recepient-box .view-total-box .view-wrap {
  margin-left: auto;
  position: relative;
}
.bill-purchase .form .recepient-box .view-total-box .view-wrap .text {
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  gap: 0.6rem;
}
.bill-purchase .form .recepient-box .view-total-box .view-wrap .text:hover figure {
  transform: translateX(3px);
}
.bill-purchase .form .recepient-box .view-total-box .view-wrap .text figure {
  width: 1.5rem;
  height: 1.5rem;
  display: grid;
  place-items: center;
  transition: transform 300ms ease-in-out;
}
.bill-purchase .form .recepient-box .view-total-box .view-wrap .text figure svg {
  width: 100%;
  height: 100%;
}
.bill-purchase .form .recepient-box .view-total-box .view-wrap .view-wrap__container {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.bill-purchase .form .recepient-box .view-total-box .view-wrap .view-wrap__container span {
  background: #f0eeff;
  border-radius: 7.2rem;
  padding: 0.1rem 1.2rem;
}
.bill-purchase .form .recepient-box .view-total-box .view-wrap .view-wrap__container span p {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  font-weight: 600;
  gap: 0.6rem;
  color: #755ae2;
}
.bill-purchase .form .recepient-box .view-total-box .view-wrap .cancel-box {
  margin-left: auto;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  background-color: #ffdfdd;
  margin-left: auto;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.3s;
}
.bill-purchase .form .recepient-box .view-total-box .view-wrap .cancel-box:hover {
  transform: scale(1.1);
}
.bill-purchase .form .recepient-box .view-total-box .view-wrap .cancel-box .icon {
  fill: #ff0f00;
  width: 1.1rem;
  height: 1.1rem;
}
.bill-purchase .form .recepient-box .view-total-box .view-wrap .view-all-box {
  position: absolute;
  bottom: 105%;
  right: 0;
  width: 35rem;
  height: 30rem;
  padding: 2rem;
  z-index: 4;
  overflow-y: scroll;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 2rem;
  transform: scale(0);
  background-color: rgb(255, 255, 255);
  transition: all 0.3s ease-in-out;
  transform-origin: bottom right;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.bill-purchase .form .recepient-box .view-total-box .view-wrap .view-all-box .wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.bill-purchase .form .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap {
  display: flex;
  align-items: center;
  padding: 1rem 1.3rem;
  border-radius: 0.8rem;
}
.bill-purchase .form .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .text-box .name {
  font-weight: 700;
  font-size: 1.3rem;
}
.bill-purchase .form .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .text-box .text {
  font-size: 1.1rem;
  font-weight: 400;
}
.bill-purchase .form .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .img-box {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
}
.bill-purchase .form .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.bill-purchase .form .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .cancel-box {
  margin-left: auto;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  background-color: #ffdfdd;
  margin-left: auto;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.3s;
}
.bill-purchase .form .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .cancel-box:hover {
  transform: scale(1.1);
}
.bill-purchase .form .recepient-box .view-total-box .view-wrap .view-all-box .wrap .child-wrap .cancel-box .icon {
  fill: #ff0f00;
  width: 1.1rem;
  height: 1.1rem;
}
.bill-purchase .form .recepient-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar:horizontal {
  display: none;
}
.bill-purchase .form .recepient-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar {
  width: 0.3rem;
}
.bill-purchase .form .recepient-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.bill-purchase .form .recepient-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar-thumb {
  background: #020202;
  border-radius: 30rem;
}
.bill-purchase .form .recepient-box .view-total-box .view-wrap .view-all-box::-webkit-scrollbar-thumb:hover {
  background: #020202;
}
.bill-purchase .form .recepient-box .view-total-box .view-wrap .view-all-box-show {
  transform: scale(1);
}
.bill-purchase .form .recepient-box .view-total-box .view-wrap .text {
  cursor: pointer;
  font-weight: 600;
  color: var(--primary-purple);
}
.bill-purchase .form .recepient-box .preview-box {
  margin-top: 1rem;
  max-height: 15rem;
  padding: 1rem 0rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1.5rem;
}
.bill-purchase .form .recepient-box .preview-box .box {
  padding: 1rem;
  cursor: pointer;
  position: relative;
  background: #f7f8f7;
  border: 1px solid #f7f8f7;
  box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04), 0px 0px 2px rgba(51, 51, 51, 0.02);
  border-radius: 8px;
}
.bill-purchase .form .recepient-box .preview-box .box .raven-name {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 140%;
  /* identical to box height, or 20px */
  letter-spacing: -0.004em;
  color: #000000;
}
.bill-purchase .form .recepient-box .preview-box .box .img-box {
  display: grid;
  place-items: center;
  width: 3.5rem;
}
.bill-purchase .form .recepient-box .preview-box .box .cancel-box {
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  background-color: #ffdfdd;
  margin-left: auto;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.3s;
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.bill-purchase .form .recepient-box .preview-box .box .cancel-box:hover {
  transform: scale(1.1);
}
.bill-purchase .form .recepient-box .preview-box .box .cancel-box .icon {
  fill: #ff0f00;
  width: 1.1rem;
  height: 1.1rem;
}
.bill-purchase .form .recepient-box .preview-box .box .name {
  margin-top: 2rem;
  font-size: 1.45rem;
}
.bill-purchase .form .recepient-box-show {
  overflow-x: unset;
  overflow-y: unset;
  max-height: 30rem;
  transition: all 300ms linear-bezier(0, 1.05, 0, 1);
}
.bill-purchase .form .label-span-amount {
  font-weight: 600 !important;
  opacity: 1 !important;
}
.bill-purchase .form .verify-name-box {
  display: flex;
  align-items: center;
  margin-top: -1rem;
  justify-content: center;
  border-radius: 7.2rem;
  width: -moz-max-content;
  width: max-content;
  height: 2.8rem;
  padding: 0 1.2rem;
  background: var(--raven-blue-shade-20, #e9f5ff);
}
.bill-purchase .form .verify-name-box .text {
  color: var(--raven-accent-blue, #476885);
  font-weight: 600;
  text-align: center;
  font-size: 1.3rem;
  margin-left: 0.5rem;
}
.bill-purchase .form .select-network-provider_wrap {
  flex-direction: column;
  width: 100%;
  gap: 1.4rem;
  display: flex;
  margin-top: 0.8rem;
}
.bill-purchase .form .select-network-provider_wrap label {
  font-size: 1.4rem;
}
.bill-purchase .form .select-network-provider_wrap .select-network-provider {
  display: flex;
  gap: 1rem;
}
.bill-purchase .form .select-network-provider_wrap .select-network-provider .select-network-provider__item {
  display: flex;
  height: 9rem;
  width: 8rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  cursor: pointer;
  border-radius: 1.2rem;
  border: 0.8px solid var(--ravengreyshade-50, #e5e6e6);
}
.bill-purchase .form .select-network-provider_wrap .select-network-provider .select-network-provider__item img {
  width: 3.5rem;
  height: 3.5rem;
}
.bill-purchase .form .select-network-provider_wrap .select-network-provider .select-network-provider__item.active {
  background: var(--raven-blue-shade-20, #e9f5ff);
  border: 2px solid var(--raven-primary-dark-green, #014345);
}
.bill-purchase .form .add-another-box {
  display: flex;
  align-items: center;
  margin-top: -1rem;
  align-self: flex-start;
  cursor: default;
  opacity: 0.5;
}
.bill-purchase .form .add-another-box:hover .img-box {
  transform: scale(1.2);
}
.bill-purchase .form .add-another-box .text {
  color: var(--primary-purple);
  font-weight: 600;
  font-size: 1.3rem;
}
.bill-purchase .form .add-another-box .img-box {
  transition: all 0.3s ease-in-out;
  width: 2rem;
  height: 2rem;
  display: grid;
  place-items: center;
  margin-right: 0.3rem;
}
.bill-purchase .form .add-another-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.bill-purchase .form .add-another-box-active {
  opacity: 1;
  cursor: pointer;
}
.bill-purchase .form .label-span {
  font-weight: 700;
  opacity: 0.5;
  cursor: default;
}
.bill-purchase .form .label-span-active {
  opacity: 1;
  cursor: pointer;
}
.bill-purchase .form .bg-box {
  width: 100%;
  height: 10rem;
  background-color: #f7f8f7;
  border-radius: 1.6rem;
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}

@keyframes moveUpAnimeSendMoney {
  0% {
    transform: translateY(-3%) translateX(-50%);
    opacity: 0.7;
  }
  100% {
    transform: translateY(0%) translateX(-50%);
    opacity: 1;
  }
}
@keyframes moveUpAnime {
  0% {
    transform: translateY(2%);
    opacity: 0.7;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
.bulk-drop-wrap {
  position: relative;
}
.bulk-drop-wrap .select-bulk-type-box {
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.3s;
  -moz-transition: max-height 0.3s;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
}
.bulk-drop-wrap .select-bulk-type-box .second-drop-child-wrap {
  position: relative;
}
.bulk-drop-wrap .select-bulk-type-box .second-drop-child-wrap .child-row-two {
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-20%);
  padding: 2rem;
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
  border-radius: 1.2rem;
}
.bulk-drop-wrap .select-bulk-type-box .second-drop-child-wrap .child-row-two > *:first-child {
  padding-bottom: 1.5rem;
}
.bulk-drop-wrap .select-bulk-type-box .second-drop-child-wrap .child-row-two > *:last-child {
  border: unset !important;
}
.bulk-drop-wrap .select-bulk-type-box .second-drop-child-wrap .child-row-two .child-row {
  margin-left: unset;
  transform: unset !important;
}
.bulk-drop-wrap .select-bulk-type-box .second-drop-child-wrap .child-row-two .child-row-parent {
  padding: 1.5rem 0rem !important;
}
.bulk-drop-wrap .select-bulk-type-box .child-row {
  display: flex;
  align-items: center;
  width: -moz-max-content;
  width: max-content;
  align-self: stretch;
  margin-left: 5rem;
  width: 100%;
}
.bulk-drop-wrap .select-bulk-type-box .child-row:hover .img-box {
  transform: scale(1.03);
}
.bulk-drop-wrap .select-bulk-type-box .child-row .title {
  font-weight: 700;
}
.bulk-drop-wrap .select-bulk-type-box .child-row .img-box {
  width: 4rem;
  height: 4rem;
  display: grid;
  place-items: center;
  margin-right: 1rem;
  transition: all 0.3s;
  border-radius: 50%;
  background-color: #f7f8f7;
}
.bulk-drop-wrap .select-bulk-type-box .child-row .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 50%;
  height: 50%;
}
.bulk-drop-wrap .select-bulk-type-box-show {
  max-height: 30rem;
  overflow-x: visible;
  overflow-y: visible;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
}
.bulk-drop-wrap .select-bulk-type-box-show > *:not(:last-child) {
  padding-bottom: 1.5rem;
}

.send-money-select-bulk-type-modal-wrap {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-bottom: 2rem;
}
.send-money-select-bulk-type-modal-wrap .des-text {
  font-size: 1.5rem;
  line-height: 2rem;
  background-color: #fff6ed;
  display: flex;
  align-items: flex-start;
  padding: 2rem 1rem;
  border-radius: 1.2rem;
}
.send-money-select-bulk-type-modal-wrap .des-text span {
  font-size: 1.35rem;
}
.send-money-select-bulk-type-modal-wrap .select-box {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1rem;
  padding-top: 2rem;
  margin-bottom: 2rem;
}
.send-money-select-bulk-type-modal-wrap .select-box .select-item {
  display: flex;
  align-items: center;
  padding: 2rem 1.5rem;
  border-radius: 0.8rem;
  cursor: pointer;
}
.send-money-select-bulk-type-modal-wrap .select-box .select-item:hover .icon {
  transform: translateX(0.3rem);
}
.send-money-select-bulk-type-modal-wrap .select-box .select-item .text-box {
  margin-right: auto;
}
.send-money-select-bulk-type-modal-wrap .select-box .select-item .text-box .text {
  font-weight: 700;
  font-size: 1.55rem;
}
.send-money-select-bulk-type-modal-wrap .select-box .select-item .img-box {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  margin-right: 1.5rem;
  display: grid;
  place-items: center;
}
.send-money-select-bulk-type-modal-wrap .select-box .select-item .img-box .img {
  width: 65%;
  height: 65%;
  -o-object-fit: contain;
     object-fit: contain;
}
.send-money-select-bulk-type-modal-wrap .select-box .select-item .icon {
  width: 2rem;
  height: 2rem;
  color: #755ae2;
  transition: all 0.3s ease-in-out;
}
.send-money-select-bulk-type-modal-wrap .switch-box {
  display: flex;
  align-items: center;
  padding: 0.7rem 1.5rem;
  border-radius: 3rem;
  gap: 2rem;
  width: -moz-max-content;
  width: max-content;
}
.send-money-select-bulk-type-modal-wrap .switch-box .switch-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 3rem;
  gap: 0.8rem;
  cursor: pointer;
  padding: 1rem 1.5rem;
}
.send-money-select-bulk-type-modal-wrap .switch-box .switch-item-active {
  cursor: default;
  box-shadow: 0px 3.0687px 4.0916px rgba(51, 51, 51, 0.04), 0px 0px 2.0458px rgba(51, 51, 51, 0.02);
  background-color: #755ae2;
}
.send-money-select-bulk-type-modal-wrap .switch-box .switch-item-active .text {
  color: #ffffff;
}

.coming-soon-type-box {
  position: relative;
}
.coming-soon-type-box .box-card {
  opacity: 0.5;
}
.coming-soon-type-box .security-lock-wrap-box {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.step-two-bills {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 4.3rem;
}
.step-two-bills .preview-bill__logo {
  position: absolute;
  top: 0;
  right: 19rem;
}
.step-two-bills .preview-bill__logo .airtime-data {
  width: 9rem;
  height: 9rem;
  display: grid;
  place-items: center;
  position: relative;
}
.step-two-bills .preview-bill__logo .airtime-data img {
  width: 85%;
  height: 85%;
}
.step-two-bills .preview-bill__logo .airtime-data svg {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 3rem;
  height: 3rem;
}

.preview-bill {
  flex: 1;
  height: 100%;
  width: 100%;
  background: #ffffff;
  border: 0.8px solid #e5e6e6;
  border-radius: 16px;
  padding: 2rem;
}
.preview-bill .preview-bill__header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 4rem;
}
.preview-bill .preview-bill__header h5 {
  font-weight: 700;
  font-size: 2rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}
.preview-bill .preview-bill__header small {
  font-weight: 400;
  font-size: 1.4rem;
  display: flex;
  margin-top: 0.4rem;
  align-items: center;
  text-align: center;
  color: #676767;
}
.preview-bill .preview-bill__table {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.preview-bill .preview-bill__table .table__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 2rem 0;
  border-bottom: 1px solid #eeeeee;
}
.preview-bill .preview-bill__table .table__item p {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 140%;
  /* identical to box height, or 20px */
  display: flex;
  align-items: center;
  color: #000000;
}
.preview-bill .preview-bill__button {
  margin-top: 4rem;
}
.preview-bill .preview-bill__switch {
  display: flex;
  align-items: center;
  justify-self: center;
  width: 100%;
  justify-content: center;
  margin-top: 2rem;
  gap: 0.6rem;
}
.preview-bill .preview-bill__switch figure {
  background: #f7f8f7;
  height: 3rem;
  width: 3rem;
  display: grid;
  place-items: center;
  border-radius: 2rem;
  cursor: pointer;
}
.preview-bill .preview-bill__switch figure svg {
  width: 40%;
  height: 40%;
}
.preview-bill .preview-bill__switch figure:first-child {
  transform: rotate(180deg);
}
.preview-bill .preview-bill__switch .switch__pages {
  display: flex;
  height: 3rem;
  width: auto;
  padding: 0 1rem;
  align-items: center;
  background: #f7f8f7;
  border-radius: 2rem;
}
.preview-bill .preview-bill__switch .switch__pages p {
  font-weight: 700;
  font-size: 1.2rem;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #1b1b1b;
}
.preview-bill .preview-bill__switch .switch__pages p:last-child {
  color: #acacac;
}/*# sourceMappingURL=index.css.map */