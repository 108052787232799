.bank-box-overview {
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
	height: 100%;

	.empty_all_bb {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		h6 {
			font-size: 2rem;
			font-style: normal;
			font-weight: 600;
			color: #014345;
		}

		p {
			color: var(--RAVEN-GREY, #676767);
			text-align: center;
			font-size: 1.6rem;
			font-style: normal;
			font-weight: 400;
			line-height: 160%; /* 25.6px */
		}
	}

	&__stats-wrapper {
		display: flex;
		flex-direction: row;
		gap: 2.4rem;
		align-items: center;

		&--stats {
			border-radius: 12px;
			background: var(--themelightmode, #fff);
			height: 18.3rem;
			flex: 1 0 0;
			padding: 1.2rem 1.6rem;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding-bottom: 2.8rem;

			.top {
				width: 100%;
				display: flex;
				justify-items: flex-start;
				justify-content: space-between;

				figure {
					width: 4.5rem;
					height: 4.5rem;
					display: grid;
					place-items: center;

					svg {
						width: 100%;
						height: 100%;
					}
				}
				.button {
					border-radius: 72px;
					min-width: 12rem;
					box-shadow: none;
					border: none;
					height: min-content;
					padding: 1rem 0;
					background: var(--raven-grey-shade-10, #f7f8f7);

					p {
						color: var(--themedarkmode, #000);
						text-align: center;
						font-size: 1.2rem;
						font-style: normal;
						font-weight: 400;
						line-height: 140%; /* 16.8px */
					}
				}
			}

			.bottom {
				p {
					color: var(--RAVEN-GREY, #676767);
					font-size: 1.6rem;
					font-style: normal;
					font-weight: 400;
					line-height: 160%; /* 25.6px */
				}

				h6 {
					color: var(--themedarkmode, #000);
					font-size: 2.4rem;
					font-style: normal;
					font-weight: 700;
					line-height: 140%; /* 33.6px */
				}
			}
		}
	}

	&__bottom-contain {
		width: 100%;
		display: flex;
		flex: 1;
		// max-height: 80%;
		// background-color: red;
		// height: min-height;
		// max-height: calc(100vh - 50%);
		padding-top: 2.4rem;
		gap: 2rem;
		padding-bottom: 2rem;

		.top-transacting {
			display: flex;
			flex-direction: column;
			background: #ffffff;
			flex: 0 0 30%;
			border-radius: 1.2rem;
			padding: 1.6rem;
			height: 100%;

			&__title {
				display: flex;
				align-items: center;
				gap: 1.2rem;

				figure {
					width: 4.5rem;
					height: 4.5rem;
					display: grid;
					place-items: center;

					svg {
						width: 100%;
						height: 100%;
					}
				}

				h6 {
					color: var(--themedarkmode, #000);
					font-size: 1.6rem;
					font-style: normal;
					font-weight: 600;
					line-height: 140%; /* 22.4px */
				}
			}

			&__users {
				display: flex;
				flex-direction: column;
				gap: 2rem;
				max-height: 40rem;
				flex: 1;
				margin-top: 3rem;
				overflow-y: auto;

				.top-users {
					display: flex;
					height: 7.2rem;
					padding: 1.5rem 1.6rem;
					align-items: flex-start;
					gap: var(--Count, 10px);
					border-radius: 10px;
					border: 1px solid var(--raven-black-100, #eee);

					span:first-child {
						width: 4.5rem;
						height: 4.5rem;
						display: grid;
						place-items: center;
						border-radius: 72px;

						p {
							color: var(--primary-darkgreen-100, #014345);
							text-align: center;
							font-size: 2rem;
							font-style: normal;
							font-weight: 600;
							line-height: 160%; /* 32px */
						}
					}

					.name-box {
						display: flex;
						flex-direction: column;

						.name {
							color: var(--raven-black-800, #333);
							font-size: 1.4rem;
							font-style: normal;
							font-weight: 500;
							line-height: 140%; /* 19.6px */
						}

						.email {
							color: var(--raven-black-500, #8b8b8b);
							font-size: 1.2rem;
							font-style: normal;
							font-weight: 500;
							line-height: 140%; /* 16.8px */
						}
					}
				}
			}
		}

		.charts__graph {
			background: #ffffff;
			height: 100%;
			width: 100%;
			display: flex;
			flex: 1;
			flex-direction: column;
			// background-color: yellow;
			border-radius: 1.2rem;
			padding: 2.8rem 2.4rem;

			.graph__top {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.top__title {
					display: flex;
					flex-direction: column;
					gap: 1.2rem;

					p:first-child {
						font-style: normal;
						font-weight: 700;
						font-size: 1.8rem;
						line-height: 140%;
						// color: #000000;
					}

					.title__graph-indicator {
						display: flex;
						gap: 1.6rem;
						align-items: center;
						margin-top: 1.4rem;

						span {
							display: flex;
							align-items: center;
							gap: 0.8rem;

							&:is(:first-child) {
								p:first-child {
									width: 1.8rem;
									height: 1.8rem;
									background: #014345;
									border-radius: 0.4rem;
								}
							}

							&:is(:last-child) {
								p:first-child {
									width: 1.8rem;
									height: 1.8rem;
									background: #9be5c9;
									border-radius: 0.4rem;
								}
							}
						}
					}
				}

				.top__filter {
					display: flex;
					gap: 1rem;
					padding: 1rem 1rem;
					border: 1px solid #e3e3e3;
					border-radius: 200px;
					align-items: center;

					span {
						gap: 0.8rem;
						align-items: center;
						display: flex;

						figure {
							display: grid;
							cursor: pointer;
							place-items: center;
						}
					}
				}
			}

			.graph__no-record {
				height: 89%;
				background: #f7f7f7;
				border-radius: 0.8rem;
				margin-top: 1.8rem;
				display: flex;
				align-items: center;
				justify-content: center;
				background-repeat: no-repeat;
				background-size: contain;
				background-position: bottom center;

				background-image: url('../../../../../assets/images/graph_no_data.svg');

				.no-record__text {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					flex-direction: column;
					width: 50%;
					gap: 0.2rem;

					h5 {
						font-weight: 600;
						font-size: 2rem;
						line-height: 160%;
						text-align: center;
						color: #014345;
					}

					p {
						font-weight: 400;
						font-size: 1.6rem;
						line-height: 160%;
						text-align: center;
						text-align: center;
						color: var(--base-background-black);
					}
				}
			}

			.graph__main {
				width: 100%;
				height: 100%;
				// flex: 1;
				// background-color: #755ae2;
				margin-top: 1.8rem;
			}
		}
	}
}
