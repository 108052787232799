.pagination {
	display: flex;
	flex-direction: column;

	&__controls {
		display: flex;
		align-items: center;
		justify-content: center;
		// margin-top: 20px;
	}

	&__page-count {
		display: flex;
		color: #1b1b1b;
	}

	&__button {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0.5rem 0.5rem;
		font-size: 1.4rem;
		margin: 0 5px;
		color: #1b1b1b;
		cursor: pointer;
		transition: background-color 0.3s;

		&--disabled {
			opacity: 0.2;
			cursor: not-allowed;

			svg {
				-webkit-text-stroke-color: black;
			}
		}
	}
}
