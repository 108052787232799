.withdrawal-bankbox-modal-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  .title-text-box {
    margin-top: -1rem;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    margin-bottom: 1rem;

    .title {
      font-weight: 600;
      font-size: 2.3rem;
    }

    .text {
      color: rgba(103, 103, 103, 1);
    }
  }

  .current-balance-box {
    background-color: rgba(247, 248, 247, 1);
    padding: 1.5rem;
    border-radius: 0.8rem;
    // margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    .text {
        color: rgba(100, 100, 100, 1);
        font-size: 1.3rem;
      }

      .amount{
        font-size: 2.2rem;
        font-weight: 700;
        margin-top: -.5rem;
        margin-bottom: .5rem;
      }

    .copy-text-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      background-color: rgba(255, 255, 255, 1);
      align-self: flex-start;
      padding: 0.8rem 1rem;
      border-radius: 1.6rem;

      .copy-box {
        .img-box {
          width: 2rem;
          height: 2rem;
          cursor: pointer;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      .copy-text{
        color: rgba(0, 0, 0, 1);
        font-size: 1.3rem;
      }
    }
  }
}
