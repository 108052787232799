.issuing {
	.table__main {
		.table__card-pan {
			display: flex;
			align-items: center;
			gap: 0.6rem;

			figure {
				width: 3.5rem;
				height: auto;

				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
}

.virtual {
	border-radius: 72px;
	background: var(--raven-blue-shade-20, #e9f5ff);
	color: var(--raven-accent-blue, #476885);
	text-transform: capitalize;
}
