.mobile-bills-wrap {
  display: flex;
  flex-wrap: wrap;
  border-radius: 16px;
  background: var(--base-white, #fff);
  width: 100%;
  flex-direction: row;
  height: -moz-max-content;
  height: max-content;
  align-items: center;
  gap: 2rem;
  height: 100%;
}
.mobile-bills-wrap .mobile-bills-wrap__item {
  border-radius: 1.2rem;
  flex: 1;
  min-width: 40%;
  max-width: 50%;
  border: 1px solid var(--ravengreyshade-50, #e5e6e6);
  padding: 1.2rem;
}
.mobile-bills-wrap .mobile-bills-wrap__item figure {
  margin-bottom: 3.2rem;
}
.mobile-bills-wrap .mobile-bills-wrap__item .item__content {
  display: flex;
  gap: 1.2rem;
  flex-direction: column;
}
.mobile-bills-wrap .mobile-bills-wrap__item .item__content h5 {
  color: #000;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
}
.mobile-bills-wrap .mobile-bills-wrap__item .item__content p {
  color: var(--base-black-base-black, #1b1b1b);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
}/*# sourceMappingURL=index.css.map */