.mobile-table-card-wrapper-box {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 1.6rem 0;
  border-bottom: 1px solid var(--ravengreyshade-50, #e5e6e6);
}
.mobile-table-card-wrapper-box .avatar-img-box {
  display: grid;
  place-items: center;
  align-self: center;
  margin-right: 1rem;
  flex: 0 0 5%;
}
.mobile-table-card-wrapper-box .avatar-img-box .avatar-box {
  width: 3.3rem;
  height: 3.3rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  grid-gap: 0.4rem;
}
.mobile-table-card-wrapper-box .avatar-img-box .avatar-box span {
  font-weight: 700;
  font-size: 1.1rem;
  text-transform: uppercase;
}
.mobile-table-card-wrapper-box .avatar-img-box .img-box-cre-deb {
  width: 3.3rem;
  height: 3.3rem;
  background-color: #e8fff6;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.mobile-table-card-wrapper-box .avatar-img-box .img-box-cre-deb .img {
  width: 35%;
  height: 35%;
  -o-object-fit: contain;
     object-fit: contain;
}
.mobile-table-card-wrapper-box .avatar-img-box .img-box-debit {
  background-color: #fff5f5;
}
.mobile-table-card-wrapper-box .title-text-box .title {
  font-weight: 700;
  font-size: 1.3rem;
}
.mobile-table-card-wrapper-box .title-text-box .text {
  margin-top: 0.6rem;
  font-size: 1.1rem;
}
.mobile-table-card-wrapper-box .amount-box {
  margin-left: auto;
  text-align: end;
}
.mobile-table-card-wrapper-box .amount-box .text {
  margin-top: 0.6rem;
  font-size: 1.1rem;
}
.mobile-table-card-wrapper-box .amount-box .amount {
  font-weight: 700;
  font-size: 1.25rem;
}/*# sourceMappingURL=index.css.map */