.withdrawal-bankbox-modal-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.withdrawal-bankbox-modal-wrap .title-text-box {
  margin-top: -1rem;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin-bottom: 1rem;
}
.withdrawal-bankbox-modal-wrap .title-text-box .title {
  font-weight: 600;
  font-size: 2.3rem;
}
.withdrawal-bankbox-modal-wrap .title-text-box .text {
  color: rgb(103, 103, 103);
}
.withdrawal-bankbox-modal-wrap .current-balance-box {
  background-color: rgb(247, 248, 247);
  padding: 1.5rem;
  border-radius: 0.8rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
.withdrawal-bankbox-modal-wrap .current-balance-box .text {
  color: rgb(100, 100, 100);
  font-size: 1.3rem;
}
.withdrawal-bankbox-modal-wrap .current-balance-box .amount {
  font-size: 2.2rem;
  font-weight: 700;
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
}
.withdrawal-bankbox-modal-wrap .current-balance-box .copy-text-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  background-color: rgb(255, 255, 255);
  align-self: flex-start;
  padding: 0.8rem 1rem;
  border-radius: 1.6rem;
}
.withdrawal-bankbox-modal-wrap .current-balance-box .copy-text-box .copy-box .img-box {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}
.withdrawal-bankbox-modal-wrap .current-balance-box .copy-text-box .copy-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.withdrawal-bankbox-modal-wrap .current-balance-box .copy-text-box .copy-text {
  color: rgb(0, 0, 0);
  font-size: 1.3rem;
}/*# sourceMappingURL=WithdrawalModal.css.map */