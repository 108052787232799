.bankbox-trx-tbl-name {
  display: flex;
  flex-direction: column;
}
.bankbox-trx-tbl-name p {
  color: var(--themedarkmode, #000);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
}
.bankbox-trx-tbl-name p:last-child {
  color: var(--RAVEN-GREY, #676767);
}/*# sourceMappingURL=index.css.map */