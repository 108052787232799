.new-transfer {
  display: flex;
  padding: 2rem;
  width: 35%;
  flex-direction: column;
}
.new-transfer .mode-items-wrap {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  border-radius: 16px;
  padding: 1.6rem;
  background: #f9f9f9;
}
.new-transfer__mode {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 2rem;
}
.new-transfer__mode .mode__title {
  font-weight: 600;
  font-size: 2rem;
  display: flex;
  align-items: center;
  color: #000000;
  margin-top: 2rem;
}
.new-transfer__mode .mode__title-bulk-mode {
  display: flex;
  color: #000000;
  margin-top: 2rem;
  flex-direction: column;
}
.new-transfer__mode .mode__title-bulk-mode h5 {
  font-weight: 600;
  font-size: 2rem;
}
.new-transfer__mode .mode__title-bulk-mode p {
  font-weight: 400;
  font-size: 1.4rem;
  color: #676767;
}
.new-transfer__mode .mode__items {
  display: flex;
  border-radius: 1.2rem;
  padding: 1.6rem;
  gap: 1.7rem;
  cursor: pointer;
  background-color: white;
  position: relative;
}
.new-transfer__mode .mode__items .checkmark {
  display: none;
}
.new-transfer__mode .mode__items.active .checkmark {
  content: "";
  position: absolute;
  top: 0;
  display: grid;
  right: 0;
  place-items: center;
  padding: 0.5rem 1rem;
  border-bottom-left-radius: 1.2rem;
  border-top-right-radius: 1.2rem;
  background-color: #014345;
}
.new-transfer__mode .mode__items.active .checkmark svg path {
  stroke: white;
}
.new-transfer__mode .mode__items .items__icons {
  width: 5rem;
  height: 5rem;
  display: grid;
  align-items: center;
}
.new-transfer__mode .mode__items .items__icons svg {
  width: 100%;
  height: 100%;
}
.new-transfer__mode .mode__items .items__content {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}
.new-transfer__mode .mode__items .items__content p {
  font-weight: 600;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  color: #000000;
}
.new-transfer__mode .mode__items .items__content small {
  font-weight: 400;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  color: #676767;
}
.new-transfer__mode .mode__button {
  margin-top: 7rem;
  width: 100%;
}/*# sourceMappingURL=index.css.map */