.atlas-drop-wrap {
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  gap: 1rem;
  background: #ffffff;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 8px;
  display: flex;
  width: -moz-max-content;
  width: max-content;
  z-index: 111;
  transform-origin: top;
  transform: scale(0);
  max-height: 0;
  overflow: hidden;
}
.atlas-drop-wrap.show-drop {
  transform: scale(1);
  max-height: 300rem;
  padding: 1rem;
}
.atlas-drop-wrap.show-drop .more_item {
  width: 100%;
  padding: 1.4rem;
}
.atlas-drop-wrap .more_item {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}
.atlas-drop-wrap .more_item:nth-child(2) {
  border-bottom: 0.4px solid #e5e6e6;
  border-top: 0.4px solid #e5e6e6;
}
.atlas-drop-wrap .more_item figure {
  display: grid;
  place-items: center;
  height: 2rem;
  width: 2rem;
}
.atlas-drop-wrap .more_item figure svg {
  width: 100%;
  height: 100%;
}/*# sourceMappingURL=index.css.map */