.collections-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.collections-wrap .collections-wrap__top {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.8rem;
}
.collections-wrap .collections-wrap__top .top__title {
  display: flex;
  align-items: flex-start;
  gap: 0.2rem;
  flex-direction: column;
}
.collections-wrap .collections-wrap__top .top__title h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
}
.collections-wrap .collections-wrap__top .top__title p {
  font-weight: 400;
  font-size: 1.6rem;
  color: #676767;
}
.collections-wrap .collections-wrap__top .request-money {
  display: flex;
  border: none;
  background: #014345;
  box-shadow: none;
  padding: 1.6rem;
  border-radius: 1rem;
  transition: transform 300ms ease-in-out;
  cursor: pointer;
  align-items: center;
  gap: 1rem;
}
.collections-wrap .collections-wrap__top .request-money:hover {
  transform: translateY(-2px);
}
.collections-wrap .collections-wrap__top .request-money:hover figure {
  transform: scale(1.1);
}
.collections-wrap .collections-wrap__top .request-money figure {
  width: 2.5rem;
  height: 2.5rem;
  display: grid;
  place-items: center;
  transition: transform 300ms ease-in-out;
}
.collections-wrap .collections-wrap__top .request-money figure svg {
  height: 100%;
  width: 100%;
}
.collections-wrap .collections-wrap__top .request-money p {
  color: white;
  font-weight: 500;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.collections-wrap .collections__content {
  display: flex;
  width: 100%;
  height: 85%;
  background: #ffffff;
  border-radius: 1.6rem;
  padding: 2rem;
  flex-direction: column;
}
.collections-wrap .collections__content .content__top-bar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.collections-wrap .collections__content .content__top-bar .top-bar__filter-export {
  display: flex;
  gap: 1.6rem;
  align-items: center;
  z-index: 1;
  position: relative;
}
.collections-wrap .collections__content .content__top-bar .top-bar__filter-export .filter-export__export-btn,
.collections-wrap .collections__content .content__top-bar .top-bar__filter-export .filter-export__filter-btn {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 1.6rem 2.4rem;
  gap: 0.8em;
  cursor: pointer;
  height: 5rem;
  background: #f7f8f7;
  border-radius: 1rem;
  z-index: 100;
}
.collections-wrap .collections__content .content__top-bar .top-bar__filter-export .filter-export__export-btn p,
.collections-wrap .collections__content .content__top-bar .top-bar__filter-export .filter-export__filter-btn p {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #676767;
}
.collections-wrap .collections__content .content__top-bar .top-bar__filter-export .filter-export__export-btn figure,
.collections-wrap .collections__content .content__top-bar .top-bar__filter-export .filter-export__filter-btn figure {
  width: -moz-fit-content;
  width: fit-content;
  display: grid;
  place-items: center;
}
.collections-wrap .collections__content .content__top-bar .top-bar__filter-export .filter-export__export-btn figure svg,
.collections-wrap .collections__content .content__top-bar .top-bar__filter-export .filter-export__filter-btn figure svg {
  width: 2rem;
  height: 2rem;
}
.collections-wrap .collections__content .content__top-bar .top-bar__filter-export .filter-export__drop-down {
  position: absolute;
  background: #ffffff;
  width: -moz-max-content;
  width: max-content;
  height: -moz-max-content;
  height: max-content;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 1.6rem;
  box-shadow: 0;
  top: 110%;
  right: 55%;
  padding: 2rem;
  transform-origin: top right;
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
  z-index: 100;
}
.collections-wrap .collections__content .content__top-bar .top-bar__filter-export .filter-export__drop-down--open {
  transform: scale(1);
}
.collections-wrap .collections__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__switch {
  display: flex;
  width: 100%;
  cursor: pointer;
  justify-content: flex-start;
  gap: 1.2rem;
  border-bottom: 0.8px solid #e5e6e6;
}
.collections-wrap .collections__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__switch span {
  gap: 0.4rem;
  padding-bottom: 1.6rem;
}
.collections-wrap .collections__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__switch span p {
  font-weight: 400;
  font-size: 1.4rem;
  display: grid;
  place-items: center;
  padding: 1.2rem 1.6rem;
  border-radius: 8px;
  color: #1b1b1b;
}
.collections-wrap .collections__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__switch span.active {
  border-bottom: 4px solid #014345;
}
.collections-wrap .collections__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__switch span.active p {
  color: #014345;
  font-weight: 600;
  background: #e9f5ff;
}
.collections-wrap .collections__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range {
  display: flex;
  flex-direction: column;
  margin-top: 2.4rem;
}
.collections-wrap .collections__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range h5 {
  font-weight: 600;
  font-size: 1.4rem;
  color: #014345;
}
.collections-wrap .collections__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range .custom-date-range__grouped-form {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 1.2rem;
  padding-bottom: 2.2rem;
  border-bottom: 0.7px solid #e5e6e6;
}
.collections-wrap .collections__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range .custom-date-range__type {
  margin-top: 2.2rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
.collections-wrap .collections__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range .drop-down__action-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  margin-top: 5.2rem;
  padding: 2.3rem 0rem 0.3rem 0rem;
  border-top: 0.7px solid #e5e6e6;
}
.collections-wrap .collections__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range .drop-down__action-btns button {
  width: 50%;
}
.collections-wrap .collections__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range .drop-down__action-btns button:first-child {
  background: #e9f5ff;
  color: #014345;
}
.collections-wrap .collections__content .content__top-bar .top-bar__filter-export .filter-export__drop-down-closer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  display: none;
  visibility: none;
}
.collections-wrap .collections__content .content__top-bar .top-bar__filter-export .filter-export__drop-down-closer--opened {
  display: unset;
  visibility: unset;
}
.collections-wrap .collections__content .content__main {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.collections-wrap .collections__content .content__main--top-bar {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.collections-wrap .collections__content .content__main--top-bar .top-bar__filter {
  display: flex;
  align-items: center;
  gap: 2.4rem;
  align-items: center;
}
.collections-wrap .collections__content .content__main--top-bar .top-bar__filter .main__active-filters {
  align-items: center;
  overflow: hidden;
  max-height: 0;
  gap: 1rem;
  display: flex;
  transition: max-height 300ms ease-in-out;
}
.collections-wrap .collections__content .content__main--top-bar .top-bar__filter .main__active-filters--show {
  display: flex;
  overflow: unset;
  max-height: 30rem;
  transition: max-height 300ms ease-in-out;
}
.collections-wrap .collections__content .content__main--top-bar .top-bar__filter .main__active-filters .active-filters__collections {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-direction: row;
}
.collections-wrap .collections__content .content__main--top-bar .top-bar__filter .main__active-filters .active-filters__item {
  background: #e9f5ff;
  border-radius: 7.2rem;
  display: flex;
  padding: 0.55rem 0.7rem;
  align-items: center;
  gap: 1rem;
}
.collections-wrap .collections__content .content__main--top-bar .top-bar__filter .main__active-filters .active-filters__item figure {
  width: 1.5rem;
  height: 1.5rem;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.collections-wrap .collections__content .content__main--top-bar .top-bar__filter .main__active-filters .active-filters__item figure svg {
  width: 100%;
  height: 100%;
}
.collections-wrap .collections__content .content__main--top-bar .top-bar__filter .main__active-filters .active-filters__item p {
  color: #476885;
  display: flex;
  font-size: 1.2rem;
  align-items: center;
  gap: 0.6rem;
}
.collections-wrap .collections__content .content__main--top-bar .top-bar__filter .main__active-filters .active-filters__clear-item {
  background: #fff5f5;
  border-radius: 72px;
  border-radius: 7.2rem;
  display: flex;
  padding: 0.55rem 0.7rem;
  align-items: center;
  cursor: pointer;
}
.collections-wrap .collections__content .content__main--top-bar .top-bar__filter .main__active-filters .active-filters__clear-item p {
  font-weight: 600;
  font-size: 1.2rem;
  color: #ff0f00;
}
.collections-wrap .collections__content .content__main .main__wrap {
  display: flex;
  margin-top: 2rem;
  width: 100%;
  height: 85%;
  overflow: hidden;
}
.collections-wrap .collections__content .content__main .main__wrap .transaction__table {
  width: 100%;
  height: 100%;
  border-radius: 1.2rem;
  overflow: auto;
}
.collections-wrap .collections__content .content__main .main__wrap .transaction__table .table__main {
  border-radius: 1.2rem;
  overflow: auto;
}
.collections-wrap .collections__content .content__main .main__wrap .transaction__table .table__main .session-wrap {
  position: relative;
}
.collections-wrap .collections__content .content__main .main__wrap .transaction__table .table__main .main__direction {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}
.collections-wrap .collections__content .content__main .main__wrap .transaction__table .table__main .main__direction figure {
  display: grid;
  place-items: center;
  width: 2.75rem;
  height: 2.75rem;
}
.collections-wrap .collections__content .content__main .main__wrap .transaction__table .table__main .main__direction figure svg {
  width: 100%;
  height: 100%;
}

@keyframes confettiAnimation {
  0% {
    transform: rotate(0deg) translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: rotate(-15deg) translate(100%, -100%);
    opacity: 0;
  }
}
.copy-text {
  cursor: pointer;
}

.copy-animation {
  position: absolute;
  color: #014345;
  font-size: 1.2rem;
  right: 3rem;
  top: 0;
  animation-name: confettiAnimation;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}/*# sourceMappingURL=index.css.map */