.kyc-dashboard-wrapper {
	direction: flex;
	flex-direction: column;
	background-color: white;
	height: 100%;
	flex: 1;
	width: 100%;
	padding: 5.6rem 2rem 2rem 2rem;

	&__title {
		display: flex;
		flex-direction: column;

		h5 {
			color: #000;
			font-size: 2.4rem;
			font-style: normal;
			font-weight: 700;
		}

		span {
			color: var(--raven-base-bg-dark, #676767);
			font-size: 1.4rem;
			font-style: normal;
			font-weight: 400;
		}
	}

	&__select-verification-type {
		display: flex;
		flex-direction: column;
		margin-top: 3.2rem;
	}

	&__form-wrap {
		display: flex;
		padding: 1.2rem;
		justify-content: center;
		max-height: 35rem;
		// align-items: center;
		// padding-top: 3.2rem;
		overflow-y: scroll;
		// max-height: fit-content;
		border-radius: 1.2rem;
		margin-top: 2.8rem;
		border-top: 0.6px solid var(--raven-black-300, #d1d1d1);
		flex-direction: column;
		gap: 2.4rem;
		// background-color: yellow;

		.grouped-input {
			display: flex;
			width: 100%;
			gap: 1.7rem;
			// background-color: red;

			.grouped-input__item {
				width: 50%;

				input {
					width: 100%;
				}
			}
		}

		&.nin-verification {
			// padding-bottom: 7rem;
		}

		&.bvn-verification {
			// padding-bottom: 7rem;
		}
	}

	&__form-btn {
		margin-top: 5rem;
	}

	&__verified-details {
		display: flex;
		flex: 1;
		flex-direction: column;
		background-color: white;
		.avatar {
			width: 7rem;
			height: 7rem;
			background-color: #f7f8f7;
			border-radius: 50%;
			display: grid;
			place-items: center;

			p {
				color: var(--primary-darkgreen-100, #014345);
				text-align: center;
				font-size: 2rem;
				font-style: normal;
				font-weight: 600;
			}
		}
		// disable action bar arrow-icon
		.action_bar__info .action-box .arrow-icon {
			display: none;
		}

		.verified-details__information {
			display: flex;
			flex-direction: column;
			flex: 1;
			padding: 2rem;

			&--owner {
				display: flex;
				align-items: center;
				gap: 1.6rem;
				border-bottom: 1px solid var(--raven-black-200, #e3e3e3);
				padding-bottom: 2rem;
				span {
					display: flex;
					flex-direction: column;
					gap: 1.2rem;

					h5 {
						font-weight: 700;
						font-size: 2.4rem;
					}

					p {
						overflow: hidden;
						color: var(--raven-base-bg-dark, #676767);
						text-overflow: ellipsis;
						font-size: 1.4rem;
					}
				}
				figure {
					width: 10rem;
					height: 10rem;
					display: grid;
					place-items: center;
					object-fit: contain;
					overflow: hidden;
					border-radius: 50%;

					img {
						width: 10rem;
						object-fit: 100%;
						object-position: top center;
						height: 10rem;
					}
				}
			}

			&--details {
				display: flex;
				flex: 1;
				width: 100%;
				margin-top: 3.2rem;

				div {
					display: flex;
					flex-direction: column;
					gap: 4rem;

					span {
						display: flex;
						flex-direction: column;
						gap: 1rem;
						small {
							color: var(--raven-base-bg-dark, #676767);
							font-size: 1.2rem;
							font-weight: 500;
						}

						p {
							color: var(--raven-base-background-dark, #020202);
							font-size: 1.4rem;
						}
					}
				}

				div {
					flex: 0 0 25%;
				}

				// div:last-child {
				// 	flex: 0 0 30%;
				// }
			}

			&--preview-json {
				display: flex;
				width: 100%;
				float: bottom;
				justify-content: flex-end;

				div {
					display: flex;
					// width: 19.2rem;
					cursor: pointer;
					height: 5.4rem;
					padding: 1.6rem 2.4rem;
					justify-content: center;
					align-items: center;
					gap: 8px;
					flex-shrink: 0;
					border-radius: 10px;
					background: var(--raven-black-50, #f7f7f7);

					&:hover {
					}

					p {
						color: var(--raven-primary-dark-green, #014345);
						font-size: 1.6rem;
						font-style: normal;
						font-weight: 600;
					}
				}
			}
		}
	}
}

// styles for code preview component
.code-editor-wrap {
	display: flex;
	flex-direction: column;
	// background-color: red;
	flex: 1;
	max-height: 50rem;
	overflow: hidden;
	position: relative;

	.copy-button {
		position: absolute;
		top: 2rem;
		right: 2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0.7rem;
		border-radius: 0.7rem;
		border: 1px solid var(--raven-black-200, #e3e3e3);
		padding: 0.5rem 1rem;
		cursor: pointer;

		figure {
			width: 1.5rem;
			height: 1.5rem;
			display: grid;
			place-items: center;

			svg {
				width: 100%;
				height: 100%;

				path {
					stroke: #014345;
				}
			}
		}

		.checkmark {
			border-radius: 50px;
			background-color: #014345;

			svg {
				stroke: white;
				width: 70%;
				height: 70%;
			}
		}

		p {
			color: var(--raven-primary-dark-green, #014345);
			font-size: 1.4rem;
			font-weight: 500;
		}
	}
}
