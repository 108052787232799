.new-transfer {
	display: flex;
	padding: 2rem;
	width: 35%;
	flex-direction: column;
	.mode-items-wrap {
		display: flex;
		flex-direction: column;
		gap: 1.6rem;
		border-radius: 16px;
		padding: 1.6rem;
		background: #f9f9f9;
		// background-color: red;
	}
	&__mode {
		display: flex;
		flex: 1;
		flex-direction: column;
		gap: 2rem;

		.mode {
			&__title {
				font-weight: 600;
				font-size: 2rem;
				display: flex;
				align-items: center;
				color: #000000;
				margin-top: 2rem;
			}

			&__title-bulk-mode {
				display: flex;
				color: #000000;
				margin-top: 2rem;
				flex-direction: column;

				h5 {
					font-weight: 600;
					font-size: 2rem;
				}

				p {
					font-weight: 400;
					font-size: 1.4rem;
					color: #676767;
				}
			}

			&__items {
				display: flex;
				// border: 0.8px solid #cccccc;
				border-radius: 1.2rem;
				padding: 1.6rem;
				gap: 1.7rem;
				cursor: pointer;
				background-color: white;
				position: relative;

				.checkmark {
					display: none;
				}

				&.active {
					.checkmark {
						content: '';
						position: absolute;
						top: 0;
						display: grid;
						right: 0;
						place-items: center;
						padding: 0.5rem 1rem;
						border-bottom-left-radius: 1.2rem;
						border-top-right-radius: 1.2rem;
						background-color: #014345;

						svg path {
							stroke: white;
						}
					}
				}

				.items {
					&__icons {
						width: 5rem;
						height: 5rem;
						display: grid;
						align-items: center;

						svg {
							width: 100%;
							height: 100%;
						}
					}

					&__content {
						display: flex;
						flex-direction: column;
						gap: 0.6rem;

						p {
							font-weight: 600;
							font-size: 1.4rem;
							display: flex;
							align-items: center;
							color: #000000;
						}

						small {
							font-weight: 400;
							font-size: 1.2rem;
							display: flex;
							align-items: center;
							color: #676767;
						}
					}
				}
			}

			&__button {
				margin-top: 7rem;
				width: 100%;
			}
		}
	}
}
