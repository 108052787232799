.dashboard-layout-wrap {
	width: 100vw;
	height: 100vh;
	position: relative;
	overflow: hidden;
	position: relative;

	.loader-animation {
		display: flex;
		// background-color: red;
		/* From https://css.glass */
		background: rgba(0, 0, 0, 0.8);
		box-shadow: 0 4px 30px rgba(0, 0, 0, 0.181);
		backdrop-filter: blur(1px);
		-webkit-backdrop-filter: blur(1px);
		width: 100vw;
		height: 100vh;
		position: fixed;
		z-index: 1000089877665;
		display: grid;
		place-items: center;

		img {
			width: 48rem;
			height: 48rem;
		}
	}

	.mobile-invisible-close {
		content: '';
		position: absolute;
		bottom: 0;
		width: 100vw;
		height: 90vh;
		z-index: 3;
	}

	.side-nav-wrap {
		position: fixed;
		top: 0;
		left: 0;
		width: 25rem;
		// background: linear-gradient(180deg, #014345 0%, #012223 100%), #014345;
		height: 100vh;
		overflow-y: auto;
		display: flex;

		@media only screen and (max-width: 65em) {
			position: absolute;
			top: 0;
			left: 0;
			// transform: translateX(-100%);
			width: 7rem;
			overflow-x: hidden;
			z-index: 4;
			padding-top: 0.5rem;
		}

		@media only screen and (max-width: 56em) {
			width: 27.5rem;
			transform: translateX(-100%);
			height: 100vh;
			position: fixed;
			transition: all 0.4s ease-in-out;
			z-index: 10;
			padding: 0rem;
		}

		@media only screen and (max-width: 56em) {
			&::-webkit-scrollbar {
				display: none;
			}
		}

		&::-webkit-scrollbar {
			display: none;
		}

		@media only screen and (max-width: 42em) {
			overflow-y: scroll;
			display: unset;
			// flex-direction: column;
			// background-color: red;
			overflow-x: hidden;

			& > * {
				height: 100vh;
				padding-bottom: 4rem;
				overflow-x: hidden;
				overflow-y: scroll;
			}
		}

		&:hover::-webkit-scrollbar {
			display: block;
		}

		/* width */
		&::-webkit-scrollbar {
			width: 0.5rem;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			-webkit-box-shadow: none !important;
			background-color: transparent !important;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: #cccccc;
			border-radius: 30rem;
		}

		/* Handle on hover */
		&::-webkit-scrollbar-thumb {
			background: #cccccc;
		}
	}

	@media only screen and (max-width: 56em) {
		.side-nav-wrap-show {
			transform: translateX(0%);
		}
	}

	.main-body-wrap {
		position: absolute;
		right: 0;
		top: 0;
		width: calc(100% - 25rem);
		height: 100vh;
		display: flex;
		flex-direction: column;

		@media only screen and (min-width: 110em) {
			width: calc(100% - 25rem) !important;
			// display: none;
			// left: 25rem;
			right: 0;
			// background-color: green;
		}

		@media only screen and (max-width: 65em) {
			width: calc(100% - 7rem);
		}

		@media only screen and (max-width: 56em) {
			width: 100%;
		}

		.header-wrap {
			position: relative;
			z-index: 3;
			padding: 1rem 6rem;
			//   background-color: red;

			&.onboarding-header {
				padding: 1rem 9.3rem;
			}

			@media only screen and (min-width: 110em) {
				padding: 1rem 7rem;
			}

			@media only screen and (max-width: 42em) {
				padding: 1rem 2.5rem;
				position: fixed;
				top: 0;
				left: 0;
				width: 100vw;
			}
		}

		.main-wrap {
			flex: 1;
			overflow-y: scroll;
			//   background-color: #F9F9F9;
			position: relative;
			z-index: 2;
			padding: 2.5rem 6.4rem;

			display: flex;
			flex-direction: column;

			@media only screen and (min-width: 110em) {
				width: calc(160rem - 23rem) !important;
				padding: 2.5rem 7rem;
			}

			@media only screen and (max-width: 58em) {
				// flex: unset;
				// min-height: 100vh;
			}

			@media only screen and (max-width: 42em) {
				padding: 2.5rem;
				padding-top: 10rem;
				overflow-y: unset;
			}

			&::-webkit-scrollbar:horizontal {
				display: none;
			}

			/* width */
			&::-webkit-scrollbar {
				width: 0.5rem;
			}

			/* Track */
			&::-webkit-scrollbar-track {
				-webkit-box-shadow: none !important;
				background-color: transparent !important;
			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: #020202;
				border-radius: 30rem;
			}

			/* Handle on hover */
			&::-webkit-scrollbar-thumb:hover {
				background: #020202;
			}

			//   .child {
			//     height: 100%;
			//     width: 100%;
			//     background-color: red;
			//   }
		}
	}
}
