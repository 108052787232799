.settings {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.settings__webhook {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.settings__webhook figure {
  width: -moz-max-content;
  width: max-content;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  color: var(--primary-purple-100, #755ae2);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
}
.settings__webhook figure svg {
  width: 1.5rem;
  height: 1.5rem;
}
.settings .settings__title {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.settings .settings__title .title__main {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.settings .settings__title .title__main h5 {
  font-weight: 700;
  font-size: 2rem;
  line-height: 140%;
}
.settings .settings__title .title__main p {
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 160%;
  color: #676767;
}
.settings .settings__title .title__swap-btn {
  display: flex;
  align-items: center;
  padding: 1rem 2.3rem;
  gap: 1rem;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 10px;
  transition: transform 0.2s linear;
}
.settings .settings__title .title__swap-btn:hover {
  transform: translateY(-3px);
}
.settings .settings__title .title__swap-btn:hover figure {
  animation: rotate 1s cubic-bezier(0.1, 0.5, 0.3, 1);
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(100deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.settings .settings__title .title__swap-btn p {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #014345;
}
.settings .settings__title .title__swap-btn figure {
  display: grid;
  place-items: center;
  width: 2rem;
  height: 2rem;
}
.settings .settings__title .title__swap-btn figure svg {
  width: 100%;
  height: 100%;
}
.settings .settings__sidebar {
  display: flex;
  align-items: flex-start;
  padding: 2rem;
  background: #ffffff;
  border-radius: 1.8rem;
  margin-top: 3.2rem;
  width: 100%;
  gap: 2.4rem;
  flex: 1;
  max-height: 90%;
}
.settings .settings__sidebar .sidebar__selector {
  background: #f9f9f9;
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  width: 20%;
  gap: 2rem;
  padding: 2rem;
}
.settings .settings__sidebar .sidebar__selector .selector__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 1.6rem 2.4rem;
  gap: 0.8rem;
  cursor: pointer;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 10px;
}
.settings .settings__sidebar .sidebar__selector .selector__item p {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #8b8b8b;
}
.settings .settings__sidebar .sidebar__selector .selector__item svg {
  width: 2rem;
  height: 2rem;
  display: grid;
  place-items: center;
}
.settings .settings__sidebar .sidebar__selector .selector__item.active {
  background: #ffffff;
}
.settings .settings__sidebar .sidebar__selector .selector__item.active p {
  color: var(--raven-primary-dark-green, #014345);
  font-weight: 700;
}
.settings .settings__sidebar .settings__main-wrap {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  width: 85%;
  height: 100%;
  border-radius: 1.2rem;
  padding: 2rem;
  border: 1px solid var(--ravengreyshade-50, #e5e6e6);
}
.settings .settings__sidebar .settings__main-wrap .main-wrap__title {
  display: flex;
  padding-bottom: 2rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.settings .settings__sidebar .settings__main-wrap .main-wrap__title .title__left h5 {
  color: var(--raven-base-background-dark, #020202);
  font-size: 2rem;
  font-weight: 600;
}
.settings .settings__sidebar .settings__main-wrap .main-wrap__title .title__left p {
  color: var(--raven-base-bg-dark, #676767);
  font-size: 1.2rem;
  line-height: 140%;
}
.settings .settings__sidebar .settings__main-wrap .main-wrap__title .title__button {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
.settings .settings__sidebar .settings__main-wrap .main-wrap__title .title__button svg path:first-child {
  fill: #9be5c9;
}
.settings .settings__sidebar .settings__main-wrap .history__content {
  display: flex;
  width: 100%;
  flex: 1;
  background: #ffffff;
  border-radius: 1.6rem;
  padding: 2rem;
  overflow-y: scroll;
  flex-direction: column;
}
.settings .settings__sidebar .settings__main-wrap .history__content::-webkit-scrollbar {
  display: none;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export {
  display: flex;
  gap: 1.6rem;
  align-items: center;
  z-index: 1;
  position: relative;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__export-btn,
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__filter-btn {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 1.6rem 2.4rem;
  gap: 0.8em;
  cursor: pointer;
  height: 5rem;
  background: #f7f8f7;
  border-radius: 1rem;
  z-index: 100;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__export-btn p,
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__filter-btn p {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #676767;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__export-btn figure,
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__filter-btn figure {
  width: -moz-fit-content;
  width: fit-content;
  display: grid;
  place-items: center;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__export-btn figure svg,
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__filter-btn figure svg {
  width: 2rem;
  height: 2rem;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down {
  position: absolute;
  background: #ffffff;
  width: -moz-max-content;
  width: max-content;
  height: -moz-max-content;
  height: max-content;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 1.6rem;
  box-shadow: 0;
  top: 110%;
  right: 55%;
  padding: 2rem;
  transform-origin: top right;
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
  z-index: 100;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down--open {
  transform: scale(1);
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__switch {
  display: flex;
  width: 100%;
  cursor: pointer;
  justify-content: flex-start;
  gap: 1.2rem;
  border-bottom: 0.8px solid #e5e6e6;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__switch span {
  gap: 0.4rem;
  padding-bottom: 1.6rem;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__switch span p {
  font-weight: 400;
  font-size: 1.4rem;
  display: grid;
  place-items: center;
  padding: 1.2rem 1.6rem;
  border-radius: 8px;
  color: #1b1b1b;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__switch span.active {
  border-bottom: 4px solid #014345;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__switch span.active p {
  color: #014345;
  font-weight: 600;
  background: #e9f5ff;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range {
  display: flex;
  flex-direction: column;
  margin-top: 2.4rem;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range h5 {
  font-weight: 600;
  font-size: 1.4rem;
  color: #014345;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range .custom-date-range__grouped-form {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 1.2rem;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range .drop-down__action-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  margin-top: 5.2rem;
  padding: 2.3rem 0rem 0.3rem 0rem;
  border-top: 0.7px solid #e5e6e6;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range .drop-down__action-btns button {
  width: 50%;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range .drop-down__action-btns button:first-child {
  background: #e9f5ff;
  color: #014345;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down-closer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  display: none;
  visibility: none;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__top-bar .top-bar__filter-export .filter-export__drop-down-closer--opened {
  display: unset;
  visibility: unset;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main {
  display: flex;
  width: 100%;
  overflow-y: scroll;
  flex: 1;
  flex-direction: column;
  margin-top: 2rem;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main::-webkit-scrollbar {
  display: none;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main--top-bar {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main--top-bar .top-bar__title {
  display: flex;
  align-items: center;
  gap: 2.4rem;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main--top-bar .top-bar__title h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */
  display: flex;
  align-items: center;
  /* RAVEN/Base Black */
  color: #1b1b1b;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main--top-bar .top-bar__title p {
  display: none;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main--top-bar .top-bar__title p.active {
  display: grid;
  place-items: center;
  padding: 0.8rem 1.2rem;
  background: #f7f8f7;
  border-radius: 7.2rem;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 140%;
  text-align: center;
  color: #014345;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__active-filters {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  max-height: 0;
  display: flex;
  transition: max-height 300ms ease-in-out;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__active-filters--show {
  display: flex;
  overflow: unset;
  max-height: 30rem;
  transition: max-height 300ms ease-in-out;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__active-filters .active-filters__item {
  background: #e9f5ff;
  border-radius: 7.2rem;
  display: flex;
  padding: 0.55rem 0.7rem;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__active-filters .active-filters__item figure {
  width: 1.75rem;
  height: 1.75rem;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__active-filters .active-filters__item figure svg {
  width: 100%;
  height: 100%;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__active-filters .active-filters__item p {
  color: #476885;
  display: flex;
  align-items: center;
  gap: 0.6rem;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__active-filters .active-filters__clear-item {
  background: #fff5f5;
  border-radius: 72px;
  border-radius: 7.2rem;
  display: flex;
  padding: 0.55rem 0.7rem;
  align-items: center;
  cursor: pointer;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__active-filters .active-filters__clear-item p {
  font-weight: 600;
  font-size: 1.2rem;
  color: #ff0f00;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__wrap {
  display: flex;
  margin-top: 2rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__wrap .transaction__table {
  width: 100%;
  height: 100%;
  border-radius: 1.2rem;
  overflow: auto;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__wrap .transaction__table::-webkit-scrollbar {
  display: none;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__wrap .transaction__table .table__main {
  border-radius: 1.2rem;
  overflow: auto;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__wrap .transaction__table .table__main .main__direction {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__wrap .transaction__table .table__main .main__direction figure {
  display: grid;
  place-items: center;
  width: 2.75rem;
  height: 2.75rem;
}
.settings .settings__sidebar .settings__main-wrap .history__content .content__main .main__wrap .transaction__table .table__main .main__direction figure svg {
  width: 100%;
  height: 100%;
}
.settings .settings__sidebar .settings__main-wrap .settings__teams .content__main .main__wrap .transaction__table .table__main .account_name {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}
.settings .settings__sidebar .settings__main-wrap .settings__teams .content__main .main__wrap .transaction__table .table__main .account_name span {
  background-color: #e9f5ff;
  display: grid;
  place-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 7.5rem;
}
.settings .settings__sidebar .settings__main-wrap .settings__teams .content__main .main__wrap .transaction__table .table__main .account_name span p {
  font-weight: 600;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: #014345;
}

.fund-modal-wrap {
  width: 100%;
}
.fund-modal-wrap.no-btn .input-submit {
  visibility: hidden;
}
.fund-modal-wrap .raven-modal-content-wrap {
  width: 25%;
}
.fund-modal-wrap .fund-modal-wrap__content {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.fund-modal-wrap .fund-modal-wrap__content .content__title {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.fund-modal-wrap .fund-modal-wrap__content .content__title span h5 {
  font-weight: 600;
  font-size: 2rem;
  display: flex;
  align-items: center;
}
.fund-modal-wrap .fund-modal-wrap__content .content__title span p {
  font-weight: 400;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  color: #676767;
}
.fund-modal-wrap .fund-modal-wrap__content .content__title figure {
  display: grid;
  place-items: center;
  width: 3.5rem;
  height: 3.5rem;
}
.fund-modal-wrap .fund-modal-wrap__content .content__title figure svg {
  width: 100%;
  height: 100%;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main {
  display: flex;
  flex-direction: column;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__label {
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  margin-top: 3.2rem;
  margin-bottom: 1rem;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details {
  display: flex;
  flex-direction: column;
  border: 0.7px solid #e5e6e6;
  border-radius: 1.2rem;
  width: 100%;
  position: relative;
  height: 100%;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .panel {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
  transform: translateX(100%);
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .panel.active {
  transform: translateX(0);
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item {
  display: flex;
  padding: 2.2rem 1.6rem;
  width: 100%;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item.address {
  flex-direction: column;
  width: 100%;
  gap: 0.8rem;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item.address p {
  font-size: 1.2rem;
  width: 100%;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item.address span {
  width: 100%;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item.address span p {
  color: #676767;
  font-size: 1.2rem;
  width: 100%;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item p {
  font-weight: 400;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item:not(:last-child) {
  border-bottom: 0.6px solid #e5e6e6;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item p:first-child {
  width: 40%;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item p:last-child {
  width: 60%;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item span {
  display: flex;
  width: 60%;
  justify-content: space-between;
  align-items: center;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item span figure {
  display: grid;
  place-items: center;
  width: 2.5rem;
  cursor: pointer;
  height: auto;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__account-details .account-details__item span figure svg {
  width: 100%;
  height: 100;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__swipe-indicator {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: 2.7rem;
  width: -moz-max-content;
  width: max-content;
  align-self: center;
  background: #f9f9f9;
  border-radius: 12.8rem;
  margin-top: 2.9rem;
  padding: 1.2rem 1.4rem;
  cursor: pointer;
  position: relative;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__swipe-indicator p {
  padding: 0.4rem 1.3rem;
  background: #b9defe;
  border-radius: 3.2rem;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__swipe-indicator p.active {
  background-color: #014345;
  width: 5rem;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__swipe-indicator .tab {
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 1;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__swipe-indicator .tab.active {
  background-color: #014345;
}
.fund-modal-wrap .fund-modal-wrap__content .content__main .main__swipe-indicator .ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #014345;
  opacity: 0;
  transform: scale(0);
  pointer-events: none;
  z-index: 0;
}
@keyframes rippleEffect {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 0.5;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}

.swap-modal-wrap .swap-modal-wrap__content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.swap-modal-wrap .swap-modal-wrap__content .content__title {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.swap-modal-wrap .swap-modal-wrap__content .content__title span h5 {
  font-weight: 600;
  font-size: 2rem;
  display: flex;
  align-items: center;
}
.swap-modal-wrap .swap-modal-wrap__content .content__title span p {
  font-weight: 400;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  color: #676767;
}
.swap-modal-wrap .swap-modal-wrap__content .content__form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2.8rem;
}
.swap-modal-wrap .swap-modal-wrap__content .content__form .form__grouped {
  display: flex;
  z-index: 20;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1.8rem;
}
.swap-modal-wrap .swap-modal-wrap__content .content__form .form__grouped input {
  width: 100%;
}
.swap-modal-wrap .swap-modal-wrap__content .content__form .form__grouped .grouped__swap-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 4rem;
  transform: translateY(25%);
  border-radius: 30px;
  background: #e9f5ff;
}
.swap-modal-wrap .swap-modal-wrap__content .content__form .form__grouped .grouped__swap-icon figure {
  width: 60%;
  height: 60%;
  display: grid;
  place-items: center;
}
.swap-modal-wrap .swap-modal-wrap__content .content__form .form__grouped .grouped__swap-icon figure svg {
  width: 100%;
  height: 100%;
}
.swap-modal-wrap .swap-modal-wrap__content .content__form .form__balance {
  font-weight: 600;
  font-size: 1.4rem;
  color: #014345;
  margin-top: 2.8rem;
}
.swap-modal-wrap .swap-modal-wrap__content .content__output {
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e6e6;
  border-radius: 1.2rem;
  padding: 1.2rem;
  margin-top: 4.8rem;
  margin-bottom: 3.6rem;
}
.swap-modal-wrap .swap-modal-wrap__content .content__output .output__rate {
  padding: 0.8rem 1.2rem;
  width: -moz-max-content;
  width: max-content;
  background: #f0eeff;
  border-radius: 72px;
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
  color: #755ae2;
}
.swap-modal-wrap .swap-modal-wrap__content .content__output .output__value {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.4rem;
  margin-top: 1.9rem;
}
.swap-modal-wrap .swap-modal-wrap__content .content__output .output__value h5 {
  font-weight: 700;
  font-size: 2rem;
  display: flex;
  align-items: center;
}
.swap-modal-wrap .swap-modal-wrap__content .content__output .output__value p {
  font-weight: 400;
  font-size: 1.4rem;
  color: #676767;
}

.account-details__item .item__ravenpay {
  width: 100%;
  margin-top: 1.4rem;
  margin-bottom: 11.4rem;
}

.settings_role {
  display: grid;
  place-items: center;
  background-color: aliceblue;
  padding: 0.8rem 1.2rem;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 7.2rem;
}
.settings_role p {
  font-weight: 600;
  font-size: 1.2rem;
}
.settings_role.admin {
  background: var(--raven-purple-shade-20, #f0eeff);
}
.settings_role.admin p {
  color: var(--ravenprimarypurple, #755ae2);
}
.settings_role.operation {
  background: var(--ravenorangeshade-20, #fff6ed);
}
.settings_role.operation p {
  color: var(--raven-primary-orange, #ea872d);
}
.settings_role.developer {
  background: var(--raven-blue-shade-20, #e9f5ff);
}
.settings_role.developer p {
  color: var(--raven-accent-blue, #476885);
}

.change-password-modal .raven-modal-content-wrap {
  min-height: 55rem;
}
.change-password-modal .raven-modal-content-wrap .form {
  padding-bottom: 0;
}

.raven-modal-content-wrap .form {
  padding-bottom: 0;
}/*# sourceMappingURL=index.css.map */