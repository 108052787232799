.virtual-account {
	border-radius: 1.6rem;
	background: var(--raven-base-white, #fff);
	display: flex;
	// flex: 1;
	width: 100%;
	height: 100%;
	// background-color: red;
	padding: 1.6rem 1.6rem 2rem 1.6rem;
	flex-direction: column;
	align-items: flex-start;
	// gap: 3.7rem;

	&__tab {
		display: flex;
		align-items: center;
		width: 100%;
		border-bottom: 0.8px solid var(--ravengreyshade-50, #e5e6e6);

		.tab__item {
			cursor: pointer;
			p {
				border-radius: 0.8rem;
				padding: 1.4rem;
				margin-bottom: 1.4rem;
			}

			&--active {
				p {
					color: var(--raven-primary-dark-green, #014345);
					font-size: 1.4rem;
					font-weight: 600;
					line-height: 140%;
					background: var(--raven-blue-shade-20, #e9f5ff);
				}

				border-bottom: 4px solid var(--raven-primary-dark-green, #014345);
			}
		}
	}

	&__account-details {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 3.7rem;
		margin-top: 3.7rem;

		.account-details {
			width: 100%;
			&__account-number {
				width: 100%;
				display: flex;
				align-items: flex-end;
				gap: 1.6rem;

				span {
					display: flex;
					flex-direction: column;
					p {
						color: var(--raven-base-bg-dark, #676767);
						font-size: 1.2rem;
						text-transform: uppercase;
						line-height: 140%;
					}

					h5 {
						font-size: 2.4rem;
						font-weight: 700;
					}
				}

				figure {
					display: flex;
					gap: 0.8rem;
					padding: 0.4rem;
					align-items: center;
					border-radius: 72px;
					background: var(--raven-grey-shade-10, #f7f8f7);
					p {
						font-size: 1.2rem;
					}

					svg {
						width: 1.5rem;
						height: 1.5rem;
					}
				}
			}
			&__main {
				display: flex;
				flex-direction: column;
				border: 0.7px solid #e5e6e6;
				border-radius: 1.2rem;
				width: 100%;
				position: relative;
				height: 100%;

				.panel {
					position: absolute;
					width: 100%;
					height: 100%;
					transition: transform 0.3s ease;
					transform: translateX(100%);

					&.active {
						transform: translateX(0);
					}
				}

				.account-details__item {
					display: flex;
					padding: 0 1.6rem;
					height: 6.4rem;
					width: 100%;
					align-items: center;

					.temporal {
						background-color: #e9f5ff;
						padding: 0.8rem 1.2rem;
						height: min-content;
						border-radius: 1.6rem;
						p {
							color: #476885;
						}
					}

					&.address {
						flex-direction: column;
						width: 100%;
						gap: 0.8rem;
						p {
							font-size: 1.2rem;
							width: 100%;
						}
						span {
							width: 100%;
							p {
								color: #676767;
								font-size: 1.2rem;
								width: 100%;
							}
						}
					}

					p {
						font-weight: 400;
						font-size: 1.4rem;
						display: flex;
						align-items: center;
						// color: #000000;
					}

					&:not(:last-child) {
						border-bottom: 0.6px solid #e5e6e6;
					}

					p:first-child {
						width: 20%;
					}

					p:last-child {
						width: fit-content;
					}

					span {
						display: flex;
						width: fit-content;
						gap: 2rem;
						align-items: center;

						figure {
							display: grid;
							place-items: center;
							width: 2.5rem;
							cursor: pointer;
							height: auto;
							svg {
								width: 100%;
								height: 100;
							}
						}
					}
				}
			}
		}
	}

	.virtual-account__transactions {
		display: flex;
		width: 100%;
		margin-top: 2rem;
		flex: 1;
		background: #ffffff;
		border-radius: 1.6rem;
		overflow-y: scroll;
		flex-direction: column;

		&::-webkit-scrollbar {
			display: none;
		}

		.top-bar__search {
			padding-left: 0.3rem;
			// width: 30%;
		}
		// .form-group__deep-green-light .input-group {
		// 	border: none;
		// }

		.content__top-bar {
			display: flex;
			width: 100%;
			justify-content: space-between;
			align-items: center;

			.top-bar__filter-export {
				display: flex;
				gap: 1.6rem;
				align-items: center;
				z-index: 1;
				position: relative;

				.filter-export__export-btn,
				.filter-export__filter-btn {
					flex-direction: row;
					justify-content: center;
					align-items: center;
					display: flex;
					padding: 1.6rem 2.4rem;
					gap: 0.8em;
					cursor: pointer;
					height: 5rem;
					background: #f7f8f7;
					border-radius: 1rem;
					z-index: 100;

					p {
						font-weight: 600;
						font-size: 1.4rem;
						line-height: 140%;
						display: flex;
						align-items: center;
						color: #676767;
					}

					figure {
						width: fit-content;
						display: grid;
						place-items: center;

						svg {
							width: 2rem;
							height: 2rem;
						}
					}
				}

				.filter-export__drop-down {
					position: absolute;
					background: #ffffff;
					width: max-content;
					height: max-content;
					box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
						0px 48px 64px -36px rgba(51, 51, 51, 0.08);
					border-radius: 1.6rem;
					box-shadow: 0;
					top: 110%;
					right: 55%;
					padding: 2rem;
					transform-origin: top right;
					transform: scale(0);
					transition: transform 0.3s ease-in-out;
					z-index: 100;

					&--open {
						transform: scale(1);
					}

					.drop-down__switch {
						display: flex;
						width: 100%;
						cursor: pointer;
						justify-content: flex-start;
						gap: 1.2rem;
						border-bottom: 0.8px solid #e5e6e6;
						span {
							gap: 0.4rem;
							padding-bottom: 1.6rem;

							p {
								font-weight: 400;
								font-size: 1.4rem;
								display: grid;
								place-items: center;
								padding: 1.2rem 1.6rem;
								border-radius: 8px;
								color: #1b1b1b;
							}

							&.active {
								border-bottom: 4px solid #014345;
								p {
									color: #014345;
									font-weight: 600;
									background: #e9f5ff;
								}
							}
						}
					}

					.drop-down__custom-date-range {
						display: flex;
						flex-direction: column;
						margin-top: 2.4rem;

						h5 {
							font-weight: 600;
							font-size: 1.4rem;
							color: #014345;
						}

						.custom-date-range__grouped-form {
							display: flex;
							align-items: center;
							gap: 2rem;
							margin-top: 1.2rem;
						}

						.drop-down__action-btns {
							display: flex;
							align-items: center;
							justify-content: center;
							gap: 2rem;
							width: 100%;
							margin-top: 5.2rem;

							padding: 2.3rem 0rem 0.3rem 0rem;
							border-top: 0.7px solid #e5e6e6;

							button {
								width: 50%;

								&:first-child {
									background: #e9f5ff;
									color: #014345;
								}
							}
						}
					}
				}

				.filter-export__drop-down-closer {
					position: fixed;
					width: 100vw;
					height: 100vh;
					top: 0;
					right: 0;

					display: none;
					visibility: none;

					&--opened {
						display: unset;
						visibility: unset;
					}
				}
			}
		}

		.content__main {
			display: flex;
			width: 100%;
			overflow-y: scroll;
			flex: 1;
			flex-direction: column;
			margin-top: 2rem;

			&::-webkit-scrollbar {
				display: none;
			}

			&--top-bar {
				display: flex;
				align-items: center;
				width: 100%;
				justify-content: space-between;

				.top-bar__title {
					display: flex;
					align-items: center;
					gap: 2.4rem;

					h5 {
						font-weight: 600;
						font-size: 16px;
						line-height: 140%;
						/* or 22px */

						display: flex;
						align-items: center;

						/* RAVEN/Base Black */

						color: #1b1b1b;
					}

					p {
						display: none;
						&.active {
							display: grid;
							place-items: center;
							padding: 0.8rem 1.2rem;
							background: #f7f8f7;
							border-radius: 7.2rem;
							font-weight: 600;
							font-size: 1.2rem;
							line-height: 140%;
							text-align: center;
							color: #014345;
						}
					}
				}
			}

			.main__active-filters {
				width: 100%;
				justify-content: space-between;
				align-items: center;
				overflow: hidden;
				max-height: 0;
				display: flex;
				transition: max-height 300ms ease-in-out;

				&--show {
					display: flex;
					overflow: unset;
					max-height: 30rem;
					transition: max-height 300ms ease-in-out;
				}

				.active-filters__item {
					background: #e9f5ff;
					border-radius: 7.2rem;
					display: flex;
					padding: 0.55rem 0.7rem;
					align-items: center;
					gap: 1rem;
					margin-top: 1rem;
					margin-bottom: 2rem;

					figure {
						width: 1.75rem;
						height: 1.75rem;
						display: grid;
						place-items: center;
						cursor: pointer;

						svg {
							width: 100%;
							height: 100%;
						}
					}

					p {
						color: #476885;
						display: flex;
						align-items: center;
						gap: 0.6rem;
					}
				}

				.active-filters__clear-item {
					background: #fff5f5;
					border-radius: 72px;
					border-radius: 7.2rem;
					display: flex;
					padding: 0.55rem 0.7rem;
					align-items: center;
					cursor: pointer;

					p {
						font-weight: 600;
						font-size: 1.2rem;
						color: #ff0f00;
					}
				}
			}

			.main__wrap {
				display: flex;
				margin-top: 2rem;
				width: 100%;
				height: 100%;
				// background-color: red;
				overflow: hidden;

				.transaction__table {
					width: 100%;
					// background-color: yellow;
					height: 100%;
					border-radius: 1.2rem;
					overflow: auto;

					&::-webkit-scrollbar {
						display: none;
					}

					.table__main {
						border-radius: 1.2rem;
						overflow: auto;

						.main__direction {
							display: flex;
							align-items: center;
							gap: 1.2rem;

							figure {
								display: grid;
								place-items: center;
								width: 2.75rem;
								height: 2.75rem;

								svg {
									width: 100%;
									height: 100%;
								}
							}
						}

						.main__reference {
							display: flex;
							align-items: center;
							gap: 1rem;

							svg {
								circle {
									fill: #e9f5ff;
								}
							}
						}
					}
				}
			}
		}
	}
}

.virtual-account-settings {
	display: flex;
	width: 5.4rem;
	height: 5.4rem;
	padding: 1.6rem;
	justify-content: center;
	align-items: center;
	border-radius: 1rem;
	background: var(--raven-base-white, #fff);
	box-shadow: 0px 48px 64px -36px rgba(51, 51, 51, 0.08),
		0px 24px 48px -8px rgba(51, 51, 51, 0.04);
	cursor: pointer;
	&:hover {
		svg {
			transform: rotate(360deg);
		}
	}

	svg {
		width: 3rem;
		height: 3rem;
		transition: transform 300ms ease-in-out;
	}
}

.create-virtual-wrap {
	display: flex;
	flex-direction: column;

	&__title {
		display: flex;
		flex-direction: column;
		gap: 0.4rem;
		margin-bottom: 2.8rem;
		h5 {
			font-size: 2.4rem;
			font-weight: 700;
		}

		p {
			color: var(--raven-base-bg-dark, #676767);
			font-size: 1.4rem;
		}
	}

	&__form {
		display: flex;
		flex-direction: column;
		gap: 2.4rem;
		margin-bottom: 5.8rem;

		.form__grouped {
			display: flex;
			align-items: center;
			gap: 1.6rem;

			input {
				flex: 1 0 0;
			}
		}
	}
}
