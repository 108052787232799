.bankbox-modals {
  display: flex;
  min-height: 80rem;
  width: 100%;
  flex: 1;
}
.bankbox-modals__left {
  flex: 40%;
  position: relative;
  border-radius: var(--Count, 0px);
  padding: 2.8rem;
  padding-top: 15rem;
  position: relative;
}
.bankbox-modals__left--bg-absolute::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  flex: 1;
  bottom: 0;
  right: 0;
  z-index: -1;
  cursor: pointer;
  background-repeat: no-repeat;
  background-image: url("../../../../../../assets/bb-create-user-bg.svg");
  background-size: 100%; /* Adjust the size for the background image */
  background-position: bottom right;
}
.bankbox-modals__left--bg-absolute::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  flex: 1;
  bottom: 0;
  right: 0;
  z-index: -2;
  cursor: pointer;
  background: linear-gradient(180deg, #f7f7f7 0%, #f7f7f7 100%);
  background-position: bottom right;
}
.bankbox-modals__left--stepper {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}
.bankbox-modals__left--stepper .stepper-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.8rem;
}
.bankbox-modals__left--stepper .stepper-item.completed span {
  border-color: #014345;
  background-color: #014345;
}
.bankbox-modals__left--stepper .stepper-item.completed span p {
  color: var(--base-white, var(--themelightmode, #fff));
}
.bankbox-modals__left--stepper .stepper-item.active span {
  border-color: #014345;
  background-color: #014345;
}
.bankbox-modals__left--stepper .stepper-item.active span p {
  color: var(--base-white, var(--themelightmode, #fff));
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
}
.bankbox-modals__left--stepper .stepper-item span {
  width: 3rem;
  height: 3rem;
  flex-shrink: 0;
  display: grid;
  place-items: center;
  border-radius: 72px;
  border: 1px solid #d1d1d1;
}
.bankbox-modals__left--stepper .stepper-item span p {
  color: var(--raven-black-600, #646464);
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
}
.bankbox-modals__left--stepper .stepper-item h6 {
  color: var(--raven-black-600, #646464);
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
}
.bankbox-modals__right {
  flex: 60%;
  padding: 5.6rem 4.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.bankbox-modals__right--content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.bankbox-modals__right--content .basic-content__title {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.8rem;
}
.bankbox-modals__right--content .basic-content__title h5 {
  color: var(--themedarkmode, #000);
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 600;
}
.bankbox-modals__right--content .basic-content__title h6 {
  color: var(--RAVEN-GREY, #676767);
  font-size: 3.6rem;
  font-style: normal;
  font-weight: 500;
}
.bankbox-modals__right--content .basic-content__business-logo {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  margin-top: 2rem;
  margin-bottom: 2.8rem;
}
.bankbox-modals__right--content .basic-content__business-logo figure {
  width: 8.5rem;
  height: 8.5rem;
  border-radius: 72px;
  cursor: pointer;
}
.bankbox-modals__right--content .basic-content__business-logo figure img {
  width: 100%;
  height: 100%;
  border-radius: 72px;
  -o-object-fit: cover;
     object-fit: cover;
}
.bankbox-modals__right--content .basic-content__business-logo p {
  color: var(--RAVEN-GREY, #676767);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  width: 40%;
}
.bankbox-modals__right--content .basic-content__input-contain {
  display: flex;
  flex-direction: column;
}
.bankbox-modals__right--content .basic-content__input-contain--brand-color {
  margin-top: 3.2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: var(--Count, 0px);
}
.bankbox-modals__right--content .basic-content__input-contain--brand-color .selector-title {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
  padding-bottom: 0.6rem;
  border-bottom: 1px solid var(--raven-black-200, #e3e3e3);
}
.bankbox-modals__right--content .basic-content__input-contain--brand-color .selector-title p {
  color: var(--themedarkmode, #000);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}
.bankbox-modals__right--content .basic-content__input-contain--brand-color .color-selector {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  margin-bottom: 4.8rem;
}
.bankbox-modals__right--content .basic-content__input-contain--brand-color .color-selector .bColors {
  width: 5rem;
  height: 5rem;
  display: grid;
  place-items: center;
  border-radius: 72px;
  cursor: pointer;
}
.bankbox-modals__right--content .basic-content__input-contain--brand-color .color-selector .bColors.active {
  border: 2px solid;
}
.bankbox-modals__right--content .price-content__input-contain {
  margin-top: 2rem;
  margin-bottom: 4.8rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}
.bankbox-modals__right--content .price-content__input-contain--grouped {
  display: flex;
  gap: 1.6rem;
}/*# sourceMappingURL=index.css.map */