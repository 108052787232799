.report-chart-wrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 2rem;
}
.report-chart-wrap__top {
  display: flex;
  width: 100%;
  background-color: white;
  gap: 2.2rem;
  padding: 2.4rem;
  border-radius: 1.6rem;
}
.report-chart-wrap__top .top__income-n-expense {
  display: flex;
  flex-direction: column;
  flex: 0 0 30%;
  gap: 2.2rem;
  margin-right: 0.7rem;
}
.report-chart-wrap__top .top__income-n-expense .income-n-expense__total {
  border: 1px solid rgba(204, 204, 204, 0.5);
  box-shadow: 0px 4.5297px 6.0396px -7.5495px rgba(51, 51, 51, 0.03);
  filter: drop-shadow(0px 1.5099px 3.0198px rgba(51, 51, 51, 0.05));
  border-radius: 6.0396px;
  display: flex;
  padding: 1.6rem;
  flex-direction: column;
  flex: 1;
}
.report-chart-wrap__top .top__income-n-expense .income-n-expense__total .total__title {
  font-weight: 400;
  font-size: 1rem;
  display: flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #676767;
}
.report-chart-wrap__top .top__income-n-expense .income-n-expense__total .total__amount {
  display: flex;
  justify-content: space-between;
}
.report-chart-wrap__top .top__income-n-expense .income-n-expense__total .total__amount h5 {
  font-weight: 700;
  font-size: 2.4rem;
  display: flex;
  align-items: center;
  text-align: right;
  color: #020202;
}
.report-chart-wrap__top .top__income-n-expense .income-n-expense__total .total__summary {
  font-weight: 400;
  font-size: 1rem;
  display: flex;
  align-items: center;
  text-align: right;
  color: #676767;
  gap: 0.5rem;
}
.report-chart-wrap__top .top__income-n-expense .income-n-expense__total .total__filter-transaction {
  font-weight: 600;
  font-size: 1rem;
  display: flex;
  justify-self: flex-end;
  color: #755ae2;
  margin-top: 1.3rem;
  align-items: center;
  padding-top: 1rem;
  flex: 1;
  border-top: 1.5099px solid #f7f8f7;
}
.report-chart-wrap__top .top__graph {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-left: 1px solid #f0eeff;
}
.report-chart-wrap__top .top__graph .charts__graph {
  display: flex;
  flex: 1;
  padding-left: 2.5rem;
  flex-direction: column;
  border-radius: 1.2rem;
}
.report-chart-wrap__top .top__graph .charts__graph .graph__top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: -moz-min-content;
  height: min-content;
  margin-bottom: 2.5rem;
}
.report-chart-wrap__top .top__graph .charts__graph .graph__top .top__title {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
.report-chart-wrap__top .top__graph .charts__graph .graph__top .top__title p {
  font-weight: 400;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  color: #676767;
}
.report-chart-wrap__top .top__graph .charts__graph .graph__top .top__title h5 {
  font-weight: 700;
  font-size: 2rem;
  display: flex;
  align-items: center;
  color: #1b1b1b;
}
.report-chart-wrap__top .top__graph .charts__graph .graph__top .top__title .title__last-updated {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}
.report-chart-wrap__top .top__graph .charts__graph .graph__top .top__title .title__last-updated figure {
  width: 2rem;
  height: 2rem;
  display: grid;
  place-items: center;
  border: 0.6px solid #e3e3e3;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 200px;
}
.report-chart-wrap__top .top__graph .charts__graph .graph__top .top__title .title__last-updated figure svg {
  width: 70%;
  height: 70%;
}
.report-chart-wrap__top .top__graph .charts__graph .graph__top .top__filter {
  display: flex;
  gap: 1rem;
  padding: 1rem 1rem;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  align-items: center;
}
.report-chart-wrap__top .top__graph .charts__graph .graph__top .top__filter span {
  gap: 0.8rem;
  align-items: center;
  display: flex;
}
.report-chart-wrap__top .top__graph .charts__graph .graph__top .top__filter span figure {
  display: grid;
  place-items: center;
}
.report-chart-wrap__top .top__graph .charts__graph .graph__no-record {
  height: 89%;
  background: #f7f7f7;
  border-radius: 0.8rem;
  margin-top: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;
  background-image: url("../../../../../assets/images/graph_no_data.svg");
}
.report-chart-wrap__top .top__graph .charts__graph .graph__no-record .no-record__text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  width: 50%;
  gap: 0.2rem;
}
.report-chart-wrap__top .top__graph .charts__graph .graph__no-record .no-record__text h5 {
  font-weight: 600;
  font-size: 2rem;
  line-height: 160%;
  text-align: center;
  color: #014345;
}
.report-chart-wrap__top .top__graph .charts__graph .graph__no-record .no-record__text p {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 160%;
  text-align: center;
  text-align: center;
  color: var(--base-background-black);
}
.report-chart-wrap__top .top__graph .charts__graph .graph__main {
  width: 100%;
  height: 100%;
  margin-top: 1.8rem;
}
.report-chart-wrap__bottom {
  display: flex;
  flex-wrap: wrap;
  gap: 2.1rem;
}
.report-chart-wrap__bottom .bottom__item-wrap {
  display: flex;
  background: #ffffff;
  flex-direction: column;
  border-radius: 1.2rem;
  padding: 1.2rem;
  width: 23.5%;
}
.report-chart-wrap__bottom .bottom__item-wrap h5 {
  font-weight: 700;
  font-size: 2.4rem;
  display: flex;
  margin-top: 2.3rem;
  align-items: center;
  color: #000000;
}
.report-chart-wrap__bottom .bottom__item-wrap span {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.7rem;
}
.report-chart-wrap__bottom .bottom__item-wrap span figure {
  width: 2rem;
  height: 2rem;
  display: grid;
  place-items: center;
}
.report-chart-wrap__bottom .bottom__item-wrap span figure svg {
  width: 100%;
  height: 100%;
}/*# sourceMappingURL=index.css.map */