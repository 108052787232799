// @import "../../breakpoints";

.send-money-wrap-box {
	flex: 1;
	display: flex;
	padding: 2rem;
	flex-direction: column;

	@media only screen and (max-width: 42em) {
		width: 100%;
		align-self: stretch;
		flex: unset;
		// background-color: red;
	}

	.send-money-wrap {
		padding-top: 3rem;
		display: flex;
		flex-direction: column;
		width: 50rem;
		gap: 2rem;

		@media only screen and (max-width: 42em) {
			width: 100%;
			padding: 2rem 2rem;
			align-self: stretch;
		}

		.title {
			font-size: 1.6rem;
			// font-weight: 300;
		}

		.select-box {
			// display: grid;
			// grid-template-columns: repeat(2, 1fr);
			// grid-template-rows: repeat(2, 1fr);
			// grid-gap: 2.5rem;
			display: flex;
			flex-direction: column;
			// gap: 1.5rem;

			& > *:last-child {
				border: unset;
			}

			@media only screen and (max-width: 42em) {
				display: flex;
				flex-direction: column;
				gap: 2.5rem;
				align-items: flex-start;
				width: 100%;
				align-self: stretch;
			}

			.box {
				// background-color: rgba(247, 248, 247, 0.5);
				cursor: pointer;
				padding: 2.5rem 0rem;
				position: relative;
				// background-color: red;
				display: flex;
				align-items: center;
				flex-direction: row;
				// gap: 1.5rem;
				padding-left: unset;
				border-radius: unset;

				&:hover .right-arrow .icon {
					transform: translateX(0.4rem);
				}

				.text-box {
					.name {
						margin-top: unset;
						margin-bottom: 0.3rem;
					}
				}

				.img-wrap {
					margin-right: 1.3rem;
					.img-box {
						width: 4.2rem;
						height: 4.2rem;
						display: grid;
						place-items: center;
						transition: all 0.3s;
						border-radius: 50%;
						background-color: #f7f8f7 !important;
						// display: none;

						.img {
							object-fit: contain;
							width: 40%;
							height: 40%;
						}
					}
				}

				.right-arrow {
					// @media only screen and (min-width: 42em){
					//  display: none;
					// }
					margin-left: auto;

					.icon {
						width: 1.5rem;
						height: 1.5rem;
						transition: all 0.3s;
					}
				}

				@media only screen and (max-width: 42em) {
					width: 100%;
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 1rem;
					padding: 1.5rem 1rem;

					.img-box {
						@media only screen and (max-width: 38em) {
							margin-top: unset;

							width: 3.5rem;
							height: 3.5rem;
						}
					}

					.text {
						font-size: 1.2rem;
						width: 85%;
					}
				}

				.name {
					@media only screen and (max-width: 42em) {
						margin-top: unset;
						font-size: 1.45rem;
					}
				}
			}

			& > *:not(:first-child) {
				.img-wrap {
					.img-box {
						.img {
							width: 70%;
							height: 70%;
						}
					}
				}
			}

			// &>*:nth-child(2){
			//   .img-wrap{
			//     .img-box{
			//       .img{
			//         width: 65%;
			//         height: 65%;
			//       }
			//     }
			//   }
			// }
		}
	}
}

// raven user starrt

.bill-purchase {
	flex: 1;
	display: flex;
	overflow-y: scroll;
	padding: 2rem;
	flex-direction: column;
	position: relative;

	&.step2 {
		padding: 0;
	}

	.bill-purchase__title {
		display: flex;
		flex-direction: column;
		margin-bottom: 0.8rem;

		h5 {
			font-weight: 600;
			font-size: 2rem;
			display: flex;
			align-items: center;
			color: #000000;
		}

		p {
			font-weight: 400;
			font-size: 1.4rem;
			display: flex;
			align-items: center;
			color: #676767;
		}

		svg {
			width: 1.75rem;
			height: 1.75rem;

			path {
				stroke: black;
			}
		}
	}

	.form {
		flex-direction: column;
		display: flex;
		gap: 2rem;
		flex: 1;

		@media only screen and (max-width: 42em) {
			width: 90%;
		}

		.recepient-box {
			max-height: 0;
			overflow-x: hidden;
			overflow-y: hidden;
			transition: all 300ms linear-bezier(0, 1.05, 0, 1);
			-webkit-transition: all 300ms;
			-moz-transition: all 300ms;
			width: 100%;

			.text-line {
				display: grid;
				grid-template-columns: 1fr min-content 1fr;
				align-items: center;
				width: 100%;

				.text {
					color: #676767;
					background-color: #f7f8f7;
					font-size: 1.1rem;
					border: 0.1rem solid #cccccc;
					padding: 0.5rem 1.1rem;
					border-radius: 10rem;
					font-weight: 600;
				}

				span {
					height: 0.1rem;
					background-color: rgba(204, 204, 204, 0.5);
				}
			}

			.view-total-box {
				display: flex;
				align-items: center;

				.view-wrap {
					margin-left: auto;
					position: relative;
					//   color: #ff0f00;

					.text {
						font-size: 1.3rem;
						display: flex;
						align-items: center;
						gap: 0.6rem;

						&:hover {
							figure {
								transform: translateX(3px);
							}
						}

						figure {
							width: 1.5rem;
							height: 1.5rem;
							display: grid;
							place-items: center;
							transition: transform 300ms ease-in-out;

							svg {
								width: 100%;
								height: 100%;
							}
						}
						// color: currentColor;
					}

					.view-wrap__container {
						display: flex;
						align-items: center;
						gap: 1rem;

						span {
							background: #f0eeff;
							border-radius: 7.2rem;
							padding: 0.1rem 1.2rem;

							p {
								font-size: 1.2rem;
								display: flex;
								align-items: center;
								letter-spacing: 1px;
								font-weight: 600;
								gap: 0.6rem;
								color: #755ae2;
							}
						}
					}

					.cancel-box {
						margin-left: auto;
						width: 2.2rem;
						height: 2.2rem;
						border-radius: 50%;
						background-color: #ffdfdd;
						margin-left: auto;
						display: grid;
						place-items: center;
						cursor: pointer;
						transition: all 0.3s;

						&:hover {
							transform: scale(1.1);
						}

						.icon {
							fill: #ff0f00;
							width: 1.1rem;
							height: 1.1rem;
						}
					}

					.view-all-box {
						position: absolute;
						bottom: 105%;
						right: 0;
						width: 35rem;
						height: 30rem;
						padding: 2rem;
						z-index: 4;
						overflow-y: scroll;
						box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
							0px 48px 64px -36px rgba(51, 51, 51, 0.08);
						border-radius: 2rem;
						transform: scale(0);
						background-color: rgb(255, 255, 255);
						transition: all 0.3s ease-in-out;
						transform-origin: bottom right;
						border-top-right-radius: unset;
						border-bottom-right-radius: unset;

						.wrap {
							width: 100%;
							display: flex;
							flex-direction: column;
							gap: 2rem;
							// padding-bottom: 5rem;

							.child-wrap {
								display: flex;
								align-items: center;
								padding: 1rem 1.3rem;
								// border: 0.1rem solid #f7f8f7;
								border-radius: 0.8rem;

								.text-box {
									.name {
										font-weight: 700;
										font-size: 1.3rem;
									}

									.text {
										font-size: 1.1rem;
										font-weight: 400;
									}
								}

								.img-box {
									width: 3rem;
									height: 3rem;
									margin-right: 1rem;

									.img {
										width: 100%;
										height: 100%;
										object-fit: contain;
									}
								}

								.cancel-box {
									margin-left: auto;
									width: 2.2rem;
									height: 2.2rem;
									border-radius: 50%;
									background-color: #ffdfdd;
									margin-left: auto;
									display: grid;
									place-items: center;
									cursor: pointer;
									transition: all 0.3s;

									&:hover {
										transform: scale(1.1);
									}

									.icon {
										fill: #ff0f00;
										width: 1.1rem;
										height: 1.1rem;
									}
								}
							}
						}

						&::-webkit-scrollbar:horizontal {
							display: none;
						}

						/* width */
						&::-webkit-scrollbar {
							width: 0.3rem;
						}

						/* Track */
						&::-webkit-scrollbar-track {
							-webkit-box-shadow: none !important;
							background-color: transparent !important;
						}

						/* Handle */
						&::-webkit-scrollbar-thumb {
							background: #020202;
							border-radius: 30rem;
						}

						/* Handle on hover */
						&::-webkit-scrollbar-thumb:hover {
							background: #020202;
						}
					}

					.view-all-box-show {
						transform: scale(1);
					}

					.text {
						cursor: pointer;
						font-weight: 600;
						color: var(--primary-purple);
					}
				}
			}

			.preview-box {
				margin-top: 1rem;
				max-height: 15rem;
				padding: 1rem 0rem;
				// background-color: red;
				// margin-bottom: 10rem;
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-column-gap: 1.5rem;

				.box {
					// background-color: #f7f8f7;
					padding: 1rem;
					cursor: pointer;
					position: relative;
					background: #f7f8f7;
					border: 1px solid #f7f8f7;
					box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04),
						0px 0px 2px rgba(51, 51, 51, 0.02);
					border-radius: 8px;

					.raven-name {
						font-weight: 600;
						font-size: 1.4rem;
						line-height: 140%;
						/* identical to box height, or 20px */

						letter-spacing: -0.004em;

						color: #000000;
					}

					.img-box {
						display: grid;
						place-items: center;
						width: 3.5rem;
					}

					.cancel-box {
						width: 2.2rem;
						height: 2.2rem;
						border-radius: 50%;
						background-color: #ffdfdd;
						margin-left: auto;
						display: grid;
						place-items: center;
						cursor: pointer;
						transition: all 0.3s;
						position: absolute;
						top: 1rem;
						right: 1rem;

						&:hover {
							transform: scale(1.1);
						}

						.icon {
							fill: #ff0f00;
							width: 1.1rem;
							height: 1.1rem;
						}
					}

					.name {
						margin-top: 2rem;
						font-size: 1.45rem;
					}
				}
			}
		}

		.recepient-box-show {
			overflow-x: unset;
			overflow-y: unset;
			max-height: 30rem;
			transition: all 300ms linear-bezier(0, 1.05, 0, 1);
		}

		.label-span-amount {
			font-weight: 600 !important;
			opacity: 1 !important;
		}

		.verify-name-box {
			display: flex;
			align-items: center;
			margin-top: -1rem;
			justify-content: center;
			border-radius: 7.2rem;
			width: max-content;
			height: 2.8rem;
			padding: 0 1.2rem;
			background: var(--raven-blue-shade-20, #e9f5ff);

			.text {
				color: var(--raven-accent-blue, #476885);
				font-weight: 600;
				text-align: center;
				font-size: 1.3rem;
				margin-left: 0.5rem;
			}
		}

		.select-network-provider_wrap {
			flex-direction: column;
			width: 100%;
			gap: 1.4rem;
			display: flex;
			margin-top: 0.8rem;

			label {
				font-size: 1.4rem;
			}

			.select-network-provider {
				display: flex;
				gap: 1rem;

				.select-network-provider__item {
					display: flex;
					height: 9rem;
					width: 8rem;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					flex: 1 0 0;
					cursor: pointer;
					border-radius: 1.2rem;
					border: 0.8px solid var(--ravengreyshade-50, #e5e6e6);

					img {
						width: 3.5rem;
						height: 3.5rem;
					}
					&.active {
						background: var(--raven-blue-shade-20, #e9f5ff);
						border: 2px solid var(--raven-primary-dark-green, #014345);
					}
				}
			}
		}

		.add-another-box {
			display: flex;
			align-items: center;
			margin-top: -1rem;
			align-self: flex-start;
			cursor: default;
			opacity: 0.5;

			&:hover .img-box {
				transform: scale(1.2);
			}

			.text {
				color: var(--primary-purple);
				font-weight: 600;
				font-size: 1.3rem;
			}

			.img-box {
				transition: all 0.3s ease-in-out;
				width: 2rem;
				height: 2rem;
				display: grid;
				place-items: center;
				margin-right: 0.3rem;

				.img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}

		.add-another-box-active {
			opacity: 1;
			cursor: pointer;
		}

		.label-span {
			font-weight: 700;
			opacity: 0.5;
			cursor: default;
		}

		.label-span-active {
			opacity: 1;
			cursor: pointer;
		}

		.bg-box {
			width: 100%;
			height: 10rem;
			background-color: #f7f8f7;
			border-radius: 1.6rem;
			// background-image: url('../../../assets/raven-to-raven-bg-img.png');
			background-position: right;
			background-size: contain;
			background-repeat: no-repeat;
		}
	}
}

// @include xs {
//   .bill-purchase {
//     width: 100%;
//     padding: 0 2rem !important;

//     .form {
//       width: 100% !important;
//     }
//   }
// }

@keyframes moveUpAnimeSendMoney {
	0% {
		transform: translateY(-3%) translateX(-50%);
		opacity: 0.7;
	}

	100% {
		transform: translateY(0%) translateX(-50%);
		opacity: 1;
	}
}
@keyframes moveUpAnime {
	0% {
		transform: translateY(2%);
		opacity: 0.7;
	}

	100% {
		transform: translateY(0%);
		opacity: 1;
	}
}

.bulk-drop-wrap {
	position: relative;

	.select-bulk-type-box {
		max-height: 0;
		// margin-left: 4.2rem;
		overflow-x: hidden;
		overflow-y: hidden;
		transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
		-webkit-transition: max-height 0.3s;
		-moz-transition: max-height 0.3s;
		display: flex;
		flex-direction: column;
		transition: all 0.3s;
		// gap: 1.5rem;

		.second-drop-child-wrap {
			position: relative;

			.child-row-two {
				position: absolute;
				bottom: 50%;
				left: 50%;
				transform: translateX(-50%) translateY(-20%);
				// width: 25rem;
				// background-color: #ff0f00;
				padding: 2rem;
				gap: 1.5rem;
				display: flex;
				flex-direction: column;
				border-radius: 1.2rem;

				& > *:first-child {
					padding-bottom: 1.5rem;
				}

				& > *:last-child {
					border: unset !important;
				}

				.child-row {
					margin-left: unset;
					// padding: 1.5rem !important;

					transform: unset !important;
				}

				.child-row-parent {
					padding: 1.5rem 0rem !important;
				}
			}
		}

		.child-row {
			display: flex;
			align-items: center;
			width: max-content;
			align-self: stretch;
			// background-color: red;
			margin-left: 5rem;
			// padding: 2rem 0rem !important;
			width: 100%;

			&:hover .img-box {
				transform: scale(1.03);
			}

			.title {
				font-weight: 700;
				// font-size: 1.4rem;
			}

			.img-box {
				width: 4rem;
				height: 4rem;
				display: grid;
				place-items: center;
				margin-right: 1rem;
				transition: all 0.3s;
				border-radius: 50%;
				background-color: #f7f8f7;
				// display: none;

				.img {
					object-fit: contain;
					width: 50%;
					height: 50%;
				}
			}
		}
	}

	.select-bulk-type-box-show {
		max-height: 30rem;
		overflow-x: visible;
		overflow-y: visible;
		transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
		& > *:not(:last-child) {
			// margin-bottom: 1.5rem;
			padding-bottom: 1.5rem;
			// border-bottom: 0.1rem solid #f7f8f7;
		}
	}
}

.send-money-select-bulk-type-modal-wrap {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	padding-bottom: 2rem;

	.des-text {
		font-size: 1.5rem;
		line-height: 2rem;
		background-color: #fff6ed;
		display: flex;
		align-items: flex-start;
		padding: 2rem 1rem;
		border-radius: 1.2rem;

		span {
			font-size: 1.35rem;
		}
	}

	.select-box {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		margin-top: 1rem;
		padding-top: 2rem;
		margin-bottom: 2rem;

		.select-item {
			display: flex;
			align-items: center;
			// justify-content: space-between;
			padding: 2rem 1.5rem;
			border-radius: 0.8rem;
			cursor: pointer;

			&:hover .icon {
				transform: translateX(0.3rem);
			}

			.text-box {
				margin-right: auto;

				.text {
					font-weight: 700;
					font-size: 1.55rem;
				}
			}

			.img-box {
				width: 4.5rem;
				height: 4.5rem;
				border-radius: 50%;
				margin-right: 1.5rem;
				display: grid;
				place-items: center;

				.img {
					width: 65%;
					height: 65%;
					object-fit: contain;
				}
			}

			.icon {
				width: 2rem;
				height: 2rem;
				color: #755ae2;
				transition: all 0.3s ease-in-out;
			}
		}
	}

	.switch-box {
		display: flex;
		align-items: center;
		padding: 0.7rem 1.5rem;
		border-radius: 3rem;
		gap: 2rem;
		width: max-content;

		.switch-item {
			cursor: pointer;
			display: flex;
			align-items: center;
			border-radius: 3rem;
			gap: 0.8rem;
			cursor: pointer;
			padding: 1rem 1.5rem;
		}

		.switch-item-active {
			cursor: default;
			box-shadow: 0px 3.0687px 4.0916px rgba(51, 51, 51, 0.04),
				0px 0px 2.0458px rgba(51, 51, 51, 0.02);
			background-color: #755ae2;

			.text {
				color: #ffffff;
			}
		}
	}
}

.coming-soon-type-box {
	position: relative;
	// cursor: default !important;

	.box-card {
		opacity: 0.5;
	}

	.security-lock-wrap-box {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0;
	}
}

// preview details styles

.step-two-bills {
	display: flex;
	flex: 1;
	flex-direction: column;
	padding-top: 4.3rem;

	.preview-bill__logo {
		position: absolute;
		top: 0;
		right: 19rem;

		.airtime-data {
			width: 9rem;
			height: 9rem;
			display: grid;
			place-items: center;
			position: relative;

			img {
				width: 85%;
				height: 85%;
			}

			svg {
				position: absolute;
				bottom: 0;
				right: 0;
				width: 3rem;
				height: 3rem;
			}
		}
	}
}
.preview-bill {
	flex: 1;
	height: 100%;
	width: 100%;
	background: #ffffff;
	border: 0.8px solid #e5e6e6;
	border-radius: 16px;
	padding: 2rem;

	.preview-bill__header {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin-top: 4rem;
		h5 {
			font-weight: 700;
			font-size: 2rem;
			display: flex;
			align-items: center;
			text-align: center;
			color: #000000;
		}

		small {
			font-weight: 400;
			font-size: 1.4rem;
			display: flex;
			margin-top: 0.4rem;
			align-items: center;
			text-align: center;
			color: #676767;
		}
	}
	.preview-bill__table {
		display: flex;
		flex: 1;
		flex-direction: column;

		// margin-top: 2rem;

		.table__item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			padding: 2rem 0;
			border-bottom: 1px solid #eeeeee;

			p {
				font-weight: 400;
				font-size: 1.4rem;
				line-height: 140%;
				/* identical to box height, or 20px */

				display: flex;
				align-items: center;

				color: #000000;
			}
		}
	}

	.preview-bill__button {
		margin-top: 4rem;
	}

	.preview-bill__switch {
		display: flex;
		align-items: center;
		justify-self: center;
		width: 100%;
		justify-content: center;
		margin-top: 2rem;
		gap: 0.6rem;

		figure {
			background: #f7f8f7;
			height: 3rem;
			width: 3rem;
			display: grid;
			place-items: center;
			border-radius: 2rem;
			cursor: pointer;

			svg {
				width: 40%;
				height: 40%;
			}
		}

		figure:first-child {
			transform: rotate(180deg);
		}

		.switch__pages {
			display: flex;
			height: 3rem;
			width: auto;
			padding: 0 1rem;
			align-items: center;
			background: #f7f8f7;
			border-radius: 2rem;

			p {
				font-weight: 700;
				font-size: 1.2rem;
				letter-spacing: 2px;
				display: flex;
				align-items: center;
				text-align: center;
				color: #1b1b1b;

				&:last-child {
					color: #acacac;
				}
			}
		}
	}
}
