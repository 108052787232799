.topup-modal-wrap {
	display: flex;
	flex-direction: column;
	gap: 2rem;

	&__transfer {
		border-radius: 1.6rem;
		border: 0.6px solid var(--raven-black-300, #d1d1d1);
		background: #fff;
		padding: 2.4rem 1.6rem;

		.details__title {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-bottom: 2rem;
			border-bottom: 1px solid #eee;
			span {
				display: flex;
				flex-direction: column;

				p:first-child {
					color: var(--raven-black-800, #333);
					font-size: 1.4rem;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
				}

				p:last-child {
					color: var(--raven-black-600, #646464);
					font-size: 1.2rem;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 166.667% */
				}
			}

			figure {
				width: 3rem;
				height: 3rem;

				svg {
					width: 100%;
					height: 100%;
				}
			}
		}

		.details__bank-account {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-bottom: 2rem;
			margin-top: 2rem;
			// border-bottom: 1px solid #eee;

			span {
				display: flex;
				flex-direction: column;

				p:first-child {
					color: var(--raven-black-600, #646464);
					font-size: 1.2rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}

				h5 {
					color: var(--raven-black-800, #333);
					font-size: 2.4rem;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
				}
			}

			button {
				display: flex;
				padding: 0.6rem 1.2rem;
				justify-content: center;
				border-radius: 100px;
				background: var(--ravenorangeshade-30, #ffe6cd);
				border: none;
				box-shadow: none;
				align-items: center;
				gap: 0.4rem;
				cursor: pointer;

				p {
					color: var(--raven-primary-orange, #ea872d);
					text-align: right;
					font-size: 1.2rem;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
				}
			}
		}
	}

	&__others {
		border-radius: 1.6rem;
		border: 0.6px solid var(--raven-black-300, #d1d1d1);
		background: #fff;
		padding: 0 1.6rem;

		.details__wrap {
			display: flex;
			gap: 2rem;
			flex-direction: column;
			width: 100%;
			// padding-bottom: 2rem;
			padding-top: 2rem;
			cursor: pointer;

			&--title {
				display: flex;
				gap: 2rem;
			}

			&--amount-field {
				border-radius: 12px;
				width: 100%;
				gap: 2rem;
				display: flex;
				flex-direction: column;
				max-height: 0;
				overflow: hidden;
				transition: max-height 0.2s cubic-bezier(0.77, 0, 0.175, 1);
				align-items: center;

				&.show {
					max-height: 500rem;
					border-top: 0.6px solid var(--raven-black-200, #e3e3e3);
					margin-bottom: 2rem;
					padding-bottom: 2rem;
					transition: max-height 0.2s cubic-bezier(0.77, 0, 0.175, 1);
				}

				div:first-child {
					// padding-top: 2rem;
					// padding-left: 2rem;
					// padding-right: 2rem;
				}
				button {
					width: 100%;
					// padding: 2rem;
				}
			}

			&:not(:last-child) {
				border-bottom: 2px solid #eee;
			}
			span {
				display: flex;
				flex-direction: column;

				p:first-child {
					color: var(--raven-black-800, #333);
					font-size: 1.4rem;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
				}

				p:last-child {
					color: var(--raven-black-600, #646464);
					font-size: 1.2rem;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 166.667% */
				}
			}

			figure {
				width: 4rem;
				height: 4rem;

				svg {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
}
