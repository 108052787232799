.dashboard-layout-wrap {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  position: relative;
}
.dashboard-layout-wrap .loader-animation {
  display: flex;
  /* From https://css.glass */
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.181);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000089877665;
  display: grid;
  place-items: center;
}
.dashboard-layout-wrap .loader-animation img {
  width: 48rem;
  height: 48rem;
}
.dashboard-layout-wrap .mobile-invisible-close {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 90vh;
  z-index: 3;
}
.dashboard-layout-wrap .side-nav-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 25rem;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
@media only screen and (max-width: 65em) {
  .dashboard-layout-wrap .side-nav-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 7rem;
    overflow-x: hidden;
    z-index: 4;
    padding-top: 0.5rem;
  }
}
@media only screen and (max-width: 56em) {
  .dashboard-layout-wrap .side-nav-wrap {
    width: 27.5rem;
    transform: translateX(-100%);
    height: 100vh;
    position: fixed;
    transition: all 0.4s ease-in-out;
    z-index: 10;
    padding: 0rem;
  }
}
@media only screen and (max-width: 56em) {
  .dashboard-layout-wrap .side-nav-wrap::-webkit-scrollbar {
    display: none;
  }
}
.dashboard-layout-wrap .side-nav-wrap::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 42em) {
  .dashboard-layout-wrap .side-nav-wrap {
    overflow-y: scroll;
    display: unset;
    overflow-x: hidden;
  }
  .dashboard-layout-wrap .side-nav-wrap > * {
    height: 100vh;
    padding-bottom: 4rem;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
.dashboard-layout-wrap .side-nav-wrap:hover::-webkit-scrollbar {
  display: block;
}
.dashboard-layout-wrap .side-nav-wrap::-webkit-scrollbar {
  width: 0.5rem;
}
.dashboard-layout-wrap .side-nav-wrap::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.dashboard-layout-wrap .side-nav-wrap::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 30rem;
}
.dashboard-layout-wrap .side-nav-wrap::-webkit-scrollbar-thumb {
  background: #cccccc;
}
@media only screen and (max-width: 56em) {
  .dashboard-layout-wrap .side-nav-wrap-show {
    transform: translateX(0%);
  }
}
.dashboard-layout-wrap .main-body-wrap {
  position: absolute;
  right: 0;
  top: 0;
  width: calc(100% - 25rem);
  height: 100vh;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 110em) {
  .dashboard-layout-wrap .main-body-wrap {
    width: calc(100% - 25rem) !important;
    right: 0;
  }
}
@media only screen and (max-width: 65em) {
  .dashboard-layout-wrap .main-body-wrap {
    width: calc(100% - 7rem);
  }
}
@media only screen and (max-width: 56em) {
  .dashboard-layout-wrap .main-body-wrap {
    width: 100%;
  }
}
.dashboard-layout-wrap .main-body-wrap .header-wrap {
  position: relative;
  z-index: 3;
  padding: 1rem 6rem;
}
.dashboard-layout-wrap .main-body-wrap .header-wrap.onboarding-header {
  padding: 1rem 9.3rem;
}
@media only screen and (min-width: 110em) {
  .dashboard-layout-wrap .main-body-wrap .header-wrap {
    padding: 1rem 7rem;
  }
}
@media only screen and (max-width: 42em) {
  .dashboard-layout-wrap .main-body-wrap .header-wrap {
    padding: 1rem 2.5rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
  }
}
.dashboard-layout-wrap .main-body-wrap .main-wrap {
  flex: 1;
  overflow-y: scroll;
  position: relative;
  z-index: 2;
  padding: 2.5rem 6.4rem;
  display: flex;
  flex-direction: column;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
@media only screen and (min-width: 110em) {
  .dashboard-layout-wrap .main-body-wrap .main-wrap {
    width: 137rem !important;
    padding: 2.5rem 7rem;
  }
}
@media only screen and (max-width: 42em) {
  .dashboard-layout-wrap .main-body-wrap .main-wrap {
    padding: 2.5rem;
    padding-top: 10rem;
    overflow-y: unset;
  }
}
.dashboard-layout-wrap .main-body-wrap .main-wrap::-webkit-scrollbar:horizontal {
  display: none;
}
.dashboard-layout-wrap .main-body-wrap .main-wrap::-webkit-scrollbar {
  width: 0.5rem;
}
.dashboard-layout-wrap .main-body-wrap .main-wrap::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.dashboard-layout-wrap .main-body-wrap .main-wrap::-webkit-scrollbar-thumb {
  background: #020202;
  border-radius: 30rem;
}
.dashboard-layout-wrap .main-body-wrap .main-wrap::-webkit-scrollbar-thumb:hover {
  background: #020202;
}/*# sourceMappingURL=index.css.map */