.mobile-onboarding-steps {
	display: flex;
	padding: 0px 4px 42px 0px;
	flex-direction: column;

	align-self: stretch;
	border-radius: 1.4rem;
	background: var(--base-white, #fff);
	margin-top: 3.2rem;

	&__complete-your-kyc {
		padding: 1rem 1.6rem;
		border-bottom: 0.8px solid var(--raven-black-200, #e3e3e3);
		margin-bottom: 28px;

		h5 {
			font-size: 1.4rem;
			font-weight: 400px;
		}
	}

	&__items {
		display: flex;
		flex-direction: row;
		height: 100px;
		overflow: hidden;
		cursor: pointer;

		.items__rails {
			display: flex;
			width: 15%;
			height: 100%;
			flex-direction: column;
			align-items: center;

			figure {
				width: 30px;
				height: 30px;
				display: grid;
				place-items: center;

				svg {
					width: 100%;
					height: 100%;
				}
			}
		}

		.items__content {
			display: flex;
			flex-direction: column;

			h5 {
				color: #000;
				font-size: 1.4rem;
				font-style: normal;
				font-weight: 600;
			}

			small {
				color: var(--base-black-base-bg-dark, #676767);
				font-size: 1rem;
				font-style: normal;
				font-weight: 400;
			}

			span {
				padding: 0 1.2rem;
				gap: 1rem;
				display: flex;
				height: 2.8rem;
				background: #fff6ed;
				border-radius: 7.2rem;
				align-items: center;
				justify-content: center;
				margin-top: 2rem;
				width: max-content;

				&.verified {
					background: #e8fff6;

					p {
						color: #1ace37;
					}
				}

				p {
					font-size: 1.2rem;
					line-height: 140%;
					display: flex;
					align-items: center;
					text-align: center;
					color: #ea872d;
				}
			}
		}
	}
}

.verification-rails {
	display: flex;
	flex-direction: column;
	gap: 3px;

	p {
		width: 1px;
		height: calc(5px);
		// border-radius: 50%;
		background: #d1d1d1;
	}

	&.completed {
		p {
			background: #755ae2;
		}
	}
}
