.currency {
  border: 0.1rem solid #cccccc;
  border-radius: 0.8rem;
  padding: 0.5rem;
  width: -moz-max-content;
  width: max-content;
  display: flex;
  align-items: center;
  gap: 1rem;
  z-index: 300323232323 !important;
  position: relative;
}
.currency .currency__flag {
  width: 3.5rem;
  height: 3rem;
  display: grid;
  padding-right: 1rem;
  border-right: 0.05rem solid #cccccc;
  place-items: center;
}
.currency .currency__flag figure {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}
.currency .currency__flag figure svg {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}
.currency .currency__value {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  cursor: pointer;
}
.currency .currency__value figure {
  display: grid;
  place-items: center;
  cursor: pointer;
}
.currency .currency__dropdown {
  display: flex;
  position: absolute;
  flex-direction: column;
  z-index: 300003434;
  top: 6rem;
  left: 0;
  background: white;
  border-radius: 0.8rem;
  box-shadow: 0px 5.69271px 11.3854px rgba(0, 0, 0, 0.05);
  opacity: 0;
  transform: translateY(-10px);
  height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.currency .currency__dropdown.open {
  opacity: 1;
  transform: translateY(0);
  height: unset;
  overflow: auto;
}
.currency .currency__dropdown .dropdown__select-item {
  display: flex;
  flex-direction: row;
  padding: 1rem 0;
  align-items: center;
  gap: 1rem;
  padding-left: 2rem;
  padding-right: 4rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.currency .currency__dropdown .dropdown__select-item:not(:last-child) {
  border-bottom: 0.4px solid var(--ravengreyshade-50, #e5e6e6);
}
.currency .currency__dropdown .dropdown__select-item:hover {
  background-color: rgba(11, 131, 119, 0.0862745098);
}
.currency .currency__dropdown .dropdown__select-item:active {
  background-color: #014345;
  color: white;
}
.currency .currency__dropdown .dropdown__select-item.active {
  background-color: #014345;
  color: white;
}
.currency .currency__dropdown .dropdown__select-item figure {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  place-items: center;
}
.currency .currency__dropdown .dropdown__select-item figure svg {
  width: 100%;
  height: 100%;
}

.form-group__black-light {
  z-index: 0;
}/*# sourceMappingURL=style.css.map */