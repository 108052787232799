.virtual-account {
  border-radius: 1.6rem;
  background: var(--raven-base-white, #fff);
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1.6rem 1.6rem 2rem 1.6rem;
  flex-direction: column;
  align-items: flex-start;
}
.virtual-account__tab {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 0.8px solid var(--ravengreyshade-50, #e5e6e6);
}
.virtual-account__tab .tab__item {
  cursor: pointer;
}
.virtual-account__tab .tab__item p {
  border-radius: 0.8rem;
  padding: 1.4rem;
  margin-bottom: 1.4rem;
}
.virtual-account__tab .tab__item--active {
  border-bottom: 4px solid var(--raven-primary-dark-green, #014345);
}
.virtual-account__tab .tab__item--active p {
  color: var(--raven-primary-dark-green, #014345);
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 140%;
  background: var(--raven-blue-shade-20, #e9f5ff);
}
.virtual-account__account-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3.7rem;
  margin-top: 3.7rem;
}
.virtual-account__account-details .account-details {
  width: 100%;
}
.virtual-account__account-details .account-details__account-number {
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 1.6rem;
}
.virtual-account__account-details .account-details__account-number span {
  display: flex;
  flex-direction: column;
}
.virtual-account__account-details .account-details__account-number span p {
  color: var(--raven-base-bg-dark, #676767);
  font-size: 1.2rem;
  text-transform: uppercase;
  line-height: 140%;
}
.virtual-account__account-details .account-details__account-number span h5 {
  font-size: 2.4rem;
  font-weight: 700;
}
.virtual-account__account-details .account-details__account-number figure {
  display: flex;
  gap: 0.8rem;
  padding: 0.4rem;
  align-items: center;
  border-radius: 72px;
  background: var(--raven-grey-shade-10, #f7f8f7);
}
.virtual-account__account-details .account-details__account-number figure p {
  font-size: 1.2rem;
}
.virtual-account__account-details .account-details__account-number figure svg {
  width: 1.5rem;
  height: 1.5rem;
}
.virtual-account__account-details .account-details__main {
  display: flex;
  flex-direction: column;
  border: 0.7px solid #e5e6e6;
  border-radius: 1.2rem;
  width: 100%;
  position: relative;
  height: 100%;
}
.virtual-account__account-details .account-details__main .panel {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
  transform: translateX(100%);
}
.virtual-account__account-details .account-details__main .panel.active {
  transform: translateX(0);
}
.virtual-account__account-details .account-details__main .account-details__item {
  display: flex;
  padding: 0 1.6rem;
  height: 6.4rem;
  width: 100%;
  align-items: center;
}
.virtual-account__account-details .account-details__main .account-details__item .temporal {
  background-color: #e9f5ff;
  padding: 0.8rem 1.2rem;
  height: -moz-min-content;
  height: min-content;
  border-radius: 1.6rem;
}
.virtual-account__account-details .account-details__main .account-details__item .temporal p {
  color: #476885;
}
.virtual-account__account-details .account-details__main .account-details__item.address {
  flex-direction: column;
  width: 100%;
  gap: 0.8rem;
}
.virtual-account__account-details .account-details__main .account-details__item.address p {
  font-size: 1.2rem;
  width: 100%;
}
.virtual-account__account-details .account-details__main .account-details__item.address span {
  width: 100%;
}
.virtual-account__account-details .account-details__main .account-details__item.address span p {
  color: #676767;
  font-size: 1.2rem;
  width: 100%;
}
.virtual-account__account-details .account-details__main .account-details__item p {
  font-weight: 400;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
}
.virtual-account__account-details .account-details__main .account-details__item:not(:last-child) {
  border-bottom: 0.6px solid #e5e6e6;
}
.virtual-account__account-details .account-details__main .account-details__item p:first-child {
  width: 20%;
}
.virtual-account__account-details .account-details__main .account-details__item p:last-child {
  width: -moz-fit-content;
  width: fit-content;
}
.virtual-account__account-details .account-details__main .account-details__item span {
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  gap: 2rem;
  align-items: center;
}
.virtual-account__account-details .account-details__main .account-details__item span figure {
  display: grid;
  place-items: center;
  width: 2.5rem;
  cursor: pointer;
  height: auto;
}
.virtual-account__account-details .account-details__main .account-details__item span figure svg {
  width: 100%;
  height: 100;
}
.virtual-account .virtual-account__transactions {
  display: flex;
  width: 100%;
  margin-top: 2rem;
  flex: 1;
  background: #ffffff;
  border-radius: 1.6rem;
  overflow-y: scroll;
  flex-direction: column;
}
.virtual-account .virtual-account__transactions::-webkit-scrollbar {
  display: none;
}
.virtual-account .virtual-account__transactions .top-bar__search {
  padding-left: 0.3rem;
}
.virtual-account .virtual-account__transactions .content__top-bar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.virtual-account .virtual-account__transactions .content__top-bar .top-bar__filter-export {
  display: flex;
  gap: 1.6rem;
  align-items: center;
  z-index: 1;
  position: relative;
}
.virtual-account .virtual-account__transactions .content__top-bar .top-bar__filter-export .filter-export__export-btn,
.virtual-account .virtual-account__transactions .content__top-bar .top-bar__filter-export .filter-export__filter-btn {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 1.6rem 2.4rem;
  gap: 0.8em;
  cursor: pointer;
  height: 5rem;
  background: #f7f8f7;
  border-radius: 1rem;
  z-index: 100;
}
.virtual-account .virtual-account__transactions .content__top-bar .top-bar__filter-export .filter-export__export-btn p,
.virtual-account .virtual-account__transactions .content__top-bar .top-bar__filter-export .filter-export__filter-btn p {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #676767;
}
.virtual-account .virtual-account__transactions .content__top-bar .top-bar__filter-export .filter-export__export-btn figure,
.virtual-account .virtual-account__transactions .content__top-bar .top-bar__filter-export .filter-export__filter-btn figure {
  width: -moz-fit-content;
  width: fit-content;
  display: grid;
  place-items: center;
}
.virtual-account .virtual-account__transactions .content__top-bar .top-bar__filter-export .filter-export__export-btn figure svg,
.virtual-account .virtual-account__transactions .content__top-bar .top-bar__filter-export .filter-export__filter-btn figure svg {
  width: 2rem;
  height: 2rem;
}
.virtual-account .virtual-account__transactions .content__top-bar .top-bar__filter-export .filter-export__drop-down {
  position: absolute;
  background: #ffffff;
  width: -moz-max-content;
  width: max-content;
  height: -moz-max-content;
  height: max-content;
  box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04), 0px 48px 64px -36px rgba(51, 51, 51, 0.08);
  border-radius: 1.6rem;
  box-shadow: 0;
  top: 110%;
  right: 55%;
  padding: 2rem;
  transform-origin: top right;
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
  z-index: 100;
}
.virtual-account .virtual-account__transactions .content__top-bar .top-bar__filter-export .filter-export__drop-down--open {
  transform: scale(1);
}
.virtual-account .virtual-account__transactions .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__switch {
  display: flex;
  width: 100%;
  cursor: pointer;
  justify-content: flex-start;
  gap: 1.2rem;
  border-bottom: 0.8px solid #e5e6e6;
}
.virtual-account .virtual-account__transactions .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__switch span {
  gap: 0.4rem;
  padding-bottom: 1.6rem;
}
.virtual-account .virtual-account__transactions .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__switch span p {
  font-weight: 400;
  font-size: 1.4rem;
  display: grid;
  place-items: center;
  padding: 1.2rem 1.6rem;
  border-radius: 8px;
  color: #1b1b1b;
}
.virtual-account .virtual-account__transactions .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__switch span.active {
  border-bottom: 4px solid #014345;
}
.virtual-account .virtual-account__transactions .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__switch span.active p {
  color: #014345;
  font-weight: 600;
  background: #e9f5ff;
}
.virtual-account .virtual-account__transactions .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range {
  display: flex;
  flex-direction: column;
  margin-top: 2.4rem;
}
.virtual-account .virtual-account__transactions .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range h5 {
  font-weight: 600;
  font-size: 1.4rem;
  color: #014345;
}
.virtual-account .virtual-account__transactions .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range .custom-date-range__grouped-form {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 1.2rem;
}
.virtual-account .virtual-account__transactions .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range .drop-down__action-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  margin-top: 5.2rem;
  padding: 2.3rem 0rem 0.3rem 0rem;
  border-top: 0.7px solid #e5e6e6;
}
.virtual-account .virtual-account__transactions .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range .drop-down__action-btns button {
  width: 50%;
}
.virtual-account .virtual-account__transactions .content__top-bar .top-bar__filter-export .filter-export__drop-down .drop-down__custom-date-range .drop-down__action-btns button:first-child {
  background: #e9f5ff;
  color: #014345;
}
.virtual-account .virtual-account__transactions .content__top-bar .top-bar__filter-export .filter-export__drop-down-closer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  display: none;
  visibility: none;
}
.virtual-account .virtual-account__transactions .content__top-bar .top-bar__filter-export .filter-export__drop-down-closer--opened {
  display: unset;
  visibility: unset;
}
.virtual-account .virtual-account__transactions .content__main {
  display: flex;
  width: 100%;
  overflow-y: scroll;
  flex: 1;
  flex-direction: column;
  margin-top: 2rem;
}
.virtual-account .virtual-account__transactions .content__main::-webkit-scrollbar {
  display: none;
}
.virtual-account .virtual-account__transactions .content__main--top-bar {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.virtual-account .virtual-account__transactions .content__main--top-bar .top-bar__title {
  display: flex;
  align-items: center;
  gap: 2.4rem;
}
.virtual-account .virtual-account__transactions .content__main--top-bar .top-bar__title h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */
  display: flex;
  align-items: center;
  /* RAVEN/Base Black */
  color: #1b1b1b;
}
.virtual-account .virtual-account__transactions .content__main--top-bar .top-bar__title p {
  display: none;
}
.virtual-account .virtual-account__transactions .content__main--top-bar .top-bar__title p.active {
  display: grid;
  place-items: center;
  padding: 0.8rem 1.2rem;
  background: #f7f8f7;
  border-radius: 7.2rem;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 140%;
  text-align: center;
  color: #014345;
}
.virtual-account .virtual-account__transactions .content__main .main__active-filters {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  max-height: 0;
  display: flex;
  transition: max-height 300ms ease-in-out;
}
.virtual-account .virtual-account__transactions .content__main .main__active-filters--show {
  display: flex;
  overflow: unset;
  max-height: 30rem;
  transition: max-height 300ms ease-in-out;
}
.virtual-account .virtual-account__transactions .content__main .main__active-filters .active-filters__item {
  background: #e9f5ff;
  border-radius: 7.2rem;
  display: flex;
  padding: 0.55rem 0.7rem;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.virtual-account .virtual-account__transactions .content__main .main__active-filters .active-filters__item figure {
  width: 1.75rem;
  height: 1.75rem;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.virtual-account .virtual-account__transactions .content__main .main__active-filters .active-filters__item figure svg {
  width: 100%;
  height: 100%;
}
.virtual-account .virtual-account__transactions .content__main .main__active-filters .active-filters__item p {
  color: #476885;
  display: flex;
  align-items: center;
  gap: 0.6rem;
}
.virtual-account .virtual-account__transactions .content__main .main__active-filters .active-filters__clear-item {
  background: #fff5f5;
  border-radius: 72px;
  border-radius: 7.2rem;
  display: flex;
  padding: 0.55rem 0.7rem;
  align-items: center;
  cursor: pointer;
}
.virtual-account .virtual-account__transactions .content__main .main__active-filters .active-filters__clear-item p {
  font-weight: 600;
  font-size: 1.2rem;
  color: #ff0f00;
}
.virtual-account .virtual-account__transactions .content__main .main__wrap {
  display: flex;
  margin-top: 2rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.virtual-account .virtual-account__transactions .content__main .main__wrap .transaction__table {
  width: 100%;
  height: 100%;
  border-radius: 1.2rem;
  overflow: auto;
}
.virtual-account .virtual-account__transactions .content__main .main__wrap .transaction__table::-webkit-scrollbar {
  display: none;
}
.virtual-account .virtual-account__transactions .content__main .main__wrap .transaction__table .table__main {
  border-radius: 1.2rem;
  overflow: auto;
}
.virtual-account .virtual-account__transactions .content__main .main__wrap .transaction__table .table__main .main__direction {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}
.virtual-account .virtual-account__transactions .content__main .main__wrap .transaction__table .table__main .main__direction figure {
  display: grid;
  place-items: center;
  width: 2.75rem;
  height: 2.75rem;
}
.virtual-account .virtual-account__transactions .content__main .main__wrap .transaction__table .table__main .main__direction figure svg {
  width: 100%;
  height: 100%;
}
.virtual-account .virtual-account__transactions .content__main .main__wrap .transaction__table .table__main .main__reference {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.virtual-account .virtual-account__transactions .content__main .main__wrap .transaction__table .table__main .main__reference svg circle {
  fill: #e9f5ff;
}

.virtual-account-settings {
  display: flex;
  width: 5.4rem;
  height: 5.4rem;
  padding: 1.6rem;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  background: var(--raven-base-white, #fff);
  box-shadow: 0px 48px 64px -36px rgba(51, 51, 51, 0.08), 0px 24px 48px -8px rgba(51, 51, 51, 0.04);
  cursor: pointer;
}
.virtual-account-settings:hover svg {
  transform: rotate(360deg);
}
.virtual-account-settings svg {
  width: 3rem;
  height: 3rem;
  transition: transform 300ms ease-in-out;
}

.create-virtual-wrap {
  display: flex;
  flex-direction: column;
}
.create-virtual-wrap__title {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  margin-bottom: 2.8rem;
}
.create-virtual-wrap__title h5 {
  font-size: 2.4rem;
  font-weight: 700;
}
.create-virtual-wrap__title p {
  color: var(--raven-base-bg-dark, #676767);
  font-size: 1.4rem;
}
.create-virtual-wrap__form {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  margin-bottom: 5.8rem;
}
.create-virtual-wrap__form .form__grouped {
  display: flex;
  align-items: center;
  gap: 1.6rem;
}
.create-virtual-wrap__form .form__grouped input {
  flex: 1 0 0;
}/*# sourceMappingURL=index.css.map */