.transaction-status {
	&.delivered {
		background-color: #e8fff6;
		text-transform: capitalize;

		p {
			color: #1ace37;
		}
	}
}
