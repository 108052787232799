.table-action-wrap {
	display: flex;
	align-items: center;
	gap: 1rem;

	figure {
		width: 3rem;
		height: 3rem;
		display: grid;

		svg {
			width: 100%;
			height: 100%;
		}
	}
}

.table-edit-input-wrap {
	display: flex;
	align-items: center;
	// width: fit-content;

	width: 100%;
	padding: 0;
	gap: 0.6rem;
	// max-width: 15rem;
	margin: 0;

	.form-group__deep-green-light .react-select-class > *:nth-child(3) {
		// border: unset;
	}

	.table-edit-input-wrap__input {
		width: 100%;
		max-width: unset;

		.form-input {
			width: 100% !important;
			min-width: unset !important;
			max-width: unset !important;
		}
		.form-group {
			width: 100%;
		}
	}
	// input {
	// 	width: 100%;
	// }

	@keyframes blink {
		0% {
			opacity: 1;
		}
		50% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	p {
		padding: 0.3rem;
		border-radius: 50%;
		background-color: #755ae2;
		animation: blink 2s infinite;
	}

	&__input {
		// padding: 0;
		// margin: 0;
		// background-color: unset;
		// border: none;
		max-width: 15rem;
		min-width: 10rem;
		display: flex;

		.form-input {
			// background-color: unset;
			// padding: 0.6rem;
			max-width: 15rem;
			min-width: 10rem;
			// border: unset;

			&:focus {
				// border: none;
				// box-shadow: none;
			}
			&:focus:valid {
				// border: none;
				// box-shadow: none;
			}
		}
	}
}

.bulk-transfer__table-wrap {
	.table-row {
		& > *:not(:last-child) {
			padding-right: 2rem !important;
		}
	}
}
