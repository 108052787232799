.single-card-container {
	display: flex;
	width: 100%;
	flex: 1;
	border-radius: 16px;
	background-color: white;
	padding: 1.6rem;
	flex-direction: column;
	overflow: hidden;

	.single-card__toggle {
		width: 100%;
		display: flex;
		padding-bottom: 1.4rem;
		// padding-top: 1.4rem;

		.toggle__item {
			cursor: pointer;
			min-height: max-content;
			height: 6rem;
			// padding: 1.6rem;
			&.active {
				border-bottom: 5px solid #014345;
				p {
					display: flex;
					background: var(--grey-shade-10, #f7f8f7);
					cursor: pointer;
					text-wrap: nowrap;
					font-weight: 600;
				}
			}
		}
		p {
			color: var(--raven-primary-dark-green, #014345);
			font-size: 1.4rem;
			font-style: normal;
			line-height: 140%; /* 19.6px */
			border-radius: 8px;
			padding: 1.2rem 1.6rem;
			align-items: center;
		}
	}

	.single-card__content {
		display: flex;
		flex: 1;
		gap: 2rem;

		.content__left {
			width: 30%;

			figure {
				width: 100%;
				height: 100%;
				display: grid;
				place-items: center;
				border-radius: 16px;
				background: linear-gradient(
					180deg,
					#f7f8f7 0%,
					rgba(247, 248, 247, 0) 100%
				);

				img {
					width: 80%;
					object-fit: contain;
					height: 80%;
				}
			}
		}
		.content__right {
			width: 70%;

			.right__title {
				display: flex;
				flex-direction: column;

				small {
					color: var(--base-black-base-bg-dark, #676767);
					font-size: 1.2rem;
					font-style: normal;
					font-weight: 400;
					line-height: 140%; /* 16.8px */
					text-transform: uppercase;
				}

				h5 {
					color: var(--themedarkmode, #000);
					font-size: 2.4rem;
					font-style: normal;
					font-weight: 700;
				}
			}

			.right__content-table {
				display: flex;
				flex-direction: column;
				border: 0.6px solid var(--RAVEN-GREY-SHADE-50, #e5e6e6);
				border-radius: 16px;
				margin-top: 2rem;

				.content-table__row {
					display: flex;

					p {
						color: var(--themedarkmode, #000);
						font-size: 1.4rem;
						padding: 1.6rem;
						font-style: normal;
						font-weight: 400;
						line-height: 140%; /* 19.6px */
					}

					&:not(:last-child) {
						p {
							border-bottom: 0.6px solid var(--RAVEN-GREY-SHADE-50, #e5e6e6);
						}
					}

					p:first-child {
						width: 20%;
					}
					p:last-child {
						width: 80%;
					}
				}
			}
		}
	}

	.content__main--top-bar {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		// margin-bottom: 2rem;
	}
	.single-card-transactions {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	.history__content {
		display: flex;
		width: 100%;
		height: 100%;
		flex: 1;
		background: #ffffff;
		border-radius: 1.6rem;
		// padding: 2rem;
		overflow-y: scroll;
		flex-direction: column;

		&::-webkit-scrollbar {
			display: none;
		}

		.content__top-bar {
			display: flex;
			width: 100%;
			justify-content: space-between;
			align-items: center;

			.top-bar__filter-export {
				display: flex;
				gap: 1.6rem;
				align-items: center;
				z-index: 1;
				position: relative;

				.filter-export__export-btn,
				.filter-export__filter-btn {
					flex-direction: row;
					justify-content: center;
					align-items: center;
					display: flex;
					padding: 1.6rem 2.4rem;
					gap: 0.8em;
					cursor: pointer;
					height: 5rem;
					background: #f7f8f7;
					border-radius: 1rem;
					z-index: 100;

					p {
						font-weight: 600;
						font-size: 1.4rem;
						line-height: 140%;
						display: flex;
						align-items: center;
						color: #676767;
					}

					figure {
						width: fit-content;
						display: grid;
						place-items: center;

						svg {
							width: 2rem;
							height: 2rem;
						}
					}
				}

				.filter-export__drop-down {
					position: absolute;
					background: #ffffff;
					width: max-content;
					height: max-content;
					box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
						0px 48px 64px -36px rgba(51, 51, 51, 0.08);
					border-radius: 1.6rem;
					box-shadow: 0;
					top: 110%;
					right: 55%;
					padding: 2rem;
					transform-origin: top right;
					transform: scale(0);
					transition: transform 0.3s ease-in-out;
					z-index: 100;

					&--open {
						transform: scale(1);
					}

					.drop-down__switch {
						display: flex;
						width: 100%;
						cursor: pointer;
						justify-content: flex-start;
						gap: 1.2rem;
						border-bottom: 0.8px solid #e5e6e6;
						span {
							gap: 0.4rem;
							padding-bottom: 1.6rem;

							p {
								font-weight: 400;
								font-size: 1.4rem;
								display: grid;
								place-items: center;
								padding: 1.2rem 1.6rem;
								border-radius: 8px;
								color: #1b1b1b;
							}

							&.active {
								border-bottom: 4px solid #014345;
								p {
									color: #014345;
									font-weight: 600;
									background: #e9f5ff;
								}
							}
						}
					}

					.drop-down__custom-date-range {
						display: flex;
						flex-direction: column;
						margin-top: 2.4rem;

						h5 {
							font-weight: 600;
							font-size: 1.4rem;
							color: #014345;
						}

						.custom-date-range__grouped-form {
							display: flex;
							align-items: center;
							gap: 2rem;
							margin-top: 1.2rem;
						}

						.drop-down__action-btns {
							display: flex;
							align-items: center;
							justify-content: center;
							gap: 2rem;
							width: 100%;
							margin-top: 5.2rem;

							padding: 2.3rem 0rem 0.3rem 0rem;
							border-top: 0.7px solid #e5e6e6;

							button {
								width: 50%;

								&:first-child {
									background: #e9f5ff;
									color: #014345;
								}
							}
						}
					}
				}

				.filter-export__drop-down-closer {
					position: fixed;
					width: 100vw;
					height: 100vh;
					top: 0;
					right: 0;

					display: none;
					visibility: none;

					&--opened {
						display: unset;
						visibility: unset;
					}
				}
			}
		}
	}

	.content__main {
		display: flex;
		width: 100%;
		height: 90%;
		overflow: hidden;
		// flex: 1;
		flex-direction: column;
		// margin-top: 2rem;

		&::-webkit-scrollbar {
			display: none;
		}

		&--top-bar {
			display: flex;
			align-items: center;
			width: 100%;

			.top-bar__title {
				display: flex;
				align-items: center;
				gap: 2.4rem;

				h5 {
					font-weight: 600;
					font-size: 16px;
					line-height: 140%;
					/* or 22px */

					display: flex;
					align-items: center;

					/* RAVEN/Base Black */

					color: #1b1b1b;
				}

				p {
					display: none;
					&.active {
						display: grid;
						place-items: center;
						padding: 0.8rem 1.2rem;
						background: #f7f8f7;
						border-radius: 7.2rem;
						font-weight: 600;
						font-size: 1.2rem;
						line-height: 140%;
						text-align: center;
						color: #014345;
					}
				}
			}
		}

		.main__active-filters {
			width: 100%;
			justify-content: space-between;
			align-items: center;
			overflow: hidden;
			max-height: 0;
			display: flex;
			transition: max-height 300ms ease-in-out;

			&--show {
				display: flex;
				overflow: unset;
				max-height: 30rem;
				transition: max-height 300ms ease-in-out;
			}

			.active-filters__item {
				background: #e9f5ff;
				border-radius: 7.2rem;
				display: flex;
				padding: 0.55rem 0.7rem;
				align-items: center;
				gap: 1rem;
				margin-top: 1rem;
				margin-bottom: 2rem;

				figure {
					width: 1.75rem;
					height: 1.75rem;
					display: grid;
					place-items: center;
					cursor: pointer;

					svg {
						width: 100%;
						height: 100%;
					}
				}

				p {
					color: #476885;
					display: flex;
					align-items: center;
					gap: 0.6rem;
				}
			}

			.active-filters__clear-item {
				background: #fff5f5;
				border-radius: 72px;
				border-radius: 7.2rem;
				display: flex;
				padding: 0.55rem 0.7rem;
				align-items: center;
				cursor: pointer;

				p {
					font-weight: 600;
					font-size: 1.2rem;
					color: #ff0f00;
				}
			}
		}

		.main__wrap {
			display: flex;
			margin-top: 2rem;
			width: 100%;
			height: 95%;
			// background-color: red;
			overflow: hidden;

			.transaction__table {
				width: 100%;
				// background-color: yellow;
				height: 100%;
				border-radius: 1.2rem;
				overflow: auto;

				&::-webkit-scrollbar {
					display: none;
				}

				.table__main {
					border-radius: 1.2rem;
					overflow: auto;

					.main__direction {
						display: flex;
						align-items: center;
						gap: 1.2rem;

						figure {
							display: grid;
							place-items: center;
							width: 2.75rem;
							height: 2.75rem;

							svg {
								width: 100%;
								height: 100%;
							}
						}
					}
				}
			}
		}
	}
}
