.auth-legacy-switch {
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: -moz-max-content;
  width: max-content;
  display: flex;
  top: -100px;
  height: -moz-max-content;
  height: max-content;
  padding: var(--Count, 1.4rem) var(--Count, 1.6rem);
  justify-content: center;
  align-items: center;
  gap: 7.2rem;
  flex-shrink: 0;
  border-radius: 1.2rem;
  background: white;
  box-shadow: 0px 48px 64px -36px rgba(51, 51, 51, 0.08), 0px 24px 48px -8px rgba(51, 51, 51, 0.04);
  transform: translateX(-50%);
  transition: top 0.5s ease-in-out;
  z-index: 100;
}
.auth-legacy-switch.show {
  top: 4rem;
  transition: top 0.5s ease-in-out;
}
.auth-legacy-switch:not(.show) .auth-legacy-switch {
  top: -100px;
  transition: top 0.5s ease-in-out;
}
.auth-legacy-switch div {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}
.auth-legacy-switch div .info-icon {
  height: 3.5rem;
  width: 3.5rem;
  display: grid;
  place-items: center;
}
.auth-legacy-switch div .info-icon svg {
  width: 100%;
  height: 100%;
}
.auth-legacy-switch div span {
  border-radius: 10px;
  background: var(--raven-black-50, #f7f7f7);
  padding: 1.1rem 1.5rem;
  cursor: pointer;
}
.auth-legacy-switch div span figure {
  width: 3rem;
  height: 3rem;
  display: grid;
  place-items: center;
}
.auth-legacy-switch div span figure svg {
  width: 100%;
  height: 100%;
}
.auth-legacy-switch .legacy-text {
  color: var(--themedarkmode, #000);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
}
.auth-legacy-switch button {
  border: none;
  background: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  border-radius: 10px;
  background: var(--primary-blue-20, #e9f5ff);
  padding: 1.4rem 2.4rem;
  cursor: pointer;
}
.auth-legacy-switch button figure {
  width: auto;
  height: 2rem;
  display: grid;
  place-items: center;
}
.auth-legacy-switch button figure svg {
  width: 100%;
  height: 100%;
}
.auth-legacy-switch button p {
  color: var(--primary-darkgreen-100, #014345);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
}/*# sourceMappingURL=legacy.css.map */