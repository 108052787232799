.topup-modal-wrap {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.topup-modal-wrap__transfer {
  border-radius: 1.6rem;
  border: 0.6px solid var(--raven-black-300, #d1d1d1);
  background: #fff;
  padding: 2.4rem 1.6rem;
}
.topup-modal-wrap__transfer .details__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2rem;
  border-bottom: 1px solid #eee;
}
.topup-modal-wrap__transfer .details__title span {
  display: flex;
  flex-direction: column;
}
.topup-modal-wrap__transfer .details__title span p:first-child {
  color: var(--raven-black-800, #333);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.topup-modal-wrap__transfer .details__title span p:last-child {
  color: var(--raven-black-600, #646464);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}
.topup-modal-wrap__transfer .details__title figure {
  width: 3rem;
  height: 3rem;
}
.topup-modal-wrap__transfer .details__title figure svg {
  width: 100%;
  height: 100%;
}
.topup-modal-wrap__transfer .details__bank-account {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2rem;
  margin-top: 2rem;
}
.topup-modal-wrap__transfer .details__bank-account span {
  display: flex;
  flex-direction: column;
}
.topup-modal-wrap__transfer .details__bank-account span p:first-child {
  color: var(--raven-black-600, #646464);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.topup-modal-wrap__transfer .details__bank-account span h5 {
  color: var(--raven-black-800, #333);
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.topup-modal-wrap__transfer .details__bank-account button {
  display: flex;
  padding: 0.6rem 1.2rem;
  justify-content: center;
  border-radius: 100px;
  background: var(--ravenorangeshade-30, #ffe6cd);
  border: none;
  box-shadow: none;
  align-items: center;
  gap: 0.4rem;
  cursor: pointer;
}
.topup-modal-wrap__transfer .details__bank-account button p {
  color: var(--raven-primary-orange, #ea872d);
  text-align: right;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.topup-modal-wrap__others {
  border-radius: 1.6rem;
  border: 0.6px solid var(--raven-black-300, #d1d1d1);
  background: #fff;
  padding: 0 1.6rem;
}
.topup-modal-wrap__others .details__wrap {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  width: 100%;
  padding-top: 2rem;
  cursor: pointer;
}
.topup-modal-wrap__others .details__wrap--title {
  display: flex;
  gap: 2rem;
}
.topup-modal-wrap__others .details__wrap--amount-field {
  border-radius: 12px;
  width: 100%;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s cubic-bezier(0.77, 0, 0.175, 1);
  align-items: center;
}
.topup-modal-wrap__others .details__wrap--amount-field.show {
  max-height: 500rem;
  border-top: 0.6px solid var(--raven-black-200, #e3e3e3);
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  transition: max-height 0.2s cubic-bezier(0.77, 0, 0.175, 1);
}
.topup-modal-wrap__others .details__wrap--amount-field button {
  width: 100%;
}
.topup-modal-wrap__others .details__wrap:not(:last-child) {
  border-bottom: 2px solid #eee;
}
.topup-modal-wrap__others .details__wrap span {
  display: flex;
  flex-direction: column;
}
.topup-modal-wrap__others .details__wrap span p:first-child {
  color: var(--raven-black-800, #333);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.topup-modal-wrap__others .details__wrap span p:last-child {
  color: var(--raven-black-600, #646464);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}
.topup-modal-wrap__others .details__wrap figure {
  width: 4rem;
  height: 4rem;
}
.topup-modal-wrap__others .details__wrap figure svg {
  width: 100%;
  height: 100%;
}/*# sourceMappingURL=index.css.map */