.business-profile {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 12px;
  border: 1px solid var(--ravengreyshade-50, #e5e6e6);
  height: 100%;
  padding: 2.4rem;
}
.business-profile__owner {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1.6rem;
}
.business-profile__owner span {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
.business-profile__owner span h5 {
  font-weight: 700;
  font-size: 2.4rem;
}
.business-profile__owner span p {
  overflow: hidden;
  color: var(--raven-base-bg-dark, #676767);
  text-overflow: ellipsis;
  font-size: 1.4rem;
}
.business-profile__owner figure {
  width: 6.4rem;
  height: 6.4rem;
  display: grid;
  place-items: center;
  -o-object-fit: contain;
     object-fit: contain;
  overflow: hidden;
  border-radius: 50%;
}
.business-profile__owner figure .business-profile__logo-text {
  background-color: #f7f7f7;
  border-radius: 50%;
  width: 6.4rem;
  height: 6.4rem;
  place-items: center;
  display: grid;
}
.business-profile__owner figure .business-profile__logo-text p {
  text-transform: uppercase;
  color: var(--primary-darkgreen-100, #014345);
  text-align: center;
  font-size: 2.4rem;
  font-weight: 700;
}
.business-profile__owner figure img {
  width: 10rem;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top center;
     object-position: top center;
  height: 10rem;
}
.business-profile__details {
  display: flex;
  flex: 1;
  width: 100%;
  margin-top: 3.2rem;
  border-radius: 10px;
  border: 0.6px solid var(--raven-black-200, #e3e3e3);
  padding: 1.6rem;
}
.business-profile__details div {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.business-profile__details div span {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.business-profile__details div span small {
  color: var(--raven-base-bg-dark, #676767);
  font-size: 1.2rem;
  font-weight: 500;
}
.business-profile__details div span p {
  color: var(--raven-base-background-dark, #020202);
  font-size: 1.4rem;
}
.business-profile__details div:first-child {
  flex: 0 0 30%;
}
.business-profile__details div:last-child {
  flex: 0 0 30%;
}

.verification-settings {
  display: flex;
  flex-direction: column;
  border-radius: 1.2rem;
  flex: 1;
  border: 1px solid var(--ravengreyshade-50, #e5e6e6);
  padding: 2.4rem;
  height: 100%;
  overflow-y: auto;
}
.verification-settings__title {
  display: flex;
  flex-direction: column;
  margin-bottom: 3.3rem;
}
.verification-settings__title h5 {
  font-size: 2.4rem;
  font-weight: 700;
}
.verification-settings__title p {
  font-size: 1.4rem;
  color: var(--raven-base-bg-dark, #676767);
}
.verification-settings__main {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}
.verification-settings__main .main__items {
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  border: 1px solid #e5e6e6;
  background: var(--raven-base-white, #fff);
  padding: 1.6rem;
  width: 100%;
  justify-content: space-between;
}
.verification-settings__main .main__items .items__grouped {
  display: flex;
  align-items: center;
  gap: 1.3rem;
}
.verification-settings__main .main__items .items__grouped figure {
  width: 8rem;
  height: 8rem;
  display: grid;
  place-items: center;
}
.verification-settings__main .main__items .items__grouped figure svg {
  width: 100%;
  height: 100%;
}
.verification-settings__main .main__items .items__grouped div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
}
.verification-settings__main .main__items .items__grouped div span {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.verification-settings__main .main__items .items__grouped div span h5 {
  color: var(--raven-base-background-dark, #020202);
  font-size: 1.6rem;
  line-height: 140%;
  font-weight: 700;
}
.verification-settings__main .main__items .items__grouped div span p {
  color: var(--raven-base-bg-dark, #676767);
  font-size: 1.2rem;
  line-height: 140%;
}
.verification-settings__main .main__items .items__grouped span {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.verification-settings__main .main__items .items__grouped span h5 {
  color: var(--raven-base-background-dark, #020202);
  font-size: 1.6rem;
  line-height: 140%;
  font-weight: 700;
}
.verification-settings__main .main__items .items__grouped span p {
  color: var(--raven-base-bg-dark, #676767);
  font-size: 1.2rem;
  line-height: 140%;
}
.verification-settings__main .main__items .items__status {
  display: flex;
  height: 2.8rem;
  padding: 0.8rem 1.2rem;
  border-radius: 72px;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.verification-settings__main .main__items .items__status figure {
  width: 1.5rem;
  height: 1.5rem;
  place-items: center;
}
.verification-settings__main .main__items .items__status figure svg {
  width: 100%;
  height: 100%;
}
.verification-settings__main .main__items .items__status.verified {
  background: var(--ravengreenshade-30, #e8fff6);
}
.verification-settings__main .main__items .items__status.verified p {
  color: var(--ravensuccess, #1ace37);
}
.verification-settings__main .main__items .items__status.failed {
  background: var(--raven-error-10, #fff5f5);
}
.verification-settings__main .main__items .items__status.failed p {
  color: var(--raven-error-color, #ff0f00);
}
.verification-settings__main .main__items .items__status.pending {
  background: var(--raven-error-10, #fff6ed);
}
.verification-settings__main .main__items .items__status.pending p {
  color: var(--raven-error-color, #ea872d);
}
.verification-settings__main .main__items .items__status.review {
  background: var(--raven-error-10, #e9f5ff);
}
.verification-settings__main .main__items .items__status.review p {
  color: var(--raven-error-color, #476885);
}
.verification-settings__main .main__items .items__status p {
  text-align: center;
  font-size: 1.2rem;
  line-height: 140%;
}

.webhook-settings {
  display: flex;
  flex-direction: column;
  border-radius: 1.2rem;
  flex: 1;
  height: 100%;
  border: 1px solid var(--ravengreyshade-50, #e5e6e6);
  padding: 2.4rem;
}
.webhook-settings__title {
  display: flex;
  flex-direction: column;
  margin-bottom: 3.3rem;
}
.webhook-settings__title h5 {
  font-size: 2.4rem;
  font-weight: 700;
}
.webhook-settings__title p {
  font-size: 1.4rem;
  color: var(--raven-base-bg-dark, #676767);
}
.webhook-settings__main {
  display: flex;
  gap: 2rem;
  width: 100%;
}
.webhook-settings__main .main__right,
.webhook-settings__main .main__left {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 2.6rem;
}
.webhook-settings__main .main__right .left__input,
.webhook-settings__main .main__left .left__input {
  display: flex;
  position: relative;
}
.webhook-settings__main .main__right .left__input .input__copy-icon,
.webhook-settings__main .main__left .left__input .input__copy-icon {
  z-index: 200;
  position: absolute;
  top: 3.5rem;
  right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  place-items: center;
}
.webhook-settings__main .main__right .left__input .input__copy-icon figure,
.webhook-settings__main .main__left .left__input .input__copy-icon figure {
  display: grid;
  place-items: center;
  width: 2.5rem;
  cursor: pointer;
  height: 2.5rem;
}
.webhook-settings__main .main__right .form-group__deep-green-light .input-group .show-hide,
.webhook-settings__main .main__left .form-group__deep-green-light .input-group .show-hide {
  display: none;
}
.webhook-settings__button {
  margin-top: 4.2rem;
}

.payment-settings {
  display: flex;
  flex-direction: column;
  border-radius: 1.2rem;
  flex: 1;
  border: 1px solid var(--ravengreyshade-50, #e5e6e6);
  padding: 2.4rem;
  height: 100%;
}
.payment-settings__title {
  display: flex;
  flex-direction: column;
  margin-bottom: 3.3rem;
}
.payment-settings__title h5 {
  font-size: 2.4rem;
  font-weight: 700;
}
.payment-settings__title p {
  font-size: 1.4rem;
  color: var(--raven-base-bg-dark, #676767);
}
.payment-settings__main {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  overflow-y: scroll;
}
.payment-settings__main::-webkit-scrollbar {
  display: none;
}
.payment-settings__main .main__items {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid #e5e6e6;
  background: var(--raven-base-white, #fff);
  padding: 1.6rem;
  width: 100%;
  gap: 4.2rem;
}
.payment-settings__main .main__items .items__grouped {
  display: flex;
  align-items: center;
  gap: 1.3rem;
}
.payment-settings__main .main__items .items__grouped figure {
  width: 8rem;
  height: 8rem;
  display: grid;
  place-items: center;
}
.payment-settings__main .main__items .items__grouped figure svg {
  width: 100%;
  height: 100%;
}
.payment-settings__main .main__items .items__grouped div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
}
.payment-settings__main .main__items .items__grouped div span {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.payment-settings__main .main__items .items__grouped div span h5 {
  color: var(--raven-base-background-dark, #020202);
  font-size: 1.6rem;
  line-height: 140%;
  font-weight: 700;
}
.payment-settings__main .main__items .items__grouped div span p {
  color: var(--raven-base-bg-dark, #676767);
  font-size: 1.2rem;
  line-height: 140%;
}
.payment-settings__main .main__items .items__grouped span {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.payment-settings__main .main__items .items__grouped span h5 {
  color: var(--raven-base-background-dark, #020202);
  font-size: 1.6rem;
  line-height: 140%;
  font-weight: 700;
}
.payment-settings__main .main__items .items__grouped span p {
  color: var(--raven-base-bg-dark, #676767);
  font-size: 1.2rem;
  line-height: 140%;
}
.payment-settings__main .main__items .items__options {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}
.payment-settings__main .main__items .items__options .options__right,
.payment-settings__main .main__items .items__options .options__left {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  gap: 2rem;
}
.payment-settings__main .main__items .items__options .options__right .right__item,
.payment-settings__main .main__items .items__options .options__right .left__item,
.payment-settings__main .main__items .items__options .options__left .right__item,
.payment-settings__main .main__items .items__options .options__left .left__item {
  padding: 1.8rem;
  gap: 1.4rem;
  border-radius: 8px;
  display: flex;
  border: 1px solid var(--ravengreyshade-50, #e5e6e6);
  cursor: pointer;
}
.payment-settings__main .main__items .items__options .options__right .right__item.inactive span p,
.payment-settings__main .main__items .items__options .options__right .left__item.inactive span p,
.payment-settings__main .main__items .items__options .options__left .right__item.inactive span p,
.payment-settings__main .main__items .items__options .options__left .left__item.inactive span p {
  font-weight: 400;
}
.payment-settings__main .main__items .items__options .options__right .right__item.active,
.payment-settings__main .main__items .items__options .options__right .left__item.active,
.payment-settings__main .main__items .items__options .options__left .right__item.active,
.payment-settings__main .main__items .items__options .options__left .left__item.active {
  border: 2px solid var(--raven-primary-dark-green, #014345);
}
.payment-settings__main .main__items .items__options .options__right .right__item span,
.payment-settings__main .main__items .items__options .options__right .left__item span,
.payment-settings__main .main__items .items__options .options__left .right__item span,
.payment-settings__main .main__items .items__options .options__left .left__item span {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.payment-settings__main .main__items .items__options .options__right .right__item span p,
.payment-settings__main .main__items .items__options .options__right .left__item span p,
.payment-settings__main .main__items .items__options .options__left .right__item span p,
.payment-settings__main .main__items .items__options .options__left .left__item span p {
  color: var(--raven-base-background-dark, #020202);
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 140%;
}
.payment-settings__main .main__items .items__options .options__right .right__item span small,
.payment-settings__main .main__items .items__options .options__right .left__item span small,
.payment-settings__main .main__items .items__options .options__left .right__item span small,
.payment-settings__main .main__items .items__options .options__left .left__item span small {
  color: var(--raven-base-bg-dark, #676767);
  font-size: 1.2rem;
  line-height: 140%;
}

.settings-modal-wrap {
  display: flex;
  flex-direction: column;
}
.settings-modal-wrap__title {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  margin-bottom: 2.8rem;
}
.settings-modal-wrap__title h5 {
  font-size: 2.4rem;
  font-weight: 700;
}
.settings-modal-wrap__title p {
  color: var(--raven-base-bg-dark, #676767);
  font-size: 1.4rem;
}
.settings-modal-wrap__form {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  margin-bottom: 5.8rem;
}
.settings-modal-wrap__form .form__grouped {
  display: flex;
  align-items: center;
  gap: 1.6rem;
}
.settings-modal-wrap__form .form__grouped input {
  flex: 1 0 0;
}
.settings-modal-wrap__stepper {
  display: inline-flex;
  padding: 12px 14px;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  border-radius: 128px;
  margin-bottom: 3.6rem;
  width: -moz-min-content;
  width: min-content;
  background: var(--base-background, #f9f9f9);
}
.settings-modal-wrap__stepper p {
  width: 2.4rem;
  height: 0.8rem;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 3.2rem;
  background: var(--raven-black-200, #e3e3e3);
}
.settings-modal-wrap__stepper p.active {
  width: 4.7rem;
}/*# sourceMappingURL=index.css.map */