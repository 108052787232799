.transaction-status {
	display: grid;
	place-items: center;
	background-color: aliceblue;
	padding: 0.8rem 1.2rem;
	width: fit-content;
	border-radius: 7.2rem;

	p {
		font-weight: 600;
		font-size: 1.2rem;
	}

	&.pending {
		background-color: #fff6ed;
		p {
			color: #ea872d;
		}
	}

	&.successful {
		background-color: #e8fff6;
		p {
			color: #1ace37;
		}
	}

	&.failed {
		background-color: #fff5f5;
		p {
			color: #ff0f00;
		}
	}
}

.transaction-details-modal {
	display: flex;
	flex: 1;
	flex-direction: column;

	&__title {
		font-size: 2rem;
		font-weight: 400;
		margin-bottom: 1.9rem;
	}

	&__amount-view {
		display: flex;
		background: #f9f9f9;
		border-radius: 1.2rem;
		align-items: center;
		justify-content: center;
		padding: 2.4rem;
		flex-direction: column;
		margin-bottom: 2rem;

		.amount-view {
			&__direction {
				display: flex;
				gap: 1.2rem;
				align-items: center;

				.direction__badge {
					display: flex;
					align-items: center;
					border-radius: 7.2rem;
					padding: 0.6rem 1.2rem;
					gap: 0.6rem;

					&--successful {
						background: #1ace37;

						p {
							color: white;
						}
					}

					&--failed {
						background: #ff0f00;
						p {
							color: white;
						}
					}

					p {
						font-weight: 600;
						font-size: 1.2rem;
					}

					figure {
						width: 1.2rem;
						height: 1.2rem;
						display: grid;
						place-items: center;

						svg {
							width: 100%;
							height: 100%;
						}
					}
				}

				.direction__green-checkmark {
					display: flex;
					align-items: center;

					&.failed {
						figure {
							width: 2rem;
							height: 2rem;
						}
					}

					justify-content: center;
					width: 3rem;
					height: 3rem;
					border-radius: 2rem;
					background-color: #fffbfb;

					box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
						0px 48px 64px -36px rgba(51, 51, 51, 0.08);

					figure {
						width: 1.2rem;
						height: 1.2rem;
						display: grid;
						place-items: center;

						svg {
							width: 100%;
							height: 100%;
						}
					}
				}
			}
			&__amount {
				font-size: 2.4rem;
				font-weight: 600;
				margin-top: 0.8rem;
			}

			&__actions {
				display: flex;
				align-items: center;
				gap: 1.6rem;
				justify-content: center;
				height: fit-content;
				margin-top: 2rem;
				// margin-bottom: 2rem;
				flex: 1;
				box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
					0px 48px 64px -36px rgba(51, 51, 51, 0.08);

				figure {
					width: 5rem;
					height: 5rem;
					display: grid;
					place-items: center;
					background: #fffbfb;
					box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
						0px 48px 64px -36px rgba(51, 51, 51, 0.08);
					border-radius: 2rem;
					cursor: pointer;

					svg {
						width: 2rem;
						height: 2rem;
					}
				}
			}
		}
	}

	&__content {
		border-radius: 1.2rem;
		margin-bottom: 3rem;
		display: flex;
		width: 100%;
		flex-direction: column;
		.content__title {
			display: flex;
			width: 100%;
			justify-content: space-between;

			span {
				h5 {
					font-weight: 600;
					font-size: 2rem;
					display: flex;
					align-items: center;
					// color: #000000;
				}

				p {
					font-weight: 400;
					font-size: 1.4rem;
					display: flex;
					align-items: center;
					color: #676767;
				}
			}

			figure {
				display: grid;
				place-items: center;
				width: 3.5rem;
				height: 3.5rem;

				svg {
					width: 100%;
					height: 100%;
				}
			}
		}

		.content__main {
			display: flex;
			flex-direction: column;

			.main__label {
				font-size: 1.6rem;
				display: flex;
				align-items: center;
				margin-top: 3.2rem;
				// color: #000000;
				margin-bottom: 1rem;
			}

			.main__account-details {
				display: flex;
				flex-direction: column;
				border: 0.7px solid #e5e6e6;
				border-radius: 1.2rem;
				width: 100%;
				position: relative;
				height: 100%;

				.panel {
					position: absolute;
					width: 100%;
					height: 100%;
					transition: transform 0.3s ease;
					transform: translateX(100%);

					&.active {
						transform: translateX(0);
					}
				}

				.account-details__item {
					display: flex;
					padding: 2.2rem 1.6rem;
					width: 100%;

					&.address {
						flex-direction: column;
						width: 100%;
						gap: 0.8rem;
						p {
							font-size: 1.2rem;
							width: 100%;
						}
						span {
							width: 100%;
							p {
								color: #676767;
								font-size: 1.2rem;
								width: 100%;
							}
						}
					}

					p {
						font-weight: 400;
						font-size: 1.4rem;
						display: flex;
						align-items: center;
						// color: #000000;
					}

					&:not(:last-child) {
						border-bottom: 0.6px solid #e5e6e6;
					}

					p:first-child {
						width: 40%;
					}

					p:last-child {
						width: 60%;
					}

					span {
						display: flex;
						width: 60%;
						justify-content: space-between;
						align-items: center;

						figure {
							display: grid;
							place-items: center;
							width: 2.5rem;
							cursor: pointer;
							height: auto;
							svg {
								width: 100%;
								height: 100;
							}
						}
					}
				}
			}
		}
	}
}

.history__content {
	.content__main {
		.main__wrap {
			.transaction__table {
				.table__main {
					.account_name {
						display: flex;
						align-items: center;
						gap: 1.6rem;
						span {
							background-color: #e9f5ff;
							display: grid;
							place-items: center;
							width: 4rem;
							height: 4rem;
							border-radius: 7.5rem;

							p {
								font-weight: 600;
								font-size: 1.2rem;
								display: flex;
								align-items: center;
								text-align: center;
								color: #014345;
							}
						}
					}

					.date-wrap__icons {
						display: flex;
						align-items: center;
						gap: 1rem;
						position: relative;

						&.disabled {
							opacity: 1;
							figure:first-child {
								svg {
									circle {
										fill: #ffe6cd;
									}
								}
							}
						}

						figure {
							width: 3rem;
							height: 3rem;
							display: grid;
							place-items: center;

							svg {
								width: 100%;
								height: 100%;
							}
						}
					}

					.more-wrap {
						align-items: flex-start;
						flex-direction: column;
						justify-content: space-between;
						position: absolute;
						gap: 1rem;
						padding: 1rem;
						background: #ffffff;
						box-shadow: 0px 24px 48px -8px rgba(51, 51, 51, 0.04),
							0px 48px 64px -36px rgba(51, 51, 51, 0.08);
						border-radius: 8px;
						display: flex;
						top: 4rem;
						width: max-content;
						right: 4rem;
						z-index: 111;
						transform-origin: top right;
						transform: scale(0);
						transition: transform 0.3s ease-in-out;

						&.show-drop {
							transform: scale(1);
							transition: transform 0.3s ease-in-out;
						}
						.more_item {
							display: flex;
							align-items: center;
							padding: 1.4rem;
							gap: 1rem;

							&:nth-child(2) {
								border-bottom: 0.4px solid #e5e6e6;
								border-top: 0.4px solid #e5e6e6;
							}
							figure {
								display: grid;
								place-items: center;
								height: 2rem;
								width: 2rem;

								svg {
									width: 100%;
									height: 100%;
								}
							}
						}
					}

					.disabled {
						opacity: 0.4;
					}

					.main {
						&__link-wrap {
							display: flex;
							align-items: center;
							gap: 0.6rem;

							figure {
								width: 2.5rem;
								height: 2.5rem;
								display: grid;
								place-items: center;

								svg {
									width: 100%;
									height: 100%;
								}
							}
						}

						&__date-wrap {
							display: flex;
							align-items: center;
							gap: 0.6rem;
						}
					}
				}
			}
		}
	}
}
