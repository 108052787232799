.no-record {
	height: 100%;
	width: 100%;
	background: #f7f7f7;
	border-radius: 0.8rem;
	margin-top: 1.8rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: bottom center;
	background-image: url('../../../../assets/images/graph_no_data.svg');

	.no-record__text {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 50%;
		gap: 0.2rem;

		h5 {
			font-weight: 600;
			font-size: 2rem;
			text-align: center;
			color: #014345;
		}

		p {
			font-weight: 400;
			font-size: 1.6rem;
			text-align: center;
			text-align: center;
			color: var(--base-background-black);
		}
	}
}

.no-record-mascot {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	background: #f9fafb;
	border: 1px solid #e5e6e6;
	border-radius: 1.2rem;
	position: relative;
	// margin-top: 2rem;

	.no-record-mascot__top {
		width: 100%;
		height: 50%;

		figure {
			width: 100%;
			height: 100%;
			display: flex;

			svg {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}

	.no-record-mascot__bottom {
		width: 100%;
		height: 100%;
		background: #ffffff;
		border-radius: 32px 32px 0px 0px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 2rem;

		h5 {
			font-weight: 600;
			margin-top: 10rem;
			font-size: 2rem;
			text-align: center;
			margin-bottom: -1rem;
			// color: #000000;
		}

		p {
			font-weight: 400;
			font-size: 1.6rem;
			width: 46rem;
			text-align: center;
			color: #676767;
		}
	}

	.button__wrap {
		display: flex;
		align-items: center;
		gap: 1rem;

		figure {
			width: 2rem;
			height: 2rem;
			display: grid;

			svg {
				width: 100%;
				height: 100%;
			}
		}
	}
	.mascot {
		position: absolute;
		top: 15%;
		right: 50%;
		transform: translateX(50%);

		figure {
			display: grid;
			place-items: center;
			width: 20rem;
			height: 20rem;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}
}
