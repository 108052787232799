.pagination {
  display: flex;
  flex-direction: column;
}
.pagination__controls {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination__page-count {
  display: flex;
  color: #1b1b1b;
}
.pagination__button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.5rem;
  font-size: 1.4rem;
  margin: 0 5px;
  color: #1b1b1b;
  cursor: pointer;
  transition: background-color 0.3s;
}
.pagination__button--disabled {
  opacity: 0.2;
  cursor: not-allowed;
}
.pagination__button--disabled svg {
  -webkit-text-stroke-color: black;
}/*# sourceMappingURL=index.css.map */