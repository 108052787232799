.modal-transaction-main-wrap .raven-modal-content-wrap {
  width: 38rem;
}
.modal-transaction-main-wrap .transaction-pin-wrap .title {
  font-weight: 700;
  color: #020202;
  font-size: 1.7rem;
}
.modal-transaction-main-wrap .transaction-pin-wrap .text {
  font-size: 1.3rem;
  color: #676767;
}
.modal-transaction-main-wrap .transaction-pin-wrap .form-pin {
  margin-top: 3rem;
}/*# sourceMappingURL=index.css.map */