.overview {
	display: flex;
	width: 100%;
	height: 100%;
	flex: 1;
	// background-color: red;
	flex-direction: column;

	.overview__actionbar {
		margin-top: 10rem;
		// position: absolute;
	}

	.overview__top-bar {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;

		.top-bar__greetings {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 0.2rem;

			h5 {
				font-style: normal;
				font-weight: 700;
				font-size: 2rem;
				line-height: 140%;
				// color: #000000;
			}

			p {
				font-weight: 400;
				font-size: 1.4rem;
				line-height: 140%;
				color: var(--base-background-black);
			}
		}
	}

	.overview__wallets-wrap {
		display: flex;
		width: 100%;
		margin-top: 3.5rem;
	}
	.overview__wallets {
		display: flex;
		width: 100%;
		// max-height: 23rem;
		gap: 2rem;

		.wallets__balance {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 1.6rem;
			// width: 30%;
			height: min-content;
			flex: 0 0 30%;
			border-radius: 1.2rem;
			background: white;
			border: 1px solid #eeeeee;
			border-radius: 1.2rem;

			.balance__currency {
				display: flex;
				align-items: center;
				width: 100%;
				span {
					width: 6.5rem;
					height: 4rem;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					gap: 0.4rem;

					p {
						font-style: normal;
						font-weight: 400;
						font-size: 1.4rem;
						line-height: 140%;
					}

					&.active {
						background: #f7f8f7;
						border-radius: 0.8rem;

						p {
							font-weight: 600;
							color: #014345;
						}
					}
				}
			}

			.balance__description {
				display: flex;
				gap: 1rem;
				width: 100%;
				align-items: center;
				margin-top: 1.6rem;

				figure {
					width: 6%;
					display: grid;
					place-items: center;
					height: auto;

					svg {
						width: 100%;
						height: auto;
					}
				}
				p {
					font-style: normal;
					font-weight: 600;
					font-size: 1.2rem;
					line-height: 140%;
					display: flex;
					align-items: center;
					color: #676767;
				}
			}

			.balance__amount {
				margin-top: 1rem;
				h5 {
					font-weight: 700;
					font-size: 2.4rem;
					line-height: 140%;
					display: flex;
					align-items: center;
					// color: #000000;
				}
			}

			.balance__view-all-wallet {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				margin-top: 2rem;
				cursor: pointer;

				&:hover {
					span {
						figure {
							transform: translateX(3px);
						}
					}
				}

				span {
					display: flex;
					font-weight: 600;
					align-items: center;
					font-size: 1.2rem;
					line-height: 140%;
					display: flex;
					align-items: center;
					color: #755ae2;
					gap: 0.6rem;

					figure {
						display: grid;
						place-items: center;
						width: 10%;
						transition: transform 300ms ease-in-out;

						svg {
							width: 100%;
						}
					}
				}

				div {
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					padding: 0.8rem 1.2rem;
					background: var(--raven-purple-shade-20, #f0eeff);
					border-radius: 7.2rem;

					gap: 0.4rem;

					figure {
						width: 1.2rem;
						height: 1.2rem;
						display: grid;
						place-items: center;

						svg {
							width: 100%;
							height: 100%;
						}
					}

					p {
						font-weight: 700;
						font-size: 1rem;
						line-height: 140%;
						display: flex;
						align-items: center;
						text-align: center;
						color: var(--ravenprimarypurple, #755ae2);
					}
				}
			}
		}

		.wallets__statistics {
			display: grid;
			height: 100%;
			gap: 2rem;
			width: 70%;
			grid-template-columns: repeat(3, 1fr);

			.statistics__item {
				background: #ffffff;
				border: 1px solid #eeeeee;
				border-radius: 1.2rem;
				padding: 2.7rem 1.2rem;
				padding-bottom: 1.7rem;
				display: flex;
				align-items: flex-start;
				flex-direction: column;
				position: relative;

				figure {
					width: 4.6rem;
					height: auto;
					margin-bottom: auto;

					svg {
						width: 100%;
						height: auto;
					}
				}

				h5 {
					font-weight: 700;
					font-size: 2.4rem;
					// line-height: 140%;
					display: flex;
					align-items: center;
					// color: #000000;
				}
				p {
					font-weight: 400;
					font-size: 1.6rem;
					line-height: 200%;
					display: flex;
					align-items: center;
					color: var(--base-background-black);
					// margin-top: 2rem;
				}

				&--request-settlement {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					margin-top: 2rem;
					position: absolute;
					top: 2rem;
					right: 2rem;
					cursor: pointer;

					&:hover {
						span {
							figure {
								transform: translateX(3px);
							}
						}
					}

					span {
						display: flex;
						font-weight: 600;
						align-items: center;
						font-size: 1.2rem;
						line-height: 140%;
						display: flex;
						align-items: center;
						color: #755ae2;
						gap: 0.6rem;

						figure {
							display: grid;
							place-items: center;
							width: 10%;
							transition: transform 300ms ease-in-out;

							svg {
								width: 100%;
							}
						}
					}

					div {
						display: flex;
						flex-direction: row;
						justify-content: center;
						align-items: center;
						padding: 0.8rem 1.2rem;
						background: var(--raven-purple-shade-20, #f0eeff);
						border-radius: 7.2rem;

						gap: 0.4rem;

						figure {
							width: 1.2rem;
							height: 1.2rem;
							display: grid;
							place-items: center;

							svg {
								width: 100%;
								height: 100%;
							}
						}

						p {
							font-weight: 700;
							font-size: 1rem;
							line-height: 140%;
							display: flex;
							align-items: center;
							text-align: center;
							color: var(--ravenprimarypurple, #755ae2);
						}
					}
				}
			}
		}
	}

	.overview__charts {
		width: 100%;
		display: flex;
		// flex: 1;
		// max-height: 80%;
		// background-color: red;
		height: min-height;
		max-height: calc(100vh - 50%);
		padding-top: 2.4rem;
		gap: 2rem;
		padding-bottom: 2rem;

		.charts__guage {
			display: flex;
			flex-direction: column;
			background: #ffffff;
			flex: 0 0 30%;
			border: 1px solid #e3e3e3;
			border-radius: 1.2rem;
			padding: 1.6rem;

			.guage__wrap {
				width: 100%;
				background: #f9f9f9;
				display: flex;
				// flex: 1;
				position: relative;
				flex-direction: column;
				align-items: center;
				border-radius: 1.2rem 1.2rem 0px 0px;
				padding-top: 1.7rem;
				padding-right: 1.2rem;
				padding-left: 1.2rem;

				.wrap__filter {
					display: flex;
					align-items: center;
					width: 100%;
					justify-content: space-between;
					margin-bottom: 3rem;

					span {
						// width: 6.5rem;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;
						padding: 1.1rem 1.6rem;

						p {
							font-style: normal;
							font-weight: 400;
							font-size: 1.4rem;
							line-height: 140%;
						}

						&.active {
							background: white;
							border-radius: 0.8rem;

							p {
								font-weight: 600;
								color: #014345;
							}
						}
					}
				}

				.wrap__guage {
					width: 110%;
					height: 100%;
					padding: 0;
					overflow: hidden;
					margin-bottom: -5%;
					position: relative;

					#gauge {
						width: 100%;
						height: 100%;
						padding: 0;
						margin: 0;
						display: grid;
						// overflow: hidden;
						place-items: center;
						// background-color: red;

						svg {
							width: 100%;
							display: grid;
							place-items: center;
						}
					}
				}
				.guage-drop-down-wrap {
					border-radius: 72px;
					padding: 0.8rem 1.2rem;
					background: #0b8376;
					display: flex;
					position: absolute;
					bottom: 0;
					bottom: 2rem; /* Move it down by 50% of the container's height */
					left: 50%; /* Move it horizontally by 50% of the container's width */
					transform: translate(-50%, -50%); /* Center the div */
					height: min-content;
					gap: 0.4rem;
					color: white;
					cursor: pointer;
					box-shadow: 0px 48px 64px -36px rgba(51, 51, 51, 0.08),
						0px 24px 48px -8px rgba(51, 51, 51, 0.04);
					svg {
						path {
							stroke: white;
						}
					}

					&__main {
						bottom: 0;
						left: 50%;
						min-width: 50%;
						position: absolute;
						transform: translate(-50%, 100%);
						height: min-content;
						z-index: 100%;
						box-shadow: 0px 48px 64px -36px rgba(51, 51, 51, 0.08),
							0px 24px 48px -8px rgba(51, 51, 51, 0.04);

						.guage-drop-active {
							border-radius: 8px;
							width: 100%;
							border: none;
							background: var(--grey-shade-10, #f7f8f7);
						}
					}
				}
			}

			.guage__stats {
				display: flex;
				align-items: flex-start;
				gap: 1.2rem;
				flex-direction: column;
				margin-top: 2rem;
				overflow-y: auto;

				.stats__item {
					display: flex;
					width: 100%;
					align-items: center;
					justify-content: space-between;
					padding: 1rem 0.8rem;
					background: #ffffff;
					border: 0.6px solid #e5e6e6;
					border-radius: 0.8rem;

					.item__name {
						display: flex;
						gap: 0.8rem;
						align-items: center;

						p:first-child {
							width: 1.4rem;
							height: 1.4rem;
							border-radius: 50%;
						}

						p:last-child {
							font-weight: 400;
							font-size: 1.2rem;
							line-height: 140%;
							display: flex;
							align-items: center;
							color: #1b1b1b;
						}
					}

					.item__count {
						display: flex;
						flex-direction: row;
						justify-content: center;
						align-items: center;
						padding: 0.8rem 1.2rem;
						border-radius: 50%;

						p {
							font-weight: 600;
							font-size: 1.2rem;
							line-height: 140%;
							text-align: center;
						}
					}

					&.green {
						.item__name {
							p:first-child {
								background: #1ace37;
								border: 1px solid #1ace37;
							}
						}

						.item__count {
							background: #f7f8f7;

							p {
								color: #1ace37;
							}
						}
					}

					&.red {
						.item__name {
							p:first-child {
								background: #ff0f00;
								border: 1px solid #ff0f00;
							}
						}

						.item__count {
							background: #fff5f5;

							p {
								color: #ff0f00;
							}
						}
					}

					&.orange {
						.item__name {
							p:first-child {
								background: #ea872d;
								border: 1px solid #ea872d;
							}
						}

						.item__count {
							background: #fff6ed;

							p {
								color: #ea872d;
							}
						}
					}
				}
			}
		}

		.charts__graph {
			background: #ffffff;
			height: 100%;
			width: 100%;
			display: flex;
			flex: 1;
			flex-direction: column;
			// background-color: yellow;
			border: 1px solid #eeeeee;
			border-radius: 1.2rem;
			padding: 2.8rem 2.4rem;

			.graph__top {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.top__title {
					display: flex;
					flex-direction: column;
					gap: 1.2rem;

					p:first-child {
						font-style: normal;
						font-weight: 700;
						font-size: 1.8rem;
						line-height: 140%;
						// color: #000000;
					}

					.title__graph-indicator {
						display: flex;
						gap: 1.6rem;
						align-items: center;
						margin-top: 1.4rem;

						span {
							display: flex;
							align-items: center;
							gap: 0.8rem;

							&:is(:first-child) {
								p:first-child {
									width: 1.8rem;
									height: 1.8rem;
									background: #014345;
									border-radius: 0.4rem;
								}
							}

							&:is(:last-child) {
								p:first-child {
									width: 1.8rem;
									height: 1.8rem;
									background: #9be5c9;
									border-radius: 0.4rem;
								}
							}
						}
					}
				}

				.top__filter {
					display: flex;
					gap: 1rem;
					padding: 1rem 1rem;
					border: 1px solid #e3e3e3;
					border-radius: 200px;
					align-items: center;

					span {
						gap: 0.8rem;
						align-items: center;
						display: flex;

						figure {
							display: grid;
							cursor: pointer;
							place-items: center;
						}
					}
				}
			}

			.graph__no-record {
				height: 89%;
				background: #f7f7f7;
				border-radius: 0.8rem;
				margin-top: 1.8rem;
				display: flex;
				align-items: center;
				justify-content: center;
				background-repeat: no-repeat;
				background-size: contain;
				background-position: bottom center;

				background-image: url('../../../../assets/images/graph_no_data.svg');

				.no-record__text {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					flex-direction: column;
					width: 50%;
					gap: 0.2rem;

					h5 {
						font-weight: 600;
						font-size: 2rem;
						line-height: 160%;
						text-align: center;
						color: #014345;
					}

					p {
						font-weight: 400;
						font-size: 1.6rem;
						line-height: 160%;
						text-align: center;
						text-align: center;
						color: var(--base-background-black);
					}
				}
			}

			.graph__main {
				width: 100%;
				height: 100%;
				// flex: 1;
				// background-color: #755ae2;
				margin-top: 1.8rem;
			}
		}
	}
}

.drop-box-guage {
	border-radius: 72px;
	padding: 0.8rem 1.2rem;
	background: #fff;
	box-shadow: 0px 48px 64px -36px rgba(51, 51, 51, 0.08),
		0px 24px 48px -8px rgba(51, 51, 51, 0.04);
}

.request-settlement-modal {
	display: flex;
	flex-direction: column;
	&__title {
		display: flex;
		flex-direction: column;
		margin-bottom: 2rem;

		h6 {
			font-size: 20px;
			font-weight: 600;
			line-height: 32px;
			text-align: left;
		}

		p {
			color: var(--BaseBlack-Base-BG-Dark, #676767);
			font-size: 1.4rem;
			font-style: normal;
			font-weight: 400;
		}
	}

	&__collection-balance {
		display: flex;
		height: 3.2rem;
		padding: 0.8rem 1.2rem;
		justify-content: center;
		align-items: center;
		gap: 1rem;
		flex-shrink: 0;
		border-radius: 7.2rem;
		background: var(--RAVEN-Primary-PURPLE-20, #f0eeff);
		width: min-content;
		margin-bottom: 2.4rem;

		p {
			color: var(--RAVEN-Primary-PURPLE-Primary-PURPLE-100, #755ae2);
			text-align: center;
			font-size: 1.2rem;
			font-style: normal;
			text-wrap: nowrap;
			font-weight: 600;
		}
	}

	&__settlement-fee {
		display: flex;
		height: 3.2rem;
		padding: 0.8rem 1.2rem 0.8rem 0.6rem;
		justify-content: center;
		align-items: center;
		gap: 0.4rem;
		border-radius: 7.2rem;
		background: var(--Primary-ORANGE-20, #fff6ed);
		align-items: center;
		gap: 0.2rem;
		width: min-content;
		margin-top: 2rem;

		p {
			color: var(--Primary-ORANGE-100, #ea872d);
			text-align: center;
			font-size: 1.2rem;
			font-style: normal;
			font-weight: 500;
			text-wrap: nowrap;
		}

		figure {
			width: 1.5rem;
			height: 1.5rem;
			display: grid;
			place-items: center;
			svg {
				width: 100%;
				height: 100%;
				g path {
					stroke: #ea872d;
				}
			}
		}
	}
}
