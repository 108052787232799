.verification-wrap-box {
  width: 100%;
  margin-top: 3rem;
}
.verification-wrap-box .text-box-verify {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 3rem;
}
.verification-wrap-box .text-box-verify .small-title {
  color: #676767;
  font-size: 1.4rem;
  font-weight: 400;
}
.verification-wrap-box .text-box-verify .big-title {
  font-size: 2.1rem;
  font-weight: 700;
}
.verification-wrap-box .verification-summary {
  margin: auto 0rem;
  margin-bottom: 4rem;
  width: 100%;
  flex-direction: column;
  display: flex;
  border-radius: 1.2rem;
}
.verification-wrap-box .verification-summary > * {
  padding: 1.7rem 1.5rem;
}
.verification-wrap-box .verification-summary .top-info-box .text {
  text-transform: uppercase;
}
.verification-wrap-box .verification-summary .middle-info-box .text {
  font-weight: 500;
  line-height: 2.1rem;
  display: inline-block;
}
.verification-wrap-box .verification-summary .middle-info-box .text span {
  color: #755ae2;
  cursor: pointer;
  margin-right: 0.8rem;
}
.verification-wrap-box .verification-summary .bottom-info-box {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  cursor: pointer;
}
.verification-wrap-box .verification-summary .bottom-info-box:hover .icon {
  transform: translateX(0.45rem);
}
.verification-wrap-box .verification-summary .bottom-info-box .img-box {
  width: 2.5rem;
  height: 2.5rem;
}
.verification-wrap-box .verification-summary .bottom-info-box .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.verification-wrap-box .verification-summary .bottom-info-box .text {
  font-weight: 600;
}
.verification-wrap-box .verification-summary .bottom-info-box .icon {
  margin-left: auto;
  color: #755ae2;
  transition: all 0.3s ease-in-out;
}
.verification-wrap-box .form {
  margin: auto 0rem;
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: 2rem;
}
.verification-wrap-box .form .verify-name-box {
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-top: -1rem;
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.3s;
  -moz-transition: max-height 0.3s;
  width: 100%;
}
.verification-wrap-box .form .verify-name-box > *:first-child .label-check {
  border-radius: 50%;
  transform: scale(0.8);
}
.verification-wrap-box .form .verify-name-box .text {
  font-weight: 600;
  font-size: 1.3rem;
  margin-left: 0.5rem;
}
.verification-wrap-box .form .verify-name-box-show {
  max-height: 5rem;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
}
.verification-wrap-box .form .btn-submit {
  margin-top: 2rem;
}
.verification-wrap-box .form .label-class-span {
  font-weight: 700;
  text-transform: uppercase;
  opacity: 0.5;
}
.verification-wrap-box .form .label-class-span-active {
  opacity: 1;
}
.verification-wrap-box .form .how-text {
  display: flex;
  margin-top: -1rem;
  color: #ea872d;
  cursor: pointer;
  align-self: flex-start;
}
.verification-wrap-box .form .how-text .img-box {
  width: 1.5rem;
  height: 1.5rem;
}
.verification-wrap-box .form .how-text .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.verification-wrap-box .form .how-text .text {
  margin-left: 0.5rem;
  font-size: 1.2rem;
}
.verification-wrap-box .form .show-option-box {
  display: flex;
  flex-direction: column;
}
.verification-wrap-box .form .show-option-box .upload-box {
  margin-top: 2rem;
}
.verification-wrap-box .form .show-option-box .title {
  color: #676767;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 42em) {
  .verification-wrap-box .form .show-option-box .title {
    margin-top: 1rem;
  }
}
.verification-wrap-box .form .show-option-box .select-wrap {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  max-height: calc(100vh - 45rem);
  overflow-y: scroll;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.verification-wrap-box .form .show-option-box .select-wrap::-webkit-scrollbar:horizontal {
  display: none;
}
.verification-wrap-box .form .show-option-box .select-wrap::-webkit-scrollbar {
  width: 0.5rem;
}
.verification-wrap-box .form .show-option-box .select-wrap::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.verification-wrap-box .form .show-option-box .select-wrap::-webkit-scrollbar-thumb {
  background: #020202;
  border-radius: 30rem;
}
.verification-wrap-box .form .show-option-box .select-wrap::-webkit-scrollbar-thumb:hover {
  background: #020202;
}
.verification-wrap-box .form .show-option-box .select-wrap .box {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 0.8rem;
  border: 0.1rem solid #cccccc;
  padding: 1.6rem 1.3rem 1.6rem 1.6rem;
  cursor: pointer;
  gap: 0.4rem;
  transition: all 0.3s ease-in-out;
}
.verification-wrap-box .form .show-option-box .select-wrap .box .name {
  font-size: 1.3rem;
  color: #676767;
}
@media only screen and (max-width: 42em) {
  .verification-wrap-box .form .show-option-box .select-wrap .box .name {
    font-size: 1.25rem;
  }
}
.verification-wrap-box .form .show-option-box .select-wrap .box .des {
  font-weight: 700;
  font-size: 1.6rem;
  color: #020202;
}
@media only screen and (max-width: 42em) {
  .verification-wrap-box .form .show-option-box .select-wrap .box .des {
    font-size: 1.45rem;
  }
}
.verification-wrap-box .form .show-option-box .select-wrap .box .check-wrap {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2.1rem;
  height: 2.1rem;
  border-radius: 50%;
  border: 0.1rem solid #cccccc;
}
.verification-wrap-box .form .show-option-box .select-wrap .box .check-wrap .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.8rem;
  height: 0.8rem;
  visibility: hidden;
}
.verification-wrap-box .form .show-option-box .select-wrap .box-active {
  border: 0.1rem solid #020202;
}
.verification-wrap-box .form .show-option-box .select-wrap .box-active .check-wrap {
  border: 0.1rem solid #020202;
  background-color: #020202;
}
.verification-wrap-box .form .show-option-box .select-wrap .box-active .check-wrap .icon {
  visibility: visible;
  color: #ffffff;
}
.verification-wrap-box .form-business .two-wrap-business {
  display: grid;
  grid-template-columns: 10rem 1fr;
  grid-column-gap: 1.5rem;
}
.verification-wrap-box .form-business .two-equal-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1.5rem;
}
.verification-wrap-box .form-business .accountPurpose {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: flex-start;
}
.verification-wrap-box .form-business .purposeContain {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 1.5rem;
  /* background-color: red; */
  width: 100%;
  margin-bottom: 3rem;
  align-items: center;
}
.verification-wrap-box .form-business .purposeContain .child_wrap {
  padding: 1.5rem 1.3rem;
  background-color: #f7f8f7;
  border-radius: 4rem;
  font-size: 1.35rem;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.3s;
  text-align: center;
}
.verification-wrap-box .form-business .purposeContain .child_wrap_active {
  background-color: #e5e6e6;
  border: 0.15rem solid #020202;
}
.verification-wrap-box .form-business .purposeContain .child_wrap:hover {
  transform: translateY(-0.1rem);
}
.verification-wrap-box .form-business .purposeContain .child_wrap span {
  display: inline-block;
  /* font-weight: 800; */
}
.verification-wrap-box .form-business .purposeContain .child_wrap_active span {
  font-weight: 800;
  color: #020202;
}
.verification-wrap-box .form-business .purposeContain .checkbox_wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.verification-wrap-box .form-business .purposeContain .checkbox_wrap .input_check {
  display: none;
}
.verification-wrap-box .form-business .purposeContain .checkbox_wrap .label {
  display: flex;
  align-items: center;
}

.reusable-verified-pending-wrap {
  min-height: 21rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  border-radius: 1.2rem;
}
.reusable-verified-pending-wrap .failed-btn {
  background-color: transparent !important;
  border-width: 0.15rem;
}
.reusable-verified-pending-wrap .failed-btn span {
  font-weight: 700;
}
.reusable-verified-pending-wrap .img-wrap .img-box {
  width: 4rem;
  height: 4rem;
}
.reusable-verified-pending-wrap .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.reusable-verified-pending-wrap .text {
  text-align: center;
  width: 75%;
}/*# sourceMappingURL=styles.css.map */