.omnisearch {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 93rem;
  max-height: 64rem;
  top: 52px;
  border-radius: 0px 0px 16px 16px;
  overflow-y: scroll;
  background: #fff;
  /* Raven/Shadow LG */
  box-shadow: 0px 48px 64px -36px rgba(51, 51, 51, 0.08), 0px 24px 48px -8px rgba(51, 51, 51, 0.04);
  padding: 0.8rem 1.6rem;
  padding-bottom: 3rem;
}
.omnisearch .empty_screen_wrap {
  height: 30rem;
  margin-top: 2rem;
}
.omnisearch__tabs {
  display: flex;
  align-items: center;
  height: 5rem;
  width: 100%;
}
.omnisearch__tabs--item {
  display: flex;
  height: 44px;
  padding: 1.6rem;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  cursor: pointer;
}
.omnisearch__tabs--item span {
  color: var(--raven-black-600, #646464);
  font-size: 1.4rem;
  font-style: normal;
}
.omnisearch__tabs--item.active {
  background: var(--grey-shade-10, #f7f8f7);
}
.omnisearch__tabs--item.active span {
  color: var(--primary-darkgreen-100, #014345);
  text-align: center;
  font-weight: 700;
}
.omnisearch__showing-n-result-of {
  margin-top: 2rem;
}
.omnisearch__showing-n-result-of p {
  color: var(--raven-black-500, #8b8b8b);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
}
.omnisearch__showing-n-result-of p b {
  color: var(--base-black-base-black, #1b1b1b);
  font-weight: 600;
}
.omnisearch__empty-search-results {
  display: flex;
  flex-direction: column;
  padding: 1.6rem 38.6rem 1rem 1.6rem;
  align-self: stretch;
  border-bottom: 0.8px solid var(--ravengreyshade-50, #e5e6e6);
  gap: 1.2rem;
}
.omnisearch__empty-search-results p:first-child {
  color: var(--raven-black-500, #8b8b8b);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
}
.omnisearch__empty-search-results p:last-child {
  color: var(--raven-black-500, #8b8b8b);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
}
.omnisearch__search-results {
  display: flex;
  flex-direction: column;
  padding: 1.6rem 1rem 1rem 1.6rem;
  align-self: stretch;
  width: 100%;
  gap: 1.2rem;
}
.omnisearch__search-results p:first-child {
  color: #000;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
}
.omnisearch__search-results .search-results {
  width: 100%;
  align-self: stretch;
  flex: 1;
  color: var(--base-black-base-bg-dark, #676767);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
}
.omnisearch__search-results .search-results__item {
  display: flex;
  flex-direction: column;
  gap: 0.9rem;
  padding: 1rem 0;
  cursor: pointer;
  border-bottom: 0.8px solid var(--ravengreyshade-50, #e5e6e6);
}
.omnisearch__search-results .search-results b {
  color: var(--base-black-base-black, #1b1b1b);
}
.omnisearch__search-results .search-results .succesfull {
  color: var(--success-100, #1ace37);
}
.omnisearch__search-results .search-results .pending {
  color: var(--success-100, #ea872d);
}
.omnisearch__single-tab {
  width: 100%;
}
.omnisearch__single-tab--no-result {
  display: flex;
  width: 100%;
  height: 60rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
}
.omnisearch__single-tab--no-result figure {
  display: grid;
  width: 15rem;
  height: 15rem;
}
.omnisearch__single-tab--no-result figure svg {
  width: 100%;
  height: 100%;
}
.omnisearch__single-tab--no-result span {
  color: var(--raven-black-600, #646464);
  text-align: center;
  width: 32%;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.omnisearch__single-tab--no-result span p {
  color: var(--base-black-base-black, #1b1b1b);
  font-weight: 400;
  line-height: 140%;
  font-size: 2.4rem;
}/*# sourceMappingURL=index.css.map */